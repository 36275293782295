import * as React from "react"
const EuropeComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    fill="none"
    viewBox="0 -4 28 28"
    {...props}
  >
    <g clipPath="url(#a)">
      <rect width={28} height={20} fill="#fff" rx={2} />
      <mask
        id="b"
        width={28}
        height={20}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "alpha",
        }}
      >
        <rect width={28} height={20} fill="#fff" rx={2} />
      </mask>
      <g mask="url(#b)">
        <path fill="#043CAE" d="M0 0h28v20H0z" />
        <path
          fill="#FFD429"
          fillRule="evenodd"
          d="M13.057 4.276 14 4l.943.276-.276-.943.276-.942-.943.276-.943-.276.276.942-.276.943Zm0 13.334.943-.277.943.277-.276-.943.276-.943L14 16l-.943-.276.276.943-.276.942Zm7.61-6.943-.943.276L20 10l-.276-.943.943.276.942-.276-.276.943.277.943-.943-.276Zm-14.277.276.943-.276.943.276L8 10l.276-.943-.943.276-.942-.276.276.943-.276.943Zm13.383-3.61-.942.276.276-.942-.276-.943.942.276.943-.276-.276.943.276.942-.943-.276Zm-12.49 6.943L8.227 14l.943.276-.276-.943.276-.943-.943.277-.942-.277.276.943-.276.943Zm10.05-9.383-.942.276.276-.942-.277-.943.943.276.943-.276-.276.943.276.942-.943-.276Zm-7.61 11.823.944-.276.943.276-.277-.943.277-.942-.943.276-.943-.276.276.943-.276.942ZM19.774 14l-.942.276.276-.943-.276-.943.942.277.943-.277-.276.943.276.943-.943-.276ZM7.283 7.61l.943-.277.943.276-.276-.942.276-.943L8.226 6l-.942-.276.276.943-.276.942Zm10.05 8.83-.942.276.276-.943-.277-.942.943.276.943-.276-.276.943.276.942-.943-.276ZM9.723 5.17l.944-.277.943.276-.277-.942.277-.943-.943.276-.943-.276.276.943-.276.942Z"
          clipRule="evenodd"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <rect width={28} height={20} fill="#fff" rx={2} />
      </clipPath>
    </defs>
  </svg>
)
export default EuropeComponent
