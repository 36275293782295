import {  useState,useEffect } from 'react';
import ExperienceComponentMobile from '../../Mobile/Components/ExperienceComponentMobile';

import { useLocation } from 'react-router-dom';
import { useDecryptUserId } from '../../../services/TalentCards/useDecryptUserId';

const ThreeMobileViewComponent = () => {
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [error, setError] = useState(null);

  const [loadingList, setLoadingList] = useState(true);
  const [selectedEmployeeId, setselectedEmployeeId] = useState(null);
  const { decryptUserId, loading: decryptLoading } = useDecryptUserId();
  const location = useLocation();
  const {
    empleadoDetails,
    userInfoSSFF,
    selectedEmployeeId: encryptedUserId,
  } = location.state || {};
  // Cargamos empleado y empleadoDetails desde location.state si están presentes
  const empleado = location.state?.empleado || null;

  useEffect(() => {
    const fetchDecryptedId = async () => {
      try {
        if (encryptedUserId) {
          const decryptedId = await decryptUserId(encryptedUserId);
          console.log('User ID desencriptado:', decryptedId);
          setselectedEmployeeId(decryptedId);
        }
      } catch (err) {
        console.error('No se pudo desencriptar el ID:', err.message);
      }
    };

    fetchDecryptedId();
  }, [encryptedUserId, decryptUserId]);

  return (
    <ExperienceComponentMobile
      empleadoDetails={empleadoDetails}
      selectedEmployeeId={selectedEmployeeId}
      userInfoSSFF={userInfoSSFF}
      empleado={empleado}
    />
  );
};

export default ThreeMobileViewComponent;

