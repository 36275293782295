// CardEmployeeMobile.js
import ImageComponent from '../../assests/mainPageComponent/cardEmployee/ImageProfile';
import { useNavigate } from 'react-router-dom';
import '../Styles/cardMobile.css';
import { FetchEmployeeDetails } from '../../../services/TalentCards/FetchEmployeeDetails';
import React, { useState, useEffect } from 'react';
import ClusterComponentCardMobile from './ClusterComponentCardMobile';
import userImg from '../../../images/user-account.png';
import CryptoJS from 'crypto-js';

function CardEmployeeMobile({ empleado, onSelect, onOpenModal, isSelected,obOptions,idToken }) {
  const navigate = useNavigate();
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [error, setError] = useState(null);
  const [empleadoDetails, setEmpleadoDetails] = useState(null);

  const user_id = empleado.userId;
  const secretKey = process.env.REACT_APP_TALENT_APP;

  // Encriptar y codificar el user_id
  const encrypted = CryptoJS.AES.encrypt(user_id, secretKey).toString();
  const encryptedUserId = encodeURIComponent(btoa(encrypted));

  useEffect(() => {
    if (!user_id) return;

    const loadEmpleadoDetails = async () => {
      setLoadingDetails(true);
      try {
        const data = await FetchEmployeeDetails(encryptedUserId,idToken);
        setEmpleadoDetails(data);
      } catch (error) {
        console.error(
          `Error al obtener los datos del empleado con ID ${user_id}:`,
          error.message
        );
        setError(error);
      } finally {
        setLoadingDetails(false);
      }
    };

    loadEmpleadoDetails();
  }, [user_id]);

  
  const handleCardDoubleClick = (event) => {
    if (!event.target.closest('.btn-fichas')) {
    
      navigate(`/talentCards/${encryptedUserId}`, {
        state: { empleado },
      });
    }
  };

 

  const truncateText = (text, maxLength) => {
    if (!text) {
      return ''; // Retorna un string vacío si el texto es undefined o null
    }
    return text.length > maxLength
      ? text.substring(0, maxLength) + '...'
      : text;
  };

  const maxLength = 18;
  const position = empleado.empInfo?.jobInfoNav?.results?.[0]?.jobTitle || '';
  const truncatedJobTitle = truncateText(position, maxLength);

  const maxLengthName = 20;
  const displayName = empleado.displayName;
  const truncatedName = truncateText(displayName, maxLengthName);

  // Obtener la cadena de la imagen en Base64
  const base64Image = empleado.empInfo?.photoNav?.results?.[0]?.photo;

  // Construir la URL de la imagen en Base64
  const imageUrl = base64Image
    ? `data:image/jpeg;base64,${base64Image}`
    : userImg;

  const positions = [
    { key: 'cluster_ceo', label: 'CEO' },
    { key: 'cluster_b2c_growth', label: 'B2C' },
    { key: 'cluster_b2b_growth', label: 'B2B' },
    { key: 'cluster_technology', label: 'TEC' },
    { key: 'cluster_enablers', label: 'EN' },
    { key: 'cluster_pm_transversal', label: 'PM' }

  ].filter((position) => empleadoDetails && empleadoDetails[position.key]);



   // Filtrado basado en OB
   const employeeOB = empleado.empInfo?.jobInfoNav?.results?.[0]?.businessUnitNav?.name;
   const isOBMatched = obOptions.some(ob => ob.value === employeeOB);
 
   if (!isOBMatched) {
     return null; 
   }



  return (
    <div
      className={`card-mobile  mb-2  rounded-4 shadow ${
        isSelected ? 'selected' : ''
      }`}
      onClick={handleCardDoubleClick}
    >
      <div className="card-body-mobile ms-3 mt-3 d-flex align-items-center">
        <div className="Imagen-mobile rounded-circle me-4 mb-2">
          {imageUrl && (
            <img
              src={imageUrl}
              alt="Foto de empleado"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '50%',
              }}
            />
          )}
        </div>
        <div className="me-auto ">
          <h5 className="card-title-mobile text-primary fs-6 mb-0 ">
            {truncatedName}
          </h5>
          <p className="card-text-mobile fs-6 mb-0">{truncatedJobTitle}</p>
          <div className="mt-1 d-flex align-items-start mb-1">
            <ClusterComponentCardMobile
              positions={positions}
              empleadoDetails={empleadoDetails}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardEmployeeMobile;
