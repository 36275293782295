import React, { useEffect, useState } from 'react';
import { validateFields } from '../validateFields';
import NewJobClassificationForm from './newJobClassificationForm';
import NewPositionForm from './newPositionForm';
import { RadioGroup, SwitchGroup } from '../inputs/radioGroup';

export const OrganizationalChangePeople = ({ formData, ticketData, handleInputChange, errors = {}, setErrors }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [showManagerInput, setShowManagerInput] = useState(false);
  const [showRoleChangeInput, setShowRoleChangeInput] = useState(false);
  const [showNewDutiesInput, setShowNewDutiesInput] = useState(false);
  const [showCategoryChangeInput, setShowCategoryChangeInput] = useState(false);

  console.log('ticketData en OrganizationalChangePeople', ticketData);

  // Establecer valores predeterminados para los campos radio
  useEffect(() => {
    if (!formData.newJobClasification) handleInputChange('newJobClasification', 'no');
    if (!formData.jcCreated) handleInputChange('jcCreated', 'No');
    if (!formData.newJobPosition) handleInputChange('newJobPosition', 'no');
    if (!formData.newPositionCreated) handleInputChange('newPositionCreated', 'No');
    if (!formData.employeeAssignedToNewPosition) handleInputChange('employeeAssignedToNewPosition', 'No');
    if (!formData.positionChangeRequired) handleInputChange('positionChangeRequired', 'No');
  }, []);

  // Manejar cambios en los radios
  const handleRadioChange = (fieldName, selectedValue) => {
    handleInputChange(fieldName, selectedValue);

    // Resetear campos dependientes si se selecciona "No"
    if (selectedValue === 'No') {
      if (fieldName === 'newJobClasification') {
        handleInputChange('newJobClasificationDetails', '');
      }
      if (fieldName === 'newJobPosition') {
        handleInputChange('newJobPositionDetails', '');
      }
    }
  };

  useEffect(() => {
    // Validar campos al iniciar y al cambiar
    const fields = {
      organizationalChange: formData.organizationalChange?.value || '',
      newDepartments: formData.newDepartments?.value || '',
      newManager: formData.newManager?.value || '',
      newAsigneeManager: formData.newAsigneeManager,
      roleChange: formData.roleChange?.value || '',
      roleChangeDetails: formData.roleChangeDetails?.content?.[0]?.content?.[0]?.text || '',
      newDuties: formData.newDuties?.value || '',
      newDutiesDetails: formData.newDutiesDetails?.content?.[0]?.content?.[0]?.text || '',
      categoryChange: formData.categoryChange?.value || '',
      categoryChangeDetails: formData.categoryChangeDetails?.content?.[0]?.content?.[0]?.text || '',
      //
      hrbp: formData.hrbp || ticketData?.customfield_10394 || '',
      businessUnit: ticketData?.customfield_10395 || '',
      division: ticketData?.customfield_10396 || '',
      department: ticketData?.customfield_10349 || '',
      costCenter: ticketData?.customfield_10397 || '',
      objectivesResult: ticketData?.customfield_10398 || '',
      newJobClasification: ticketData?.customfield_10399 || '',
      jcTitle: formData.jcTitle,
      jcLevel: formData.jcLevel,
      jcPayGrade: formData.jcPayGrade,
      jcTelefónicaLevel: formData.jcTelefónicaLevel,
      jcTelefónicaGrade: formData.jcTelefónicaGrade,
      legalEntity: formData.legalEntity,
      jobFunction: formData.jobFunction,
      jcCreationDate: formData.jcCreationDate,
    };
  }, [formData, ticketData]);

  const getInputClass = (fieldValue, fieldName) => {
    if (fieldValue) {
      return 'formJiraChangeInputText prefilledBorder';
    }
    return errors[fieldName] ? 'formJiraChangeInputText errorBorder' : 'formJiraChangeInputText';
  };

  const getRadioClass = (fieldValue, fieldName) => {
    if (fieldValue) {
      return 'formJiraChangeRadio prefilledBorder';
    }
    return errors[fieldName] ? 'formJiraChangeRadio errorBorder' : 'formJiraChangeRadio';
  };

  // const handleNewJobClassification = (e) => setIsNewJobClasification(e.target.value === 'yes');
  // const handleNewJobPosition = (e) => setIsNewJobPosition(e.target.value === 'yes');

  console.log('formData en OrganizationalChangePeople', formData);

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambios organizativos</div>
      <div className='formJiraChangeRadioGroup'>
        {/* Pregunta: ¿Se trata de una reorganización? */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>¿Se trata de una reorganización?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='organizationalChange'
                className={getRadioClass(formData.organizationalChange, 'organizationalChange')}
                value='Sí'
                checked={formData.organizationalChange.value === 'Sí'}
                onChange={() => handleInputChange('organizationalChange', 'Sí')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='organizationalChange'
                className={getRadioClass(formData.organizationalChange, 'organizationalChange')}
                value='No'
                checked={formData.organizationalChange.value === 'No'}
                onChange={() => handleInputChange('organizationalChange', 'No')}
              />
              No
            </label>
          </div>
        </div>
        {/* Pregunta: ¿Hay que crear nuevos departamentos? */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>¿Hay que crear nuevos departamentos?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newDepartments'
                className={getRadioClass(formData.newDepartments, 'newDepartments')}
                value='Sí'
                checked={formData.newDepartments.value === 'Sí'}
                onChange={() => handleInputChange('newDepartments', 'Sí')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newDepartments'
                className={getRadioClass(formData.newDepartments, 'newDepartments')}
                value='No'
                checked={formData.newDepartments.value === 'No'}
                onChange={() => handleInputChange('newDepartments', 'No')}
              />
              No
            </label>
          </div>
        </div>
        {/* Pregunta: ¿Quieres asignar a esta persona/s un nuevo responsable directo? */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>¿Quieres asignar a esta persona/s un nuevo responsable directo?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newManager'
                className={getRadioClass(formData.newManager, 'newManager')}
                value='Sí'
                checked={formData.newManager.value === 'Sí'}
                onChange={() => handleInputChange('newManager', 'Sí')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newManager'
                className={getRadioClass(formData.newManager, 'newManager')}
                value='No'
                checked={formData.newManager.value === 'No'}
                onChange={() => handleInputChange('newManager', 'No')}
              />
              No
            </label>
          </div>
        </div>
        {/* Campo: Nuevo responsable directo */}
        {formData.newManager?.value === 'Sí' && (
          <div className='formJiraChangeSection'>
            <label className='formJiraChangeLabel'>
              Nombre del nuevo responsable directo:
              <input
                type='text'
                placeholder='Responsable Directo'
                className='formJiraChangeInputText'
                value={formData.newAsigneeManager || ''}
                onChange={(e) => handleInputChange('newAsigneeManager', e.target.value)}
              />
            </label>
          </div>
        )}
        {/* Pregunta: ¿Se trata de un cambio de rol? */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>¿Se trata de un cambio de rol?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='roleChange'
                className={getRadioClass(showRoleChangeInput ? 'Sí' : 'No', 'roleChange')}
                value='Sí'
                checked={formData.roleChange.value === 'Sí'}
                onChange={() => {
                  setShowRoleChangeInput(true);
                  handleInputChange('roleChange', 'Sí');
                }}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='roleChange'
                className={getRadioClass(showRoleChangeInput ? 'Sí' : 'No', 'roleChange')}
                value='No'
                checked={formData.roleChange.value === 'No'}
                onChange={() => {
                  setShowRoleChangeInput(false);
                  handleInputChange('roleChange', 'No');
                  handleInputChange('roleChangeDetails', '');
                }}
              />
              No
            </label>
          </div>
        </div>
        {/* Campo: Detalles del cambio de rol */}
        {formData.roleChange?.value === 'Sí' && (
          <div className='formJiraChangeSection'>
            <label className='formJiraChangeLabel'>
              ¿Puedes indicarnos en qué consiste el cambio de rol:
              <input
                type='text'
                placeholder='Detalles del cambio de rol'
                className='formJiraChangeInputText'
                value={formData.roleChangeDetails?.content?.[0]?.content?.[0]?.text || ''}
                onChange={(e) => handleInputChange('roleChangeDetails', e.target.value)}
              />
            </label>
          </div>
        )}
        {/* Pregunta: ¿Cambian las funciones de esta persona? */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>¿Cambian las funciones de esta persona?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newDuties'
                className={getRadioClass(showNewDutiesInput ? 'Sí' : 'No', 'newDuties')}
                value='Sí'
                checked={formData.newDuties.value === 'Sí'}
                onChange={() => {
                  setShowNewDutiesInput(true);
                  handleInputChange('newDuties', 'Sí');
                }}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newDuties'
                className={getRadioClass(showNewDutiesInput ? 'Sí' : 'No', 'newDuties')}
                value='No'
                checked={formData.newDuties.value === 'No'}
                onChange={() => {
                  setShowNewDutiesInput(false);
                  handleInputChange('newDuties', 'No');
                  handleInputChange('newDutiesDetails', '');
                }}
              />
              No
            </label>
          </div>
        </div>
        {/* Campo: Detalles de las nuevas funciones */}
        {formData.newDuties?.value === 'Sí' && (
          <div className='formJiraChangeSection'>
            <label className='formJiraChangeLabel'>
              ¿Puedes indicarnos en qué consisten esas nuevas funciones?:
              <input
                type='text'
                placeholder='Detalles de las nuevas funciones'
                className='formJiraChangeInputText'
                value={formData.newDutiesDetails?.content?.[0]?.content?.[0]?.text || ''}
                onChange={(e) => handleInputChange('newDutiesDetails', e.target.value)}
              />
            </label>
          </div>
        )}
        {/* Pregunta: ¿Se trata de un cambio de categoría? */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>¿Se trata de un cambio de categoría?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='categoryChange'
                className={getRadioClass(showCategoryChangeInput ? 'Sí' : 'No', 'categoryChange')}
                value='Sí'
                checked={formData.categoryChange.value === 'Sí'}
                onChange={() => {
                  setShowCategoryChangeInput(true);
                  handleInputChange('categoryChange', 'Sí');
                }}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='categoryChange'
                className={getRadioClass(showCategoryChangeInput ? 'Sí' : 'No', 'categoryChange')}
                value='No'
                checked={formData.categoryChange.value === 'No'}
                onChange={() => {
                  setShowCategoryChangeInput(false);
                  handleInputChange('categoryChange', 'No');
                }}
              />
              No
            </label>
          </div>
        </div>
        {/* Campo: ¿Qué cambio de categoría quieres realizar? */}
        {formData.categoryChange.value === 'Sí' && (
          <div className='formJiraChangeSection'>
            <label className='formJiraChangeLabel'>
              ¿Qué cambio de categoría quieres realizar?
              <input
                type='text'
                placeholder='Describe el cambio de categoría'
                className='formJiraChangeInputText'
                value={formData.categoryChangeDetails?.content?.[0]?.content?.[0]?.text || ''}
                onChange={(e) => handleInputChange('categoryChangeDetails', e.target.value)}
              />
            </label>
          </div>
        )}
        {/* Campo: HRBP y Unidad de negocio */}
        <div className='formJiraChangeSection_both'>
          <label className='formJiraChangeLabelRadio'>
            HRBP:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={ticketData?.customfield_10394 || ''}
              onChange={(e) => handleInputChange('hrbp', e.target.value)}
            />
          </label>
          <label className='formJiraChangeLabelRadio'>
            Unidad de negocio:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={ticketData?.customfield_10395 || ''}
              onChange={(e) => handleInputChange('businessUnit', e.target.value)}
            />
          </label>
        </div>
        {/* Campo: División y Departamento */}
        <div className='formJiraChangeSection_both'>
          <label className='formJiraChangeLabelRadio'>
            División:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={ticketData?.customfield_10396 || ''}
              onChange={(e) => handleInputChange('division', e.target.value)}
            />
          </label>
          <label className='formJiraChangeLabelRadio'>
            Departamento:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={ticketData?.customfield_10349 || ''}
              onChange={(e) => handleInputChange('department', e.target.value)}
            />
          </label>
        </div>
        {/* Campo: Centro de coste y Resultados objetivos */}
        <div className='formJiraChangeSection_both'>
          <label className='formJiraChangeLabelRadio'>
            Centro de coste:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={ticketData?.customfield_10397 || ''}
              onChange={(e) => handleInputChange('costCenter', e.target.value)}
            />
          </label>
          <label className='formJiraChangeLabelRadio'>
            Resultados objetivos:
            <input
              type='text'
              placeholder=''
              className='formJiraChangeInputText'
              value={ticketData?.customfield_10398 || ''}
              onChange={(e) => handleInputChange('objectivesResult', e.target.value)}
            />
          </label>
        </div>
        <div className='formArticleSection'>
          <RadioGroup
            label='¿El cambio implica la creación de una nueva Job Classification?'
            name='newJobClasification'
            options={[
              { label: 'Sí', value: 'yes' },
              { label: 'No', value: 'no' },
            ]}
            formData={formData}
            handleInputChange={handleInputChange}
          />
          <NewJobClassificationForm formData={formData} handleInputChange={handleInputChange} showForm={formData.newJobClasification === 'yes'} />
        </div>

        {/* Nueva Job Classification creada */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>Nueva Job Classification creada</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='jcCreated'
                className={getRadioClass(formData.jcCreated, 'jcCreated')}
                value='Sí'
                checked={formData.jcCreated === 'Sí'}
                onChange={() => handleInputChange('jcCreated', 'Sí')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='jcCreated'
                className={getRadioClass(formData.jcCreated, 'jcCreated')}
                value='No'
                checked={formData.jcCreated === 'No'}
                onChange={() => handleInputChange('jcCreated', 'No')}
              />
              No
            </label>
          </div>
        </div>

        {/* Job Classification / Puesto */}
        <div className='formJiraChangeSection_both'>
          <label className='formJiraChangeLabelRadio'>
            Job Classification:
            <input
              type='text'
              placeholder=''
              className={getInputClass(formData.jobClassification, 'jobClassification')}
              value={formData.jobClassification || ''}
              onChange={(e) => handleInputChange('jobClassification', e.target.value)}
            />
          </label>

          {/* Rol / Perfil Global */}
          <label className='formJiraChangeLabelRadio'>
            Rol / Perfil Global:
            <input
              type='text'
              placeholder=''
              className={getInputClass(formData.globalRole, 'globalRole')}
              value={formData.globalRole || ''}
              onChange={(e) => handleInputChange('globalRole', e.target.value)}
            />
          </label>
        </div>

        {/* Categoría */}
        <div className='formJiraChangeSection_both'>
          <label className='formJiraChangeLabelRadio'>
            Categoría:
            <input
              type='text'
              placeholder=''
              className={getInputClass(formData.category, 'category')}
              value={formData.category || ''}
              onChange={(e) => handleInputChange('category', e.target.value)}
            />
          </label>

          {/* Código Benchmark */}
          <label className='formJiraChangeLabelRadio'>
            Código Benchmark:
            <input
              type='text'
              placeholder=''
              className={getInputClass(formData.benchmarkCode, 'benchmarkCode')}
              value={formData.benchmarkCode || ''}
              onChange={(e) => handleInputChange('benchmarkCode', e.target.value)}
            />
          </label>
        </div>

        {/* Pregunta: ¿El cambio implica la creación de una nueva posición? */}
        <div className='formArticleSection'>
          <RadioGroup
            label='¿El cambio implica la creación de una nueva posición?'
            name='newJobPosition'
            options={[
              { label: 'Sí', value: 'yes' },
              { label: 'No', value: 'no' },
            ]}
            formData={formData}
            handleInputChange={handleInputChange}
          />
          <NewPositionForm formData={formData} handleInputChange={handleInputChange} showForm={formData.newJobPosition === 'yes'} />
        </div>

        {/* Nueva posición creada */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>Nueva posición creada</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newPositionCreated'
                className={getRadioClass(formData.newPositionCreated, 'newPositionCreated')}
                value='Sí'
                checked={formData.newPositionCreated === 'Sí'}
                onChange={() => handleInputChange('newPositionCreated', 'Sí')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newPositionCreated'
                className={getRadioClass(formData.newPositionCreated, 'newPositionCreated')}
                value='No'
                checked={formData.newPositionCreated === 'No'}
                onChange={() => handleInputChange('newPositionCreated', 'No')}
              />
              No
            </label>
          </div>
        </div>

        {/* Empleado asignado a la nueva posición */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>Empleado asignado a la nueva posición</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='employeeAssignedToNewPosition'
                className={getRadioClass(formData.employeeAssignedToNewPosition, 'employeeAssignedToNewPosition')}
                value='Sí'
                checked={formData.employeeAssignedToNewPosition === 'Sí'}
                onChange={() => handleInputChange('employeeAssignedToNewPosition', 'Sí')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='employeeAssignedToNewPosition'
                className={getRadioClass(formData.employeeAssignedToNewPosition, 'employeeAssignedToNewPosition')}
                value='No'
                checked={formData.employeeAssignedToNewPosition === 'No'}
                onChange={() => handleInputChange('employeeAssignedToNewPosition', 'No')}
              />
              No
            </label>
          </div>
        </div>

        {/* Código de la posición */}
        <div className='formJiraChangeSection'>
          <label className='formJiraChangeLabelRadio'>
            Código de la posición:
            <input
              type='text'
              placeholder='Código de la posición'
              className={getInputClass(formData.positionCode, 'positionCode')}
              value={formData.positionCode || ''}
              onChange={(e) => handleInputChange('positionCode', e.target.value)}
            />
          </label>
        </div>

        {/* ¿Es necesario realizar algún cambio en la posición? */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>¿Es necesario realizar algún cambio en la posición?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='positionChangeRequired'
                className={getRadioClass(formData.positionChangeRequired, 'positionChangeRequired')}
                value='Sí'
                checked={formData.positionChangeRequired === 'Sí'}
                onChange={() => handleInputChange('positionChangeRequired', 'Sí')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='positionChangeRequired'
                className={getRadioClass(formData.positionChangeRequired, 'positionChangeRequired')}
                value='No'
                checked={formData.positionChangeRequired === 'No'}
                onChange={() => handleInputChange('positionChangeRequired', 'No')}
              />
              No
            </label>
          </div>
        </div>

        <div className='formJiraChangeSection'>
          <label className='formJiraChangeLabel'>
            Comentarios cambios en la posición:
            <textarea
              placeholder='Comentarios sobre los cambios en la posición'
              className={getInputClass(formData.positionChangeComments, 'positionChangeComments')}
              value={formData.positionChangeComments || ''}
              onChange={(e) => handleInputChange('positionChangeComments', e.target.value)}
            />
          </label>
        </div>
      </div>
    </div>
  );
};
