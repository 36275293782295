import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import GeneralInfoViewnext from './formComponents/generalInfoViewnext';
import OrganizationalChangeViewnext from './formComponents/organizationalChangeViewnext';
import OthersViewnext from './formComponents/othersViewnext';

const FormViewnextMain = () => {
  const [focusedButton, setFocusedButton] = useState('generalInfo');
  const [ssffId, setSsffId] = useState('');
  const [newPositionCreated, setNewPositionCreated] = useState('');
  const [employeeAssignedToNewPosition, setEmployeeAssignedToNewPosition] = useState('');
  const [positionCode, setPositionCode] = useState('');
  const [positionChangeRequired, setPositionChangeRequired] = useState('');
  const [positionChangeComments, setPositionChangeComments] = useState('');
  const [organizationalChangeStatus, setOrganizationalChangeStatus] = useState('');
  const [salaryChangeStatus, setSalaryChangeStatus] = useState('');
  const [locationChangeStatus, setLocationChangeStatus] = useState('');
  const [contractChangeStatus, setContractChangeStatus] = useState('');

  const handleMenuClick = (type) => {
    setFocusedButton(type);
  };

  return (
    <>
      <section className='formJiraChange'>
        <div className='jiraChangeForm__breadcrumb'>
          <img src='path-to-circle-icon.png' alt='circle-icon' className='circle-icon' />
          <Link to='/form' className='jiraChangeForm__breadcrumb--title'>
            INICIO
          </Link>
          <div className='jiraChangeForm__breadcrumb--text'>&gt; SOLICITUD VIEWNEXT</div>
        </div>
        <h1 className='formJiraChange__title'>Solicitud de Viewnext</h1>
        <div className='jiraChangeForm_menuFormContainer'>
          <nav className='jiraChangeForm_menu'>
            <button
              onClick={() => handleMenuClick('generalInfo')}
              style={{
                backgroundColor: focusedButton === 'generalInfo' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'generalInfo' ? '#06f' : 'initial',
              }}
            >
              Información General
            </button>
            <button
              onClick={() => handleMenuClick('organizationalChange')}
              style={{
                backgroundColor: focusedButton === 'organizationalChange' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'organizationalChange' ? '#06f' : 'initial',
              }}
            >
              Cambios Organizativos
            </button>
            <button
              onClick={() => handleMenuClick('others')}
              style={{
                backgroundColor: focusedButton === 'others' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'others' ? '#06f' : 'initial',
              }}
            >
              Otros
            </button>
          </nav>

          <form className='formJiraChange_form'>
            <div className='formJiraChange_section'>
              {focusedButton === 'generalInfo' && <GeneralInfoViewnext />}
              {focusedButton === 'organizationalChange' && <OrganizationalChangeViewnext />}
              {focusedButton === 'others' && (
                <OthersViewnext
                  organizationalChangeStatus={organizationalChangeStatus}
                  setOrganizationalChangeStatus={setOrganizationalChangeStatus}
                  salaryChangeStatus={salaryChangeStatus}
                  setSalaryChangeStatus={setSalaryChangeStatus}
                  locationChangeStatus={locationChangeStatus}
                  setLocationChangeStatus={setLocationChangeStatus}
                  contractChangeStatus={contractChangeStatus}
                  setContractChangeStatus={setContractChangeStatus}
                />
              )}
            </div>
            <div className='formJiraChangeButton_section'>
              <button className='formJiraChangeButton enabled' type='submit'>
                Enviar
              </button>
              <button className='secondaryButton' type='button'>
                Limpiar
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default FormViewnextMain;
