import React from 'react';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import LeadershipTablePDF from "./LeadershipTablePDF"; 
import SpainIcon from "../../../../images/talentCard/talentFiles/countries/spain.png";
import UruguayIcon from "../../../../images/talentCard/talentFiles/countries/uruguay.png";
import ArgentinaIcon from "../../../../images/talentCard/talentFiles/countries/argentina.png";
import ChileIcon from "../../../../images/talentCard/talentFiles/countries/chile.png";
import ColombiaIcon from "../../../../images/talentCard/talentFiles/countries/colombia.png";
import EcuadorIcon from "../../../../images/talentCard/talentFiles/countries/ecuador.png";
import PeruIcon from "../../../../images/talentCard/talentFiles/countries/peru.png";
import BrazilIcon from "../../../../images/talentCard/talentFiles/countries/brazil.png";
import UkIcon from "../../../../images/talentCard/talentFiles/countries/united-kingdom.png";
import EuropeIcon from "../../../../images/talentCard/talentFiles/countries/europe.png";
import NoMobilityIcon from "../../../../images/talentCard/talentFiles/countries/no-mobility.png";
import MobilityIcon from "../../../../images/talentCard/talentFiles/countries/mobility.png";
import HispamIcon from "../../../../images/talentCard/talentFiles/countries/hispam.png";
import GermanyIcon from "../../../../images/talentCard/talentFiles/countries/germany.png"

const countryIcons = {
  Germany: GermanyIcon,
  Spain: SpainIcon,
  Uruguay: UruguayIcon,
  Argentina: ArgentinaIcon,
  Chile: ChileIcon,
  Colombia: ColombiaIcon,
  Ecuador: EcuadorIcon,
  Peru: PeruIcon,
  Brazil: BrazilIcon,
  UK: UkIcon,
  Hispam: HispamIcon,
  Europe: EuropeIcon,
  SI: MobilityIcon,
  YES: MobilityIcon,
  NO: NoMobilityIcon,
  null:NoMobilityIcon
};

function getCountryIconImage(countryName) {
  return countryIcons[countryName] || null;
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    padding: 10,
    fontFamily: 'Helvetica'
  },
  employeeName: {
    fontSize: 14,
    fontFamily: 'Helvetica',
    marginTop: 13,
    marginBottom: 10,
    color: '#007bff',
  },
  title: {
    fontSize: 12,
    marginBottom: 10,
    color: '#007bff',
    marginTop: 20,
  },
  subtitle: {
    fontSize: 12,
    fontFamily:  'Helvetica',
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  skillTable: {
    marginBottom: 10,
    fontSize: 10,
  },
  countryIcon: {
    width: 10,
    height: 10,
    marginRight: 5,
    marginTop: 5,

  },
  listItem: {
    fontSize: 10,
    marginBottom: 10, // Aumenta el espacio entre habilidades
    color: '#000',   // Color de texto negro
  },
  lineItem: {
    fontSize: 10,
    color: '#000',
    marginBottom: 2, // Ajusta el espacio entre líneas si es necesario
  },
});


const LeadershipPDF = ({ empleadoDetails,empleado }) => {
  const firstCountry = empleadoDetails.countries ? empleadoDetails.countries[0] : null;
  const countryIcon = getCountryIconImage(firstCountry);
  const position = empleado.empInfo?.jobInfoNav?.results?.[0]?.jobTitle || '';


  return (
    <View style={styles.container}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {countryIcon && <Image src={countryIcon} style={styles.countryIcon} />}
        <Text style={styles.employeeName}>{empleado.displayName}</Text>
      </View>

      <Text style={styles.subtitle}>{position}</Text>

      <View style={{ marginBottom: 20 }}>
        <Text style={styles.title}>Leadership</Text>
        <LeadershipTablePDF leadershipData={empleadoDetails} />
      </View>

      <Text style={styles.title}>Critical skillset overview</Text>
      <View style={styles.skillTable}>
        {empleadoDetails.skillset_overview && (
          <View>
            {empleadoDetails.skillset_overview.split('\n').map((skill, index) => (
              <Text key={index} style={styles.listItem}>
                {skill.split('\\n').map((line, i) => (
                  <Text key={i} style={styles.lineItem}>
                    {line}
                  </Text>
                ))}
              </Text>
            ))}
          </View>
        )}
      </View>

    </View>
  );
}

export default LeadershipPDF;
