import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import CardEmployeeMobile from '../../Mobile/Components/CardEmployeeMobile';
import HeaderComponentMobile from '../../Mobile/Components/HeaderTalenComponentMobile';
import SideBarComponentMobile from './SideBarComponentMobile';
import Header from '../../../components/header';



function MainComponentMobile({
  userInfoSSFF,
  empleadosList,
  loadingList,
  clusterEmployeeIds,
  addEmployee,
  isAdding,
  obOptions,
  idToken,
  handleFilterSearcher,
  filterInSearcher,
  setHandleUpperCategoryIdParam,
}) {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [isSelectByClick, setIsSelectByClick] = useState(false);
  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const [isDropdownSelected, setIsDropdownSelected] = useState(false);
  const [selectedDropdownEmployeeId, setSelectedDropdownEmployeeId] =
    useState(null);
  const [selectedOB, setSelectedOB] = useState('');

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);

  const hasFetched = useRef(false);

  useEffect(() => {
    let filtered = Array.isArray(empleadosList)
      ? empleadosList
      : Object.values(empleadosList); 

    if (isSelectByClick && isDropdownSelected) {
      setIsSelectByClick(false);
      return;
    }

    if (selectedDropdownEmployeeId && isDropdownSelected) {
      filtered = filtered.filter((empleado) =>
        selectedDropdownEmployeeId.includes(empleado.userId)
      );
    } else if (searchTerm) {
      filtered = filtered.filter((empleado) => {
        const nombre = empleado.displayName
          ? empleado.displayName.toLowerCase()
          : '';
        const id = empleado.userId ? empleado.userId.toString() : '';
        return (
          nombre.includes(searchTerm.toLowerCase()) || id.includes(searchTerm)
        );
      });
    }

     // Mostrar todos los empleados si "Seleccionar..." está activo
    if (!selectedOB || selectedOB.value === 'seleccion') {
      setFilteredEmployee(filtered); 
      return;
    }

    // Filtrar por OB seleccionado
    const selectedOBValue = selectedOB.value;
    filtered = filtered.filter(empleado =>
      empleado.empInfo?.jobInfoNav?.results?.[0]?.businessUnitNav?.name === selectedOBValue
    );

    setFilteredEmployee(filtered);
  }, [
    searchTerm,
    selectedDropdownEmployeeId,
    empleadosList,
    isDropdownSelected,
    selectedOB,
  ]);

  const handleClusterEmployeeIdChange = (id, fromDropdown) => {
    if (fromDropdown) {
      setSelectedDropdownEmployeeId(id);
      setIsDropdownSelected(true);
    } else {
      setSelectedDropdownEmployeeId('');
      setIsDropdownSelected(false);
    }
  };

  const handleCardClick = (id) => {
    if (isSelectionMode) {
      setSelectedEmployeeId((prevId) => {
        if (prevId === id) {
          setEmployeeToDelete(null);
          setIsDeleteModalOpen(false);
          setIsSelectionMode(false);
          return null;
        } else {
          const empleado = empleadosList[id]; // Accedemos directamente al empleado usando el ID como clave
          if (empleado) {
            setEmployeeToDelete(empleado);
            setIsDeleteModalOpen(true);
            setIsSelectionMode(false);
          }
          return id;
        }
      });
    }
  };

  const backPagePeoplex = () => {
    navigate('/home');
  };

  if (loadingList) return <p>Cargando...</p>;

  return (
    <div className="">
      {/* <HeaderComponentMobile onBack={backPagePeoplex} className="" /> */}
      <Header
                    handleFilterSearcher={handleFilterSearcher}
                    filterInSearcher={filterInSearcher}
                    setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                    userInfoSSFF={userInfoSSFF}
      />
      <div className="d-flex flex-column align-items-center">
        <div className="row w-100">
          <div className="col-12 d-flex justify-content-center">
            <SideBarComponentMobile
              onAdd={() => console.log('Agregar empleado')}
              selectedEmployeeId={selectedEmployeeId}
              clusterEmployeeIds={clusterEmployeeIds}
              selectedDropdownEmployeeId={selectedDropdownEmployeeId}
              addEmployee={addEmployee}
              isAdding={isAdding}
              onClusterEmployeeIdChange={handleClusterEmployeeIdChange}
              empleadosList={empleadosList}
              selectedOB={selectedOB} // Pasamos el estado de OB al Sidebar
              setSelectedOB={setSelectedOB} // Pasamos el setter de OB al Sidebar
              obOptions={obOptions}
              idToken={idToken}
            />
          </div>
        </div>

        <div className="row w-100 justify-content-center">
          <div className="col-12 d-flex flex-wrap justify-content-center me-3">
            {Object.values(filteredEmployee).map((empleado) => (
              <CardEmployeeMobile
                key={empleado.userId}
                empleado={empleado}
                onSelect={handleCardClick}
                obOptions={obOptions} 
                isSelected={
                  employeeToDelete &&
                  empleado.userId === employeeToDelete.userId
                }
                idToken={idToken}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainComponentMobile;
