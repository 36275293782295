import React from 'react';

export const RadioGroup = ({ label, name, options, formData, handleInputChange }) => (
  <div className='formJiraChangeRadio'>
    <label className='formJiraChangeRadioLabel'>{label}</label>
    <div className='formJiraChangeRadio'>
      {options.map((option, index) => (
        <label className='formJiraChangeRadioLabel' key={index}>
          <input
            type='radio'
            name={name}
            className='formJiraChangeRadio'
            value={option.value}
            checked={formData[name] === option.value}
            onChange={() => handleInputChange(name, option.value)}
          />
          {option.label}
        </label>
      ))}
    </div>
  </div>
);
