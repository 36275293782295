import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import SpainComponent from "../../assests/FileComponentes/countries/SpainComponent";
import GermanyComponent from "../../assests/FileComponentes/countries/GermanyComponent";
import UruguayComponent from "../../assests/FileComponentes/countries/UruguayComponent";
import ArgentinaIconComponent from "../../assests/FileComponentes/countries/ArgentinaIconComponent";
import ChileIconComponent from "../../assests/FileComponentes/countries/ChileIconComponent";
import ColombiaIconComponent from "../../assests/FileComponentes/countries/ColombiaIconComponent";
import EcuadorIconComponent from "../../assests/FileComponentes/countries/EcuadorIconComponent";
import PeruIconComponent from "../../assests/FileComponentes/countries/PeruIconComponent";
import BrasilIconComponent from "../../assests/FileComponentes/countries/BrasilIconComponent";
import UkIconComponent from "../../assests/FileComponentes/countries/UkIconComponent";
import MexicoIconComponent from "../../assests/FileComponentes/countries/MexicoIconComponent";
import SouthIconComponent from "../../assests/FileComponentes/countries/SouthIconComponent";
import EuropeComponent from "../../assests/FileComponentes/countries/EuropeIconComponent";
import { toast, ToastContainer } from "react-toastify";
import '../Styles/SecondTalentView/countryModal.css';
import { updateEmployee } from '../../../services/TalentCards/FetchUpdateEmployee';
import SiIconComponent from "../../assests/FileComponentes/countries/SiIconMovilityComponent";
import NoIconComponent from "../../assests/FileComponentes/countries/NoIconMovilityComponent";
import CryptoJS from 'crypto-js';


const countryIconComponents = {
    Spain: SpainComponent,
    Germany: GermanyComponent,
    Uruguay: UruguayComponent,
    Argentina: ArgentinaIconComponent,
    Chile: ChileIconComponent,
    Colombia: ColombiaIconComponent,
    Ecuador: EcuadorIconComponent,
    Peru: PeruIconComponent,
    Brazil: BrasilIconComponent,
    UK: UkIconComponent,
    Mexico: MexicoIconComponent,
    Hispam: SouthIconComponent,
    NO: NoIconComponent,
    Europe: EuropeComponent
};

function getCountryIconComponent(countryName) {
    return countryIconComponents[countryName] || DefaultCountryIconComponent;
}

const DefaultCountryIconComponent = () => (
    <div style={{ width: "24px", height: "24px", background: "gray", marginRight: "5px" }} />
);

const CountryModal = ({ isOpen, onClose, empleadoDetails, selectedEmployeeId, userInfoSSFF, onUpdateEmployeeDetails,idToken,
    jwtSSFF }) => {

    //const userId = userInfoSSFF.userId;
    const userId=  userInfoSSFF?.d?.results?.[0]?.userId ?? userInfoSSFF?.userId;
    const secretKey = process.env.REACT_APP_TALENT_APP;
    
    
    // Encriptar y codificar el user_id
    const encrypted = CryptoJS.AES.encrypt(selectedEmployeeId, secretKey).toString();
    const encryptedSelectedId = encodeURIComponent(btoa(encrypted));


    const [countryStatus, setCountryStatus] = useState('SI');
    const [updatedCountries, setUpdatedCountries] = useState([]);
    const [prevCountries, setPrevCountries] = useState([]);

    useEffect(() => {
        if (empleadoDetails.mobility) {
            const selectedCountries = empleadoDetails.mobility.map(item => item.country);
            const isNoOrTbc = selectedCountries.includes("TBC") || selectedCountries.includes("NO");
            setCountryStatus(isNoOrTbc ? 'NO' : 'SI');
            setUpdatedCountries(isNoOrTbc ? [] : selectedCountries);
            setPrevCountries(isNoOrTbc ? [] : selectedCountries);
        }
    }, [empleadoDetails]);

    const handleToggle = () => {
        let newStatus = countryStatus === 'SI' ? 'NO' : 'SI';
        setCountryStatus(newStatus);
        if (newStatus === 'SI') {
            setUpdatedCountries(prevCountries.length ? prevCountries : []);
        } else {
            setPrevCountries(updatedCountries);
            setUpdatedCountries(['NO']);
        }
    };

    const handleCountryDoubleClick = (country) => {
        if (countryStatus === 'SI') {
            let newCountries;
            if (updatedCountries.includes(country)) {
                newCountries = updatedCountries.filter(c => c !== country);
            } else {
                newCountries = [...updatedCountries, country];
            }
            setUpdatedCountries(newCountries);
        }
    };

    const handleSave = async () => {
        if (countryStatus === 'SI' && updatedCountries.length === 0) {
            toast.error("Debe seleccionar al menos un país.");
            return;
        }

        const mobilityToSave = countryStatus === 'SI' ? 
            updatedCountries.map(country => ({ country })) : 
            [{ country: 'NO' }];

        const updatedEmployeeData = {
            ...empleadoDetails,
            mobility: mobilityToSave,
        };

        try {
            await updateEmployee(encryptedSelectedId, updatedEmployeeData, idToken,
                jwtSSFF);
            onUpdateEmployeeDetails(updatedEmployeeData);
            onClose();
        } catch (error) {
            console.error('Error al actualizar los datos de movilidad:', error);
            toast.error('Error al actualizar los datos de movilidad.');
        }
    };

    if (!isOpen) {
        return null;
    }

    const allCountries = Object.keys(countryIconComponents);

    return (
        <Modal show={isOpen} onHide={onClose} centered dialogClassName="custom-modal">
            <Modal.Body className="p-4">
                <h4 className="text-primary mb-3 mt-2">Mobility to:</h4>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>Cambia tu movilidad:</span>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={countryStatus === 'SI'}
                            onChange={handleToggle}
                        />
                    </div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: "20px" }}>
                    {allCountries.map((country, index) => {
                        const CountryIconComponent = getCountryIconComponent(country);
                        const isSelected = updatedCountries.includes(country);
                        return (
                            <div
                                key={index}
                                className="d-flex flex-column align-items-center mx-2"
                                onClick={() => handleCountryDoubleClick(country)}
                                style={{
                                    cursor: countryStatus === 'SI' ? 'pointer' : 'not-allowed',
                                    opacity: countryStatus === 'SI' ? (isSelected ? 1 : 0.5) : 0.5
                                }}
                            >
                                <CountryIconComponent className='rounded-circle mb-2 mt-2 country' />
                                <span className="name-country">{country}</span>
                            </div>
                        );
                    })}
                </div>
                <div className="d-flex justify-content-end gap-2 mt-3">
                    <Button className="btn-mantener rounded-pill" onClick={onClose}>Cancelar</Button>
                    <Button className="btn-delete rounded-pill" onClick={handleSave}>Guardar</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};





export default CountryModal;