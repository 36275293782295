import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { Document, Page, Image as PdfImage } from "@react-pdf/renderer";

// Importar las imágenes de los países
import SpainIcon from "../../../../images/talentCard/talentFiles/countries/spain.png";
import UruguayIcon from "../../../../images/talentCard/talentFiles/countries/uruguay.png";
import ArgentinaIcon from "../../../../images/talentCard/talentFiles/countries/argentina.png";
import ChileIcon from "../../../../images/talentCard/talentFiles/countries/chile.png";
import ColombiaIcon from "../../../../images/talentCard/talentFiles/countries/colombia.png";
import EcuadorIcon from "../../../../images/talentCard/talentFiles/countries/ecuador.png";
import PeruIcon from "../../../../images/talentCard/talentFiles/countries/peru.png";
import BrazilIcon from "../../../../images/talentCard/talentFiles/countries/brazil.png";
import UkIcon from "../../../../images/talentCard/talentFiles/countries/united-kingdom.png";
import EuropeIcon from "../../../../images/talentCard/talentFiles/countries/europe.png";
import NoMobilityIcon from "../../../../images/talentCard/talentFiles/countries/no-mobility.png";
import MobilityIcon from "../../../../images/talentCard/talentFiles/countries/mobility.png";
import HispamIcon from "../../../../images/talentCard/talentFiles/countries/hispam.png";
import GermanyIcon from "../../../../images/talentCard/talentFiles/countries/germany.png";

//import ArrowPDF from "../../../../images/talentCard/talentFiles/arrow1.png";
import ArrowPDF from '../../../assests/FileComponentes/PDFIcons/ArrowPDF';

// Objeto que mapea cada país a su respectiva imagen PNG
const countryIcons = {
  Spain: SpainIcon,
  Germany: GermanyIcon,
  Uruguay: UruguayIcon,
  Argentina: ArgentinaIcon,
  Chile: ChileIcon,
  Colombia: ColombiaIcon,
  Ecuador: EcuadorIcon,
  Peru: PeruIcon,
  Brazil: BrazilIcon,
  UK: UkIcon,
  Hispam: HispamIcon,
  Europe: EuropeIcon,
  SI: MobilityIcon,
  YES: MobilityIcon,
  NO: NoMobilityIcon,
};

// Función para obtener la imagen del país según el nombre del país
function getCountryIconImage(countryName) {
  return countryIcons[countryName] || null;
}

// Estilos (modificados para @react-pdf/renderer)
const styles = StyleSheet.create({
 
  section: {
    width: 'auto',
    height: 'auto',
  },
  heading: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    color: "#0066ff",
    marginBottom: 10,
    marginTop:10,
  },
  text: {
    fontSize: 10,
    marginBottom: 5,
    marginTop:5,
  },
  ovalContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 9,
    fontSize: 8,
  },
  oval: {
    flexDirection: "row",
    width: 80,
    height: 27,
    backgroundColor: '#007bff',
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    marginRight: 5,
    paddingRight: 10,
  },
  circle: {
    backgroundColor: '#007bff',
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 9,

  },
  ovalText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    marginLeft: 2,
  },
  arrowContainer: {
    position: 'relative',
    marginLeft: 2,
    zIndex: 10,
  },
  smallFontSize: {
    fontSize: 8,
    marginRight: 5,
    marginLeft: 5,
    marginTop: 0,
    marginBottom: 0,
  },
});





const OthersComponent = ({ empleadoDetails }) => {
  // Filter positions that are true
  const positions = [
    { key: 'cluster_ceo', label: 'CEO' },
    { key: 'cluster_b2c_growth', label: 'B2C' },
    { key: 'cluster_b2b_growth', label: 'B2B' },
    { key: 'cluster_technology', label: 'TEC' },
    { key: 'cluster_enablers', label: 'EN' }
  ].filter(position => empleadoDetails[position.key]);

  return (
    <View style={styles.section}>
   
      {/* Sección de Clusters */}
      <View style={styles.ovalContainer}>
        {positions.length <= 2 ? (
          // Mostrar todos los clusters en un solo óvalo si hay 1 o 2
          <View style={styles.oval}>
            <View style={styles.arrowContainer}>
              <ArrowPDF width={16} height={16} />
            </View>
            <Text style={styles.ovalText}>
              {positions.map((position, index) => (
                <Text key={index}>
                  {position.label}
                  {index < positions.length - 1 ? " & " : ""}
                </Text>
              ))}
            </Text>
          </View>
        ) : (
          // Mostrar cada cluster en su propio círculo si hay más de 2
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '15px' }}>
            {positions.map((position, index) => (
              <View key={index} style={[styles.circle, { margin: 5 }]}>
                <View style={styles.arrowContainer}>
                  <ArrowPDF width={16} height={16} />
                </View>
                <Text style={styles.ovalText}>{position.label}</Text>
              </View>
            ))}
          </View>
        )}
      </View>
  
        {/* Other Sections... */}
        <View style={styles.section}>
          <Text style={styles.heading}>Potential to Value</Text>
          {empleadoDetails.potential_years_role && (
            <View style={styles.text}>
              <Text>Years in role: {empleadoDetails.potential_years_role}</Text>
            </View>
          )}
          {empleadoDetails.potential_future_role && (
            <View style={styles.text}>
              <Text>Potential to future role(s): {empleadoDetails.potential_future_role}</Text>
            </View>
          )}
          {empleadoDetails.succession_pipeline && (
            <View style={styles.text}>
              <Text>Succession pipeline: {empleadoDetails.succession_pipeline}</Text>
            </View>
          )}
          {empleadoDetails.successors && (
            <View style={styles.text}>
              <Text>Successor(s): {empleadoDetails.successors}</Text>
            </View>
          )}
        </View>
  
        <View style={styles.section}>
        <Text style={styles.heading}>Mobility to</Text>
        <View style={{ flexDirection: "column", alignItems: "flex-start" }}>
          <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
            {empleadoDetails.mobility?.map((mobilityDetail, index) => {
              const iconImage = getCountryIconImage(mobilityDetail.country);
              return (
                <View
                  key={index}
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    marginHorizontal: 10,
                    marginBottom: 5,
                  }}
                >
                  {iconImage && <PdfImage src={iconImage} style={{ width: 16, height: 16 }} />}
                  <Text style={{ fontSize: 8, textAlign: "center" }}>{mobilityDetail.country}</Text>
                </View>
              );
            })}
            </View>
            <View style={{ marginTop: 10, alignItems: "flex-start", width: '100%' }}>
              <Text style={styles.text}>{empleadoDetails.mobility_text || "No Available"}</Text>
            </View>
          </View>
        </View>
  
        <View style={styles.section}>
          <Text style={styles.heading}>Career Action Plan</Text>
          <View style={styles.text}>
            <Text>{empleadoDetails.career_action_plan || "No Action Plan Available"}</Text>
          </View>
        </View>
  
        <View style={styles.section}>
          <Text style={styles.heading}>Additional insights</Text>
          <View style={styles.text}>
            <Text>{empleadoDetails.additional_insights || "No additional insights available"}</Text>
          </View>
        </View>
      </View>
     
    );
  };

export default OthersComponent;
