import React from 'react';
import { Modal, Button } from 'react-bootstrap';  // Asegúrate de que Modal y Button están importados correctamente
import '../Styles/modalConfirmation.css';
import { useParams  } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FetchEmployeeDetails } from '../../../services/TalentCards/FetchEmployeeDetails';
import CryptoJS from 'crypto-js';


const ModalConfirmation = ({ isOpen, empleado, onClose, onDelete,userInfoSSFF,idToken}) => {
    const [empleadoDetails, setEmpleadoDetails] = useState(null);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [error, setError] = useState(null);
    
    const secretKey = process.env.REACT_APP_TALENT_APP;

    useEffect(() => {
      if (!empleado) return;
  
      async function loadEmpleadoDetails() {
        try {
          // Encriptar y codificar el empleado.userId
          const encrypted = CryptoJS.AES.encrypt(empleado.userId, secretKey).toString();
          const encryptedUserId = encodeURIComponent(btoa(encrypted));
        
          const data = await FetchEmployeeDetails(encryptedUserId,idToken);
          setEmpleadoDetails(data);
        } catch (error) {
          setError(error);
        } finally {
          setLoadingDetails(false);
        }
      }
  
      loadEmpleadoDetails();
    }, [empleado]);




  if (!isOpen || !empleado || !empleadoDetails) {
    return null;
  }

  const fullLocation = empleado.location || '';
  const cleanLocation = fullLocation.replace(/\s*\(.*?\)\s*/g, '').trim();
  const formattedLocation = cleanLocation.charAt(0).toUpperCase() + cleanLocation.slice(1).toLowerCase();
 

  const userIdToUse = userInfoSSFF?.d?.results?.[0]?.userId ?? userInfoSSFF?.userId;
  

  return (
    
      <Modal show={isOpen} 
        onHide={onClose}
        centered
        dialogClassName="custom-modal" 
      >
      <Modal.Body className="p-4">
          <h4 className='text-primary mb-3 mt-2 '>Se eliminará la siguiente ficha:</h4>
          <p className='mb-5'>
            <strong className='fs-5'>{empleado.userId}</strong><br />
            <span className='mt-2 d-flex justify-content-start text-secondary'>
            {empleado.displayName} | { formattedLocation}<br />
            </span>
          </p>
          <div className=' d-flex justify-content-end gap-2 mt-3'>
            <Button className= "btn-mantener  rounded-pill "  onClick={onClose}>Mantener</Button>
            <Button className='btn-delete  rounded-pill ' onClick={() => onDelete(empleado.userId, userIdToUse)}>Eliminar</Button>
          </div>
      </Modal.Body>
    </Modal>
    
     
  );
};

export default ModalConfirmation;
