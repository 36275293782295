import React from 'react';
import { Page, Document, StyleSheet, View } from '@react-pdf/renderer';
import InfoPDF from './InfoPDF';
import SummaryPDF from './SummaryPDF';
import ExperiencePDF from './ExperiencePDF';
import OthersPDF from './OthersPDF';
import LeadershipPDF from './LeadershipPDF';


const styles = StyleSheet.create({
  document: {
    backgroundColor: '#FFFFFF', 
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF', 
  },
  column: {
    width: '33.33%',
    padding: 10,
  },
});

const DownloadPDF = ({ empleadoDetails, selectedEmployeeId,userInfoSSFF, empleado }) => {

  
  const hasManyExperiences = empleadoDetails.experiences.length > 11;




  return (
    <Document style={styles.document}>
      {/* Página 1 */}
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.column}>
          <InfoPDF empleadoDetails={empleadoDetails} selectedEmployeeId={selectedEmployeeId} empleado={empleado}/>
        </View>
        <View style={styles.column}>
          <SummaryPDF empleadoDetails={empleadoDetails} userInfoSSFF={userInfoSSFF} empleado={empleado}/>
        </View>
        {!hasManyExperiences && (
          <View style={styles.column}>
            <ExperiencePDF empleadoDetails={empleadoDetails} />
          </View>
        )}
      </Page>

      {/* Página 2 */}
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.column}>
          <InfoPDF empleadoDetails={empleadoDetails} selectedEmployeeId={selectedEmployeeId} empleado={empleado} />
        </View>
        <View style={styles.column}>
          <LeadershipPDF empleadoDetails={empleadoDetails} userInfoSSFF={userInfoSSFF} empleado={empleado}/>
        </View>
        <View style={styles.column}>
          <OthersPDF empleadoDetails={empleadoDetails} />
        </View>
      </Page>
    </Document>
  );
};

export default DownloadPDF;
