import * as React from "react"

const LocationComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <path
      fill="#F2F4FF"
      d="M14.434 27.27a.75.75 0 0 0 1.067 0c1.038-1.052 8.33-9.902 8.345-15.754.017-5.625-3.282-8.992-8.821-9.01h-.04c-5.514 0-8.817 3.346-8.835 8.957-.018 5.454 6.595 14.094 8.284 15.807ZM7.658 11.466c.014-4.793 2.616-7.43 7.327-7.43h.036c4.736.017 7.335 2.67 7.32 7.476-.013 4.463-5.329 11.701-7.377 14.09-2.25-2.609-7.32-9.876-7.306-14.136ZM15 9.13c1.212.004 1.806.601 1.806 1.835 0 1.23-.59 1.83-1.806 1.83-1.216 0-1.806-.6-1.806-1.834 0-1.23.593-1.83 1.806-1.83Zm-3.314 1.835c0 2.073 1.27 3.363 3.314 3.363s3.313-1.29 3.313-3.363S17.044 7.6 15 7.6c-2.045 0-3.314 1.29-3.314 3.364Z"
    />
  </svg>
)
export default LocationComponent