import React from "react";
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import TalentNoPDF from "../../../assests/FileComponentes/PDFIcons/TalentNoPDF";
import TalentYesPDF from "../../../assests/FileComponentes/PDFIcons/TalentYesPDF";
import performance from "../../../../images/talentCard/talentFiles/performance.png";

const expectedYears = [2021, 2022, 2023];

const getYearData = (year, empleadoYears) => {
  return empleadoYears.find((data) => data.year === year) || {};
};

const styles = StyleSheet.create({
  table: {
    width: "80%", // Increase width as needed
    marginBottom: 10,
    marginLeft: 0,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 24,
  },
  cellHeader: {
    backgroundColor: '#007bff',
    color: "#fff",
    fontWeight: "bold",
    paddingVertical: 5,
    paddingHorizontal: 10,
    textAlign: "center",
    flex: 1,
  },
  firstCellHeader: {
    backgroundColor: '#007bff',
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
    flex: 2,
    width: 200,
  },
  cell: {
    backgroundColor: '#007bff',
    paddingVertical: 5,
    paddingHorizontal: 5,
    textAlign: "center",
    fontSize: 10,
    color: "#fff",
    flex: 1,
    fontFamily: "Helvetica",
    overflow: "visible",
  },
  textWhite: {
    color: "#fff",
    fontSize: 11.5,
    fontFamily: "Helvetica",
  },

  textTittle: {
    color: "#fff",
    fontSize: 10,
    fontFamily: "Helvetica",
    marginLeft: 0, 
    textAlign: "left", 
    alignSelf: "flex-start", 
  },
  
  
  performanceContainer: {
    position: "relative",
  },
  performanceIcon: {
    width: 20,  
    height: 20, 
    left: -2,
    top: 2,
  },
  performanceNumber: {
    position: "absolute",
    left: 10,
    top: 9,
    color: "#007bff",
    fontSize: 10,
    fontFamily: "Helvetica",
  },
});

function TalentTablePDF({ empleadoDetails }) {
 

  const expectedYears = [2021, 2022, 2023]; // Años esperados, ajusta según sea necesario.

  // Función auxiliar para obtener los datos de un año específico
  const getYearData = (year, historyData) => {
    return historyData.find((y) => y.talent_year === year) || {};
  };

  return (
    <View style={styles.table}>
      {/* Encabezado de la tabla */}
      <View style={styles.row}>
        <View style={[styles.cellHeader, { flex: 1 }]}>
          <Text style={styles.textWhite}></Text>
        </View>
        {expectedYears.map((year) => (
          <View key={year} style={[styles.cellHeader, { flex: 1 }]}>
            <Text style={styles.textWhite}>{year}</Text>
          </View>
        ))}
      </View>

      {/* Fila de Top Talent */}
      <View style={styles.row}>
        <View style={styles.firstCellHeader}>
          <Text style={styles.textTittle}>Top Talent</Text>
        </View>
        {expectedYears.map((year) => {
          const data = getYearData(year, empleadoDetails.history); // Cambiado a history
          return (
            <View key={year} style={styles.cell}>
              {data.top_talent === 1 ? <TalentYesPDF /> : <TalentNoPDF />}
            </View>
          );
        })}
      </View>

      {/* Fila de Key Talent */}
      <View style={styles.row}>
        <View style={styles.firstCellHeader}>
          <Text style={styles.textTittle}>Key Talent</Text>
        </View>
        {expectedYears.map((year) => {
          const data = getYearData(year, empleadoDetails.history); // Cambiado a history
          return (
            <View key={year} style={styles.cell}>
              {data.key_talent === 1 ? <TalentYesPDF /> : <TalentNoPDF />}
            </View>
          );
        })}
      </View>

      {/* Fila de Performance */}
      <View style={styles.row}>
        <View style={styles.firstCellHeader}>
          <Text style={styles.textTittle}>Performance</Text>
        </View>
        {expectedYears.map((year, index) => {
          const data = getYearData(year, empleadoDetails.history); // Cambiado a history
          return (
            <View key={index} style={[styles.cell, styles.performanceContainer]}>
              {data.performance && data.performance > 0 ? (
                <>
                  <Image src={performance} style={styles.performanceIcon} />
                  <Text style={styles.performanceNumber}>
                    {data.performance}
                  </Text>
                </>
              ) : (
                <TalentNoPDF />
              )}
            </View>
          );
        })}
      </View>
    </View>
  );
}

export default TalentTablePDF;
