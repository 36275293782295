import React from 'react';
import '../Styles/clusterCardMobile.css';

const ClusterComponentCardMobile = ({ positions }) => {
  return (
    <div className="cluster-card-mobile">
      <div className="circles-container-card-mobile ">
        {positions.map((position) => (
          <div
            key={position.key}
            className="circle-card-mobile me-2 text-white"
          >
            {position.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClusterComponentCardMobile;
