import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//styles
import '../styles/bubbles_categories.css';
import elipse355 from '../../images/ellipse355.png';

const BubblesCategories = ({ setSelectedCategory, userInfoSSFF, setCategoriesData, categoriesData }) => {
  const [categoriesDataFiltered, setCategoriesDataFiltered] = useState({
    employeeCategories: [],
    managerCategories: [],
  });
  const navigate = useNavigate();

  // Obtener las CATEGORIAS
  useEffect(() => {
    const fetchCategoriesData = async () => {
      try {
        const response = await fetch(`/categories`, {
          method: 'GET',
        });
        const data = await response.json();
        if (response.ok) {
          setCategoriesData(data.categoriesData);
        } else {
          console.error('Error fetching categories:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategoriesData();
  }, []);

  // Filtrado de las CATEGORIAS obtenidas en base a reglas
  useEffect(() => {
    if (categoriesData.length > 0) {
      // Filtrar solo las categorías activas
      const activeCategories = categoriesData.filter((category) => category.is_active);

      // Categorías para empleados
      const employeeCategories = activeCategories.filter((category) => category.audience === 'PeoplEX' || category.audience === 'All Employees');

      // Categorías para managers
      const managerCategories = activeCategories.filter((category) => category.audience === 'BusinessEX');

      // Ordenar ambas listas por category_id de forma ascendente
      employeeCategories.sort((a, b) => a.category_id - b.category_id);
      managerCategories.sort((a, b) => a.category_id - b.category_id);

      // Filtrar las categorías según el valor de custom15
      if (userInfoSSFF.custom15 === 'Y') {
        // Mostrar tanto categorías de empleados como de managers
        setCategoriesDataFiltered({ employeeCategories, managerCategories });
      } else {
        // Solo mostrar categorías de empleados
        setCategoriesDataFiltered({ employeeCategories, managerCategories: [] });
      }
    }
  }, [categoriesData, userInfoSSFF]);

  const handleCategoryClick = (category) => {
    setSelectedCategory({ name: category.name, id: category.category_id });
    navigate('/subcategories');
  };

  return (
    <div className='categories' id='bubbles-categories'>
      {/* Sección de empleados */}
      <div className='categories__breadcrumb'>
        <img src={elipse355} alt='circle-icon' className='circle-icon' />
        <h4 className='categories__breadcrumb--title'>ÁREA DE EMPLEADO</h4>
      </div>
      <div className='categories__container'>
        {categoriesDataFiltered.employeeCategories.map((category) => (
          <div className='bubble__container' key={category.id} onClick={() => handleCategoryClick(category)}>
            <div className='bubble'>
              <img src={`data:image/${category.image_img};base64,${category.category_img}`} alt={`categorie-${category.name}`} />
              <h2 className='bubble__title'>{category.name}</h2>
            </div>
          </div>
        ))}
      </div>

      {/* Sección de managers, solo si custom15 es 'Y' */}
      {userInfoSSFF.custom15 === 'Y' && (
        <>
          <div className='categories__breadcrumb'>
            <img src={elipse355} alt='circle-icon' className='circle-icon' />
            <h4 className='categories__breadcrumb--title'>ÁREA DE MANAGER</h4>
          </div>
          <div className='categories__container'>
            {categoriesDataFiltered.managerCategories.map((category) => (
              <div className='bubble__container' key={category.id} onClick={() => handleCategoryClick(category)}>
                <div className='bubble'>
                  <img src={`data:image/${category.image_img};base64,${category.category_img}`} alt={`categorie-${category.name}`} />
                  <h2 className='bubble__title'>{category.name}</h2>
                  <div className='managerTag'>
                    <p className='managerTag__text'>Manager</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default BubblesCategories;
