import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import ArrowPDF from '../../../assests/FileComponentes/PDFIcons/ArrowPDF';

const styles = StyleSheet.create({
  section: {
    width: 'auto',
    height: 'auto',
  },
  table: {
    marginTop: 10,
    fontSize: 10,
  },
  heading: {
    fontSize: 12,
    color: '#007bff',
    marginTop: 10,
  },
  tableRow: {
    flexDirection: 'row',
    marginBottom: 5,
    marginRight: 10,
  },
  yearColumn: {
    width: '20%',
  },
  contentColumn: {
    width: '80%',
  },
  subtitle: {
    fontSize: 8,
    fontFamily: 'Helvetica',
  },
  ovalContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
    fontSize: 8,
    marginBottom: 9,
  },
  oval: {
    flexDirection: "row",
    width: 80,
    height: 27,
    backgroundColor: '#007bff',
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    marginRight: 5,
    paddingRight: 10,
  },
  circle: {
    backgroundColor: '#007bff',
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 9,

  },
  ovalText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    marginLeft: 2,
  },
  textSmall: {
    fontSize: 8,
    marginLeft: 2,
  },
  arrowContainer: {
    position: 'relative',
    marginLeft: 2,
    zIndex: 10,
  },
  smallFontSize: {
    fontSize: 8,
    marginRight: 5,
    marginLeft: 5,
    marginTop: 0,
    marginBottom: 0,
  },
});


const renderDateRange = (experience) => {
  const { from_year, to_year } = experience;

  if (!to_year) {
    return `${from_year}`;
  } else {
    return `${from_year} - ${to_year}`;
  }
}; 

const ExperiencePDF = ({ empleadoDetails }) => {
  if (!empleadoDetails) return null;

  // Filtramos los clusters verdaderos
  const positions = [
    { key: 'cluster_ceo', label: 'CEO' },
    { key: 'cluster_b2c_growth', label: 'B2C' },
    { key: 'cluster_b2b_growth', label: 'B2B' },
    { key: 'cluster_technology', label: 'TEC' },
    { key: 'cluster_enablers', label: 'EN' }
  ].filter(position => empleadoDetails[position.key]);

  // Determinamos si se requiere una fuente más pequeña
  const isSmallFont = empleadoDetails.experiences?.length > 11;

  return (
    <View style={styles.section}>
      {/* Sección de Clusters */}
      <View style={styles.ovalContainer}>
        {positions.length <= 2 ? (
          // Mostrar todos los clusters en un solo óvalo si hay 1 o 2
          <View style={styles.oval}>
            <View style={styles.arrowContainer}>
              <ArrowPDF width={16} height={16} />
            </View>
            <Text style={styles.ovalText}>
              {positions.map((position, index) => (
                <Text key={index}>
                  {position.label}
                  {index < positions.length - 1 ? " & " : ""}
                </Text>
              ))}
            </Text>
          </View>
        ) : (
          // Mostrar cada cluster en su propio círculo si hay más de 2
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '5px' }}>
            {positions.map((position, index) => (
              <View key={index} style={[styles.circle, { margin: 5 }]}>
                <View style={styles.arrowContainer}>
                  <ArrowPDF width={16} height={16} />
                </View>
                <Text style={styles.ovalText}>{position.label}</Text>
              </View>
            ))}
          </View>
        )}
      </View>

      {/* Sección de Experiencia Profesional */}
      <Text style={styles.heading}>Career Experience</Text>
      <View style={styles.table}>
        {empleadoDetails.experiences?.map((exp, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.yearColumn, isSmallFont && styles.smallFontSize]}>
              {renderDateRange(exp)}
            </Text>
            <View style={[styles.tableCell, styles.contentColumn]}>
              <Text style={isSmallFont && styles.smallFontSize}>{exp.job_title}</Text>
              <Text style={[styles.subtitle, isSmallFont && styles.smallFontSize]}>{exp.company}</Text>
            </View>
          </View>
        ))}
      </View>

      {/* Sección de Educación */}
      <Text style={styles.heading}>Education</Text>
      <View style={styles.table}>
        {empleadoDetails.education?.map((edu, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.yearColumn, isSmallFont && styles.smallFontSize]}>
              {renderDateRange(edu)}
            </Text>
            <View style={[styles.tableCell, styles.contentColumn]}>
              <Text style={isSmallFont && styles.smallFontSize}>{edu.title}</Text>
              <Text style={[styles.subtitle, isSmallFont && styles.smallFontSize]}>{edu.institution}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};


export default ExperiencePDF;
