import React, { useEffect, useRef, useState } from "react";

import SpainComponent from "../../assests/FileComponentes/countries/SpainComponent";
import GermanyComponent from "../../assests/FileComponentes/countries/GermanyComponent";
import UruguayComponent from "../../assests/FileComponentes/countries/UruguayComponent";
import ArgentinaIconComponent from "../../assests/FileComponentes/countries/ArgentinaIconComponent";
import ChileIconComponent from "../../assests/FileComponentes/countries/ChileIconComponent";
import ColombiaIconComponent from "../../assests/FileComponentes/countries/ColombiaIconComponent";
import EcuadorIconComponent from "../../assests/FileComponentes/countries/EcuadorIconComponent";
import PeruIconComponent from "../../assests/FileComponentes/countries/PeruIconComponent";
import BrasilIconComponent from "../../assests/FileComponentes/countries/BrasilIconComponent";
import UkIconComponent from "../../assests/FileComponentes/countries/UkIconComponent";
import MexicoIconComponent from "../../assests/FileComponentes/countries/MexicoIconComponent";
import SouthIconComponent from "../../assests/FileComponentes/countries/SouthIconComponent";
import SiIconComponent from "../../assests/FileComponentes/countries/SiIconMovilityComponent";
import NoIconComponent from "../../assests/FileComponentes/countries/NoIconMovilityComponent";
import EuropeComponent from "../../assests/FileComponentes/countries/EuropeIconComponent";

import '../Styles/SecondTalentView/othersComponent.css'
import globalStyles from "../../Styles/globalStyle";
import { toast } from "react-toastify";
import CountryModal from "../../TalentFiles/Components/CountryModal";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { updateEmployee } from "../../../services/TalentCards/FetchUpdateEmployee";
import ClusterComponent from "./ClusterComponent";
import CryptoJS from 'crypto-js';
import ClusterEditModal from "./clusterEditModal";
import AddIcon from "../../assests/mainPageComponent/sideBard/AddIcon";

const countryIconComponents = {
  Spain: SpainComponent,
  Germany: GermanyComponent,
  Uruguay: UruguayComponent,
  Argentina: ArgentinaIconComponent,
  Chile: ChileIconComponent,
  Colombia: ColombiaIconComponent,
  Ecuador: EcuadorIconComponent,
  Peru: PeruIconComponent,
  Brazil : BrasilIconComponent,
  UK:UkIconComponent,
  Mexico: MexicoIconComponent,
  Hispam:SouthIconComponent,
  SI:SiIconComponent,
  YES:SiIconComponent,
  NO:NoIconComponent,
  null:NoIconComponent,
  Europe:EuropeComponent
};

function getCountryIconComponent(countryName) {
  return countryIconComponents[countryName] || DefaultCountryIconComponent;
}

const DefaultCountryIconComponent = () => (
  <div style={{ width: "24px", height: "24px", background: "gray", marginRight: "5px" }} />
);


function OthersComponent({ empleadoDetails,selectedEmployeeId,onUpdateEmployeeDetails,
  userInfoSSFF, currentUserEmail,
  userPermissions,idToken,
  jwtSSFF}) {
  
  const secretKey = process.env.REACT_APP_TALENT_APP;
  
  const encrypted = CryptoJS.AES.encrypt(selectedEmployeeId, secretKey).toString();
  const encryptedSelectedId = encodeURIComponent(btoa(encrypted));

  //const userId = userInfoSSFF.userId;
  //Modal
  const [isModalCountryOpen, setIsModalCountryOpen] = useState(false);

  //NEWCONFIGURATION
  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState({
    /* potential_years_role: '', */
    potential_future_role: '',
    sucession_pipleline: '',
    successors: '',
    career_action_plan: '',
    additional_insights: '',
    mobility_text:' '
  });

  const [editingField, setEditingField] = useState(null);
  const [tempText, setTempText] = useState('');
  const [prevText, setPrevText] = useState('');
  const [isModalClusterOpen, setIsModalClusterOpen]= useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);



  const yearRef = useRef(null);
  const futureRef = useRef(null);
  const pipelineRef = useRef(null);
  const successorsRef = useRef(null);
  const careerRef = useRef(null);
  const insightsRef = useRef(null);
  const mobilityRef = useRef(null);

  useEffect(() => {
    if (empleadoDetails) {
      setFormData({
       /*  potential_years_role: empleadoDetails.potential_years_role || '', */
        potential_future_role: empleadoDetails.potential_future_role || '',
        sucession_pipleline: empleadoDetails.sucession_pipleline || '',
        successors: empleadoDetails.successors || '',
        career_action_plan: empleadoDetails.career_action_plan || '',
        additional_insights: empleadoDetails.additional_insights || 'Insert Additional Insights',
        mobility_text: empleadoDetails.mobility_text || 'Insert Additional Mobility'
      });
    }
  }, [empleadoDetails]);


  const handleBlur = async (field, ref) => {
    let value = tempText.trim();

    if (!value) {
      toast.error('El campo no puede estar vacío.');
      if (ref && ref.current) {
        ref.current.innerText = prevText;
      }
      setEditable(false);
      setEditingField(null);
      return;
     
    }

    confirmAlert({
      title: '¿Seguro?',
      message: '¿Estás seguro de modificar esto?',
      buttons: [
        {
          label: 'Sí',
          onClick: async () => {
            const updatedData = { ...empleadoDetails };
            updatedData[field] = value;

            try {
              await updateEmployee(encryptedSelectedId, updatedData, idToken,jwtSSFF);

              if (ref && ref.current) {
                ref.current.innerText = value;
              }

              setFormData(prevFormData => ({
                ...prevFormData,
                [field]: value
              }));

              onUpdateEmployeeDetails(updatedData); // Propagar los cambios al componente padre

            } catch (error) {
              console.error('Error updating employee:', error);
              toast.error('Error al actualizar los datos.');
              if (ref && ref.current) {
                ref.current.innerText = prevText;
              }
            }
            setEditable(false);
            setEditingField(null);
          }
        },
        {
          label: 'No',
          onClick: () => {
            if (ref && ref.current) {
              ref.current.innerText = prevText;
            }
            setEditable(false);
            setEditingField(null);
          }
        }
      ]
    });

  };

  const handleDoubleClick = (field, ref) => {
    if (userPermissions.edit_talent_cards) {
        setEditable(true);
        setEditingField(field);
        setTempText(formData[field]);
        setPrevText(formData[field]);
        setTimeout(() => {
          if (ref && ref.current) {
            ref.current.focus();
          }
        }, 0);
      }
  };

  const handleChange = (e) => {
    setTempText(e.target.innerText);
  };

  const handleKeyDown = (e,field, ref) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setFormData({
        ...formData,
        [field]: tempText
      });
      handleBlur(field, ref);
    }
  };

  const handleOpenCountryModal=()=>{
    if (userPermissions.edit_talent_cards) {
    setIsModalCountryOpen(true);
    }
    
  };
  const handleCloseCountryModal =()=>{
    setIsModalCountryOpen(false);

  };

  //clusterEditModal
  const handleOpenCLusterModal = () => {
    if (userPermissions.edit_talent_cards) {  
      setIsModalClusterOpen(true);
    }
  };

  //clusterEdit
  const handleCloseCLusterModal = () => {
    setIsModalClusterOpen(false);
  };

  //visibilidad de los Modal agregar
  const handleMouseEnter = () => {
    setIsButtonVisible(true);
  };

  const handleMouseLeave = () => {
    setIsButtonVisible(false);
  };
  

  const handleSaveClusters = async (updatedData) => {
    try {
      await updateEmployee(encryptedSelectedId, updatedData, idToken, jwtSSFF);
      onUpdateEmployeeDetails(updatedData);
      toast.success("Clusters actualizados correctamente.", {
        autoClose: 1000,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
      });
    } catch (error) {
      console.error("Error actualizando clusters:", error);
      toast.error("Error al actualizar los clusters.");
    }
  };
  
  const positions = [
    { key: 'cluster_ceo', label: 'CEO' },
    { key: 'cluster_b2c_growth', label: 'B2C' },
    { key: 'cluster_b2b_growth', label: 'B2B' },
    { key: 'cluster_technology', label: 'TEC' },
    { key: 'cluster_enablers', label: 'EN' },
    { key: 'cluster_pm_transversal', label: 'PM' }

  ].filter(position => empleadoDetails[position.key]);

  const activeClustersother = positions.length > 0;
  
  return (
    <div className="other-container">
      <div className='cluster-exp-others ms-2'>
      {activeClustersother ? (
        <div  onClick={handleOpenCLusterModal}>
          <ClusterComponent  className='ms-5' positions={positions} empleadoDetails={empleadoDetails}  />
        </div>
        ) : (
          <div className='' >
            <h4 className="text-primary fs-4 add-cluster mt-4"
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
             >Add Cluster
             {
            isButtonVisible&&(
            <AddIcon className="icon-Add ms-2"  onClick={handleOpenCLusterModal} />
            )
            }
            </h4>
            

          </div>
        
          )}
        <ClusterEditModal
            isOpen={isModalClusterOpen}
            empleadoDetails={empleadoDetails}
            onSave={handleSaveClusters}
            onClose={handleCloseCLusterModal}
          />
      </div>


      <p className="experience text-primary font-weight-light  ms-2 potential-title">
        Potential to Value
      </p>
      <div className="potencial-value">
        {empleadoDetails.potential_future_role && (
          <div className="d-flex align-items-start mt-3 ms-2">
            <span className=" me-2">
              Potential to future role(s):
            </span>
            <p className="mb-0 text-break"
              ref={futureRef}
              contentEditable={editable && editingField === 'potential_future_role'}
              suppressContentEditableWarning
              onInput={handleChange}
              onBlur={() => handleBlur('potential_future_role', futureRef)}
              onKeyDown={(e) => handleKeyDown(e, 'potential_future_role', futureRef)}
              onDoubleClick={() => handleDoubleClick('potential_future_role', futureRef)}
            >{formData.potential_future_role}</p>
          </div>
        )}

        {empleadoDetails.sucession_pipleline && (
          <div className="d-flex align-items-start mt-3 ms-2">
            <p className="me-2">
              Succession pipeline:
            </p>
            <p className="mb-0 text-break"
              ref={pipelineRef}
              contentEditable={editable && editingField === 'sucession_pipleline'}
              suppressContentEditableWarning
              onInput={handleChange}
              onBlur={() => handleBlur('sucession_pipleline', pipelineRef)}
              onKeyDown={(e) => handleKeyDown(e, 'sucession_pipleline', pipelineRef)}
              onDoubleClick={() => handleDoubleClick('sucession_pipleline', pipelineRef)}
            >
              {formData.sucession_pipleline}
            </p>
          </div>
        )}

        {empleadoDetails.successors && (
          <div className="d-flex align-items-start ms-2 mt-0">
            <p className="me-2">
              Successor(s):
            </p>
            <p className="mb-0 text-break"
              ref={successorsRef}
              contentEditable={editable && editingField === 'successors'}
              suppressContentEditableWarning
              onInput={handleChange}
              onBlur={() => handleBlur('successors', successorsRef)}
              onKeyDown={(e) => handleKeyDown(e, 'successors', successorsRef)}
              onDoubleClick={() => handleDoubleClick('successors', successorsRef)}
            >
              {formData.successors}
            </p>
          </div>
        )}
      </div>

      <h4
        className="experience fs-3  text-primary font-weight-light ms-2 mobility-title" style={{ ...globalStyles.color }}
      >
        Mobility to
      </h4>
  
      <div
        className="Mobility-content ms-2"
        style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", cursor:"pointer"}}
        onClick={handleOpenCountryModal}
      >
        {/* Renderizar los iconos de los países */}
        <div style={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap" }}>
          {empleadoDetails.mobility && empleadoDetails.mobility.map((mobilityItem, index) => {
            const CountryIconComponent = getCountryIconComponent(mobilityItem.country);
            return CountryIconComponent ? (
              <div key={index} className="d-flex flex-column align-items-center me-3 mb-2 mt-2">
                <CountryIconComponent className="rounded-circle mb-2 mt-2 country" />
                <span className="name-country fs-6">{mobilityItem.country}</span>
              </div>
            ) : (
              <div
                key={index}
                style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: "10px", marginBottom: "5px" }}
              >
                <DefaultCountryIconComponent />
                <span>{mobilityItem.country}</span>
              </div>
            );
          })}
        </div>
      </div>




      <p className="ms-2 mt-2 aditional-mobilty-info"
        ref={mobilityRef}
        contentEditable={editable && editingField === 'mobility_text'}
        suppressContentEditableWarning
        onInput={handleChange}
        onBlur={() => handleBlur('mobility_text', mobilityRef)}
        onKeyDown={(e) => handleKeyDown(e, 'mobility_text', mobilityRef)}
        onDoubleClick={() => handleDoubleClick('mobility_text', mobilityRef)}
      >{formData.mobility_text || "No Additional Mobility Available"}</p>


      <CountryModal
        isOpen={isModalCountryOpen}
        selectedEmployeeId={selectedEmployeeId}
        empleadoDetails={empleadoDetails}
        onClose={handleCloseCountryModal}
        userInfoSSFF={userInfoSSFF}
        onUpdateEmployeeDetails={onUpdateEmployeeDetails}
        idToken = {idToken}
        jwtSSFF = {jwtSSFF}
      />
      <h4
        className="experience fs-3  text-primary font-weight-light ms-2 carrer-title"
        style={{ ...globalStyles.color }}
      >
        Career Action Plan
      </h4>

      <div className="mb-3 ms-2 ">
        <ul className="list-unstyled ">
          <li className="mt-2 text-break carrer-action"
            ref={careerRef}
            contentEditable={editable && editingField === 'career_action_plan'}
            suppressContentEditableWarning
            onInput={handleChange}
            onBlur={() => handleBlur('career_action_plan', careerRef)}
            onKeyDown={(e) => handleKeyDown(e, 'career_action_plan', careerRef)}
            onDoubleClick={() => handleDoubleClick('career_action_plan', careerRef)}
          >
            {formData.career_action_plan || "No Action Plan Available"}
          </li>
        </ul>
      </div>

      <h4
        className="experience  text-primary font-weight-light fs-3 ms-2 aditional-title"
        style={{ ...globalStyles.color }}
      >
        Additional insights
      </h4>
      <div className="ms-2">
        <p
          className="fs-5 text-break aditional-Text"
          ref={insightsRef}
          contentEditable={editable && editingField === 'additional_insights'}
          suppressContentEditableWarning
          onInput={handleChange}
          onBlur={() => handleBlur('additional_insights', insightsRef)}
          onKeyDown={(e) => handleKeyDown(e, 'additional_insights', insightsRef)}
          onDoubleClick={() => handleDoubleClick('additional_insights', insightsRef)}
        >
          {formData.additional_insights}
        </p>
      </div>

    </div>
  );
}

export default OthersComponent;
