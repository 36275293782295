import * as React from "react"
const BrasilIconComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    fill="none"
    viewBox="0 -4 28 28"
    {...props}
  >
    <g clipPath="url(#a)">
      <rect width={28} height={20} fill="#fff" rx={2} />
      <mask
        id="b"
        width={28}
        height={20}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "alpha",
        }}
      >
        <rect width={28} height={20} fill="#fff" rx={2} />
      </mask>
      <g mask="url(#b)">
        <path fill="#05AB41" d="M0 0h28v20H0z" />
        <path
          fill="#FDD216"
          fillRule="evenodd"
          d="M3.532 10.56a.667.667 0 0 1 0-1.12l10.106-6.539a.667.667 0 0 1 .724 0l10.106 6.54a.667.667 0 0 1 0 1.119l-10.106 6.539a.667.667 0 0 1-.724 0l-10.106-6.54Z"
          clipRule="evenodd"
        />
        <path
          fill="#053087"
          fillRule="evenodd"
          d="M14 14.667a4.667 4.667 0 1 0 0-9.334 4.667 4.667 0 0 0 0 9.334Z"
          clipRule="evenodd"
        />
        <mask
          id="c"
          width={10}
          height={10}
          x={9}
          y={5}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "alpha",
          }}
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M14 14.667a4.667 4.667 0 1 0 0-9.334 4.667 4.667 0 0 0 0 9.334Z"
            clipRule="evenodd"
          />
        </mask>
        <g mask="url(#c)">
          <path
            stroke="#fff"
            strokeLinecap="square"
            strokeWidth={1.333}
            d="M8.78 8.78c.733-.593 3.097-.031 5.304.558 2.207.588 4.509 1.822 5.095 2.546"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <rect width={28} height={20} fill="#fff" rx={2} />
      </clipPath>
    </defs>
  </svg>
)
export default BrasilIconComponent
