import React, { useState, useEffect } from 'react';
import { ValidateForm } from './validateFormManager';
import { handleSubmit } from './handleSubmit';
import { Link } from 'react-router-dom';
import elipse355 from '../../../../images/ellipse355.png';
import '../../../styles/new/newEmployeeForm.css';
import { sortAlphabetically } from '../../../../utils/utils';
import provinciasJson from '../../../../utils/provincias.json';
import municipiosJson from '../../../../utils/municipios.json';

const NewEmpFormManagerMain = ({ userInfoSSFF }) => {
  const [formData, setFormData] = useState({
    applicantName: userInfoSSFF?.displayName || '',
    applicantEmail: userInfoSSFF?.username || '',
    businessUnit: userInfoSSFF?.custom02 || '',
    division: userInfoSSFF?.division || '',
    department: userInfoSSFF?.department || '',
    vacancyType: '',
    replacementName: '',
    sameFunctions: '',
    isIntern: 'No', // Predeterminado
    internName: '',
    needReason: '',
    criticality: '',
    responsibilities: '',
    skills: '',
    project: '',
    location: '',
    city: '',
    building: '',
    floor: '',
    desiredDate: '',
    successFactors: 'No', // Predeterminado
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [municipiosFiltered, setMunicipiosFiltered] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === 'location') {
      const idProvincia = value; // El id de la provincia seleccionada
      const filteredMunicipios = municipiosJson.filter((mun) => mun.id.startsWith(idProvincia));
      setMunicipiosFiltered(filteredMunicipios);
      setFormData((prevFormData) => ({
        ...prevFormData,
        city: '', // Reiniciar la selección de municipio
      }));
    }
  };

  const handleClear = () => {
    setFormData({
      applicantName: userInfoSSFF?.defaultFullName || '',
      applicantEmail: userInfoSSFF?.email || '',
      businessUnit: userInfoSSFF?.custom02 || '',
      division: userInfoSSFF?.division || '',
      department: userInfoSSFF?.department || '',
      vacancyType: '',
      replacementName: '',
      sameFunctions: '',
      isIntern: 'No', // Predeterminado
      internName: '',
      needReason: '',
      criticality: '',
      responsibilities: '',
      skills: '',
      project: '',
      location: '',
      city: '',
      building: '',
      floor: '',
      desiredDate: '',
      successFactors: 'No', // Predeterminado
    });
  };

  useEffect(() => {
    // Asegurar que los valores predeterminados estén configurados.
    setFormData((prevFormData) => ({
      ...prevFormData,
      isIntern: 'No',
      successFactors: 'No',
    }));
  }, []);

  const isButtonEnabled = !isSubmitting && Object.keys(errors).length === 0;

  return (
    <div className='combined-form'>
      <div className='jiraNewForm__breadcrumb'>
        <img src={elipse355} alt='circle-icon' className='circle-icon' />
        <Link to='/form' className='jiraNewForm__breadcrumb--title'>
          INICIO
        </Link>
        <div className='jiraNewForm__breadcrumb--text'>&gt; SOLICITUD DE ALTA</div>
      </div>
      <h1 className='formJiraNew__title'>Solicitud de Alta (manager)</h1>
      <form onSubmit={(e) => handleSubmit(e, formData, setIsSubmitting, handleClear, setErrors, ValidateForm)}>
        <div className='formJiraNewSection'>
          {/* Nombre y apellidos del solicitante */}
          <div className='formJiraNewSection_both'>
            <div className='formJiraNewSection_bothIndividual'>
              <label className='formJiraNewLabel_short'> Nombre y apellidos del solicitante:</label>
              <input
                type='text'
                name='applicantName'
                placeholder='Nombre y apellidos del solicitante...'
                className='formJiraNewInputText_short'
                value={formData.applicantName}
                onChange={handleChange}
              />
            </div>
            {/* Email del solicitante */}
            <div className='formJiraNewSection_bothIndividual'>
              <label className='formJiraNewLabel_short'> Email del solicitante:</label>
              <input
                type='email'
                name='applicantEmail'
                placeholder='Email del solicitante...'
                className='formJiraNewInputText_short'
                value={formData.applicantEmail}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Unidad de negocio */}
          <div className='formJiraNewSection_both'>
            <div className='formJiraNewSection_bothIndividual'>
              <label className='formJiraNewLabel_short'> Unidad de negocio:</label>
              <input
                type='text'
                name='businessUnit'
                placeholder='Unidad de negocio...'
                className='formJiraNewInputText_short'
                value={formData.businessUnit}
                onChange={handleChange}
              />
            </div>

            {/* División */}
            <div className='formJiraNewSection_bothIndividual'>
              <label className='formJiraNewLabel_short'> División:</label>
              <input
                type='text'
                name='division'
                placeholder='División...'
                className='formJiraNewInputText_short'
                value={formData.division}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Departamento */}
          <div className='formJiraNewSection_both'>
            <div className='formJiraNewSection'>
              <label className='formJiraNewLabel'> Departamento:</label>
              <input
                type='text'
                name='departament'
                placeholder='Departamento...'
                className='formJiraNewInputText_short'
                value={formData.department}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* ¿Se trata de una vacante nueva o de una sustitución? */}
          <div className='formJiraNewSection'>
            <label className='formJiraNewLabel'> ¿Se trata de una vacante nueva o de una sustitución?</label>
            <select name='vacancyType' className='formJiraNewSelect' value={formData.vacancyType} onChange={handleChange}>
              <option value=''>Seleccione una opción...</option>
              <option value='Nueva vacante'>Nueva vacante</option>
              <option value='Sustitución'>Sustitución</option>
            </select>
          </div>

          {/* Nombre y apellidos de la persona que se va a sustituir */}
          {formData.vacancyType === 'Sustitución' && (
            <>
              <div className='formJiraNewSection'>
                <label className='formJiraNewLabel'> Nombre y apellidos de la persona que se va a sustituir:</label>
                <input
                  type='text'
                  name='replacementName'
                  placeholder='Nombre y apellidos de la persona sustituida...'
                  className='formJiraNewInputText_short'
                  value={formData.replacementName}
                  onChange={handleChange}
                />
              </div>

              {/* ¿Estas funciones son las mismas que realizaba la persona sustituida o van a variar? */}
              <div className='formJiraNewSection'>
                <label className='formJiraNewLabel'>¿Estas funciones son las mismas que realizaba la persona sustituida o van a variar?</label>
                <select
                  name='sameFunctions'
                  className={`formJiraNewInputSelect ${errors.sameFunctions ? 'errorBorder' : ''}`}
                  value={formData.sameFunctions || ''}
                  onChange={handleChange}
                >
                  <option value=''>Seleccione una opción...</option>
                  <option value='Son exactamente las mismas'>Son exactamente las mismas</option>
                  <option value='Varían'>Varían</option>
                </select>
              </div>
            </>
          )}

          {/* ¿Se trata de la contratación de un becario? */}
          <div className='formJiraNewSection'>
            <label className='formJiraNewLabel'>¿Se trata de la contratación de un becario?</label>
            <div>
              <label>
                <input type='radio' name='isIntern' value='Sí' checked={formData.isIntern === 'Sí'} onChange={handleChange} />
                Sí
              </label>
              <label>
                <input type='radio' name='isIntern' value='No' checked={formData.isIntern === 'No'} onChange={handleChange} />
                No
              </label>
            </div>
          </div>

          {/* Nombre y apellidos del becario que se va a contratar */}
          {formData.isIntern === 'Sí' && (
            <div className='formJiraNewSection'>
              <label className='formJiraNewLabel'> Nombre y apellidos del becario que se va a contratar:</label>
              <input
                type='text'
                name='internName'
                placeholder='Nombre y apellidos del becario...'
                className='formJiraNewInputText_short'
                value={formData.internName}
                onChange={handleChange}
              />
            </div>
          )}

          {/* ¿Cuál es la necesidad de incorporación? */}
          <div className='formJiraNewSection'>
            <label className='formJiraNewLabel'> ¿Cuál es la necesidad de incorporación?</label>
            <textarea
              name='needReason'
              placeholder='Describe la necesidad de incorporación...'
              className={`formNewArticleTextarea ${errors.needReason ? 'errorBorder' : ''}`}
              value={formData.needReason}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* ¿Cómo de crítica es esta incorporación? */}
        <div className='formJiraNewSection'>
          <label className='formJiraNewLabel'> ¿Cómo de crítica es esta incorporación?</label>
          <select name='criticality' className={`formJiraNewSelect`} value={formData.criticality || ''} onChange={handleChange}>
            <option value=''>Seleccione una opción...</option>
            <option value='Alta'>Alta</option>
            <option value='Media'>Media</option>
            <option value='Baja'>Baja</option>
          </select>
        </div>

        {/* Principales funciones/responsabilidades */}
        <div className='formJiraNewSection'>
          <label className='formJiraNewLabel'>Principales funciones/responsabilidades que desempeñaría la persona a incorporar:</label>
          <textarea
            name='responsibilities'
            placeholder='Describa las funciones principales...'
            className='formNewArticleTextarea'
            value={formData.responsibilities || ''}
            onChange={handleChange}
          />
        </div>

        {/* Skills necesarias */}
        <div className='formJiraNewSection'>
          <label className='formJiraNewLabel'> Skills necesarias:</label>
          <textarea
            name='skills'
            placeholder='Describa las skills necesarias...'
            className='formNewArticleTextarea'
            value={formData.skills || ''}
            onChange={handleChange}
          />
        </div>

        {/* ¿A qué proyecto/proyectos estará dedicada la persona a incorporar? */}
        <div className='formJiraNewSection'>
          <label className='formJiraNewLabel'> ¿A qué proyecto/proyectos estará dedicada la persona a incorporar?</label>
          <textarea
            name='project'
            placeholder='Describe el proyecto o proyectos...'
            className='formNewArticleTextarea'
            value={formData.project || ''}
            onChange={handleChange}
          />
        </div>

        {/* Provincia de trabajo */}
        <div className='formJiraNewSection_both'>
          <div className='formJiraNewSection_bothIndividual'>
            <label className='formJiraNewLabel_short'>Provincia de trabajo:</label>
            <select name='location' value={formData.location} onChange={handleChange} className='formJiraNewInputSelect'>
              <option value=''>Seleccione una provincia...</option>
              {sortAlphabetically(provinciasJson, 'nm').map((provincia) => (
                <option key={provincia.id} value={provincia.id}>
                  {provincia.nm}
                </option>
              ))}
            </select>
          </div>

          {/* Localidad de trabajo */}
          <div className='formJiraNewSection_bothIndividual'>
            <label className='formJiraNewLabel_short'>Localidad de trabajo:</label>
            <select
              nameLocation='city'
              value={formData.city}
              onChange={handleChange}
              className='formJiraNewInputSelect'
              disabled={!formData.location} // Deshabilitar si no hay provincia seleccionada
            >
              <option value=''>Seleccione una localidad...</option>
              {sortAlphabetically(municipiosFiltered, 'nm').map((municipio) => (
                <option key={municipio.id} value={municipio.nm}>
                  {municipio.nm}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Edificio */}
        <div className='formJiraNewSection_both'>
          <div className='formJiraNewSection_bothIndividual'>
            <label className='formJiraNewLabel_short'>Edificio:</label>
            <input
              type='text'
              name='building'
              placeholder='Edificio...'
              className='formJiraNewInputText_short'
              value={formData.building}
              onChange={handleChange}
            />
          </div>

          {/* Planta */}
          <div className='formJiraNewSection_bothIndividual'>
            <label className='formJiraNewLabel_short'>Planta:</label>
            <input
              type='text'
              name='floor'
              placeholder='Planta...'
              className='formJiraNewInputText_short'
              value={formData.floor}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Fecha de incorporación deseada */}
        <div className='formJiraNewSection_both'>
          <div className='formJiraNewSection'>
            <label className='formJiraNewLabel'>Fecha de incorporación deseada:</label>
            <input
              type='date'
              name='desiredDate'
              className={`formJiraNewInputText_short ${errors.desiredDate ? 'errorBorder' : ''}`}
              value={formData.desiredDate || ''}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* ¿Necesitas acceso al módulo de Reclutamiento en SuccessFactors? */}
        <div className='formJiraNewSection'>
          <label className='formJiraNewLabel'>¿Necesitas acceso al módulo de Reclutamiento en SuccessFactors?</label>
          <div className='formJiraNewRadio_options'>
            <label className='formJiraNewRadioLabel'>
              <input type='radio' name='successFactors' value='Sí' checked={formData.successFactors === 'Sí'} onChange={handleChange} />
              Sí
            </label>
            <label className='formJiraNewRadioLabel'>
              <input type='radio' name='successFactors' value='No' checked={formData.successFactors === 'No'} onChange={handleChange} />
              No
            </label>
          </div>
        </div>

        <div className='formJiraNewButton_section'>
          <button type='button' className='secondaryButton' onClick={handleClear}>
            Limpiar
          </button>
          <button className={`formJiraNewButton`} type='submit'>
            Enviar
          </button>
          {isSubmitting && <div className='loader'></div>}
        </div>
      </form>
    </div>
  );
};

export default NewEmpFormManagerMain;
