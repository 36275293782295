import { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import DOMpurify from 'dompurify';
import { updateCanalAtencionTicket } from '../../../services/apiJira';

export const EditPopup = ({ ticket, selectedSummary, asignee, selectedStatus, selectedPriority, onClose }) => {
  const secretKey = 'your-secret-key';

  const sanitize = (value) => DOMpurify.sanitize(value);
  const encryptValue = (value) => CryptoJS.AES.encrypt(value, secretKey).toString();

  const [ticketData, setTicketData] = useState({
    issueType: selectedSummary || ticket?.fields?.issuetype?.name || '',
    summary: selectedSummary || ticket?.fields?.summary || '',
    status: selectedStatus || ticket?.fields?.status?.name || '',
    priority: selectedPriority || ticket?.fields?.priority?.name || '',
    asignee: asignee || ticket?.fields?.customfield_10404 || '',
  });

  const [feedbackMessage, setFeedbackMessage] = useState(''); // Mensaje de resultado
  const [showFeedback, setShowFeedback] = useState(false); // Mostrar u ocultar mensaje
  const [responsableOptions, setResponsableOptions] = useState([]);

  const responsableEmails = {
    'SOPORTE MANAGER': [
      'adriana.bermeojaramillo@telefonica.com',
      'carmen.arevalo@telefonica.com',
      'david.alonsogarcia@telefonica.com',
      'joseignacio.yeronmayoral@telefonica.com',
      'laura.guijarromolina@telefonica.com',
      'mariajulia.reysanchez@telefonica.com',
    ],
    'SOPORTE EMPLEADO': [
      'ana.rodriguezperez.ext@telefonica.com',
      'fatimageraldine.santoslubo.ext@telefonica.com',
      'jesus.figueroafernandez.ext@telefonica.com',
      'ursulaana.demenaperez.ext@telefonica.com',
      'veronica.montanesguerra.ext@telefonica.com',
      'mariaisabel.saraivaamaral.ext@telefonica.com',
    ],
  };

  useEffect(() => {
    if (ticket) {
      setTicketData({
        issueType: ticket?.fields?.issuetype?.name || '',
        summary: ticket?.fields?.summary || '',
        status: ticket?.fields?.status?.name || '',
        priority: ticket?.fields?.priority?.name || '',
        asignee: ticket?.fields?.customfield_10404 || '',
      });
    }
  }, [ticket]);

  useEffect(() => {
    const selectedSupportType = ticketData.summary;
    setResponsableOptions(responsableEmails[selectedSupportType] || []);
  }, [ticketData.summary]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTicketData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const prepareCustomFields = () => {
    const sanitizedAsignee = sanitize(ticketData.asignee);
    const encryptedAsignee = encryptValue(sanitizedAsignee);

    const customFields = {
      customfield_10404: encryptedAsignee,
    };

    Object.keys(customFields).forEach((key) => {
      if (!customFields[key]) {
        delete customFields[key];
      }
    });

    return customFields;
  };

  const handleSave = async () => {
    const customFields = prepareCustomFields();
    const updatedTicketData = {
      ...ticketData,
      ...customFields,
    };

    try {
      const response = await updateCanalAtencionTicket(updatedTicketData, ticket.id);
      console.log('response.status', response.status);
      if (response.status === 200) {
        setFeedbackMessage('Se ha actualizado el ticket correctamente');
        onClose();
      } else {
        setFeedbackMessage('No ha sido posible actualizar el ticket. Inténtelo en unos momentos');
      }
    } catch (error) {
      setFeedbackMessage('No ha sido posible actualizar el ticket. Inténtelo en unos momentos');
    }

    setShowFeedback(true); // Mostrar el mensaje
    setTimeout(() => {
      setShowFeedback(false); // Ocultar el mensaje después de 3 segundos
      if (feedbackMessage === 'Se ha actualizado el ticket correctamente') {
        onClose(); // Cerrar el popup si fue exitoso
      }
    }, 3000);
  };

  return (
    <div className='popup'>
      <div className='popup-content' style={{ width: '400px' }}>
        <h3>Editar Ticket - {ticket?.id}</h3>
        <div className='edit-field'>
          <label>Soporte</label>
          <select name='summary' value={ticketData.summary} onChange={handleInputChange}>
            <option value='SOPORTE MANAGER'>MANAGER</option>
            <option value='SOPORTE EMPLEADO'>EMPLEADO</option>
          </select>
        </div>
        <div className='edit-field'>
          <label>Responsable</label>
          <select name='asignee' value={ticketData.asignee} onChange={handleInputChange}>
            <option value=''>Seleccione un responsable</option>
            {responsableOptions.map((email) => (
              <option key={email} value={email}>
                {email}
              </option>
            ))}
          </select>
        </div>
        <div className='edit-field'>
          <label>Estado</label>
          <select name='status' value={ticketData.status} onChange={handleInputChange}>
            <option value='Backlog'>Abierto</option>
            <option value='En curso'>En curso</option>
            <option value='Finalizada'>Cerrado</option>
          </select>
        </div>
        <div className='edit-field'>
          <label>Prioridad</label>
          <select name='priority' value={ticketData.priority} onChange={handleInputChange}>
            <option value='High'>Alta</option>
            <option value='Medium'>Media</option>
            <option value='Low'>Baja</option>
          </select>
        </div>
        <div className='popup-buttons'>
          <button onClick={handleSave}>Guardar</button>
          <button onClick={onClose}>Cancelar</button>
        </div>

        {showFeedback && (
          <div className='feedback-popup' style={{ marginTop: '2em' }}>
            <p>{feedbackMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};
