import * as React from "react"

const ExitComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={28}
    viewBox="0 0 33 28"
    fill="none"
    {...props}
  >
    <path
      fill="#F2F4FF"
      d="M13.682.422.395 13.264c-.12.122-.215.256-.29.409a1.154 1.154 0 0 0 0 .958c.075.153.17.3.29.428l13.053 12.616a1.348 1.348 0 0 0 1.756 0c.537-.464.58-1.27.095-1.788L4.462 15.412h27.23c.72 0 1.308-.561 1.308-1.263 0-.702-.587-1.263-1.307-1.27H4.468l11.07-10.675c.022-.016.043-.038.064-.06l.031-.032a1.236 1.236 0 0 0-.1-1.788 1.342 1.342 0 0 0-1.851.098Z"
    />
  </svg>
)
export default ExitComponent
