export const fetchEmployeeDeleteList = async (idToken) => {
    try {
      const response = await fetch('/employeeDeleteList', {
        method: 'GET',
        headers: {
          'x-msal-Authorization': `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        throw new Error('Fallo en la solicitud de empleados eliminados');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error en la solicitud de empleados:', error.message);
      throw new Error('Error en la solicitud de empleados: ' + error.message);
    }
  };
  