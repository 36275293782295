import React from 'react';
import SearchIcon from '../../assests/mainPageComponent/Search';
 
const SearchBar = ({ searchTerm, setSearchTerm }) => {
 
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
 
  return (
    <div className=" mb-5 d-flex ms-3" style={{ width: '600px', height: '60px' }}>
      <div className="input-group">
        <span className="input-group-text border-0 bg-body-secondary bg-opacity-50 rounded-start-pill" id="search-addon">
          <SearchIcon />
        </span>
        <input
          type="search"
          className="form-control border-0 bg-body-secondary bg-opacity-50 rounded-end-pill"
          placeholder="Nombre, ID..."
          aria-label="Search"
          aria-describedby="search-addon"
          value={searchTerm}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};
 
export default SearchBar;