import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import searchIconHeader from '../../images/rolesPage-searcher.png';
import '../styles/rolesGroups.css';
import elipse355 from '../../images/ellipse355.png';

const RolesGroups = ({
  categories,
  extraInfo,
  selectedRole,
  setSelectedSubcategory,
  setHandleUpperCategoryIdParam,
  setIsPageGroup,
  handleFilterSearcher,
  filterInSearcher,
}) => {
  //,handleFilterSearcher,filterInSearcher,
  const [rolesPageSearcherPlaceholder, setRolesPageSearcherPlaceholder] = useState('');
  const [dataSubcategories, setDataSubcategories] = useState([]);
  const [dataExtraInfo, setDataExtraInfo] = useState([]);
  const [dataSelectedRole, setDataSelectedRole] = useState([]);

  //Inicializa la veriable "navigate" para cambiar la ruta de navegación
  const navigate = useNavigate();

  const handleChange = (ev) => {
    ev.preventDefault();
    setRolesPageSearcherPlaceholder(ev.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // Cambia a la ruta deseada
      handleFilterSearcher(event.target.value);
      setRolesPageSearcherPlaceholder('');
      setHandleUpperCategoryIdParam('138'); //(BusinessEX-40/ PeoplEX-133/ Roles-138)
      navigate('/search/articles');
    }
  };

  useEffect(() => {
    if (selectedRole.name !== undefined) {
      setDataSelectedRole(selectedRole);
      sessionStorage.setItem('dataselectedRole', JSON.stringify(selectedRole));
    } else {
      setDataSelectedRole(JSON.parse(sessionStorage.getItem('dataselectedRole')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (categories.length !== 0 && extraInfo.length !== 0) {
      setDataSubcategories(categories);
      sessionStorage.setItem('subcategories', JSON.stringify(categories));
      setDataExtraInfo(extraInfo);
      sessionStorage.setItem('extrainfo', JSON.stringify(extraInfo));
    } else {
      setDataSubcategories(JSON.parse(sessionStorage.getItem('subcategories')));
      setDataExtraInfo(JSON.parse(sessionStorage.getItem('extrainfo')));

      //sessionStorage.setItem('dataselectedSubcategory', sessionStorage.getItem('dataselectedSubcategory') );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className='rolesGroups'>
        <div className='rolesGroups__breadcrumb--container'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <Link to='/knowledgepage' className='rolesGroups__breadcrumb--title'>
            INICIO {''}
          </Link>
          <span className='rolesGroups__breadcrumb--text parent'>
            <Link to='/roles'>&gt; ROLES {''}</Link>
          </span>
          <span className='rolesGroups__breadcrumb--text'>&gt; {dataSelectedRole.name}</span>
        </div>
        <div className='rolesGroups__info'>
          <h2 className='rolesGroups__title'>{dataSelectedRole.name}</h2>
          <div className='rolesPage__searcherSection'>
            <Link to='/search/articles' onClick={() => handleFilterSearcher(filterInSearcher)}>
              <img src={searchIconHeader} alt='search-icon' className='rolesPage__searcher' />
            </Link>
            <input
              type='text'
              className='rolesPage__searcherInput'
              placeholder='Roles, familias, códigos...'
              autoComplete='off'
              value={rolesPageSearcherPlaceholder}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div className='rolesGroups__container'>
          {dataSubcategories
            .filter((category) => category.parent === dataSelectedRole.id)
            .map((role) => {
              return (
                <Link
                  to='/subcategories/articles'
                  onClick={() => {
                    setSelectedSubcategory({
                      name: role.name,
                      id: role.id,
                      parent: dataSelectedRole.name,
                    });
                    setIsPageGroup(true);
                  }}
                >
                  <div className='rolesGroups__rolesElement'>
                    <h4 className='rolesGroups__rolesElement--title'>{role.name}</h4>
                    <p className='rolesGroup__rolesElement--text'>
                      {dataExtraInfo.find((info) => info.ID_Categoria === role.id).Descripcion_Categoria}
                    </p>
                  </div>
                </Link>
              );
            })}
        </div>
      </section>
    </>
  );
};

export default RolesGroups;
