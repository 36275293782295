import { Link } from 'react-router-dom';

//styles
import '../styles/featured_content-item.css';

//images
import elipse355 from '../../images/ellipse355.png';

//components
import ArticleItem from './article-item';

const FeaturedContentItem = ({ selectedArticle, userInfoSSFF }) => {
  return (
    <>
      <section className='featuredContent__article'>
        <div className='featuredContent__article__breadcrumb--container'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <Link to='/knowledgepage' className='featuredContent__article__breadcrumb--title'>
            INICIO {''}
          </Link>
          <span className='featuredContent__article__breadcrumb--text'>&gt; CONTENIDO DESTACADO</span>
        </div>
        <p className='featuredContent__article--articleId'></p>
        <section className='featuredContent__article__content'>
          <ArticleItem
            title={selectedArticle.title}
            answer={selectedArticle.answer}
            expandedState={true}
            userInfoSSFF={userInfoSSFF}
            articleId={selectedArticle.id}
          ></ArticleItem>
        </section>
      </section>
    </>
  );
};

export default FeaturedContentItem;
