export const deleteEmpleado = async (user_id, idToken, jwtSSFF) => {
  try {
    const response = await fetch(`/employeeDelete/${user_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-msal-Authorization': `Bearer ${idToken}`,
        'x-jwt-Authorization': `Bearer ${jwtSSFF}`,
      },
    });
    if (!response.ok) {
      throw new Error('Fallo en la solicitud de eliminación del empleado');
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error(
      'Error en la solicitud de eliminación del empleado:',
      error.message
    );
    throw new Error(
      'Error en la solicitud de eliminación del empleado: ' + error.message
    );
  }
};
