import DOMpurify from 'dompurify';
import CryptoJS from 'crypto-js';
import { updateJiraTicket } from '../../../../../../../services/apiJira';
import { validateFields } from './validateFields';

const secretKey = 'your-secret-key';

const encryptValue = (value) => {
  return CryptoJS.AES.encrypt(value, secretKey).toString();
};

const handleSubmit = async (
  e,
  formData,
  isButtonEnabled,
  setIsSubmitting,
  handleClearForm,
  setFeedbackPopup,
  setMessageSendingFailed,
  filesAttached,
  ticketId
) => {
  e.preventDefault();

  if (isButtonEnabled) {
    try {
      setIsSubmitting(true);

      const sanitize = (value) => DOMpurify.sanitize(value || '');
      const encrypt = (value) => encryptValue(value);

      console.log('formData en handleSubmit', formData);

      const customFields = {
        customfield_10394: encrypt(sanitize(formData.hrbp)),
        customfield_10395: encrypt(sanitize(formData.businessUnit)),
        customfield_10396: encrypt(sanitize(formData.division)),
        customfield_10349: encrypt(sanitize(formData.department)),
        customfield_10397: encrypt(sanitize(formData.costCenter)),
        customfield_10398: encrypt(sanitize(formData.objectivesResult)),
        customfield_10399: encrypt(sanitize(formData.newJobClasification)),
        customfield_10400: encrypt(sanitize(formData.jcTitle)),
        customfield_10401: encrypt(sanitize(formData.jcLevel)),
        customfield_10402: encrypt(sanitize(formData.jcPayGrade)),
        customfield_10405: encrypt(sanitize(formData.jcTelefónicaLevel)),
        customfield_10403: encrypt(sanitize(formData.jcTelefónicaGrade)),
        customfield_10406: encrypt(sanitize(formData.legalEntity)),
        customfield_10407: encrypt(sanitize(formData.jobFunction)),
        customfield_10408: encrypt(sanitize(formData.jcCreationDate)),
        customfield_10409: encrypt(sanitize(formData.jcCreated)),
        customfield_10410: encrypt(sanitize(formData.jobClassification)),
        customfield_10411: encrypt(sanitize(formData.globalRole)),
        customfield_10412: encrypt(sanitize(formData.category)),
        customfield_10415: encrypt(sanitize(formData.benchmarkCode)),
        customfield_10416: encrypt(sanitize(formData.newJobPosition)),
        customfield_10420: encrypt(sanitize(formData.newPositionCreated)),
        customfield_10421: encrypt(sanitize(formData.employeeAssignedToNewPosition)),
        customfield_10422: encrypt(sanitize(formData.positionCode)),
        customfield_10462: encrypt(sanitize(formData.positionChangeRequired)),
        customfield_10463: encrypt(sanitize(formData.positionChangeComments)),
        customfield_10431: encrypt(sanitize(formData.hours)),

        //Otros
        customfield_10432: encrypt(sanitize(formData.organizationalChangeStatus)),
        customfield_10433: encrypt(sanitize(formData.salaryChangeStatus)),
        customfield_10434: encrypt(sanitize(formData.locationChangeStatus)),
        customfield_10435: encrypt(sanitize(formData.contractChangeStatus)),
      };

      let ticketData = {
        summary: `Cambio`,
        issueType: 'Cambio',
        priority: 'Medium',
        ...customFields,
      };

      if (ticketId) {
        ticketData.ticketId = ticketId;
      }

      if (Array.isArray(filesAttached)) {
        ticketData.filesAttached = filesAttached;
      } else {
        ticketData.filesAttached = [];
      }

      // Eliminar campos encriptados que tengan valor `undefined` para evitar errores en la API
      Object.keys(customFields).forEach((key) => {
        if (customFields[key] === '' || customFields[key] === 0) {
          delete customFields[key];
        }
      });

      console.log('ticketId', ticketId);
      console.log('filesAttached', filesAttached);

      ticketData = { ...ticketData, ticketId, filesAttached };
      console.log('Datos para updateJiraTicket:', ticketData);

      const response = await updateJiraTicket(ticketData, ticketId, filesAttached);
      console.log('response en Handle', response);

      // Verifica si el estado es 204 para mostrar el popup
      if (response.status === 204) {
        console.log('204');
        setFeedbackPopup(true);
        alert('Solicitud de cambio actualizada');

        handleClearForm();
      } else {
        console.error('Error: Código de estado no esperado:', response.status);
        setMessageSendingFailed(true);
      }
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
      setMessageSendingFailed(true);
    } finally {
      setIsSubmitting(false);
    }
  }
};

export default handleSubmit;
