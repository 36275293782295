import React from 'react';

const OrganizationalChangeViewnext = ({
  newPositionCreated,
  setNewPositionCreated,
  employeeAssignedToNewPosition,
  setEmployeeAssignedToNewPosition,
  positionCode,
  setPositionCode,
  positionChangeRequired,
  setPositionChangeRequired,
  positionChangeComments,
  setPositionChangeComments,
}) => {
  const getInputClass = (value) => (value ? 'formJiraChangeInputText' : 'formJiraChangeInputText errorBorder');

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambios organizativos</div>

      {/* Nueva posición creada */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel' htmlFor='newPositionCreated'>
          Nueva posición creada
        </label>
        <input
          type='text'
          id='newPositionCreated'
          className={getInputClass(newPositionCreated)}
          value={newPositionCreated}
          onChange={(e) => setNewPositionCreated(e.target.value)}
        />
      </div>

      {/* Empleado asignado a la nueva posición */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel' htmlFor='employeeAssignedToNewPosition'>
          Empleado asignado a la nueva posición
        </label>
        <input
          type='text'
          id='employeeAssignedToNewPosition'
          className={getInputClass(employeeAssignedToNewPosition)}
          value={employeeAssignedToNewPosition}
          onChange={(e) => setEmployeeAssignedToNewPosition(e.target.value)}
        />
      </div>

      {/* Código de la posición */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel' htmlFor='positionCode'>
          Código de la posición
        </label>
        <input
          type='text'
          id='positionCode'
          className={getInputClass(positionCode)}
          value={positionCode}
          onChange={(e) => setPositionCode(e.target.value)}
        />
      </div>

      {/* ¿Es necesario realizar algún cambio en la posición? */}
      <div className='formJiraChangeLabelRadio'>
        <label htmlFor='positionChangeRequired' className='formJiraChangeLabelRadio'>
          ¿Es necesario realizar algún cambio en la posición?
        </label>
        <div className='formJiraChangeRadio'>
          <label className='formJiraChangeLabelRadio'>
            <input
              type='radio'
              name='positionChangeRequired'
              className='formJiraChangeRadio'
              value='yes'
              checked={positionChangeRequired === 'yes'}
              onChange={() => setPositionChangeRequired('yes')}
            />
            Sí
          </label>
          <label className='formJiraChangeLabelRadio'>
            <input
              type='radio'
              name='positionChangeRequired'
              className='formJiraChangeRadio'
              value='no'
              checked={positionChangeRequired === 'no'}
              onChange={() => setPositionChangeRequired('no')}
            />
            No
          </label>
        </div>
      </div>

      {/* Comentarios cambios en la posición */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel' htmlFor='positionChangeComments'>
          Comentarios cambios en la posición
        </label>
        <textarea
          id='positionChangeComments'
          className={getInputClass(positionChangeComments)}
          value={positionChangeComments}
          onChange={(e) => setPositionChangeComments(e.target.value)}
        ></textarea>
      </div>
    </div>
  );
};

export default OrganizationalChangeViewnext;
