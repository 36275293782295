import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/subcategories.css';
import elipse355 from '../../images/ellipse355.png';

// La función de componente 'Subcategories' toma tres propiedades como argumentos destructurados:
// - 'subcategories': Contiene todas las subcategorías disponibles.
// - 'selectedCategory': La categoría principal seleccionada por el usuario.
// - 'setSelectedSubcategory': Una función que permite establecer la subcategoría seleccionada.

// Luego, se crea 'filteredSubcategories' que contiene solo las subcategorías que cumplen con dos condiciones:
// 1. El 'id' de la subcategoría no es igual al 'id' de la categoría principal seleccionada.
// 2. El 'parent' de la subcategoría coincide con el nombre de la categoría principal seleccionada.

const Subcategories = ({ subcategories, selectedCategory, setSelectedSubcategory, setIsPageGroup }) => {
  const [subcategoriesData, setSubcategoriesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Estado para la carga

  const navigate = useNavigate();

  // Obtener las SUBCATEGORIAS POR CATEGORY.ID
  // Obtener las SUBCATEGORIAS POR CATEGORY.ID
  useEffect(() => {
    const fetchSubCategoriesData = async () => {
      if (selectedCategory && selectedCategory.id) {
        try {
          setIsLoading(true); // Inicia la carga
          const response = await fetch(`/subcategories/${selectedCategory.id}`, {
            method: 'GET',
          });
          const data = await response.json();
          if (response.ok) {
            const uniqueSubcategories = Array.from(
              new Map(data.subcategoriesDataFromCategoryId.map((subcategory) => [subcategory.subcategory_id, subcategory])).values()
            );
            const sortedSubcategories = uniqueSubcategories.sort((a, b) => a.subcategory_id - b.subcategory_id);
            setSubcategoriesData(sortedSubcategories);
          } else {
            console.error('Error fetching subcategories:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching subcategories:', error);
        } finally {
          setIsLoading(false); // Finaliza la carga
        }
      } else {
        console.error('No category ID provided.');
        setIsLoading(false); // Finaliza la carga
      }
    };
    fetchSubCategoriesData();
  }, [selectedCategory]);

  const handleSubcategoryClick = (subcategory) => {
    setSelectedSubcategory({
      name: subcategory.name,
      id: subcategory.subcategory_id,
    });
    navigate('/subcategories/articles');
  };

  return (
    <>
      <section className='subcategoriesPage'>
        {/* Breadcrumb --> */}
        <div className='subcategories__breadcrumb'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <Link to='/knowledgepage' className='subcategories__breadcrumb--title'>
            INICIO {''}
          </Link>
          <span className='subcategories__breadcrumb--text'>&gt; {selectedCategory.name}</span>
        </div>
        {/* <-- Breadcrumb*/}
        <h2 className='subcategories__title'>{selectedCategory.name}</h2>

        {/* Mostrar mensaje de carga o contenido */}
        {isLoading ? (
          <div className='loading'>Loading...</div> // Mensaje de carga
        ) : (
          <div className='articlesContainer'>
            {/* Listado Subcategorías */}
            {subcategoriesData.map((subcategory) => (
              <div key={subcategory.subcategory_id} onClick={() => handleSubcategoryClick(subcategory)}>
                <article className='subcategories__articlebox'>
                  <img
                    className='articlebox__img'
                    src={`data:image/${subcategory.image_img};base64,${subcategory.subcategory_img}`}
                    alt={`subcategorie-${subcategory.name}`}
                  />
                  <div className='articlebox__textContainer'>
                    <h3 className='articlebox__textContainer__name'>{subcategory.name}</h3>
                  </div>
                </article>
              </div>
            ))}
          </div>
        )}
      </section>
    </>
  );
};

export default Subcategories;
