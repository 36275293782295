import Sidebar from '../Components/SideBar';
import ModalConfirmation from '../Components/ModalConfirmation';
import SearchBar from './SearchBar';
import CardEmployee from './CardEmployee';
import { useEffect, useState } from 'react';
import HeaderTalent from './HeaderTalent';
import { useNavigate } from 'react-router-dom';
import { deleteEmpleado } from '../../../services/TalentCards/FetchDeleteEmployee';
import MainComponentMobile from '../../Mobile/Components/MainComponentMobile';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchEmployeeOb } from '../../../services/TalentCards/FetchEmployeeOb';
import { fetchEmployeeSSFF } from '../../../services/TalentCards/FetchEmployeeSSFF';
import CryptoJS from 'crypto-js';
import Header from '../../../components/header';
import'../Styles/maintalent.css';

function MainTalent({
  userInfoSSFF,
  empleadosList,
  setEmpleadosList,
  loadingList,
  clusterEmployeeIds,
  addEmployee,
  isAdding,
  updateEmployeeListAfterDelete,
  updateEmployeeListAfterAdd,
  currentUserEmail,
  userPermissions,
  empleadosDeleteList,
  idToken,
  jwtSSFF,
  handleFilterSearcher,
  filterInSearcher,
  setHandleUpperCategoryIdParam
 
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSelectByClick, setIsSelectByClick] = useState(false);
  //cluster
  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const [isDropdownSelected, setIsDropdownSelected] = useState(false);
  const [selectedDropdownEmployeeId, setSelectedDropdownEmployeeId] =
    useState(null);
  const [selectedOB, setSelectedOB] = useState('');
  // DeleteModal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  //Mobile
  const [isMobile, setIsMobile] = useState(false);
  //OB
  const [obOptions, setObOptions] = useState([]);

  const secretKey = process.env.REACT_APP_TALENT_APP;

  // Forzar la recarga de empleadosList cada vez que se entra a MainTalent
  useEffect(() => {
    if (setEmpleadosList) {
      const fetchEmpleadosList = async () => {
        try {
          const data = await fetchEmployeeSSFF(idToken, jwtSSFF);
          setEmpleadosList(data); 
        } catch (error) {
          console.error('Error al cargar los datos de empleados:', error);
        }
      };

      fetchEmpleadosList();
    }
  }, [currentUserEmail, setEmpleadosList]);
  
  useEffect(() => {
    if (currentUserEmail) {
      const fetchOBs = async () => {
        try {
          const obData = await fetchEmployeeOb(idToken, jwtSSFF);
          const obOptions = obData.map((ob) => ({
            value: ob.ob_name,
            label: ob.ob_name,
          }));
          setObOptions(obOptions);
        } catch (error) {
          console.error('Error al obtener los OBs:', error);
        }
      };

      fetchOBs();
    }
  }, [currentUserEmail]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


   useEffect(() => {
    let filtered = Array.isArray(empleadosList)
      ? empleadosList
      : Object.values(empleadosList);
  
    if (isSelectByClick && isDropdownSelected) {
      setIsSelectByClick(false);
      return;
    }
  
    if (selectedDropdownEmployeeId && isDropdownSelected) {
      filtered = filtered.filter(empleado => selectedDropdownEmployeeId.includes(empleado.userId));
    } else if (searchTerm) {
      filtered = filtered.filter(empleado => {
        const nombre = empleado.displayName ? empleado.displayName.toLowerCase() : '';
        const id = empleado.userId ? empleado.userId.toString() : '';
        return nombre.includes(searchTerm.toLowerCase()) || id.includes(searchTerm);
      });
    }
  
    // Filtrar por OBs seleccionados
    if (selectedOB && selectedOB.length > 0) {
      const selectedOBValues = selectedOB.map((option) => option.value); 
      filtered = filtered.filter((empleado) =>
        selectedOBValues.includes(
          empleado.empInfo?.jobInfoNav?.results?.[0]?.businessUnitNav?.name
        )
      );
    }
  
    setFilteredEmployee(filtered);
  }, [searchTerm, selectedDropdownEmployeeId, empleadosList, isDropdownSelected, selectedOB]);
  
 
  const handleClusterEmployeeIdChange = (id, fromDropdown) => {
    if (fromDropdown) {
      setSelectedDropdownEmployeeId(id);
      setIsDropdownSelected(true);
    } else {
      setSelectedDropdownEmployeeId('');
      setIsDropdownSelected(false);
    }
  };

  //Modal delete
  const handleCloseModalDelete = () => {
    setIsDeleteModalOpen(false);
    setEmployeeToDelete(null);
  };

  const handleDeleteClick = () => {
    if(userPermissions.edit_talent_cards){
      setIsSelectionMode(true);
      setEmployeeToDelete(null);
      setIsDeleteModalOpen(false);
      
      // Mostramos el toast cuando el modo de selección es activado
      toast.info('Por favor, escoja el directivo a eliminar', {
        autoClose: 1000, 
      });

    }
   
  };

  const handleCardClick = (userId) => {
    if (isSelectionMode) {
      setSelectedEmployeeId((prevId) => {
        // Si el ID actual ya está seleccionado, deseleccionamos
        if (prevId === userId) {
          setEmployeeToDelete(null); 
          setIsDeleteModalOpen(false); 
          setIsSelectionMode(false); 
          return null; 
        } else {
          // Accedemos al empleado directamente en el objeto usando el userId
          const empleado = empleadosList[userId];
          // Si el empleado existe, lo preparamos para eliminación
          if (empleado) {
            setEmployeeToDelete(empleado); 
            setIsDeleteModalOpen(true); 
            setIsSelectionMode(false);
          }

          return userId;
        }
      });
    }
  };
  
  const handleConfirmDelete = async () => {
    setIsDeleting(true);

    try {
      const userIdDelete= employeeToDelete.userId;
      // Encriptar y codificar el empleado.userId
      const encrypted = CryptoJS.AES.encrypt(userIdDelete, secretKey).toString();
      const encryptedUserId = encodeURIComponent(btoa(encrypted));
  
      await deleteEmpleado(encryptedUserId,idToken, jwtSSFF);
      updateEmployeeListAfterDelete(userIdDelete);
      
      toast.success('Usuario eliminado con éxito', {
        autoClose: 1000, 
      });
      
      handleCloseModalDelete();
    } catch (error) {
      console.error('Error eliminando empleado:', error);
      toast.error('Error eliminando empleado');
    } finally {
      setIsDeleting(false);
    }
  };

 

  /* if (loadingList) return <p>Cargando...</p>; */

  return (
    <div>
      {isMobile ? (
        <MainComponentMobile
          empleadosList={empleadosList}
          loadingList={loadingList}
          addEmployee={addEmployee}
          isAdding={isAdding}
          setEmpleadosList={setEmpleadosList}
          onDelete={handleDeleteClick}
          clusterEmployeeIds={clusterEmployeeIds}
          onClusterEmployeeIdChange={handleClusterEmployeeIdChange}
          updateEmployeeListAfterAdd={updateEmployeeListAfterAdd}
          obOptions={obOptions}
          idToken={idToken}
          handleFilterSearcher={handleFilterSearcher}
          filterInSearcher={filterInSearcher}
          setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
          userInfoSSFF={userInfoSSFF}

        />
      ) : (
        <div className=" ">
          {/* <HeaderTalent onBack={backPagePeoplex} className="" /> */}
          <Header
                    handleFilterSearcher={handleFilterSearcher}
                    filterInSearcher={filterInSearcher}
                    setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                    userInfoSSFF={userInfoSSFF}
          />
          <div className=" row align-items-start mt-5 ">
            <div className="col-4 sidebar">
              <Sidebar
                onDelete={handleDeleteClick}
                onClusterEmployeeIdChange={handleClusterEmployeeIdChange}
                isAdding={isAdding}
                userInfoSSFF={userInfoSSFF}
                updateEmployeeListAfterAdd={ updateEmployeeListAfterAdd}
                selectedOB={selectedOB} 
                setSelectedOB={setSelectedOB} 
                obOptions={obOptions}
                userPermissions={userPermissions}
                empleadosList={empleadosList}
                empleadosDeleteList={empleadosDeleteList}
                idToken={idToken}
                jwtSSFF = {jwtSSFF}
              />

              <ModalConfirmation
                isOpen={isDeleteModalOpen}
                empleado={employeeToDelete}
                onClose={handleCloseModalDelete}
                onDelete={handleConfirmDelete}
                userInfoSSFF={userInfoSSFF}
                idToken={idToken}
              />
              <ToastContainer />
            </div>

            <div className="col-4 d-flex flex-column align-items-center main-card">
              <SearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                className="mb-4"
              />
              <div className="gallery w-100 d-flex flex-column align-items-center">
                {Object.values(filteredEmployee).map((empleado, index) => (
                  <CardEmployee
                    key={index}
                    empleado={empleado}
                    onSelect={handleCardClick}
                    obOptions={obOptions} 
                    isSelected={
                      employeeToDelete &&
                      empleado.userId === employeeToDelete.userId
                    }
                    isSelectionMode={isSelectionMode}
                    idToken={idToken}

                  />
                ))}
              </div>
            </div>

            <div className="col-4"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MainTalent;
