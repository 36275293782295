// Este es el componente del listado de tickets para manager

import React, { useEffect, useState } from 'react';
import { getJiraTickets } from '../../../services/apiJira';
import { useNavigate } from 'react-router-dom';
import '../../styles/contactUsButton/listTicketsContactUs.css';
import FilterSidebar from './contactUsFilterSideBar';

//ADAPTAR FILTROS PARA IMITAR FUNCIONALIDAD Y CÓDIGO DE historialTickets.js
const TicketList = () => {
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsPerPage] = useState(15);
  const [searchEmail, setSearchEmail] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchResponsable, setSearchResponsable] = useState('');
  const [selectedPriorities, setSelectedPriorities] = useState([]);
  const [searchCategorie, setSearchCategorie] = useState('');

  const navigate = useNavigate();

  // Se obtienen los tickets de Jira y se filtran para que aparezcan solo los de soporte empleado y manager
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const ticketList = await getJiraTickets();
        const initialFilteredTickets = ticketList.filter(
          (ticket) => ticket.fields.summary === 'SOPORTE EMPLEADO' || ticket.fields.summary === 'SOPORTE MANAGER'
        );
        setTickets(ticketList);
        setFilteredTickets(initialFilteredTickets);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchTickets();
  }, []);

  // useEffect(() => {
  //   const lowercasedSearchTerm = searchTerm.toLowerCase();
  //   const lowercasedSearchEmail = searchEmail.toLowerCase();
  //   const lowercasedSearchName = searchName.toLowerCase();
  //   const lowercasedSearchResponsable = searchResponsable.toLowerCase();
  //   const lowercasedSearchCategorie = searchCategorie.toLowerCase();

  //   const filteredByTypeAndSearchTerm = tickets
  //     .filter((ticket) =>
  //       selectedTypes.length > 0
  //         ? selectedTypes.includes(ticket.fields.summary)
  //         : ticket.fields.summary === 'SOPORTE EMPLEADO' || ticket.fields.summary === 'SOPORTE MANAGER'
  //     )
  //     .filter((ticket) => (selectedStatuses.length > 0 ? selectedStatuses.includes(ticket.fields.status.name) : true))
  //     .filter((ticket) => (selectedPriorities.length > 0 ? selectedPriorities.includes(ticket.fields.priority.name) : true))
  //     .filter((ticket) => {
  //       const responsable = (ticket.fields.customfield_10404 ? ticket.fields.customfield_10404 : '--').toLowerCase();
  //       return responsable.includes(searchResponsable.toLowerCase());
  //     })
  //     .filter((ticket) => {
  //       const categorie = (ticket.fields.customfield_10373 ? ticket.fields.customfield_10373 : '--').toLowerCase();
  //       return categorie.includes(searchCategorie.toLowerCase());
  //     })
  //     .filter((ticket) => {
  //       const summary = ticket.fields.summary.toLowerCase();
  //       const email = (ticket.fields.customfield_10319 ? ticket.fields.customfield_10319 : '').toLowerCase();
  //       const name = (ticket.fields.customfield_10318 ? ticket.fields.customfield_10318 : '').toLowerCase();
  //       const responsable = (ticket.fields.customfield_10404 ? ticket.fields.customfield_10404 : '-').toLowerCase();
  //       const status = ticket.fields.status.name.toLowerCase();
  //       const categorie = (ticket.fields.customfield_10373 ? ticket.fields.customfield_10373 : '-').toLowerCase();

  //       return (
  //         (summary.includes(lowercasedSearchTerm) ||
  //           email.includes(lowercasedSearchTerm) ||
  //           name.includes(lowercasedSearchTerm) ||
  //           responsable.includes(lowercasedSearchResponsable) ||
  //           status.includes(lowercasedSearchTerm)) &&
  //         email.includes(lowercasedSearchEmail) &&
  //         name.includes(lowercasedSearchName) &&
  //         categorie.includes(lowercasedSearchCategorie)
  //       );
  //     });

  //   setFilteredTickets(filteredByTypeAndSearchTerm);
  //   setCurrentPage(1);
  // }, [searchTerm, searchEmail, searchName, searchResponsable, tickets, selectedTypes, selectedStatuses, selectedPriorities, searchCategorie]);

  useEffect(() => {
    const lowercasedSearchTerm = (searchTerm || '').toLowerCase();
    const lowercasedSearchEmail = (searchEmail || '').toLowerCase();
    const lowercasedSearchName = (searchName || '').toLowerCase();
    const lowercasedSearchResponsable = (searchResponsable || '').toLowerCase();
    const lowercasedSearchCategorie = (searchCategorie || '').toLowerCase();

    const filteredByTypeAndSearchTerm = tickets
      .filter((ticket) =>
        selectedTypes.length > 0
          ? selectedTypes.includes(ticket.fields.summary)
          : ticket.fields.summary === 'SOPORTE EMPLEADO' || ticket.fields.summary === 'SOPORTE MANAGER'
      )
      .filter((ticket) => (selectedStatuses.length > 0 ? selectedStatuses.includes(ticket.fields.status.name) : true))
      .filter((ticket) => (selectedPriorities.length > 0 ? selectedPriorities.includes(ticket.fields.priority.name) : true))
      .filter((ticket) => {
        const responsable = (ticket.fields.customfield_10404 ? ticket.fields.customfield_10404 : '--').toLowerCase();
        return responsable.includes(lowercasedSearchResponsable);
      })
      .filter((ticket) => {
        const categorie = (ticket.fields.customfield_10373 ? ticket.fields.customfield_10373 : '--').toLowerCase();
        return categorie.includes(lowercasedSearchCategorie);
      })
      .filter((ticket) => {
        const summary = ticket.fields.summary.toLowerCase();
        const email = (ticket.fields.customfield_10319 ? ticket.fields.customfield_10319 : '').toLowerCase();
        const name = (ticket.fields.customfield_10318 ? ticket.fields.customfield_10318 : '').toLowerCase();
        const responsable = (ticket.fields.customfield_10404 ? ticket.fields.customfield_10404 : '-').toLowerCase();
        const status = ticket.fields.status.name.toLowerCase();
        const categorie = (ticket.fields.customfield_10373 ? ticket.fields.customfield_10373 : '-').toLowerCase();

        return (
          (summary.includes(lowercasedSearchTerm) ||
            email.includes(lowercasedSearchTerm) ||
            name.includes(lowercasedSearchTerm) ||
            responsable.includes(lowercasedSearchResponsable) ||
            status.includes(lowercasedSearchTerm)) &&
          email.includes(lowercasedSearchEmail) &&
          name.includes(lowercasedSearchName) &&
          categorie.includes(lowercasedSearchCategorie)
        );
      });

    setFilteredTickets(filteredByTypeAndSearchTerm);
    setCurrentPage(1);
  }, [searchTerm, searchEmail, searchName, searchResponsable, tickets, selectedTypes, selectedStatuses, selectedPriorities, searchCategorie]);

  const handleTypeChange = (e) => {
    const value = e.target.value;
    setSelectedTypes((prevSelectedTypes) =>
      prevSelectedTypes.includes(value) ? prevSelectedTypes.filter((type) => type !== value) : [...prevSelectedTypes, value]
    );
  };

  const handleCategoryChange = (e) => {
    setSearchCategorie(e.target.value || '');
  };

  const handleStatusChange = (e) => {
    const value = e.target.value;
    setSelectedStatuses((prevSelectedStatuses) =>
      prevSelectedStatuses.includes(value) ? prevSelectedStatuses.filter((status) => status !== value) : [...prevSelectedStatuses, value]
    );
  };
  const handlePriorityChange = (e) => {
    const value = e.target.value;
    setSelectedPriorities((prevSelectedPriorities) =>
      prevSelectedPriorities.includes(value) ? prevSelectedPriorities.filter((priority) => priority !== value) : [...prevSelectedPriorities, value]
    );
  };

  const handleEmailChange = (e) => setSearchEmail(e.target.value);
  const handleNameChange = (e) => setSearchName(e.target.value);
  const handleResponsableChange = (e) => setSearchResponsable(e.target.value);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const optionsDate = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formattedDate = date.toLocaleDateString('es-ES', optionsDate);

    const optionsTime = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
    const formattedTime = date.toLocaleTimeString('es-ES', optionsTime);

    return { formattedDate, formattedTime };
  };

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = filteredTickets.slice(indexOfFirstTicket, indexOfLastTicket);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredTickets.length / ticketsPerPage); i++) {
    pageNumbers.push(i);
  }

  if (loading) {
    return <p className='loading-message'>Cargando tickets...</p>;
  }

  if (error) {
    return <p className='error-message'>Error al cargar tickets: {error}</p>;
  }

  return (
    <div className='ticket-list-container'>
      <div className='main-content'>
        <div className='table-container'>
          <FilterSidebar
            selectedTypes={selectedTypes}
            handleTypeChange={handleTypeChange}
            selectedStatuses={selectedStatuses}
            handleStatusChange={handleStatusChange}
            searchEmail={searchEmail}
            handleEmailChange={handleEmailChange}
            searchName={searchName}
            handleNameChange={handleNameChange}
            searchResponsable={searchResponsable}
            handleResponsableChange={handleResponsableChange}
            selectedPriorities={selectedPriorities}
            handlePriorityChange={handlePriorityChange}
            handleCategoryChange={handleCategoryChange}
            searchCategorie={searchCategorie}
          />
          <div class='text-center'>
            <table className='tickets-table' style={{ fontSize: '12px' }}>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Creado por</th>
                  <th>Soporte</th>
                  <th>Responsable</th>
                  <th>Estado</th>
                  <th>Pr</th>
                  <th>Temática</th>
                  <th>Últimos cambios</th>
                </tr>
              </thead>
              <tbody>
                {currentTickets.map((ticket, index) => {
                  const { formattedDate, formattedTime } = formatDate(ticket.fields.updated);
                  return (
                    <tr
                      key={index}
                      className='ticket-row'
                      onClick={() => navigate(`/form/ContactUsTickets/details`, { state: { ticket } })}
                      style={{ cursor: 'pointer' }}
                    >
                      <td>{ticket.key}</td>
                      <td>
                        <p>{ticket.fields.customfield_10319}</p>
                        <p>{ticket.fields.customfield_10318}</p>
                      </td>
                      <td>
                        {ticket.fields.summary.includes('SOPORTE MANAGER')
                          ? 'Manager'
                          : ticket.fields.summary.includes('SOPORTE EMPLEADO')
                          ? 'Empleado'
                          : ticket.fields.summary}
                      </td>
                      <td>{ticket.fields.customfield_10404 || '--'}</td>
                      <td>
                        {ticket.fields.status.name === 'Backlog'
                          ? 'Abierto'
                          : ticket.fields.status.name === 'Finalizada'
                          ? 'Cerrado'
                          : ticket.fields.status.name}
                      </td>
                      <td>
                        {ticket.fields.priority.name === 'High' ? (
                          <span>Alta</span>
                        ) : ticket.fields.priority.name === 'Medium' ? (
                          <span>Media</span>
                        ) : ticket.fields.priority.name === 'Low' ? (
                          <span>Baja</span>
                        ) : (
                          <span>--</span>
                        )}
                      </td>
                      <td>
                        <p>{ticket.fields.customfield_10373}</p>
                      </td>
                      <td>
                        <p>{formattedDate}</p>
                        <p>{formattedTime}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='pagination'>
          {pageNumbers.map((number) => (
            <button key={number} onClick={() => setCurrentPage(number)}>
              {number}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TicketList;
