import * as React from "react"
const PerfomanceComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    viewBox="0 0 34 34"
    fill="none"
    {...props}
  >
    <g filter="url(#a)">
      <path
        fill="#F2F4FF"
        d="M16.996 0c4.131 0 7.343 1.078 9.551 3.204C28.84 5.41 30 8.71 30 13.004c0 4.292-1.163 7.585-3.453 9.792C24.34 24.922 21.127 26 16.997 26c-4.131 0-7.344-1.078-9.548-3.204C5.16 20.59 4 17.296 4 13.004c0-4.293 1.16-7.59 3.45-9.797C9.652 1.077 12.865 0 16.995 0Z"
      />
    </g>
    <defs>
      <filter
        id="a"
        width={34}
        height={34}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_208_1968"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_208_1968"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default PerfomanceComponent
