import { useState } from 'react';

const HighlightedContentList = ({ items, isLoading, userInfoSSFF, currentDate, reloadData }) => {
  const [selectedLegalEntity, setSelectedLegalEntity] = useState(''); // Estado para la entidad legal seleccionada
  const [addArticlePopup, setAddArticlePopup] = useState(false);
  const [editArticlePopup, setEditArticlePopup] = useState(false);
  const [formData, setFormData] = useState({});
  const [editFilteredItems, setEditFilteredItems] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState({});

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!items || items.length === 0) {
    return <p>No hay contenido destacado disponible.</p>;
  } // Obtener las entidades legales únicas para el select
  const legalEntities = Array.from(new Map(items.map((item) => [item.legal_entity_id, item.legal_entity_name])));
  // Filtrar los elementos por la entidad legal seleccionada
  const filteredItems = selectedLegalEntity ? items.filter((item) => item.legal_entity_id === selectedLegalEntity) : items;

  const onClose = () => {
    setAddArticlePopup(false);
    setEditArticlePopup(false);
    setEditFilteredItems([]);
    formData.legal_entity_id = '';
    setFormData({ legal_entity_id: '', article_id: '' });
    setSelectedOrders([]);
  };

  const handlePopupEntityChange = (e) => {
    const selectedId = e.target.value;
    setSelectedOrders({});
    setFormData((prev) => ({
      ...prev,
      legal_entity_id: selectedId,
    }));
    const filtered = items.filter((item) => item.legal_entity_id === selectedId);
    setEditFilteredItems(filtered);
  };

  const handleClickHighlightedContent = () => {
    setAddArticlePopup(true);
  };

  const handleAddHighlightedContent = async () => {
    try {
      // Filtrar los artículos por la jurídica seleccionada
      const articlesForSelectedEntity = items.filter((article) => article.legal_entity_id === formData.legal_entity_id);

      // Calcular el orden del nuevo artículo
      const newArticleOrder = articlesForSelectedEntity.length + 1;

      // Crear el payload con el artículo en la posición correcta
      const payload = {
        legal_entity_id: formData.legal_entity_id,
        article_id: formData.article_id,
        article_order: newArticleOrder, // Usar el orden calculado
        creator_id: userInfoSSFF.userInfoSSFF.username,
        creation_date: currentDate,
      };

      console.log('Datos enviados:', payload);

      // Enviar el payload al backend
      const response = await fetch('/addHighlightedArticles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log('Cambios guardados exitosamente.');
        setAddArticlePopup(false); // Cerrar el popup
        setFormData({ legal_entity_id: '', article_id: '' });
        reloadData(); // Recargar los datos
      } else {
        alert('Error al guardar los cambios.');
      }
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
      alert('Error al guardar los cambios.');
    }
  };

  const handleEditHighlightedContent = () => {
    setEditArticlePopup(true);
  };

  const handleEditOrderHighlightedContent = async () => {
    try {
      // Crear un arreglo con el nuevo orden
      const updatedOrders = Object.entries(selectedOrders).map(([articleId, newOrder]) => ({
        article_id: parseInt(articleId, 10),
        new_order: newOrder,
      }));

      const payload = {
        legal_entity_id: formData.legal_entity_id,
        last_modifier_id: userInfoSSFF.userInfoSSFF.username,
        last_modification_date: currentDate,
        updated_orders: updatedOrders, // Agregar los nuevos órdenes al payload
      };

      console.log('Datos enviados:', payload);

      // Cambiar el método a PUT
      const response = await fetch('/updateHighlightedArticles', {
        method: 'PUT', // Ahora es PUT
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload), // Enviar datos como JSON
      });

      if (response.ok) {
        console.log('Cambios guardados exitosamente.');
        setEditArticlePopup(false);
        reloadData(); // Recargar los datos después de guardar los cambios
      } else {
        alert('Error al guardar los cambios.');
      }
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
      alert('Error al guardar los cambios.');
    }
  };

  const handleOrderChange = (articleId, newOrder) => {
    setSelectedOrders((prev) => {
      // Elimina el artículo anterior con este orden
      const updatedOrders = { ...prev };
      const prevOrder = Object.entries(updatedOrders).find(([key, value]) => value === newOrder);
      if (prevOrder) {
        delete updatedOrders[prevOrder[0]];
      }

      // Asigna el nuevo orden al artículo actual
      updatedOrders[articleId] = newOrder;
      return updatedOrders;
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '2em 2em 0', gap: '1em' }}>
        {/* Filtro de entidad legal */}
        <div style={{ display: 'flex', height: '80px', gap: '1em', alignItems: 'center', alignContent: 'left' }}>
          <label htmlFor='legalEntitySelect' style={{ fontWeight: 'bold', fontSize: '14px' }}>
            Filtrar por entidad legal:
          </label>
          <select
            id='legalEntitySelect'
            value={selectedLegalEntity}
            onChange={(e) => setSelectedLegalEntity(e.target.value)}
            style={{ padding: '0.5em', borderRadius: '5px', border: '1px solid #ccc', fontSize: '14px' }}
          >
            <option value=''>Todas</option>
            {legalEntities.map(([id, name, index]) => (
              <option key={`${id}-${index}`} value={id}>
                {name} - {id}
              </option>
            ))}
          </select>
        </div>
        <button style={{ fontSize: '14px' }} type='button' className='btn btn-outline-primary' onClick={handleClickHighlightedContent}>
          Añadir contenido destacado
        </button>
        <button type='button' className='btn btn-outline-primary' onClick={handleEditHighlightedContent} style={{ fontSize: '14px' }}>
          Editar orden contenido destacado
        </button>
      </div>

      {/* Tabla de datos */}
      {isLoading ? (
        <div className='spinner-box'>
          <div className='pulse-container'>
            <div className='pulse-bubble pulse-bubble-1'></div>
            <div className='pulse-bubble pulse-bubble-2'></div>
            <div className='pulse-bubble pulse-bubble-3'></div>
          </div>
        </div>
      ) : filteredItems.length > 0 ? (
        <table className='table'>
          <thead>
            <tr>
              <th scope='col' style={{ textAlign: 'start', paddingLeft: '2em', fontWeight: 'bold' }}>
                Id del artículo
              </th>
              <th scope='col' style={{ textAlign: 'start', paddingLeft: '2em', fontWeight: 'bold' }}>
                Título del artículo
              </th>
              <th scope='col' style={{ textAlign: 'center', fontWeight: 'bold' }}>
                Orden
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((item, index) => (
              <tr key={index}>
                {/* Información del artículo */}
                <td style={{ textAlign: 'start', paddingLeft: '2em' }}>{item.article_id}</td>
                <td style={{ textAlign: 'start', paddingLeft: '2em' }}>{item.title}</td>
                <td style={{ textAlign: 'center' }}>{item.article_order}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className='table'>
          <thead>
            <tr>
              <th scope='col' style={{ width: '50%', textAlign: 'start', paddingLeft: '2em', fontWeight: 'bold' }}>
                Id del artículo
              </th>
              <th scope='col' style={{ width: '8%', textAlign: 'center', fontWeight: 'bold' }}>
                Posición orden
              </th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      )}
      {editArticlePopup && (
        <div className='catPopup-overlay'>
          <div className='popup-container'>
            <div className='verpopup'>
              <div style={{ float: 'right', cursor: 'pointer', border: 'none', background: 'none', fontSize: '1.5em', width: '100%' }}>
                <button onClick={onClose} style={{ float: 'right', border: 'none', background: 'none', fontSize: '1.5em' }}>
                  &times;
                </button>
                <h5>Editar orden contenido destacado</h5>
              </div>
              <form style={{ width: '80%' }}>
                <div className='mb-3'>
                  <div style={{ display: 'flex', gap: '3em' }}>
                    <label htmlFor='legalEntities' className='form-label'>
                      <strong>Jurídica:</strong>
                      <select
                        className='form-select'
                        id='legalEntities'
                        name='legal_entity_id'
                        value={formData.legal_entity_id}
                        onChange={handlePopupEntityChange}
                      >
                        <option value=''>Jurídica</option>
                        {legalEntities.map(([id, name]) => (
                          <option key={id} value={id}>
                            {name} - {id}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </div>
              </form>
              {editFilteredItems.length > 0 && (
                <>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th scope='col' style={{ textAlign: 'start', paddingLeft: '2em', fontWeight: 'bold' }}>
                          Id del artículo
                        </th>
                        <th scope='col' style={{ textAlign: 'start', paddingLeft: '2em', fontWeight: 'bold' }}>
                          Título del artículo
                        </th>
                        <th scope='col' style={{ textAlign: 'center', fontWeight: 'bold' }}>
                          Antiguo orden
                        </th>
                        <th scope='col' style={{ textAlign: 'center', fontWeight: 'bold' }}>
                          Nuevo orden
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {editFilteredItems.map((item) => (
                        <tr key={item.article_id}>
                          <td style={{ textAlign: 'start', paddingLeft: '2em' }}>{item.article_id}</td>
                          <td style={{ textAlign: 'start', paddingLeft: '2em' }}>{item.title}</td>
                          <td style={{ textAlign: 'center' }}>{item.article_order}</td>
                          <td style={{ textAlign: 'center' }}>
                            <select
                              value={selectedOrders[item.article_id] || ''}
                              onChange={(e) => handleOrderChange(item.article_id, parseInt(e.target.value, 10))}
                            >
                              <option value=''>Seleccionar</option>
                              {Array.from({ length: editFilteredItems.length }, (_, i) => i + 1).map((order) => (
                                <option key={order} value={order} disabled={Object.values(selectedOrders).includes(order)}>
                                  {order}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div style={{ width: '100%' }}>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={handleEditOrderHighlightedContent}
                      style={{ float: 'right' }}
                      disabled={
                        // Deshabilitar si no se han rellenado todos los `select`
                        Object.keys(selectedOrders).length !== editFilteredItems.length
                      }
                    >
                      Guardar cambios
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {addArticlePopup && (
        <div className='catPopup-overlay'>
          <div className='popup-container'>
            <div className='verpopup'>
              <div style={{ float: 'right', cursor: 'pointer', border: 'none', background: 'none', fontSize: '1.5em', width: '100%' }}>
                <button onClick={onClose} style={{ float: 'right', border: 'none', background: 'none', fontSize: '1.5em' }}>
                  &times;
                </button>
                <h5>Añadir contenido destacado</h5>
              </div>
              <form style={{ width: '80%' }}>
                <div className='mb-3'>
                  <div style={{ display: 'flex', gap: '3em' }}>
                    <label htmlFor='legalEntities' className='form-label'>
                      <strong>Jurídica:</strong>
                      <select
                        className='form-select'
                        id='legalEntities'
                        name='legal_entity_id'
                        value={formData.legal_entity_id}
                        onChange={handlePopupEntityChange}
                      >
                        <option value=''>Jurídica</option>
                        {legalEntities.map(([id, name]) => (
                          <option key={id} value={id}>
                            {name} - {id}
                          </option>
                        ))}
                      </select>
                    </label>
                    <label htmlFor='article_title' className='form-label'>
                      <strong>Id del artículo:</strong>
                      <input
                        type='number'
                        className='form-control'
                        id='article_id'
                        name='article_id'
                        value={formData.article_id || ''}
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                </div>
              </form>
              <div style={{ width: '100%' }}>
                <button type='button' className='btn btn-primary' onClick={handleAddHighlightedContent} style={{ float: 'right' }}>
                  Guardar cambios
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default HighlightedContentList;
