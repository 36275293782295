
import React, { useState, useRef, useEffect } from 'react';
import '../Styles/FirstTalentView/experienceComponent.css'
import CarrerModal from "./CarrerModal";
import { toast } from "react-toastify";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import EducationModal from './EducationModal';
import AddIcon from '../../assests/mainPageComponent/sideBard/AddIcon';
import ClusterComponent from './ClusterComponent';
import { updateEmployee } from '../../../services/TalentCards/FetchUpdateEmployee';
import CryptoJS from 'crypto-js';
import ClusterEditModal from './clusterEditModal';

function ExperienceComponent({ empleadoDetails, selectedEmployeeId , userInfoSSFF,
  onUpdateEmployeeDetails, currentUserEmail,
  userPermissions, idToken,jwtSSFF}) {
  
  const secretKey = process.env.REACT_APP_TALENT_APP;
  
  const encrypted = CryptoJS.AES.encrypt(selectedEmployeeId, secretKey).toString();
  const encryptedSelectedId = encodeURIComponent(btoa(encrypted));
  //Modal
  const [isModalCarrerOpen, setIsModalCarrerOpen] = useState(false);
  const [isModalEducationOpen, setIsModalEducationOpen] = useState(false);
  const [isModalClusterOpen, setIsModalClusterOpen]= useState(false);

  //Visbilidad de los modal agregar
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isButtonVisibleEducation, setIsButtonVisibleEducation] = useState(false);

  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState([]);
  const [editingField, setEditingField] = useState(null);
  const [tempText, setTempText] = useState('');
  const [prevText, setPrevText] = useState('');
  
  const titleRefs = useRef([]);
  const companyRefs = useRef([]);
  const fromRefs = useRef([]);
  const toRefs = useRef([]);

  const educationTitleRefs = useRef([]);
  const institutionRefs = useRef([]);
  const educationFromRefs = useRef([]);
  const educationToRefs = useRef([]);

  

  useEffect(() => {
    if (empleadoDetails && empleadoDetails.experiences) {
      setFormData(empleadoDetails.experiences.map(exp => ({
        job_title: exp.job_title || '',
        company: exp.company || '',
        from_year: exp.from_year || '',
        to_year: exp.to_year || ''
      })));
    } else {
      setFormData([]);
    }
  }, [empleadoDetails]);

  const handleOpenCarrerModal = () => {
    if (userPermissions.edit_talent_cards) {  
      setIsModalCarrerOpen(true);
    }
  };

  const handleCloseCarrerModal = () => {
    setIsModalCarrerOpen(false);
  };

  //clusterEditModal
  const handleOpenCLusterModal = () => {
    if (userPermissions.edit_talent_cards) {  
      setIsModalClusterOpen(true);
    }
  };

  //clusterEdit
  const handleCloseCLusterModal = () => {
    setIsModalClusterOpen(false);
  };

  //visibilidad de los Modal agregar
  const handleMouseEnter = () => {
    setIsButtonVisible(true);
  };

  const handleMouseLeave = () => {
    setIsButtonVisible(false);
  };

  const handleMouseEnterEducation = () => {
    setIsButtonVisibleEducation(true);
  };

  const handleMouseLeaveEducation = () => {
    setIsButtonVisibleEducation(false);
  };

  const handleOpenEducationModal = () => {
    if (userPermissions.edit_talent_cards) {  
    setIsModalEducationOpen(true);
    }
  };

  const handleCloseEducationModal = () => {
    setIsModalEducationOpen(false);
  };

  const handleBlur = async (index, field, ref, isEducation = false) => {
    let value = tempText ? tempText.trim() : '';

    if (!value) {
        toast.error('El campo no puede estar vacío.');
        if (ref && ref.current) {
            ref.current.innerText = prevText; 
        }
        setEditable(false);
        setEditingField(null);
        return;
    }

    confirmAlert({
        title: '¿Seguro?',
        message: '¿Estás seguro de modificar esto?',
        buttons: [
            {
                label: 'Sí',
                onClick: async () => {
                    if (isEducation) {
                      let updatedEducation = [...empleadoDetails.education];
                      updatedEducation[index][field] = value;
                      let updatedData = {
                          ...empleadoDetails,
                          education: updatedEducation
                      };

                      try {
                        await updateEmployee(encryptedSelectedId, updatedData,idToken,jwtSSFF);
                        setFormData(prevFormData => prevFormData.map((item, idx) => idx === index ? { ...item, [field]: value } : item));
                        onUpdateEmployeeDetails(updatedData);
                        toast.success('Datos actualizados correctamente.');
                      } catch (error) {
                        console.error('Error updating employee:', error);
                        toast.error('Error al actualizar los datos del empleado.');
                        if (ref && ref.current) {
                          ref.current.innerText = prevText;
                        }
                      }

                      setEditable(false);
                      setEditingField(null);
                    } else {
                      let updatedExperiences = [...formData];
                      updatedExperiences[index][field] = value;

                      let updatedData = {
                          ...empleadoDetails,
                          experiences: updatedExperiences.map((exp, i) => ({
                              ...exp,
                              job_title: i === index && field === 'job_title' ? value : exp.job_title,
                              company: i === index && field === 'company' ? value : exp.company,
                              from_year: i === index && field === 'from_year' ? value : exp.from_year,
                              to_year: i === index && field === 'to_year' ? value : exp.to_year,
                          }))
                      };

                      try {
                        await updateEmployee(encryptedSelectedId, updatedData,idToken,jwtSSFF);
                        setFormData(updatedExperiences);
                        onUpdateEmployeeDetails(updatedData);
                        toast.success('Datos actualizados correctamente.');

                      } catch (error) {
                        console.error('Error updating employee:', error);
                        toast.error('Error al actualizar los datos del empleado.');
                        if (ref && ref.current) {
                          ref.current.innerText = prevText;
                        }
                      }

                      setEditable(false);
                      setEditingField(null);
                    }
                }
            },
            {
                label: 'No',
                onClick: () => {
                    if (ref && ref.current) {
                        ref.current.innerText = prevText; 
                    }
                    setEditable(false);
                    setEditingField(null);
                }
            }
        ]
    });
  };

  const handleDoubleClick = (index, field, ref, isEducation = false) => {
    if (userPermissions.edit_talent_cards) {  
        setEditable(true);
        setEditingField({ index, field });
        if (isEducation) {
          setTempText(String(empleadoDetails.education[index][field]));
          setPrevText(String(empleadoDetails.education[index][field]));
        } else {
          setTempText(String(formData[index][field]));
          setPrevText(String(formData[index][field]));
        }
        setTimeout(() => {
            if (ref && ref.current) {
                ref.current.focus();
            }
        }, 0);
    } 
  };
  
  const handleChange = (e) => {
    setTempText(e.target.innerText);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { index, field } = editingField;
      handleBlur(index, field, getRef(index, field));
    }
  };

  const getRef = (index, field) => {
    switch(field) {
        case 'job_title':
            return titleRefs.current[index];
        case 'company':
            return companyRefs.current[index];
        case 'from_year':
            return fromRefs.current[index];
        case 'to_year':
            return toRefs.current[index];
        case 'title':
            return educationTitleRefs.current[index];
        case 'institution':
            return institutionRefs.current[index];
        case 'educationFrom':
            return educationFromRefs.current[index];
        case 'educationTo':
            return educationToRefs.current[index];
        default:
            return null;
    }
  };

  const renderDateRange = (item, index, isEducation = false) => {
    return (
      <span>
        <span
          ref={el => isEducation ? educationFromRefs.current[index] = el : fromRefs.current[index] = el}
          contentEditable={editable && editingField?.index === index && editingField?.field === 'from_year'}
          suppressContentEditableWarning
          onInput={handleChange}
          onBlur={() => handleBlur(index, 'from_year', isEducation ? educationFromRefs.current[index] : fromRefs.current[index], isEducation)}
          onKeyDown={handleKeyDown}
          onDoubleClick={() => handleDoubleClick(index, 'from_year', isEducation ? educationFromRefs.current[index] : fromRefs.current[index], isEducation)}
          style={{ cursor: editable ? 'text' : 'pointer' }}
        >
          {item.from_year }
        </span>
        {' - '}
        <span
          ref={el => isEducation ? educationToRefs.current[index] = el : toRefs.current[index] = el}
          contentEditable={editable && editingField?.index === index && editingField?.field === 'to_year'}
          suppressContentEditableWarning
          onInput={handleChange}
          onBlur={() => handleBlur(index, 'to_year', isEducation ? educationToRefs.current[index] : toRefs.current[index], isEducation)}
          onKeyDown={handleKeyDown}
          onDoubleClick={() => handleDoubleClick(index, 'to_year', isEducation ? educationToRefs.current[index] : toRefs.current[index], isEducation)}
          style={{ cursor: editable ? 'text' : 'pointer' }}
        >
          {item.to_year  === 2024 ? 'Present' : item.to_year}
        </span>
      </span>
    );
  };

  const updateExperiences = (newExperience) => {
    const updatedExperiences = [...empleadoDetails.experiences, newExperience];
    updatedExperiences.sort((a, b) => b.from_year - a.from_year);

    const updatedData = { ...empleadoDetails, experiences: updatedExperiences };
    updateEmployee(encryptedSelectedId, updatedData,idToken,jwtSSFF);
    onUpdateEmployeeDetails(updatedData);
    handleCloseCarrerModal();
  };

  const updateEducation = (newEducation) => {
    const updatedEducation = [...empleadoDetails.education, newEducation];
    updatedEducation.sort((a, b) => b.from_year - a.from_year);

    const updatedData = { ...empleadoDetails, education: updatedEducation };
    updateEmployee(encryptedSelectedId,updatedData,idToken,jwtSSFF);
    onUpdateEmployeeDetails(updatedData);
    handleCloseEducationModal();
  };

  const handleSaveClusters = async (updatedData) => {
    try {
      await updateEmployee(encryptedSelectedId, updatedData, idToken, jwtSSFF);
      onUpdateEmployeeDetails(updatedData);
      toast.success("Clusters actualizados correctamente.", {
        autoClose: 1000,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
      });
    } catch (error) {
      console.error("Error actualizando clusters:", error);
      toast.error("Error al actualizar los clusters.");
    }
  };
  
 
  const positions = [
    { key: 'cluster_ceo', label: 'CEO' },
    { key: 'cluster_b2c_growth', label: 'B2C' },
    { key: 'cluster_b2b_growth', label: 'B2B' },
    { key: 'cluster_technology', label: 'TEC' },
    { key: 'cluster_enablers', label: 'EN' },
    { key: 'cluster_pm_transversal', label: 'PM' }
  ].filter(position => empleadoDetails[position.key]);

  const activeClusters = positions.length > 0;
  
  return (
    <div className="experience-container">
      
      <div className='cluster-exp '>
      {activeClusters ? (
        <div  onClick={handleOpenCLusterModal}>
          <ClusterComponent  className='ms-5' positions={positions} empleadoDetails={empleadoDetails}  />
        </div>
        ) : (
          <div className='' >
            <h4 className="text-primary fs-4 add-cluster mt-4"
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
             >Add Cluster
             {
            isButtonVisible&&(
            <AddIcon className="icon-Add ms-2"  onClick={handleOpenCLusterModal} />
            )
            }
            </h4>
            

          </div>
        
          )}
        <ClusterEditModal
            isOpen={isModalClusterOpen}
            empleadoDetails={empleadoDetails}
            onSave={handleSaveClusters}
            onClose={handleCloseCLusterModal}
          />
      </div>

      <div className="carrer-container mt-1">
        <h4 className="experience-title text-primary font-weight-light fs-4" 
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          >
          Career experience
          {
            isButtonVisible&&(
              <AddIcon className="icon-Add ms-2" onClick={handleOpenCarrerModal} />
            )
          }
        </h4>
        <CarrerModal isOpen={isModalCarrerOpen} onClose={handleCloseCarrerModal}  onSave={updateExperiences}/>
        <div className="scroll-container-experience">
          <table className="carrer-table table table-sm table-borderless">
            <tbody>
              {empleadoDetails.experiences.map((exp, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index !== empleadoDetails.experiences.length - 1 ? "white" : "none",
                    color: index !== empleadoDetails.experiences.length - 1 ? "black" : "black",
                  }}
                >
                  <td style={{ whiteSpace: 'nowrap',paddingRight: '18px' }}>
                    {renderDateRange(exp, index)}
                  </td>
                  <td>
                    <div
                      ref={el => titleRefs.current[index] = el}
                      contentEditable={editable && editingField?.index === index && editingField?.field === 'job_title'}
                      suppressContentEditableWarning
                      onInput={handleChange}
                      onBlur={() => handleBlur(index, 'job_title', titleRefs.current[index])}
                      onKeyDown={handleKeyDown}
                      onDoubleClick={() => handleDoubleClick(index, 'job_title', titleRefs.current[index])}
                    >
                      {exp.job_title}
                    </div>
                    <div className="text-muted small"
                      ref={el => companyRefs.current[index] = el}
                      contentEditable={editable && editingField?.index === index && editingField?.field === 'company'}
                      suppressContentEditableWarning
                      onInput={handleChange}
                      onBlur={() => handleBlur(index, 'company', companyRefs.current[index])}
                      onKeyDown={handleKeyDown}
                      onDoubleClick={() => handleDoubleClick(index, 'company', companyRefs.current[index])}
                    >
                      {exp.company}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="education-container mt-3">
        <h4 className="education-title text-primary experience fs-4"
        onMouseEnter={handleMouseEnterEducation}
        onMouseLeave={handleMouseLeaveEducation}
        >Education
        {
            isButtonVisibleEducation&&(
              <AddIcon className="icon-Add ms-2" onClick={handleOpenEducationModal} />
            )
          }
        </h4>
          
        <EducationModal isOpen={isModalEducationOpen} onClose={handleCloseEducationModal} onSave={updateEducation} />

        <div className="scroll-container-education">
          <table className="education-table table table-sm table-borderless" style={{ marginTop: '1rem' }}>
            <tbody>
              {empleadoDetails.education.map((edu, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index !== empleadoDetails.education.length - 1 ? "white" : "none",
                    color: index !== empleadoDetails.education.length - 1 ? "black" : "white",
                  }}
                >
                  <td style={{ whiteSpace: 'nowrap', paddingRight: '18px' }}>
                    {renderDateRange(edu, index, true)}
                  </td>
                  <td className="info-education">
                    <div
                      ref={el => educationTitleRefs.current[index] = el}
                      contentEditable={editable && editingField?.index === index && editingField?.field === 'title'}
                      suppressContentEditableWarning
                      onInput={handleChange}
                      onBlur={() => handleBlur(index, 'title', educationTitleRefs.current[index], true)}
                      onKeyDown={handleKeyDown}
                      onDoubleClick={() => handleDoubleClick(index, 'title', educationTitleRefs.current[index], true)}
                    >
                      {edu.title}
                    </div>
                    <div className="text-muted small"
                      ref={el => institutionRefs.current[index] = el}
                      contentEditable={editable && editingField?.index === index && editingField?.field === 'institution'}
                      suppressContentEditableWarning
                      onInput={handleChange}
                      onBlur={() => handleBlur(index, 'institution', institutionRefs.current[index], true)}
                      onKeyDown={handleKeyDown}
                      onDoubleClick={() => handleDoubleClick(index, 'institution', institutionRefs.current[index], true)}
                    >
                      {edu.institution}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>
    </div>
  );
}

export default ExperienceComponent;
