import * as React from "react"

const ArrowComponentMobile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={25}
    fill="none"
    viewBox="0 0 35 35"
    {...props}
  >
    <path
      fill="#007bff"
      d="m31.845 13.891-.625-4.964a1.14 1.14 0 0 0-1.148-.979h-5.09a1.136 1.136 0 0 0-1.156 1.11c0 .611.523 1.11 1.156 1.11h2.745l-7.27 9.91-9.907-4.174a1.207 1.207 0 0 0-1.483.476l-5.776 9.79c-.31.535-.118 1.21.43 1.513.003.004.007.004.011.008a1.18 1.18 0 0 0 1.573-.42l5.266-8.918 9.816 4.126c.502.216 1.09.06 1.413-.376l7.463-10.189.278 2.245c.09.615.662 1.05 1.295.978a1.126 1.126 0 0 0 1.01-1.234v-.012Z"
    />
  </svg>
)
export default ArrowComponentMobile