const JIRA_API_URL = 'https://jirabi-tgt.atlassian.net/rest/api';

export const createJiraTicket = async (ticketData, filesAttached) => {
  console.log('ticketData en APIJIRA', ticketData);

  const requestData = {
    ...ticketData,
    filesAttached: filesAttached || [],
  };

  console.log(requestData);

  const response = await fetch('/jira/createJiraTicket', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });

  if (!response.ok) {
    throw new Error('Error en la solicitud a JIRA');
  }

  const data = await response.json();
  return data;
};

export const getJiraTickets = async () => {
  const jwtSSFF = localStorage.getItem('jwtSSFFToken');

  try {
    const response = await fetch('/jira/getJiraTickets', {
      method: 'GET',
      headers: {
        'x-jwt-Authorization': `Bearer ${jwtSSFF}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Error al obtener los tickets de JIRA');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener los tickets:', error);
    throw error;
  }
};

export const getProcesosJiraTickets = async () => {
  const jwtSSFF = localStorage.getItem('jwtSSFFToken');

  try {
    const response = await fetch('/jira/getProcesosJiraTickets', {
      method: 'GET',
      headers: {
        'x-jwt-Authorization': `Bearer ${jwtSSFF}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Error al obtener los tickets de JIRA');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener los tickets:', error);
    throw error;
  }
};

export const getEmployeeJiraTickets = async () => {
  const jwtSSFF = localStorage.getItem('jwtSSFFToken');

  try {
    const response = await fetch('/jira/getEmployeeJiraTickets', {
      method: 'GET',
      headers: {
        'x-jwt-Authorization': `Bearer ${jwtSSFF}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Error al obtener los tickets de JIRA');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener los tickets:', error);
    throw error;
  }
};

export const updateJiraTicket = async (updatedTicketData, ticketId, filesAttached) => {
  console.log('ticketId en apiJira', ticketId);

  const requestData = {
    ...updatedTicketData,
    ticketId,
    filesAttached: filesAttached || [],
  };

  console.log('requestData en apiJira', requestData);

  const response = await fetch('/jira/updateJiraTicket', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });

  if (response.status === 204) {
    console.log('Respuesta exitosa sin contenido (204)');
    return { status: 204 }; // Devuelve un objeto indicando el éxito
  }

  if (!response.ok) {
    throw new Error('Error en la solicitud de actualización a JIRA');
  }

  const data = await response.json(); // Solo llamar a json() si hay contenido
  return data;
};

export const updateCanalAtencionTicket = async (updatedTicketData, ticketId, filesAttached) => {
  const requestData = {
    ...updatedTicketData,
    ticketId,
    filesAttached: filesAttached || [],
  };

  const response = await fetch('/jira/updateCanalAtencionTicket', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });

  // Lanza un error si la solicitud no fue exitosa
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Error en la solicitud de actualización a JIRA');
  }

  const data = await response.json();
  return {
    status: response.status, // Incluye el status aquí
    data,
  };
};

export const getJiraTicketAttachments = async (ticketId) => {
  try {
    const response = await fetch(`/jira/getJiraTicketsAttachments/${ticketId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Error al obtener los tickets de JIRA');
    }
    return response.json();
  } catch (error) {
    console.error('Error al obtener los adjuntos del ticket:', error);
    throw error;
  }
};

export const downloadAttachmentJira = async (attachmentId) => {
  try {
    // Construir la URL de descarga del archivo adjunto
    const downloadUrl = `${JIRA_API_URL}/2/attachment/content/${attachmentId}`;

    // Crear un enlace temporal
    const link = document.createElement('a');
    link.href = downloadUrl;

    // Establecer el nombre del archivo por defecto con la extensión .pdf
    link.download = `attachment_${attachmentId}.pdf`; // Nombre del archivo descargado

    // Simular un clic para iniciar la descarga
    document.body.appendChild(link);
    link.click();

    // Remover el enlace después de que se haya simulado el clic
    document.body.removeChild(link);

    return true; // Indica que la acción fue exitosa
  } catch (error) {
    console.error('Error al descargar el archivo adjunto:', error);
    throw error;
  }
};
