import { useLocation } from 'react-router-dom';
import { TicketsContactUsInfo } from './ticketsContactUsInfo';
import ContactUsEmailAnswer from './contactUsEmailAnswer';
import ContactUsInbentaInfo from './contactUsInbentaInfo';
import '../../styles/contactUsButton/detailsTicketsContactUs.css';
import React, { useState } from 'react';

// Componente que muestra los tickets enviados a contacta con nosotros una vez que se selecciona uno de ellos, se muestra la información del ticket y la respuesta que se le ha dado.
const TicketDetail = ({ handleFilterSearcher, filterInSearcher, autocompleteArticles, userInfoSSFF, handleUpperCategoryIdParam }) => {
  const [sharedMessage, setSharedMessage] = useState('');
  const location = useLocation();
  const { ticket } = location.state || {};

  if (!ticket) {
    return <p>No se encontraron detalles del ticket.</p>;
  }
  console.log(ticket);

  const handleCopyToEmail = (content) => {
    setSharedMessage((prevMessage) => prevMessage + content); // Concatenar contenido
  };

  return (
    <div className='ticket-detail-container'>
      <div className='ticket-detail-table-details'>
        <TicketsContactUsInfo
          ticket={ticket}
          handleFilterSearcher={handleFilterSearcher}
          filterInSearcher={filterInSearcher}
          autocompleteArticles={autocompleteArticles}
          userInfoSSFF={userInfoSSFF}
        />
      </div>

      <div className='ticket-detail-answer-tittle'>RESPUESTA</div>
      <div className='ticket-detail-answer'>
        <div className='ticket-detail-answer-email'>
          <ContactUsEmailAnswer ticket={ticket} initialMessage={sharedMessage} />
        </div>
        <div className='ticket-detail-answer-inbenta'>
          <ContactUsInbentaInfo
            handleFilterSearcher={handleFilterSearcher}
            filterInSearcher={filterInSearcher}
            autocompleteArticles={autocompleteArticles}
            handleUpperCategoryIdParam={handleUpperCategoryIdParam}
            userInfoSSFF={userInfoSSFF}
            onCopyToEmail={handleCopyToEmail}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketDetail;
