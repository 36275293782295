export const updateEmployeeNew = async (user_id, newEmployeeData,idToken,
  jwtSSFF) => {
    try {
      const response = await fetch(`/employeeUp/new/${user_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-msal-Authorization': `Bearer ${idToken}`,
          'x-jwt-Authorization': `Bearer ${jwtSSFF}`,
        },
        body: JSON.stringify(newEmployeeData),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error al añadir el empleado con user_id ${user_id}:`, error);
      throw error;
    }
  };
  