import * as React from "react"

const LeftComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={23}
    viewBox="0 0 12 23"
    fill="none"
    {...props}
  >
    <path
      stroke="#F2F4FF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 1 1 12.118 11 22"
    />
  </svg>
)
export default LeftComponent