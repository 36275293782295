import React, { useState, useEffect } from "react";
import globalStyles from "../../Styles/globalStyle";
import PhotoComponent from "../../assests/FileComponentes/iconsComponent/PhotoComponent";
import BackIconComponentBlue from "../../assests/MobileIcons/BackIconComponetBlue";
import "../Styles/summaryMobile.css";
import ClusterComponentMobileBlue from "./ClusterComponentBlue";
import RightComponentMobile from "../../assests/MobileIcons/RightComponentMobile";
import LeftComponentMobile from "../../assests/MobileIcons/LeftComponentMobile";
import DownloadComponentMobile from "../../assests/MobileIcons/DownloadComponentMobile";
import { useNavigate } from 'react-router-dom';
import userImg from '../../../images/user-account.png';
import CryptoJS from 'crypto-js';


function SummaryComponentMobile({ userInfoSSFF, selectedEmployeeId,empleadoDetails, empleado }) {
  
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [error, setError] = useState(null);
  const [empleadosList, setEmpleadosList] = useState([]);
  const [loadingList, setLoadingList] = useState(true);
  const secretKey = process.env.REACT_APP_TALENT_APP;


  // Encriptar y codificar el selectedEmployeeId
  const encrypted = CryptoJS.AES.encrypt(selectedEmployeeId, secretKey).toString();
  const encryptedUserId = encodeURIComponent(btoa(encrypted));

  const [formData, setFormData] = useState({
    profile_summary: ''
  });

  const [currentPage, setCurrentPage] = useState(2);
  const totalPages = 3;
  const navigate = useNavigate();


  const handleViewInfoMobile = () => {
    navigate(`/talentCards/${encryptedUserId}`, {
      state: { empleado }
    });
  };

  const renderDateRange = (experience) => {
    if (experience.to_year === "present") {
      return <span>{experience.from_year} - present</span>;
    } else if (experience.to_year === undefined) {
      return <span>{experience.from_year}</span>;
    } else {
      return <span>{experience.from_year} - {experience.to_year}</span>;
    }
  };

  const backPage = () => {
    navigate(`/talentCards`);
  };

 
  const handleViewMobileExperience = () => {
    navigate(`/talentCardsThreeMobile/${encryptedUserId}`, {
      state: { empleadoDetails, userInfoSSFF, selectedEmployeeId: encryptedUserId, empleado }
    });
};




  const positions = [
    { key: 'cluster_ceo', label: 'CEO' },
    { key: 'cluster_b2c_growth', label: 'B2C' },
    { key: 'cluster_b2b_growth', label: 'B2B' },
    { key: 'cluster_technology', label: 'TEC' },
    { key: 'cluster_enablers', label: 'EN' },
    { key: 'cluster_pm_transversal', label: 'PM' }

  ].filter(position => empleadoDetails[position.key]);
 

 
  

  const position = empleado.empInfo?.jobInfoNav?.results?.[0]?.jobTitle || '';

  // Obtener la cadena de la imagen en Base64
  const base64Image = empleado.empInfo?.photoNav?.results?.[0]?.photo;

  // Construir la URL de la imagen en Base64
  const imageUrl = base64Image ? `data:image/jpeg;base64,${base64Image}` : userImg;


  return (
    <div className="info-mobile p-0 ms-0 me-0" style={{ backgroundColor: "#ffff" }}>
      {/* HeaderMobile */}
      <div className="row custom-row">
        <div className="text-start">
          <div className="row align-items-center">
            <div className="col-auto">
              <div className="exit-container me-2 ms-2">
                <BackIconComponentBlue className="btnInfo-exit" onClick={backPage} />
              </div>
            </div>
            <div className="col text-start">
              <h1 className="fs-3 mt-3 mb-1 employee-name text-primary">{empleado.displayName}</h1>
              <h2 className="fs-6 employee-job">{position}</h2>
            </div>
            <div className="col-auto text-center">
                <div className="rounded-circle overflow-hidden mt-3 me-3" style={{ width: '90px', height: '90px', objectFit: 'cover', background:'#007bff' }}>
                      {imageUrl && (
                        <img 
                          src={imageUrl} 
                          alt="Foto de empleado" 
                          style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} 
                        />
                      )}
                </div>
            </div>
          </div>
        </div>
      </div>

      {/* Esto es para el cluster mobile */}
      <div className="row custom-row mb-2">
        <div className="text-start">
          <div className="row align-items-center">
            <div className="col-auto mt-3">
              <DownloadComponentMobile className="btnInfo-download ms-3" />
            </div>
            <div className="col d-flex justify-content-center ">
              {/* <ClusterComponentMobileBlue empleadoDetails={empleadoDetails} className="cluster-mobile ms-2" /> */}
              <ClusterComponentMobileBlue positions={positions} empleadoDetails={empleadoDetails} />
            </div>
            <div className="col-auto d-flex align-items-center justify-content-end mt-3 text-primary ms-1">
              <LeftComponentMobile className="btnInfo-left ms-0" onClick={handleViewInfoMobile} />
              <span className="mx-1">{currentPage}/{totalPages}</span>
              <RightComponentMobile className="btnInfo-right me-3"  onClick={handleViewMobileExperience} />
            </div>
          </div>
        </div>
      </div>

      <div className="content-summary-experience-scroll ms-2">
        {/* summaryText */}
        <div className="row custom">
          <h4 className="summary-Tittle-mobile mt-3" style={globalStyles.color}>Summary</h4>
          <p className="summary-Text-mobile text-break mt-2">{empleadoDetails.profile_summary}</p>
        </div>

        {/* TableExperience */}
        <div className="row custom">
          <div className="carrer-container mt-3">
            <h4 className="experience-title-mobile text-primary fs-4 mb-4">Career experience</h4>
            <table className="carrer-table-mobile table table-sm table-borderless">
              <tbody>
                {empleadoDetails.experiences.map((exp, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index !== empleadoDetails.experiences.length - 1 ? "white" : "none",
                      color: index !== empleadoDetails.experiences.length - 1 ? "white" : "white",
                    }}
                  >
                    <td style={{ paddingRight: '18px' }}>{renderDateRange(exp)}</td>
                    <td>
                      <div>{exp.job_title}</div>
                      <div className="text-muted small">{exp.company}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* TableEducation */}
        <div className="row custom">
          <div className="education-container mt-3 pb-5">
            <h4 className="education-title-mobile text-primary fs-4 mb-4">Education</h4>
            <table className="education-table-mobile table table-sm table-borderless" style={{ marginTop: '1rem' }}>
              <tbody>
                {empleadoDetails.education.map((edu, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index !== empleadoDetails.education.length - 1 ? "white" : "none",
                      color: index !== empleadoDetails.education.length - 1 ? "black" : "white",
                    }}
                  >
                    <td style={{ whiteSpace: 'nowrap', paddingRight: '18px' }}>{renderDateRange(edu)}</td>
                    <td className="info-education">
                      <div className="text-break">{edu.title}</div>
                      <div className="text-muted small">{edu.institution}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SummaryComponentMobile;
