import * as React from "react"
const AddIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={57}
    height={57}
    fill="none"
    viewBox="0 0 57 57"
    {...props}

  >
    <circle cx={28.5} cy={28.5} r={28.5} fill="#06F" />
    <path
      fill="#F2F4FF"
      d="M29 17.321a.78.78 0 0 1 .778.778l-.004 9.129h9.132c.429.007.775.36.763.793a.775.775 0 0 1-.763.763h-9.133l-.004 9.107a.78.78 0 0 1-1.557 0l.005-9.106-9.103.008a.779.779 0 0 1 .001-1.557l9.106-.004.004-9.136c0-.428.346-.774.775-.775Z"
    />
  </svg>
)
export default AddIcon