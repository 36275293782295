import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { extractTicketData } from './ticketUtils';
import { getJiraTicketAttachments } from '../../../services/apiJira';
import FormPeopleMain from '../changeForm/formPeople/form/components/formPeopleMain';
import TerminationMenu from '../terminationForm/menuTermination';
//import PruebaEjemplo from './changeForm/formPeople/form/ticketDetails/pruebaEjemplo.js';

const TicketDetails = (userInfoSSFF, usersInfoSSFF) => {
  const location = useLocation();
  const { ticket, type } = location.state || {}; // Extrae ticket y type del estado

  const [ticketData, setTicketData] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [error, setError] = useState(null);
  console.log('ticket', ticket);

  useEffect(() => {
    if (ticket && type) {
      const fetchAttachments = async () => {
        try {
          console.log('ticket.key', ticket.key);
          const ticketId = ticket.key;
          const attachmentsData = await getJiraTicketAttachments(ticketId);
          console.log('attachmentsData', attachmentsData.filename);
          setAttachments(attachmentsData);
        } catch (err) {
          console.error('Error al obtener adjuntos:', err);
          setError('Error al obtener los adjuntos');
        }
      };
      const { fields } = ticket;

      const data = extractTicketData(fields, type, attachments);
      setTicketData(data);
    }
  }, [ticket, type, attachments]);

  if (!ticketData) {
    return <p>Cargando detalles del ticket...</p>;
  }
  let content;

  console.log('ticket TYPE en ticketDetails', type);
  console.log('ticketData en ticketDetails', ticketData);
  if (type === 'change') {
    content = <FormPeopleMain ticketData={ticketData} userInfoSSFF={userInfoSSFF} />;
  } else if (type === 'termination') {
    content = <TerminationMenu ticketData={ticketData} userInfoSSFF={userInfoSSFF} />;
  } else {
    content = <div>No hay detalles disponibles.</div>;
  }

  return <div className='ticket-details-container'>{content}</div>;
};

export default TicketDetails;
