import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addEmployeeId } from '../../../services/TalentCards/FetchEmployeeAdd';
import { updateEmployeeNew } from '../../../services/TalentCards/FetchUpdateEmployeeNew';
import CryptoJS from 'crypto-js';
import DOMpurify from 'dompurify';


const countryOptions = [
  { value: 'Peru', label: 'Peru' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Argentina', label: 'Argentina' },
  { value: 'Spain', label: 'Spain' },
  { value: 'Chile', label: 'Chile' },
  { value: 'Colombia', label: 'Colombia' },
  { value: 'Ecuador', label: 'Ecuador' },
  { value: 'Brazil', label: 'Brazil' },
  { value: 'UK', label: 'UK' },
  { value: 'Mexico', label: 'Mexico' },
  { value: 'Hispam', label: 'Hispam' },
  { value: 'Europe', label: 'Europe' },


];

const languageOptions = [
  { value: 'English', label: 'English' },
  { value: 'French', label: 'French' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'German', label: 'German' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Portuguese', label: 'Portuguese' },
];

const levelOptions = [
  { value: 'native', label: 'Native' },
  { value: 'advanced', label: 'Advanced' },
  { value: 'intermediate', label: 'Intermediate' },
  { value: 'basic', label: 'Basic' },
  
];

const CountrySelect = [
  { value: 'Andorra', label: 'Andorra' },
  { value: 'Somalia', label: 'Somalia' },
  { value: 'San Martin (parte francesa)', label: 'San Martin (parte francesa)' },
  { value: 'Isla Bouvet', label: 'Isla Bouvet' },
  { value: 'Mayotte', label: 'Mayotte' },
  { value: 'Islas Turcas y Caicos', label: 'Islas Turcas y Caicos' },
  { value: 'Perú', label: 'Perú' },
  { value: 'Corea', label: 'Corea' },
  { value: 'Singapur', label: 'Singapur' },
  { value: 'Isla Norfolk', label: 'Isla Norfolk' },
  { value: 'Islas Feroe', label: 'Islas Feroe' },
  { value: 'Chipre', label: 'Chipre' },
  { value: 'Túnez', label: 'Túnez' },
  { value: 'San Marino', label: 'San Marino' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Hungría', label: 'Hungría' },
  { value: 'Colombia', label: 'Colombia' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Antártida', label: 'Antártida' },
  { value: 'Albania', label: 'Albania' },
  { value: 'Camboya', label: 'Camboya' },
  { value: 'Macedonia, Ex República Yugoslava de', label: 'Macedonia, Ex República Yugoslava de' },
  { value: 'Ucrania', label: 'Ucrania' },
  { value: 'Benín', label: 'Benín' },
  { value: 'Santo Tomé y Príncipe', label: 'Santo Tomé y Príncipe' },
  { value: 'Islas Vírgenes de Estados Unidos', label: 'Islas Vírgenes de Estados Unidos' },
  { value: 'Italia', label: 'Italia' },
  { value: 'Trinidad y Tobago', label: 'Trinidad y Tobago' },
  { value: 'Surinam', label: 'Surinam' },
  { value: 'Comoras', label: 'Comoras' },
  { value: 'Israel', label: 'Israel' },
  { value: 'Congo, República Democrática del', label: 'Congo, República Democrática del' },
  { value: 'Argelia', label: 'Argelia' },
  { value: 'Zambia', label: 'Zambia' },
  { value: 'Kuwait', label: 'Kuwait' },
  { value: 'Liberia', label: 'Liberia' },
  { value: 'Polinesia Francesa', label: 'Polinesia Francesa' },
  { value: 'República Democrática Popular Lao', label: 'República Democrática Popular Lao' },
  { value: 'Nigeria', label: 'Nigeria' },
  { value: 'Islas Marianas del Norte', label: 'Islas Marianas del Norte' },
  { value: 'Japón', label: 'Japón' },
  { value: 'Bután', label: 'Bután' },
  { value: 'Guam', label: 'Guam' },
  { value: 'Sierra Leona', label: 'Sierra Leona' },
  { value: 'China', label: 'China' },
  { value: 'Letonia', label: 'Letonia' },
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Belice', label: 'Belice' },
  { value: 'Botsuana', label: 'Botsuana' },
  { value: 'Islas Ultramarinas Menores de Estados Unidos', label: 'Islas Ultramarinas Menores de Estados Unidos' },
  { value: 'Kirguistán', label: 'Kirguistán' },
  { value: 'Australia', label: 'Australia' },
  { value: 'Dominica', label: 'Dominica' },
  { value: 'Países Bajos', label: 'Países Bajos' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Canadá', label: 'Canadá' },
  { value: 'Vanuatu', label: 'Vanuatu' },
  { value: 'Grecia', label: 'Grecia' },
  { value: 'Congo', label: 'Congo' },
  { value: 'Sahara Occidental', label: 'Sahara Occidental' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Islas Heard y McDonald', label: 'Islas Heard y McDonald' },
  { value: 'Guernsey', label: 'Guernsey' },
  { value: 'Papúa Nueva Guinea', label: 'Papúa Nueva Guinea' },
  { value: 'Costa de Marfil', label: 'Costa de Marfil' },
  { value: 'Afganistán', label: 'Afganistán' },
  { value: 'Nueva Zelanda', label: 'Nueva Zelanda' },
  { value: 'San Cristóbal y Nieves', label: 'San Cristóbal y Nieves' },
  { value: 'Guadalupe', label: 'Guadalupe' },
  { value: 'Bielorrusia', label: 'Bielorrusia' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'Islandia', label: 'Islandia' },
  { value: 'Libia', label: 'Libia' },
  { value: 'Santa Lucía', label: 'Santa Lucía' },
  { value: 'Islas Cocos (Keeling)', label: 'Islas Cocos (Keeling)' },
  { value: 'Emiratos Árabes Unidos', label: 'Emiratos Árabes Unidos' },
  { value: 'Moldavia, República de', label: 'Moldavia, República de' },
  { value: 'Tailandia', label: 'Tailandia' },
  { value: 'El Salvador', label: 'El Salvador' },
  { value: 'Marruecos', label: 'Marruecos' },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Eslovaquia', label: 'Eslovaquia' },
  { value: 'Sudán del Sur', label: 'Sudán del Sur' },
  { value: 'Sudáfrica', label: 'Sudáfrica' },
  { value: 'Eritrea', label: 'Eritrea' },
  { value: 'Micronesia', label: 'Micronesia' },
  { value: 'Barbados', label: 'Barbados' },
  { value: 'Zimbabue', label: 'Zimbabue' },
  { value: 'Namibia', label: 'Namibia' },
  { value: 'Guinea Ecuatorial', label: 'Guinea Ecuatorial' },
  { value: 'Santa Elena, Ascensión y Tristán de Acuña', label: 'Santa Elena, Ascensión y Tristán de Acuña' },
  { value: 'Sudán', label: 'Sudán' },
  { value: 'Chad', label: 'Chad' },
  { value: 'Chile', label: 'Chile' },
  { value: 'Omán', label: 'Omán' },
  { value: 'Noruega', label: 'Noruega' },
  { value: 'Kosovo', label: 'Kosovo' },
  { value: 'Macao', label: 'Macao' },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Pitcairn', label: 'Pitcairn' },
  { value: 'Ecuador', label: 'Ecuador' },
  { value: 'Territorios Australes Franceses', label: 'Territorios Australes Franceses' },
  { value: 'Isla de Navidad', label: 'Isla de Navidad' },
  { value: 'Rumanía', label: 'Rumanía' },
  { value: 'Islas Malvinas', label: 'Islas Malvinas' },
  { value: 'Malasia', label: 'Malasia' },
  { value: 'Guatemala', label: 'Guatemala' },
  { value: 'Svalbard y Jan Mayen', label: 'Svalbard y Jan Mayen' },
  { value: 'Alemania', label: 'Alemania' },
  { value: 'Turkmenistán', label: 'Turkmenistán' },
  { value: 'Islas Georgias del Sur y Sandwich del Sur', label: 'Islas Georgias del Sur y Sandwich del Sur' },
  { value: 'Yemen', label: 'Yemen' },
  { value: 'Gabón', label: 'Gabón' },
  { value: 'Nueva Caledonia', label: 'Nueva Caledonia' },
  { value: 'Fiyi', label: 'Fiyi' },
  { value: 'Jordania', label: 'Jordania' },
  { value: 'Marruecos', label: 'Marruecos' },
  { value: 'Tanzania', label: 'Tanzania' },
  { value: 'Islas Virgenes de Estados Unidos', label: 'Islas Virgenes de Estados Unidos' },
  { value: 'Kenia', label: 'Kenia' },
  { value: 'Togo', label: 'Togo' },
  { value: 'Mongolia', label: 'Mongolia' },
  { value: 'Lesoto', label: 'Lesoto' },
  { value: 'Túnez', label: 'Túnez' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
  { value: 'Mali', label: 'Mali' },
  { value: 'Siria', label: 'Siria' },
  { value: 'Libano', label: 'Libano' },
  { value: 'España', label: 'España' },
  { value: 'México', label: 'México' },
  { value: 'Panamá', label: 'Panamá' },
  { value: 'Finlandia', label: 'Finlandia' },
  { value: 'Irlanda', label: 'Irlanda' },
  { value: 'Japón', label: 'Japón' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Noruega', label: 'Noruega' },
  { value: 'Islandia', label: 'Islandia' },
  { value: 'Zambia', label: 'Zambia' },
  { value: 'Malaui', label: 'Malaui' },
  { value: 'Mozambique', label: 'Mozambique' },
  { value: 'Angola', label: 'Angola' },
  { value: 'Seychelles', label: 'Seychelles' },
  { value: 'Haití', label: 'Haití' },
  { value: 'Yemen', label: 'Yemen' },
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Jordania', label: 'Jordania' },
  { value: 'Cuba', label: 'Cuba' },
  { value: 'Guinea-Bisáu', label: 'Guinea-Bisáu' },
  { value: 'Kazajistán', label: 'Kazajistán' },
  { value: 'Antigua y Barbuda', label: 'Antigua y Barbuda' },
  { value: 'Ghana', label: 'Ghana' },
  { value: 'Laos', label: 'Laos' },
  { value: 'Hungría', label: 'Hungría' },
  { value: 'Albania', label: 'Albania' },
  { value: 'Ucrania', label: 'Ucrania' },
  { value: 'Vietnam', label: 'Vietnam' },
  { value: 'Egipto', label: 'Egipto' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Marruecos', label: 'Marruecos' },
  { value: 'Montenegro', label: 'Montenegro' },
  { value: 'Haití', label: 'Haití' },
  { value: 'Argelia', label: 'Argelia' },
  { value: 'Indonesia', label: 'Indonesia' },
  { value: 'Liberia', label: 'Liberia' },
  { value: 'Mozambique', label: 'Mozambique' },
  { value: 'Costa de Marfil', label: 'Costa de Marfil' },
  { value: 'Níger', label: 'Níger' },
  { value: 'Malaui', label: 'Malaui' },
  { value: 'Tayikistán', label: 'Tayikistán' },
  { value: 'Libia', label: 'Libia' },
  { value: 'Filipinas', label: 'Filipinas' },
  { value: 'Ucrania', label: 'Ucrania' },
  { value: 'Pakistán', label: 'Pakistán' },
  { value: 'Uganda', label: 'Uganda' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Mauritania', label: 'Mauritania' },
  { value: 'Camerún', label: 'Camerún' },
  { value: 'Camboya', label: 'Camboya' },
  { value: 'Kiribati', label: 'Kiribati' },
  { value: 'Filipinas', label: 'Filipinas' },
  { value: 'República Centroafricana', label: 'República Centroafricana' },
  { value: 'Uganda', label: 'Uganda' },
  { value: 'Montenegro', label: 'Montenegro' },
  { value: 'Níger', label: 'Níger' },
  { value: 'Macedonia', label: 'Macedonia' },
  { value: 'Líbano', label: 'Líbano' },
  { value: 'Palestina, Estado de', label: 'Palestina, Estado de' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
  { value: 'San Vicente y las Granadinas', label: 'San Vicente y las Granadinas' },
  { value: 'Finlandia', label: 'Finlandia' },
  { value: 'Irak', label: 'Irak' },
  { value: 'San Bartolomé', label: 'San Bartolomé' },
  { value: 'Cuba', label: 'Cuba' },
  { value: 'España', label: 'España' },
  { value: 'Taiwán', label: 'Taiwán' },
  { value: 'Estados Unidos', label: 'Estados Unidos' },
  { value: 'Tokelau', label: 'Tokelau' },
  { value: 'Bolivia', label: 'Bolivia' },
  { value: 'Etiopía', label: 'Etiopía' },
  { value: 'Corea, República Popular Democrática del Sur', label: 'Corea, República Popular Democrática del Sur' },
  { value: 'Catar', label: 'Catar' },
  { value: 'Luxemburgo', label: 'Luxemburgo' },
  { value: 'Nauru', label: 'Nauru' },
  { value: 'Maldivas', label: 'Maldivas' },
  { value: 'Arabia Saudita', label: 'Arabia Saudita' },
  { value: 'Ghana', label: 'Ghana' },
  { value: 'Senegal', label: 'Senegal' },
  { value: 'Panamá', label: 'Panamá' },
  { value: 'Madagascar', label: 'Madagascar' },
  { value: 'Hong Kong', label: 'Hong Kong' },
  { value: 'Tuvalu', label: 'Tuvalu' },
  { value: 'Cabo Verde', label: 'Cabo Verde' },
  { value: 'República Checa', label: 'República Checa' },
  { value: 'Guinea', label: 'Guinea' },
  { value: 'Martinica', label: 'Martinica' },
  { value: 'Palaos', label: 'Palaos' },
  { value: 'Irán, República Islámica de', label: 'Irán, República Islámica de' },
  { value: 'Haití', label: 'Haití' },
  { value: 'Bangladesh', label: 'Bangladesh' },
  { value: 'Granada', label: 'Granada' },
  { value: 'Polonia', label: 'Polonia' },
  { value: 'Paraguay', label: 'Paraguay' },
  { value: 'Timor Oriental', label: 'Timor Oriental' },
  { value: 'Angola', label: 'Angola' },
  { value: 'Groenlandia', label: 'Groenlandia' },
  { value: 'República Dominicana', label: 'República Dominicana' },
  { value: 'Malaui', label: 'Malaui' },
  { value: 'Yibuti', label: 'Yibuti' },
  { value: 'Islas Salomón', label: 'Islas Salomón' },
  { value: 'Pakistán', label: 'Pakistán' },
  { value: 'Reino Unido', label: 'Reino Unido' },
  { value: 'Kazajistán', label: 'Kazajistán' },
  { value: 'San Pedro y Miquelón', label: 'San Pedro y Miquelón' },
  { value: 'Guayana Francesa', label: 'Guayana Francesa' },
  { value: 'Ruanda', label: 'Ruanda' },
  { value: 'Dinamarca', label: 'Dinamarca' },
  { value: 'Bosnia-Herzegovina', label: 'Bosnia-Herzegovina' },
  { value: 'Camerún', label: 'Camerún' },
  { value: 'Burundi', label: 'Burundi' },
  { value: 'Antillas Holandesas', label: 'Antillas Holandesas' },
  { value: 'Isla de Man', label: 'Isla de Man' },
  { value: 'Brunei Darussalam', label: 'Brunei Darussalam' },
  { value: 'Togo', label: 'Togo' },
  { value: 'Santa Sede', label: 'Santa Sede' },
  { value: 'Niue', label: 'Niue' },
  { value: 'Burkina Faso', label: 'Burkina Faso' },
  { value: 'Egipto', label: 'Egipto' },
  { value: 'República Árabe Siria', label: 'República Árabe Siria' },
  { value: 'México', label: 'México' },
  { value: 'Brasil', label: 'Brasil' },
  { value: 'Wallis y Futuna', label: 'Wallis y Futuna' },
  { value: 'Guinea-Bisáu', label: 'Guinea-Bisáu' },
  { value: 'Anguila', label: 'Anguila' },
  { value: 'Suiza', label: 'Suiza' },
  { value: 'Federación Rusa', label: 'Federación Rusa' },
  { value: 'Bermuda', label: 'Bermuda' },
  { value: 'Argentina', label: 'Argentina' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Esuatini', label: 'Esuatini' },
  { value: 'Reunión', label: 'Reunión' },
  { value: 'Bahréin', label: 'Bahréin' },
  { value: 'Azerbaiyán', label: 'Azerbaiyán' },
  { value: 'Bahamas', label: 'Bahamas' },
  { value: 'India', label: 'India' },
  { value: 'Puerto Rico', label: 'Puerto Rico' },
  { value: 'Territorio Británico del Océano Índico', label: 'Territorio Británico del Océano Índico' },
  { value: 'Gambia', label: 'Gambia' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Vietnam', label: 'Vietnam' },
  { value: 'Lituania', label: 'Lituania' },
  { value: 'Turquía', label: 'Turquía' },
  { value: 'Islas Caimán', label: 'Islas Caimán' },
  { value: 'Mongolia', label: 'Mongolia' },
  { value: 'Irlanda', label: 'Irlanda' },
  { value: 'Mauricio', label: 'Mauricio' },
  { value: 'Islas Cook', label: 'Islas Cook' },
  { value: 'Mauritania', label: 'Mauritania' },
  { value: 'Suecia', label: 'Suecia' },
  { value: 'Mozambique', label: 'Mozambique' },
  { value: 'Tanzania', label: 'Tanzania' },
  { value: 'Seychelles', label: 'Seychelles' },
  { value: 'Islas Marshall', label: 'Islas Marshall' },
  { value: 'Honduras', label: 'Honduras' },
  { value: 'Antigua y Barbuda', label: 'Antigua y Barbuda' },
  { value: 'Samoa Americana', label: 'Samoa Americana' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Bélgica', label: 'Bélgica' },
  { value: 'Islas Vírgenes Británicas', label: 'Islas Vírgenes Británicas' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Lesoto', label: 'Lesoto' },
  { value: 'Aruba', label: 'Aruba' },
  { value: 'Islas Aland', label: 'Islas Aland' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Tayikistán', label: 'Tayikistán' },
  { value: 'Tonga', label: 'Tonga' },
  { value: 'Nepal', label: 'Nepal' },
  { value: 'Costa Rica', label: 'Costa Rica' },
  { value: 'Jersey', label: 'Jersey' },
  { value: 'Gibraltar', label: 'Gibraltar' },
  { value: 'Samoa', label: 'Samoa' },
  { value: 'Mali', label: 'Mali' }
];


const AddEmployeeModal = ({ isOpen, onClose, userInfoSSFF, updateEmployeeListAfterAdd,
  empleadosList,empleadosDeleteList, idToken,
  jwtSSFF}) => {
  
 
    const getLastYearsHistory = () => {
      const currentYear = new Date().getFullYear();
      return Array.from({ length: 3 }, (_, index) => ({
        talent_year: currentYear - index,
        top_talent: 0,
        key_talent: 0,
        performance: 0,
      }));
    };
    

  const [newEmployee, setNewEmployee] = useState({
    user_id: '',
    profile_summary: '',
    country: '',
    cluster_b2c_growth: 'false',
    cluster_b2b_growth: 'false',
    cluster_technology: 'false',
    cluster_enablers: 'false',
    cluster_pm_transversal: 'false',
    cluster_ceo: 'false',
    leadership_impact: 1,
    leadership_collaboration: 1,
    leadership_transformation: 1,
    leadership_growth: 1,
   /*  potential_years_role: '', */
    potential_future_role: '',
    succession_warning: 'false',
    succession_pipeline: '',
    successors_warning: 'false',
    successors: '',
    skillset_overview: '',
    career_action_plan: '',
    additional_insights: '',
    mobility_text: '',
    experience: [{ from_year: null, to_year: null, company: '', job_title: '' }],
    languages: [{ language: '', level: '' }],
    education: [{ from_year: null, to_year: null, institution: '', title: '' }],
    /* history: [{ talent_year: null, top_talent: 0, key_talent: 0, performance: 0 }], */
    history: getLastYearsHistory(),
    mobility_countries: [{ country: '' }]
  });
  const secretKey = process.env.REACT_APP_TALENT_APP;

  // Clave secreta para encriptar y desencriptar
  
  const sanitize = (value) => DOMpurify.sanitize(value, { ALLOWED_TAGS: [], ALLOWED_ATTR: {} });



  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = sanitize(value); 
      setNewEmployee({ ...newEmployee, [name]: sanitizedValue });
  };

  const handleChangeId = (e) => {
    const { name, value } = e.target;
    // Permitir solo hasta 7 caracteres numéricos
    if (/^\d{0,7}$/.test(value)) {
      setNewEmployee({ ...newEmployee, [name]: value });
    }
  };

  const handleArrayChange = (e, index, field, arrayName) => {
    const { value } = e.target;
    const sanitizedValue = sanitize(value);
    const updatedArray = [...newEmployee[arrayName]];
    updatedArray[index][field] = sanitizedValue;
    setNewEmployee({ ...newEmployee, [arrayName]: updatedArray });
  };

  const handleCountrySelectChange = (selectedOption) => {
    setNewEmployee({ ...newEmployee, country: selectedOption ? selectedOption.value : '' });
  };

  const handleCountryChange = (selectedOptions) => {
    const mobility_countries = selectedOptions ? selectedOptions.map(option => ({ country: option.value })) : [];
    setNewEmployee({ ...newEmployee, mobility_countries });
  };

  const handleLanguageChange = (selectedOptions) => {
    const languages = selectedOptions ? selectedOptions.map(option => ({ language: option.value, level: '' })) : [];
    setNewEmployee({ ...newEmployee, languages });
  };

  const handleLevelChange = (selectedOptions, index) => {
    const updatedLanguages = [...newEmployee.languages];
    updatedLanguages[index].level = selectedOptions ? selectedOptions.value : '';
    setNewEmployee({ ...newEmployee, languages: updatedLanguages });
  };

  const handleYearChange = (date, index, field, arrayName) => {
    const updatedArray = [...newEmployee[arrayName]];
    updatedArray[index][field] = date ? date.getFullYear() : '';
    setNewEmployee({ ...newEmployee, [arrayName]: updatedArray });
  };

  const handleDateChange = (date, index, field, arrayName) => {
    const updatedArray = [...newEmployee[arrayName]];
    if (field === 'from_year') {
      const toDate = updatedArray[index].to_year ? new Date(updatedArray[index].to_year, 0, 1) : null;
      if (toDate && date && date > toDate) {
        toast.error('La fecha de inicio no puede ser mayor que la fecha de fin.');
        return;
      }
    } else if (field === 'to_year') {
      const fromDate = updatedArray[index].from_year ? new Date(updatedArray[index].from_year, 0, 1) : null;
      if (fromDate && date && date < fromDate) {
        toast.error('La fecha de fin no puede ser menor que la fecha de inicio.');
        return;
      }
    }
    updatedArray[index][field] = date ? date.getFullYear().toString() : null;
    setNewEmployee({ ...newEmployee, [arrayName]: updatedArray });
  };

  const validateForm = () => {
    const requiredFields = ['user_id', 'profile_summary', 'country','successors',
    'potential_future_role',
    'skillset_overview',
    'career_action_plan',
    'additional_insights',
    'mobility_text']; 
    const booleanFields = ['cluster_b2c_growth', 'cluster_b2b_growth', 'cluster_technology', 'cluster_enablers','cluster_pm_transversal','cluster_ceo']; 
    const numericFields = ['leadership_impact', 'leadership_collaboration', 'leadership_transformation', 'leadership_growth']; 
    const arrayFields = ['experience', 'languages', 'education', 'history', 'mobility_countries']; 
  
    // Validar campos de texto
    const invalidTextFields = requiredFields.filter(
      (field) => !newEmployee[field] || (typeof newEmployee[field] === 'string' && newEmployee[field].trim() === '')
    );
  
    // Validar booleanos
    const invalidBooleanFields = booleanFields.filter(
      (field) => newEmployee[field] === undefined || newEmployee[field] === null
    );
  
    // Validar números
    const invalidNumericFields = numericFields.filter(
      (field) => newEmployee[field] === undefined || newEmployee[field] === null
    );
  
    for (const arrayField of arrayFields) {
      if (!Array.isArray(newEmployee[arrayField]) || newEmployee[arrayField].length === 0) {
        toast.error(`El campo ${arrayField} no puede estar vacío.`);
        return false;
      }
    
      // Validar campos dentro de arreglos
      for (const item of newEmployee[arrayField]) {
        for (const key in item) {
          // Validación especial para `history`
          if (arrayField === 'history') {
            if (item[key] === null || item[key] === undefined || item[key] === '') {
              toast.error(`Todos los campos dentro de ${arrayField} son obligatorios.`);
              return false;
            }
          } else {
            // Validación general para otros campos de tipo arreglo
            if (!item[key] || item[key] === '') {
              toast.error(`Todos los campos dentro de ${arrayField} son obligatorios.`);
              return false;
            }
          }
        }
      }
    }
    
  
    if (invalidTextFields.length > 0) {
      toast.error(`Los siguientes campos son obligatorios: ${invalidTextFields.join(', ')}`);
      return false;
    }
  
    if (invalidBooleanFields.length > 0) {
      toast.error(`Los siguientes campos booleanos son obligatorios: ${invalidBooleanFields.join(', ')}`);
      return false;
    }
  
    if (invalidNumericFields.length > 0) {
      toast.error(`Los siguientes campos numéricos son obligatorios: ${invalidNumericFields.join(', ')}`);
      return false;
    }
  
    return true; 
  };

  const sanitizeEmployeeData = (data) => {
    const sanitizedData = {};
    for (const [key, value] of Object.entries(data)) {
      if (typeof value === 'string') {
        sanitizedData[key] = sanitize(value); 
      } else if (Array.isArray(value)) {
        sanitizedData[key] = value.map((item) =>
          typeof item === 'object' ? sanitizeEmployeeData(item) : sanitize(item)
        );
      } else {
        sanitizedData[key] = value; 
      }
    }
    return sanitizedData;
  };
  
  const encryptData = (data, secretKey) => {
    const encryptedData = {};
    for (const [key, value] of Object.entries(data)) {
      if (typeof value === 'string') {
        const encrypted = CryptoJS.AES.encrypt(value, secretKey).toString();
        encryptedData[key] = encodeURIComponent(btoa(encrypted));
      } else if (Array.isArray(value)) {
        encryptedData[key] = value.map((item) =>
          typeof item === 'object' ? encryptData(item, secretKey) : encryptData({ value: item }, secretKey).value
        );
      } else {
        encryptedData[key] = value;
      }
    }
    return encryptedData;
  };


  const handleAddEmployee = async () => {
    if (!validateForm()) return;
    const localNewEmployee = { ...newEmployee};
    const sanitizedEmployee = sanitizeEmployeeData(localNewEmployee);
    // Cifrar los datos
    const encryptedEmployee = encryptData(sanitizedEmployee, secretKey);

    try {
      const userIdAdd = sanitizedEmployee.user_id;
        
      const existingEmployee = empleadosDeleteList.find(empleado => empleado.user_id === userIdAdd);
      

        const { user_id, ...employeeData } = encryptedEmployee;
  
        // Encriptar y codificar el userIdAdd
        const encrypted = CryptoJS.AES.encrypt(userIdAdd, secretKey).toString();
        const encryptedUserId = encodeURIComponent(btoa(encrypted));

        await updateEmployeeNew(encryptedUserId, employeeData,idToken,
          jwtSSFF);

        await updateEmployeeListAfterAdd(newEmployee);
  
        toast.success('Datos del empleado actualizados con éxito.');
      
  
      setNewEmployee({
        user_id: '',
        profile_summary: '',
        country: '',
        cluster_b2c_growth: 'false',
        cluster_b2b_growth: 'false',
        cluster_technology: 'false',
        cluster_enablers: 'false',
        cluster_pm_transversal: 'false',
        cluster_ceo: 'false',
        leadership_impact: 1,
        leadership_collaboration: 1,
        leadership_transformation: 1,
        leadership_growth: 1,
       /*  potential_years_role: '', */
        potential_future_role: '',
        succession_warning: 'false',
        succession_pipeline: '',
        successors_warning: 'false',
        successors: '',
        skillset_overview: '',
        career_action_plan: '',
        additional_insights: '',
        mobility_text: '',
        experience: [{ from_year: null, to_year: null, company: '', job_title: '' }],
        languages: [{ language: '', level: '' }],
        education: [{ from_year: null, to_year: null, institution: '', title: '' }],
        /* history: [{ talent_year: null, top_talent: 0, key_talent: 0, performance: 0 }], */
        history: getLastYearsHistory(),
        mobility_countries: [{ country: '' }]
      });
  
      onClose(); 
    } catch (error) {
      console.error('Error al añadir o actualizar el empleado:', error);
    }
  };
  

  return (
    <Modal show={isOpen} onHide={onClose} centered size="xl">
      <Modal.Body className="p-4">
        <h4 className="text-primary mb-3 mt-2">Añadir Nuevo Empleado</h4>
        <Row>
          <Col md={6}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">ID</label>
              <input
                  type="text"
                  className="form-control"
                  name="user_id"
                  value={newEmployee.user_id}
                  onChange={handleChangeId}
                  maxLength={7} 
                  pattern="\d{7}" 
                  title="El ID debe contener exactamente 7 números"
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Resumen:</label>
              <textarea
                className="form-control"
                name="profile_summary"
                value={newEmployee.profile_summary}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Country:</label>
              <Select
                name="country"
                options={CountrySelect}
                value={CountrySelect.find(option => option.value === newEmployee.country)}
                onChange={handleCountrySelectChange}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">B2B Growth:</label>
              <select
                className="form-control"
                name="cluster_b2c_growth"
                value={newEmployee.cluster_b2c_growth}
                onChange={handleChange}
              >
                <option value="true">true</option>
                <option value="false">false</option>
              </select>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">B2C Growth:</label>
              <select
                className="form-control"
                name="cluster_b2b_growth"
                value={newEmployee.cluster_b2b_growth}
                onChange={handleChange}
              >
                <option value="true">true</option>
                <option value="false">false</option>
              </select>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Technology:</label>
              <select
                className="form-control"
                name="cluster_technology"
                value={newEmployee.cluster_technology}
                onChange={handleChange}
              >
                <option value="true">true</option>
                <option value="false">false</option>
              </select>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Enablers:</label>
              <select
                className="form-control"
                name="cluster_enablers"
                value={newEmployee.cluster_enablers}
                onChange={handleChange}
              >
                <option value="true">true</option>
                <option value="false">false</option>
              </select>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Ceo Pool:</label>
              <select
                className="form-control"
                name="cluster_ceo"
                value={newEmployee.cluster_ceo}
                onChange={handleChange}
              >
                <option value="true">true</option>
                <option value="false">false</option>
              </select>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">PM Transversal:</label>
              <select
                className="form-control"
                name="cluster_pm_transversal"
                value={newEmployee.cluster_pm_transversal}
                onChange={handleChange}
              >
                <option value="true">true</option>
                <option value="false">false</option>
              </select>
            </div>
          </Col>
          <h3 className="text-primary fs-5 ms-4 me-4">Leadership</h3>
          <Col md={6}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Impact:</label>
              <select
                className="form-control"
                name="leadership_impact"
                value={newEmployee.leadership_impact}
                onChange={handleChange}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Collaboration:</label>
              <select
                className="form-control"
                name="leadership_collaboration"
                value={newEmployee.leadership_collaboration} 
                onChange={handleChange}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Transformation:</label>
              <select
                className="form-control"
                name="leadership_transformation"
                value={newEmployee.leadership_transformation}
                onChange={handleChange}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Growth:</label>
              <select
                className="form-control"
                name="leadership_growth"
                value={newEmployee.leadership_growth}
                onChange={handleChange}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </Col>
         {/*  <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Potential Years Role:</label>
              <textarea
                className="form-control"
                name="potential_years_role"
                value={newEmployee.potential_years_role}
                onChange={handleChange}
              />
            </div>
          </Col> */}
      

          <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Potential Future Role:</label>
              <textarea
                className="form-control"
                name="potential_future_role"
                value={newEmployee.potential_future_role}
                onChange={handleChange}
              />
            </div>
          </Col>
          <h3 className="text-primary fs-5 ms-4 me-4">Warning</h3>
          <Col md={6}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Succession Warning:</label>
              <select
                className="form-control"
                name="succession_warning"
                value={newEmployee.succession_warning}
                onChange={handleChange}
              >
                <option value="true">true</option>
                <option value="false">false</option>
              </select>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Successors Warning:</label>
              <select
                className="form-control"
                name="successors_warning"
                value={newEmployee.successors_warning}
                onChange={handleChange}
              >
                <option value="true">true</option>
                <option value="false">false</option>
              </select>
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Succession Pipeline:</label>
              <textarea
                className="form-control"
                name="succession_pipeline"
                value={newEmployee.succession_pipeline}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Successors:</label>
              <textarea
                className="form-control"
                name="successors"
                value={newEmployee.successors}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Skillset Overview:</label>
              <textarea
                className="form-control"
                name="skillset_overview"
                value={newEmployee.skillset_overview}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Plan de Acción:</label>
              <input
                type="text"
                className="form-control"
                name="career_action_plan"
                value={newEmployee.career_action_plan}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Additional Insights:</label>
              <textarea
                className="form-control"
                name="additional_insights"
                value={newEmployee.additional_insights}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Additional Mobility:</label>
              <textarea
                className="form-control"
                name="mobility_text"
                value={newEmployee.mobility_text}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Idiomas:</label>
              <Select
                isMulti
                name="languages"
                options={languageOptions}
                className="basic-single-select"
                classNamePrefix="select"
                value={languageOptions.filter(option => newEmployee.languages.map(lang => lang.language).includes(option.value))}
                onChange={handleLanguageChange}
                placeholder="Seleccione los idiomas"
              />
            </div>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Niveles:</label>
              {newEmployee.languages.map((language, index) => (
                <Select
                  key={index}
                  name={`levels-${index}`}
                  options={levelOptions}
                  className="basic-single-select"
                  classNamePrefix="select"
                  value={levelOptions.find(option => option.value === language.level)}
                  onChange={(selectedOption) => handleLevelChange(selectedOption, index)}
                  placeholder="Seleccione el nivel"
                />
              ))}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Años:</label>
              {newEmployee.history.map((yearData, index) => (
                <div key={index} className="mb-2">
                  <Row className="align-items-center">
                    <Col md={3}>
                      <label className="form-label ms-5 text-primary">Año</label>
                      <DatePicker
                        selected={yearData.talent_year ? new Date(yearData.talent_year, 0, 1) : null}
                        onChange={(date) => handleYearChange(date, index, 'talent_year', 'history')}
                        showYearPicker
                        dateFormat="yyyy"
                        className="form-control"
                        placeholderText="Seleccionar año"
                        disabled
                      />
                    </Col>
                    <Col md={3}>
                      <label className="form-label text-primary">Top Talent</label>
                      <select
                        className="form-control"
                        name={`top_talent-${index}`}
                        value={yearData.top_talent}
                        onChange={(e) => handleArrayChange(e, index, 'top_talent', 'history')}
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                      </select>
                    </Col>
                    <Col md={3}>
                      <label className="form-label text-primary">Key Talent</label>
                      <select
                        className="form-control"
                        name={`key_talent-${index}`}
                        value={yearData.key_talent}
                        onChange={(e) => handleArrayChange(e, index, 'key_talent', 'history')}
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                      </select>
                    </Col>
                    <Col md={3}>
                      <label className="form-label text-primary">Performance</label>
                      <select
                        className="form-control"
                        name={`performance-${index}`}
                        value={yearData.performance}
                        onChange={(e) => handleArrayChange(e, index, 'performance', 'history')}
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Experiencias:</label>
              {newEmployee.experience.map((experience, index) => (
                <div key={index} className="mb-2">
                  <Row className="align-items-center">
                    <Col md={4}>
                      <DatePicker
                        selected={experience.from_year ? new Date(experience.from_year, 0, 1) : null}
                        onChange={(date) => handleDateChange(date, index, 'from_year', 'experience')}
                        showYearPicker
                        dateFormat="yyyy"
                        className="form-control"
                        placeholderText="Desde"
                        filterDate={(date) =>  date.getFullYear() <= 2024}

                      />
                    </Col>
                    <Col md={4}>
                      <DatePicker
                        selected={experience.to_year ? new Date(experience.to_year, 0, 1) : null}
                        onChange={(date) => handleDateChange(date, index, 'to_year', 'experience')}
                        showYearPicker
                        dateFormat="yyyy"
                        className="form-control"
                        placeholderText="Hasta"
                        filterDate={(date) =>  date.getFullYear() <= 2024}

                      />
                    </Col>
                    <Col md={12}>
                      <input
                        type="text"
                        className="form-control mt-3"
                        name={`company-${index}`}
                        value={experience.company}
                        onChange={(e) => handleArrayChange(e, index, 'company', 'experience')}
                        placeholder="Compañía"
                      />
                    </Col>
                    <Col md={12}>
                      <input
                        type="text"
                        className="form-control mt-3"
                        name={`job_title-${index}`}
                        value={experience.job_title}
                        onChange={(e) => handleArrayChange(e, index, 'job_title', 'experience')}
                        placeholder="Título del Puesto"
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Educación:</label>
              {newEmployee.education.map((education, index) => (
                <div key={index} className="mb-2">
                  <Row className="align-items-center">
                    <Col md={4}>
                      <DatePicker
                        selected={education.from_year ? new Date(education.from_year, 0, 1) : null}
                        onChange={(date) => handleDateChange(date, index, 'from_year', 'education')}
                        showYearPicker
                        dateFormat="yyyy"
                        className="form-control"
                        placeholderText="Desde"
                        filterDate={(date) =>  date.getFullYear() <= 2024}

                      />
                    </Col>
                    <Col md={4}>
                      <DatePicker
                        selected={education.to_year ? new Date(education.to_year, 0, 1) : null}
                        onChange={(date) => handleDateChange(date, index, 'to_year', 'education')}
                        showYearPicker
                        dateFormat="yyyy"
                        className="form-control mt-3"
                        placeholderText="Hasta"
                        filterDate={(date) =>  date.getFullYear() <= 2024}
                      />
                    </Col>
                    <Col md={12}>
                      <input
                        type="text"
                        className="form-control mt-3"
                        name={`institution-${index}`}
                        value={education.institution}
                        onChange={(e) => handleArrayChange(e, index, 'institution', 'education')}
                        placeholder="Institución"
                      />
                    </Col>
                    <Col md={12}>
                      <input
                        type="text"
                        className="form-control mt-3"
                        name={`title-${index}`}
                        value={education.title}
                        onChange={(e) => handleArrayChange(e, index, 'title', 'education')}
                        placeholder="Título"
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3 ms-4 me-4">
              <label className="form-label text-primary mb-0 fs-5">Países:</label>
              <Select
                isMulti
                options={countryOptions}
                value={countryOptions.filter(option => newEmployee.mobility_countries.map(mc => mc.country).includes(option.value))}
                onChange={handleCountryChange}
                placeholder="Seleccione países"
              />
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end gap-2 mt-3">
          <Button className="btn-mantener rounded-pill" onClick={handleAddEmployee}>Añadir</Button>
          <Button className="btn-delete rounded-pill" onClick={onClose}>Cancelar</Button>
        </div>
      </Modal.Body>
      <ToastContainer />
    </Modal>
  );
}

export default AddEmployeeModal;
