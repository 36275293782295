import React, { useState, useEffect } from 'react';
import SpainComponent from '../../assests/FileComponentes/countries/SpainComponent';
import GermanyComponent from '../../assests/FileComponentes/countries/GermanyComponent';
import UruguayComponent from '../../assests/FileComponentes/countries/UruguayComponent';
import ArgentinaIconComponent from '../../assests/FileComponentes/countries/ArgentinaIconComponent';
import ChileIconComponent from '../../assests/FileComponentes/countries/ChileIconComponent';
import ColombiaIconComponent from '../../assests/FileComponentes/countries/ColombiaIconComponent';
import EcuadorIconComponent from '../../assests/FileComponentes/countries/EcuadorIconComponent';
import PeruIconComponent from '../../assests/FileComponentes/countries/PeruIconComponent';
import BrasilIconComponent from '../../assests/FileComponentes/countries/BrasilIconComponent';
import UkIconComponent from '../../assests/FileComponentes/countries/UkIconComponent';
import MexicoIconComponent from '../../assests/FileComponentes/countries/MexicoIconComponent';
import SouthIconComponent from '../../assests/FileComponentes/countries/SouthIconComponent';
import globalStyles from '../../Styles/globalStyle';
import BackIconComponentBlue from '../../assests/MobileIcons/BackIconComponetBlue';
import '../Styles/experienceMobile.css';
import ClusterComponentMobileBlue from './ClusterComponentBlue';
import RightComponentMobile from '../../assests/MobileIcons/RightComponentMobile';
import LeftComponentMobile from '../../assests/MobileIcons/LeftComponentMobile';
import DownloadComponentMobile from '../../assests/MobileIcons/DownloadComponentMobile';
import SiIconComponent from '../../assests/FileComponentes/countries/SiIconMovilityComponent';
import NoIconComponent from '../../assests/FileComponentes/countries/NoIconMovilityComponent';
import EuropeComponent from '../../assests/FileComponentes/countries/EuropeIconComponent';
import LeadershipTableMobile from './LeaderShipTableMobile';
import { useNavigate } from 'react-router-dom';
import userImg from '../../../images/user-account.png';
import CryptoJS from 'crypto-js';

const countryIconComponents = {
  Spain: SpainComponent,
  Germany: GermanyComponent,
  Uruguay: UruguayComponent,
  Argentina: ArgentinaIconComponent,
  Chile: ChileIconComponent,
  Colombia: ColombiaIconComponent,
  Ecuador: EcuadorIconComponent,
  Peru: PeruIconComponent,
  Brasil: BrasilIconComponent,
  UK: UkIconComponent,
  Mexico: MexicoIconComponent,
  Hispam: SouthIconComponent,
  SI: SiIconComponent,
  YES: SiIconComponent,
  NO: NoIconComponent,
  null: NoIconComponent,
  Europe: EuropeComponent,
  TBC: NoIconComponent,
};

function getCountryIconComponent(countryName) {
  return countryIconComponents[countryName] || DefaultCountryIconComponent;
}

const DefaultCountryIconComponent = () => (
  <div
    style={{
      width: '24px',
      height: '24px',
      background: 'gray',
      marginRight: '5px',
    }}
  />
);
const styleCSO = {
  fontSize: ' 1.125em',
  marginBottom: '10px',
};

function ExperienceComponentMobile({
  selectedEmployeeId,
  userInfoSSFF,
  empleadoDetails,
  empleado,
}) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(3);
  const totalPages = 3;
  const [formData, setFormData] = useState({
    skillset_overview: '',
  });

  const [prevSkills, setPrevSkills] = useState([]);
  const secretKey = process.env.REACT_APP_TALENT_APP;
  
  // Encriptar y codificar el selectedEmployeeId
  const encrypted = CryptoJS.AES.encrypt(selectedEmployeeId, secretKey).toString();
  const encryptedUserId = encodeURIComponent(btoa(encrypted));

  useEffect(() => {
    if (empleadoDetails) {
      setFormData({
        skillset_overview: empleadoDetails.skillset_overview || ' ',
      });
      setPrevSkills(
        empleadoDetails.skillset_overview
          ? empleadoDetails.skillset_overview.split('\n')
          : []
      );
    } else {
      setFormData({
        skillset_overview: ' ',
      });
      setPrevSkills([]);
    }
  }, [empleadoDetails, selectedEmployeeId]);

  const backPage = () => {
    navigate(`/talentCards`);
  };


  const handleBackViewSummaryMobile = () => {
 
    // Navega a la ruta deseada con el ID encriptado
    navigate(`/talentCardsSecond/${encryptedUserId}`, {
      state: {
        empleadoDetails,
        userInfoSSFF,
        selectedEmployeeId: encryptedUserId,
        empleado,
      },
    });
  };

  const position = empleado.empInfo?.jobInfoNav?.results?.[0]?.jobTitle || '';

  // Obtener la cadena de la imagen en Base64
  const base64Image = empleado.empInfo?.photoNav?.results?.[0]?.photo;

  // Construir la URL de la imagen en Base64
  const imageUrl = base64Image
    ? `data:image/jpeg;base64,${base64Image}`
    : userImg;

  const positions = [
    { key: 'cluster_ceo', label: 'CEO' },
    { key: 'cluster_b2c_growth', label: 'B2C' },
    { key: 'cluster_b2b_growth', label: 'B2B' },
    { key: 'cluster_technology', label: 'TEC' },
    { key: 'cluster_enablers', label: 'EN' },
    { key: 'cluster_pm_transversal', label: 'PM' }

  ].filter((position) => empleadoDetails[position.key]);

  const formatTextWithNewLines = (text) => {
    return text.split(/\n{2,}/).map((paragraph, index) => (
      <p key={index}>
        {paragraph.split('\n').map((line, i) => (
          <React.Fragment key={i}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
    ));
  };

  return (
    <div
      className="experience-mobile p-0 ms-0 me-0 "
      style={{ backgroundColor: '#ffff' }}
    >
      {/* HeaderMobile */}
      <div className="row custom-row">
        <div className=" text-start">
          <div className="row align-items-center">
            <div className="col-auto ">
              <div className="exit-container me-2 ms-2">
                <BackIconComponentBlue
                  className="btnInfo-exit"
                  onClick={backPage}
                />
              </div>
            </div>
            <div className="col  text-start ">
              <h1 className="fs-3 mt-3 mb-1 employee-name text-primary">
                {empleado.displayName}
              </h1>
              <h2 className="fs-6 employee-job-title">{position}</h2>
            </div>
            <div className="col-auto text-center">
              <div
                className="rounded-circle overflow-hidden mt-3 me-3"
                style={{
                  width: '90px',
                  height: '90px',
                  objectFit: 'cover',
                  background: '#007bff',
                }}
              >
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt="Foto de empleado"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Esto es para el cluster mobile */}
      <div className="row custom-row mb-2">
        <div className=" text-start">
          <div className="row align-items-center">
            <div className="col-auto mt-3">
              <DownloadComponentMobile className="btnInfo-download ms-3" />
            </div>
            <div className="col d-flex justify-content-center">
              <ClusterComponentMobileBlue
                positions={positions}
                empleadoDetails={empleadoDetails}
              />
            </div>
            <div className="col-auto d-flex align-items-center justify-content-end mt-3 text-primary ms-1">
              <LeftComponentMobile
                className="btnInfo-left ms-0"
                onClick={handleBackViewSummaryMobile}
              />
              <span className="mx-1">
                {currentPage}/{totalPages}
              </span>
              <RightComponentMobile className="btnInfo-right  me-3" />
            </div>
          </div>
        </div>
      </div>

      <div className="content-others-components-scroll ms-2 ">
        {/* LeaderShip */}
        <div className="row custom mb-4">
          <h4
            className="experience mt-3 fs-4 mb-4 leaderShip-title-mobile ms-1"
            style={globalStyles.color}
          >
            Leadership
          </h4>
          <div className="tabla-LeaderShip-Table">
            {/*Tabla LaderShip*/}
            {selectedEmployeeId && (
              <LeadershipTableMobile leadershipData={empleadoDetails} />
            )}
          </div>
        </div>

        {/* Critical Skillset */}
        <div className="row custom mb-5">
          <p
            className="experience mt-5 fs-4 skillset-title-mobile mb-4 ms-1"
            style={globalStyles.color}
          >
            Critical skillset overview
          </p>
          <div className="fs-6 mb-4 skill-table-mobile ms-1">
            {selectedEmployeeId &&
              empleadoDetails &&
              empleadoDetails.skillset_overview && (
                <ul className="list-unstyled">
                  {empleadoDetails.skillset_overview
                    .split('\n')
                    .map((skill, index) => (
                      <li key={index} suppressContentEditableWarning={true}>
                        {skill.split('\\n').map((line, i) => (
                          <span key={i} style={{ display: 'block' }}>
                            {line}
                          </span>
                        ))}
                      </li>
                    ))}
                </ul>
              )}
          </div>
        </div>

        {/* PotentialValue */}
        <div className="row custom mb-5">
          <p className="experience text-primary fs-4 potential-title-mobile mb-4 ms-1 ">
            Potential to Value
          </p>
          <div className="potential-value-mobile  ms-1">
            {empleadoDetails.potential_years_role && (
              <div className="d-flex align-items-start  potential-year-text-mobile ">
                <span className="me-2 mb-3">Years in role:</span>
                <p className="mb-0">{empleadoDetails.potential_years_role}</p>
              </div>
            )}
            {empleadoDetails.potential_future_role && (
              <div className="d-flex align-items-start mt-3 potential-text-mobile">
                <span className=" me-2 mb-3">Potential to future role(s):</span>
                <p className="mb-0 text-break  ms-2">
                  {empleadoDetails.potential_future_role}
                </p>
              </div>
            )}

            {empleadoDetails.sucession_pipleline && (
              <div className="d-flex align-items-start mt-3  succesion-text-mobile">
                <p className="me-2 mb-5 ">Succession pipeline:</p>
                <p className="mb-0 text-break">
                  {empleadoDetails.sucession_pipleline}
                </p>
              </div>
            )}

            {empleadoDetails.successors && (
              <div className="d-flex align-items-start successors-text-mobile mt-0">
                <p className="me-2 succesor-title-mobile">Successor(s):</p>
                <p className="mb-0 text-break ">
                  {empleadoDetails.successors}{' '}
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Mobility */}
        <div className="row custom mb-4">
          <h4
            className="fs-4 mb-4 text-primary mobility-title-mobile ms-1 "
            style={{ ...globalStyles.color }}
          >
            Mobility to
          </h4>
          <div
            className="Mobility-content  ms-1"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {empleadoDetails.mobility &&
              empleadoDetails.mobility.map((mobilityItem, index) => {
                const CountryIconComponent = getCountryIconComponent(
                  mobilityItem.country
                );
                if (CountryIconComponent) {
                  return (
                    <div
                      key={index}
                      className="d-flex flex-column align-items-center me-3  ms-1"
                    >
                      <CountryIconComponent className="rounded-circle mb-2 mt-2 country" />
                      <span className="name-country-mobile fs-6">
                        {mobilityItem.country}
                      </span>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '10px',
                      }}
                    >
                      <DefaultCountryIconComponent />
                      <span>{mobilityItem.country}</span>
                    </div>
                  );
                }
              })}
          </div>
          <p className="fs-6 mt-4 aditional-mobilty-info-mobile  ms-1">
            {empleadoDetails.mobility_text ||
              'No Additional Mobility Available'}
          </p>
        </div>

        {/* Career Action Plan */}
        <div className="row custom mb-4">
          <h4
            className="fs-4 mb-4 text-primary carrer-title-mobile ms-1 "
            style={{ ...globalStyles.color }}
          >
            Career Action Plan
          </h4>
          <div className="mb-3  ms-1">
            <ul className="list-unstyled">
              <li className="fs-6 mt-2 text-break carrer-text-mobile">
                {empleadoDetails.career_action_plan
                  ? formatTextWithNewLines(empleadoDetails.career_action_plan)
                  : 'No Action Plan Available'}
              </li>
            </ul>
          </div>
        </div>

        {/* Additional */}
        <div className="row custom">
          <h4
            className="experience text-primary fs-4 aditional-title-mobile  ms-1"
            style={{ ...globalStyles.color }}
          >
            Additional insights
          </h4>
          <div className="aditional-content-mobile mb-5  ms-1">
            <p className="fs-6 text-break aditional-Text-mobile mt-2">
              {empleadoDetails.additional_insights
                ? formatTextWithNewLines(empleadoDetails.additional_insights)
                : 'No Additional Insights Available'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExperienceComponentMobile;
