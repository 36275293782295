import React from 'react';
import { useEffect, useState } from 'react';
import InfoComponent from '../Components/InfoComponent'; // Ajusta esta ruta según tu estructura
import InfoComponentMobile from '../../Mobile/Components/InfoComponentMobile'; // Ajusta esta ruta según tu estructura
import SummaryComponent from '../Components/SummaryComponent'; // Ajusta esta ruta según tu estructura
import ExperienceComponent from '../Components/ExperienceComponent'; // Ajusta esta ruta según tu estructura
import { useParams, useLocation } from 'react-router-dom';
import { FetchEmployeeDetails } from '../../../services/TalentCards/FetchEmployeeDetails';
import { useDecryptUserId } from '../../../services/TalentCards/useDecryptUserId';
import LoadingButton from '../Components/loadingButton';
import LoadingAnimation from '../Components/loadingButtonSplit';

const TalentViewComponent = ({ userInfoSSFF, currentUserEmail,
  userPermissions,empleadosList,idToken,jwtSSFF}) => {
  const { user_id: encryptedUserId } = useParams();
  
  const location = useLocation();
  const { empleado } = location.state || {};

  const [isMobile, setIsMobile] = useState(false);
  const [empleadoDetails, setEmpleadoDetails] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [error, setError] = useState(null);

  const [user_id, setUserId] = useState(null);
  const { decryptUserId, loading: decryptLoading } = useDecryptUserId();

  useEffect(() => {
    const fetchDecryptedId = async () => {
      try {
        if (encryptedUserId) {
          const decryptedId = await decryptUserId(encryptedUserId);
          setUserId(decryptedId);
        }
      } catch (err) {
        console.error('No se pudo desencriptar el ID:', err.message);
        setError(err.message);
      }
    };

    fetchDecryptedId();
  }, [encryptedUserId, decryptUserId]);

  const empleado_userId = empleado?.userId;


  useEffect(() => {
    if (!user_id) return;

    // Verificación de coincidencia de IDs
    if (user_id !== empleado_userId) {
      console.warn('El user_id no coincide con el empleado actual.');
      return;
    }

    async function loadEmpleadoDetails() {
      setLoadingDetails(true);
      try {
        const data = await FetchEmployeeDetails(encryptedUserId,idToken);
        setEmpleadoDetails(data);
      } catch (error) {
        console.error(
          `Error al obtener los datos del empleado con ID ${user_id}:`,
          error.message
        );
        setError(error);
      } finally {
        setLoadingDetails(false);
      }
    }

    if (user_id) {
      loadEmpleadoDetails();
    }
  }, [user_id, empleado_userId, encryptedUserId]);

 
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderDateRange = (experience) => {
    if (experience.to === 'present') {
      return <span>{experience.from} - present</span>;
    } else if (experience.to === undefined) {
      return <span>{experience.from}</span>;
    } else {
      return (
        <span>
          {experience.from} - {experience.to}
        </span>
      );
    }
  };

  if (loadingDetails || decryptLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center min-vh-100 bg-light">
        <LoadingAnimation />
      </div>
    );
  }
  if (error) return <p>Error al cargar los datos!</p>;
  if (!empleadoDetails || !empleadosList)
    return <p>No se encontraron detalles del empleado.</p>;

  return (
    <div>
      {isMobile ? (
        <InfoComponentMobile
          userInfoSSFF={userInfoSSFF}
          empleadoDetails={empleadoDetails}
          selectedEmployeeId={user_id}
          empleadosList={empleadosList}
          empleado={empleado}
        />
      ) : (
        <div className=" p-2 ms-4 me-4 ">
          <div className="row d-flex ">
            <div className="informationEmployee col-md-3 p-0 info-shadow d-flex flex-column">
              <InfoComponent
                userInfoSSFF={userInfoSSFF}
                selectedEmployeeId={user_id}
                empleadoDetails={empleadoDetails}
                onUpdateEmployeeDetails={setEmpleadoDetails}
                empleado={empleado}
                userPermissions={userPermissions}
                currentUserEmail={currentUserEmail}
                idToken={idToken}
                jwtSSFF={jwtSSFF}
              />
            </div>
            <div className="summaryComponent col-md-4 d-flex flex-column">
              <SummaryComponent
                empleadoDetails={empleadoDetails}
                selectedEmployeeId={user_id}
                empleadosList={empleadosList}
                userInfoSSFF={userInfoSSFF}
                onUpdateEmployeeDetails={setEmpleadoDetails}
                empleado={empleado}
                userPermissions={userPermissions}
                currentUserEmail={currentUserEmail}
                idToken={idToken}
                jwtSSFF={jwtSSFF}
              />
            </div>
            <div className="experienceComponent col-md-4 d-flex flex-column ms-4 me-4">
              <ExperienceComponent
                empleadoDetails={empleadoDetails}
                renderDateRange={renderDateRange}
                selectedEmployeeId={user_id}
                userInfoSSFF={userInfoSSFF}
                onUpdateEmployeeDetails={setEmpleadoDetails}
                userPermissions={userPermissions}
                currentUserEmail={currentUserEmail}
                idToken={idToken}
                jwtSSFF={jwtSSFF}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TalentViewComponent;
