import React from 'react';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import PersonComponent from "../../../assests/FileComponentes/PDFIcons/PersonPDFComponent";
import LocationComponent from "../../../assests/FileComponentes/PDFIcons/LocationPDFComponent";
import ClockComponent from "../../../assests/FileComponentes/PDFIcons/ClockPDFComponent";
import TalentTablePDF from "./talentTablePDF";
import userImg from '../../../../images/user-account.png';
import logo from "../../../../images/talentCard/talentFiles/logoTelefonica1.png";

const styles = StyleSheet.create({
  totalInfo: {
    flexDirection: 'row',
    backgroundColor: '#007bff',
    paddingLeft: 10,
    marginTop: 2,
    height: 570,
    alignItems: 'flex-start',
  },
  photoContainer: {
    position: 'absolute',
    top: 20,
    left: 20,
    width: 70,
    height: 70,
    borderRadius: 25,
    overflow: 'hidden',
  },
  photoComponent: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%',
  },

  rightContainer: {
    position: 'absolute', 
    top: 25, 
    left: 100, 
    flexDirection: 'column', 
    justifyContent: 'flex-start',
  },
  row: {
    flexDirection: 'row', 
    alignItems: 'center', 
    flexWrap: 'nowrap',
    width: 100,
    marginTop: 15,

  },

  rowPerson: {
    flexDirection: 'row', 
    alignItems: 'center', 
    flexWrap: 'nowrap',
    width: 100,
    marginTop: 5,
  },

  boxText: {
    fontSize: 12,
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'left',
  },

  
  
  personContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 5,
    marginTop: 5,
  },



  infoYear: {
    marginLeft: 10,
    color: '#fff',
    marginTop: 100,
    flex: 1,
    flexShrink: 1,
  },
  infoText: {
    fontSize: 12,
    marginBottom: 5,
    color: '#fff',
    marginLeft: 5,
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 5,
    marginBottom: 5,
  },
  talentSubtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
    color: '#fff', 
  },
  talentTableContainer: {
    marginTop: 10,

  },
  langSubtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
    color: '#fff',
  },
  language: {
    fontSize: 10,
    color: '#fff',
  },
  languageContainer: {
    flexDirection: 'row',
  },
  logo: {
    marginTop: 10,
    width: 150,
    height: 80,
    alignSelf: "center", 
    resizeMode: "contain", 
  },
});

function InfoPDF({ empleadoDetails, selectedEmployeeId, empleado }) {


    // Obtener la cadena de la imagen en Base64
    const base64Image = empleado.empInfo?.photoNav?.results?.[0]?.photo;

    // Construir la URL de la imagen en Base64
    const imageUrl = base64Image ? `data:image/jpeg;base64,${base64Image}` : userImg;

    // Obtener la fecha de nacimiento del empleado
    const birthdayTimestamp = empleado.empInfo?.personNav?.dateOfBirth?.match(/\d+/)?.[0];
    const birthday = birthdayTimestamp ? new Date(parseInt(birthdayTimestamp)) : null;

    // Calcular la edad si se dispone de la fecha de nacimiento
    const calculateAge = (birthday) => {
      const ageDifMs = Date.now() - birthday.getTime();
      const ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    const age = birthday ? calculateAge(birthday) : null;

  // Obtener las fechas de entrada y antigüedad
  const positionEntryDateTicks = empleado.empInfo?.jobInfoNav?.results?.[0]?.positionEntryDate?.match(/\d+/)?.[0];
  const seniorityDateTicks = empleado.empInfo?.seniorityDate?.match(/\d+/)?.[0];

  // Convertir las fechas a objetos Date
  const positionEntryDate = positionEntryDateTicks ? new Date(parseInt(positionEntryDateTicks)) : null;
  const seniorityDate = seniorityDateTicks ? new Date(parseInt(seniorityDateTicks)) : null;

  // Extraer solo el año
  const getYear = (date) => {
    return date ? date.getFullYear() : 'N/A';
  };

  const positionEntryYear = getYear(positionEntryDate);
  const seniorityYear = getYear(seniorityDate);
 const yearsinJob = positionEntryYear - seniorityYear; 


  const fullLocation = empleado.location || '';
  const cleanLocation = fullLocation.replace(/\s*\(.*?\)\s*/g, '').trim();
  // Convertir todo a minúsculas y luego capitalizar la primera letra
  const formattedLocation = cleanLocation.charAt(0).toUpperCase() + cleanLocation.slice(1).toLowerCase();


  // Obtener el año actual
  const currentYear = new Date().getFullYear();

  // Obtener el valor de positionEntryDate
  const positionEntryDateYears = empleado.empInfo?.jobInfoNav?.results?.[0]?.positionEntryDate;

  // Calcular los años en el puesto
  let yearsInRole  = "N/A";
  if (positionEntryDateYears) {
    const positionEntryYear = new Date(parseInt(positionEntryDateYears.match(/\d+/)[0], 10)).getFullYear();
      yearsInRole  = currentYear - positionEntryYear;
  }


  return (
    <View style={styles.totalInfo}>
      <View style={styles.photoContainer}>
            {imageUrl && (
                <Image 
                    source={{ uri: imageUrl }} 
                    style={styles.photoComponent} 
                />
            )}
      </View> 

      {/* Contenedor Derecho */}
      <View style={styles.rightContainer}>
        {/* Primer Cuadrado */}
        <View style={styles.row}>
          <Text style={styles.boxText}>{age} years old</Text>
        </View>
        {/* Segundo Cuadrado */}
        <View style={styles.rowPerson}>
        <PersonComponent   style={styles.personContainer}/>
        <Text style={styles.boxText}>{selectedEmployeeId}</Text>
        </View>
      </View>
        
     

      <View style={styles.infoYear}>
    
        <View style={styles.iconContainer}>
          <ClockComponent />
          <Text style={styles.infoText}>{`${yearsInRole}  years in current role \n${yearsinJob} years in Telefónica`}</Text>
        </View>

        <View style={styles.iconContainer}>
          <LocationComponent />
          <Text style={styles.infoText}>{formattedLocation}</Text>
        </View>

        <Text style={styles.talentSubtitle}>Talent Pool & Performance</Text>
        <View style={styles.talentTableContainer}>
          <TalentTablePDF empleadoDetails={empleadoDetails} />
        </View>

        <View style={{ marginTop: 10 }}>
          <Text style={styles.langSubtitle}>Languages</Text>
          {empleadoDetails.languages && empleadoDetails.languages.map((lang, index) => (
            <View key={index} style={styles.languageContainer}>
              <Text style={styles.language}>{lang.language}</Text>
              {lang.level && (
                <Text style={[styles.language, styles.level]}>({lang.level})</Text>
              )}
            </View>
          ))}
        </View>
        <Image src={logo} style={styles.logo} />
      </View>
    </View>
  );
}

export default InfoPDF;

