import React, { useState,useEffect,useRef  } from "react";
//import LogoSummary from "../../assests/FileComponentes/iconsComponent/LogoSummary";
import SpainComponent from "../../assests/FileComponentes/countries/SpainComponent";
import GermanyComponent from "../../assests/FileComponentes/countries/GermanyComponent";
import UruguayComponent from "../../assests/FileComponentes/countries/UruguayComponent";
import ArgentinaIconComponent from "../../assests/FileComponentes/countries/ArgentinaIconComponent";
import ChileIconComponent from "../../assests/FileComponentes/countries/ChileIconComponent";
import ColombiaIconComponent from "../../assests/FileComponentes/countries/ColombiaIconComponent";
import EcuadorIconComponent from "../../assests/FileComponentes/countries/EcuadorIconComponent";
import PeruIconComponent from "../../assests/FileComponentes/countries/PeruIconComponent";
import BrasilIconComponent from "../../assests/FileComponentes/countries/BrasilIconComponent";
import UkIconComponent from "../../assests/FileComponentes/countries/UkIconComponent";
import MexicoIconComponent from "../../assests/FileComponentes/countries/MexicoIconComponent";
import SouthIconComponent from "../../assests/FileComponentes/countries/SouthIconComponent";
import globalStyles from "../../Styles/globalStyle";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { updateEmployee } from "../../../services/TalentCards/FetchUpdateEmployee";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import '../Styles/FirstTalentView/summaryComponent.css'
import CryptoJS from 'crypto-js';
import NoIconComponent from "../../assests/FileComponentes/countries/NoIconMovilityComponent";


const countryAliases = {
  "BRA_TBRA_0001_SP MTZ ECOBERRI": "Brazil",
  "Ger-1156-01 münchen georg-brauchle-ring 50": "Germany",
  "Distrito telefónica-s1": "Spain",
  "Madrid": "Spain",
};

const countryComponents = {
  Spain: SpainComponent,
  Madrid:SpainComponent,
  Germany: GermanyComponent,
  Uruguay: UruguayComponent,
  Argentina: ArgentinaIconComponent,
  Chile: ChileIconComponent,
  Colombia: ColombiaIconComponent,
  Ecuador: EcuadorIconComponent,
  Peru: PeruIconComponent,
  Brazil : BrasilIconComponent,
  UK:UkIconComponent,
  Mexico: MexicoIconComponent,
  Hispam:SouthIconComponent,
  null :NoIconComponent
};

function SummaryComponent({ empleadoDetails,selectedEmployeeId,
  empleado, currentUserEmail,
  userPermissions, onUpdateEmployeeDetails,idToken,jwtSSFF}) {
  
  const secretKey = process.env.REACT_APP_TALENT_APP;
  
  // Encriptar y codificar el user_id
  const encrypted = CryptoJS.AES.encrypt(selectedEmployeeId, secretKey).toString();
  const encryptedSelectedId = encodeURIComponent(btoa(encrypted));
  
  //const userId = userInfoSSFF.userId;
  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState({
    profile_summary: empleadoDetails?.profile_summary|| ""
  });

  const [editingField, setEditingField] = useState(null);
  const [tempText, setTempText] = useState('');
  const [prevText, setPrevText] = useState('');

  
  const summaryRef = useRef(null);




  useEffect(() => {
    setFormData({
        profile_summary: empleadoDetails?.profile_summary || ""
    });
  }, [empleadoDetails]);

  


  const handleBlur = async (field, ref) => {
    const value = tempText.trim();

    if (!value) {
        toast.error('El campo no puede estar vacío.');
        ref.current.innerText = prevText || 'Insert summary profile please!';
        setEditable(false);
        setEditingField(null);
        return;
    }

    confirmAlert({
        title: '¿Seguro?',
        message: '¿Estás seguro de modificar esto?',
        buttons: [
            {
                label: 'Sí',
                onClick: async () => {
                    try {
                        const updatedData = { 
                            ...empleadoDetails,
                            [field]: value 
                        };

                        await updateEmployee(encryptedSelectedId,updatedData,idToken,jwtSSFF);

                        // Asegúrate de actualizar el estado global o el estado compartido
                        onUpdateEmployeeDetails(updatedData);

                        ref.current.innerText = value;
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            [field]: value
                        }));

                        toast.success('El perfil ha sido actualizado con éxito.');
                    } catch (error) {
                        console.error('Error updating employee:', error);
                        toast.error('Error al actualizar los datos del empleado.');
                        ref.current.innerText = prevText;
                    }
                }
            },
            {
                label: 'No',
                onClick: () => {
                    ref.current.innerText = prevText || 'Insert summary profile please!';
                }
            }
        ]
    });

    setEditable(false);
    setEditingField(null);
  };



  const handleDoubleClick = (field, ref) => {
    if (userPermissions.edit_talent_cards) { 
      setEditable(true);
      setEditingField(field);
      setTempText(formData[field]);
      setPrevText(formData[field]);
      setTimeout(() => {
        ref.current.focus();
      }, 0);
    }
  };

  const handleChange = (e) => {
    setTempText(e.target.innerText);
  };

  const location = empleadoDetails?.country;
  
  const LogoSummary = location && countryComponents[location] ? countryComponents[location] : countryComponents[null];

  const position = empleado.empInfo?.jobInfoNav?.results?.[0]?.jobTitle || '' ;

  return (
      <div className="ms-4 summary-container ">
        <ToastContainer />
        <div className="d-flex align-items-center justify-content-start  employee-details-sumComp">
          {LogoSummary && <LogoSummary className="logo-summary-sumComp rounded-circle ms-1"/>}
          <h1
          className="employeeName-sumComp fs-4 ms-2 " 
          style={{...globalStyles.color}}
          
          >{empleado.displayName}</h1>
        </div>
      
        <h2 className="job-Subtitle-sumComp mt-1 fs-5"
        >
        {position}</h2>

        
        <h4 className="summary-Tittle-sumComp mt-2 " style={globalStyles.color}>Summary</h4>
        <p className="summary-Text-sumComp text-break mt-2" 
        ref={summaryRef}
        contentEditable={editable && editingField === 'profile_summary'}
        onBlur={() => handleBlur('profile_summary', summaryRef)}
        onInput={handleChange}
        onDoubleClick={() => handleDoubleClick('profile_summary', summaryRef)}
        suppressContentEditableWarning={true}
        >{formData.profile_summary|| "Insert summary profile please!"}</p>
      </div>
    );
}
  
export default SummaryComponent;
