import '../../styles/contactUsButton/contactUsFilterSideBar.css';

const FilterCheckbox = ({ label, options, selectedValues, onChange }) => {
  return (
    <div className='filter-section'>
      <p className='attentionChanel__sideBar--filterTitle'>{label}</p>
      {options.map((option) => (
        <label key={option.value} className='attentionChanel__sideBar--filterLabel'>
          <input type='checkbox' value={option.value} checked={selectedValues.includes(option.value)} onChange={onChange} />
          {option.label}
        </label>
      ))}
    </div>
  );
};

const FilterInput = ({ type, value, onChange, placeholder }) => {
  return (
    <div className='filter-section'>
      <label className='attentionChanel__sideBar--filterLabel'>
        Email: {''}
        <input type='text' placeholder={placeholder} value={value} onChange={onChange} />
      </label>
    </div>
  );
};

const FilterResponsable = ({ value, onChange }) => {
  return (
    <div className='filter-section'>
      <p className='attentionChanel__sideBar--filterTitle'>Responsable:</p>
      <label className='attentionChanel__sideBar--filterLabel'>
        <input type='text' placeholder='Buscar por responsable...' value={value} onChange={onChange} />
      </label>
    </div>
  );
};

const FilterCategory = ({ value, onChange }) => {
  return (
    <div className='filter-section'>
      <p className='attentionChanel__sideBar--filterTitle'>Temática:</p>
      <select className='attentionChanel__sideBar--filterSelect' value={value} onChange={onChange} style={{ fontSize: '12px' }}>
        <option value=''>Selecciona una categoría</option>
        <option value='Herramientas y políticas'>Herramientas y políticas</option>
        <option value='Nómina y Beneficios'>Nómina y Beneficios</option>
        <option value='Vacaciones y Permisos'>Vacaciones y Permisos</option>
        <option value='Compensación y Organización'>Compensación y Organización</option>
        <option value='Desarrollo de mi Equipo'>Desarrollo de mi Equipo</option>
        <option value='Mi Desarrollo'>Mi Desarrollo</option>
        <option value='Atracción y Retención de Talento'>Atracción y Retención de Talento</option>
      </select>
    </div>
  );
};

const FilterSidebar = ({
  selectedTypes,
  handleTypeChange,
  selectedStatuses,
  handleStatusChange,
  searchEmail,
  handleEmailChange,
  selectedPriorities,
  handlePriorityChange,
  searchResponsable,
  handleResponsableChange,
  searchCategory,
  handleCategoryChange,
}) => {
  return (
    <div className='filter-sidebar'>
      <p className='attentionChanel__sideBar--title'>Filtros</p>
      <FilterCheckbox
        label='Soporte'
        options={[
          { value: 'SOPORTE EMPLEADO', label: 'EMPLEADO' },
          { value: 'SOPORTE MANAGER', label: 'MANAGER' },
        ]}
        selectedValues={selectedTypes}
        onChange={handleTypeChange}
      />
      <FilterCheckbox
        label='Estado'
        options={[
          { value: 'Backlog', label: 'Abierto' },
          { value: 'En curso', label: 'En curso' },
          { value: 'Finalizada', label: 'Cerrado' },
        ]}
        selectedValues={selectedStatuses}
        onChange={handleStatusChange}
      />
      <FilterCheckbox
        label='Prioridad'
        options={[
          { value: 'High', label: 'Alta' },
          { value: 'Medium', label: 'Media' },
          { value: 'Low', label: 'Baja' },
        ]}
        selectedValues={selectedPriorities}
        onChange={handlePriorityChange}
      />
      <FilterInput label='Email' type='email' value={searchEmail} onChange={handleEmailChange} placeholder='example@mail.com' />
      <FilterResponsable value={searchResponsable} onChange={handleResponsableChange} />
      <FilterCategory value={searchCategory} onChange={handleCategoryChange} />
    </div>
  );
};

export default FilterSidebar;
