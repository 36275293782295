
export const fetchEmployeeSSFF = async (idToken, jwtSSFF) => {
  try {
    const response = await fetch(`/employeeSSFF`, {
      method: 'GET',
      headers: {
        'x-msal-Authorization': `Bearer ${idToken}`,
        'x-jwt-Authorization': `Bearer ${jwtSSFF}`,
      },
    });
    if (!response.ok) {
      throw new Error('Fallo en la solicitud de empleados SSFF');
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error en la solicitud de empleados:', error.message);
    throw new Error('Error en la solicitud de empleados: ' + error.message);
  }
};
