import { Link, useNavigate } from 'react-router-dom';
import elipse355 from '../../images/ellipse355.png';
import payrollIcon from '../../images/folder/nominas.svg';
import retentionsIcon from '../../images/folder/retenciones.svg';
import certificateIcon from '../../images/folder/otros_certificados.svg';
import arrowIcon from '../../images/folder/arrow.svg';
import growthIcon from '../../../src/images/mainPanel/growth.png';
import benefitsIcon from '../../images/folder/benefits.svg';
import compensationIcon from '../../images/folder/compensation.svg';
import '../styles/myFolderPage.css';
import React from 'react';

const MyFolderPage = ({ userInfoSSFF }) => {
  const navigate = useNavigate();

  // Definir las carpetas disponibles
  const folders = [
    {
      id: 'certificates',
      name: 'Certificados',
      icon: certificateIcon,
      description: 'Consulta el resto de certificados',
    },
    {
      id: 'payrolls',
      name: 'Nóminas',
      icon: payrollIcon,
      description: 'Descarga o visualiza tus últimas nóminas y recibos',
    },
    // {
    //   id: 'compensation',
    //   name: 'Compensación',
    //   icon: compensationIcon,
    //   description: 'Descarga o visualiza tu resumen de compensación',
    // },
    {
      id: 'digitalGrowth',
      name: 'Solicitudes',
      description: 'Gestiona tus solicitudes',
      link: '/form',
      icon: growthIcon,
    },

    // {
    //   id: 'canalatencion',
    //   name: 'Retenciones',
    //   icon: retentionsIcon,
    //   description: 'Certificados de retenciones de los últimos años',
    // },
    // {
    //   id: 'benefits',
    //   name: 'Beneficios',
    //   icon: benefitsIcon,
    //   description: 'Consulta certificados sobre tus ventajas en Telefónica',
    // },
  ];

  const handleNavigation = (folderId) => {
    if (folderId === 'compensation') {
      window.open('https://performancemanager5.successfactors.eu/sf/personalinfo?blockType=COMPENSATION_STATEMENT_BLOCKS#/Telefonica', '_blank');
    } else if (folderId === 'digitalGrowth') {
      navigate('/form', { state: { selected: folderId } });
    } else {
      navigate(`/folder/${folderId}`, { state: { selected: folderId } });
    }
  };

  return (
    <>
      <section className='myFolderPage'>
        <div className='myFolderPage__breadcrumb'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <Link to='/home' className='myFolderPage__breadcrumb--title'>
            INICIO
          </Link>
          <span className='myFolderPage__breadcrumb--text'>&gt; MI CARPETA</span>
        </div>
        <div className='myFolderPage__content'>
          <h1 className='myFolderPage__title'>Mi carpeta</h1>
          <div className='myFolderPage__options'>
            {folders.map((folder) => (
              <div
                key={folder.id}
                className={`myFolderPage__option ${
                  folder.id !== 'certificates' && folder.id !== 'payrolls' && folder.id !== 'compensation' && folder.id !== 'digitalGrowth'
                    ? 'myFolderPage__buttonDisabled'
                    : ''
                }`}
              >
                {folder.id === 'certificates' || folder.id === 'digitalGrowth' || folder.id === 'payrolls' ? (
                  // || folder.id === 'payrolls' || folder.id === 'compensation'
                  <>
                    <div className='myFolderPage__textContent' onClick={() => handleNavigation(folder.id)}>
                      <div className='myFolderPage__imageContent'>
                        <img src={folder.icon} alt={folder.name} className='myFolderPage__image' />
                      </div>
                      <h1 className='myFolderPage__groupTitle'>{folder.name}</h1>
                      <p className='myFolderPage__description'>{folder.description}</p>
                      <div className='myFolderPage__arrowButton'>
                        <img src={arrowIcon} alt='arrow' className='myFolderPage__arrow' />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='myFolderPage__textContent'>
                    <div className='myFolderPage__imageContent'>
                      <img src={folder.icon} alt={folder.name} className='myFolderPage__image' />
                    </div>
                    <h1 className='myFolderPage__groupTitle'>{folder.name}</h1>
                    <p className='myFolderPage__description'>{folder.description}</p>

                    <div className='myFolderPage__arrowButton'>
                      <img src={arrowIcon} alt='arrow' className='myFolderPage__arrow' />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default MyFolderPage;
