import * as React from "react"
const ColombiaIconComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={800}
    height={800}
    viewBox="0 0 473.681 473.681"
    {...props}
  >
    <path
      d="M305.456 226.706h167.967C468.105 100.604 364.238.002 236.835.002c63.535 0 66.807 111.757 68.621 226.704z"
      style={{
        fill: "#fbd015",
      }}
    />
    <path
      d="M305.456 351.039h138.888c18.679-33.869 29.337-72.783 29.337-114.195 0-3.396-.112-6.772-.254-10.138h-157.1c.677 42.889-2.958 86.193-10.871 124.333z"
      style={{
        fill: "#21428c",
      }}
    />
    <path
      d="M229.258 473.548c-.606-.015-1.215-.045-1.821-.067.602.022 1.212.052 1.821.067zM236.835 473.679c89.387 0 167.185-49.534 207.505-122.639H300.467c-14.547 70.079-20.07 122.639-63.632 122.639z"
      style={{
        fill: "#cd202a",
      }}
    />
    <path
      d="M236.835 473.679z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M.254 226.706h334.595C333.039 111.759 300.37.002 236.835.002 109.439.002 5.572 100.604.254 226.706z"
      style={{
        fill: "#fcd73e",
      }}
    />
    <path
      d="M334.849 226.706H.254C.112 230.072 0 233.449 0 236.844c0 41.412 10.658 80.326 29.337 114.195h294.645c7.913-38.14 11.548-81.444 10.867-124.333z"
      style={{
        fill: "#1d4f95",
      }}
    />
    <path
      d="M227.433 473.48c.606.022 1.215.052 1.821.067 2.517.079 5.041.131 7.576.131 43.562 0 72.596-52.56 87.143-122.639H29.333c38.903 70.525 112.689 119.106 198.1 122.441z"
      style={{
        fill: "#d11f3e",
      }}
    />
  </svg>
)
export default ColombiaIconComponent
