import React, { useState, useEffect } from 'react';
import '../styles/frequent_questions.css';
import elipse355 from '../../images/ellipse355.png';

// import FrequentQuestionItem from './frequent_question-item';
import ArticleItem from './article-item';

const Frequent_questions = ({ userInfoSSFF }) => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Define los IDs permitidos
  const allowedArticleIds = [145, 14, 146, 42, 44]; // Cambia estos números por los IDs deseados

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch('/articles', { method: 'GET' });
        const data = await response.json();
        const articlesData = data.articlesData;
        if (response.ok) {
          // Filtra los artículos con IDs permitidos
          const filteredArticles = articlesData.filter((article) => allowedArticleIds.includes(article.article_id));
          setArticles(filteredArticles);
        } else {
          console.error('Error fetching articles:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        setIsLoading(false); // Finaliza la carga
      }
    };

    fetchArticles();
  }, []);

  return (
    <>
      <section className='frequentQuestions'>
        {/* Breadcrumb */}
        <div className='frequentQuestions__breadcrumb'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <h4>PREGUNTAS FRECUENTES</h4>
        </div>

        {/* Mostrar Loading mientras carga */}
        {isLoading ? (
          <div></div>
        ) : (
          <section className='frequentQuestions__content'>
            {/* Renderizar artículos filtrados */}
            {articles.map((article) => (
              <ArticleItem
                key={article.article_id}
                title={article.article_title}
                answer={article.article_content}
                expandedState={false}
                userInfoSSFF={userInfoSSFF}
              />
            ))}
          </section>
        )}
      </section>
    </>
  );
};

export default Frequent_questions;
