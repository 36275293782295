import * as React from "react"
const SearchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#676D83"
      d="M10.021 17.83A7.846 7.846 0 0 1 4.472 4.435a7.848 7.848 0 0 1 11.473 10.677l5.704 5.704a.561.561 0 0 1-.793.793l-5.697-5.697a7.792 7.792 0 0 1-5.138 1.918Zm0-14.571A6.726 6.726 0 0 0 5.265 14.74a6.728 6.728 0 0 0 9.512-.036 6.724 6.724 0 0 0 0-9.473 6.672 6.672 0 0 0-4.756-1.972Z"
    />
  </svg>
)
export default SearchIcon