import React from 'react';
import { Svg, Path } from '@react-pdf/renderer';

const ArrowPDF = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 35 35" {...props}>
    <Path
      fill="#F2F4FF"
      d="M31.845 13.891l-0.625-4.964a1.14 1.14 0 0 0-1.148-0.979h-5.09a1.136 1.136 0 0 0-1.156 1.11c0 .611.523 1.11 1.156 1.11h2.745l-7.27 9.91-9.907-4.174a1.207 1.207 0 0 0-1.483 0.476l-5.776 9.79c-0.31 0.535-0.118 1.21 0.43 1.513a1.164 1.164 0 0 0 0.011 0.008c0.21 0.144 0.451 0.212 0.696 0.212a1.18 1.18 0 0 0 1.573-0.42l5.266-8.918 9.816 4.126c0.502 0.216 1.09 0.06 1.413-0.376l7.463-10.189 0.278 2.245c0.09 0.615 0.662 1.05 1.295 0.978a1.126 1.126 0 0 0 1.01-1.234v-0.012Z"
    />
  </Svg>
);

export default ArrowPDF;
