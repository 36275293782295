export const validateFields = (fields) => {
  const {
    effectiveDate,
    roleChange,
    changeDetails,
    roleChangeDetails,
    managerChange,
    newManager,
    salaryChange,
    currency,
    bonusOrIncentives,
    bonusPercentage,
    incentivesAmount,
    salaryReason,
    organizationalChange,
    newDepartments,
    responsiblePerson,
    city,
    building,
    floor,
    country,
  } = fields;

  const errors = {};

  // Validaciones Generales
  if (!effectiveDate) {
    errors.effectiveDate = true;
  }

  // Validaciones de Cambio Organizativo
  if (organizationalChange) {
    if (!organizationalChange) errors.organizationalChange = true;
    if (!newDepartments) errors.newDepartments = true;
    if (!responsiblePerson) errors.responsiblePerson = true;
  }

  // Validaciones de Cambio de Rol
  if (roleChange === 'yes') {
    if (!roleChangeDetails) errors.roleChangeDetails = true;
    if (managerChange === 'yes' && !newManager) errors.newManager = true;
  } else if (roleChange === 'no' && !changeDetails) {
    errors.changeDetails = true;
  }

  // Validaciones Salariales
  if (salaryChange || currency || bonusOrIncentives || salaryReason) {
    if (salaryChange === undefined || salaryChange < 0) errors.salaryChange = true;
    if (!currency) errors.currency = true;
    if (!bonusOrIncentives) errors.bonusOrIncentives = true;
    if (bonusOrIncentives === 'bonus' && (bonusPercentage === undefined || bonusPercentage < 0)) {
      errors.bonusPercentage = true;
    }
    if (bonusOrIncentives === 'incentives' && (incentivesAmount === undefined || incentivesAmount < 0)) {
      errors.incentivesAmount = true;
    }
    if (!salaryReason) errors.salaryReason = true;
  }

  // Validaciones de Cambio de Localización
  if (city || building || floor || country) {
    if (!city) errors.city = true;
    if (!building) errors.building = true;
    if (!floor) errors.floor = true;
    if (!country) errors.country = true;
  }

  return errors;
};
