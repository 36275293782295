import * as React from "react"
const ChileIconComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    aria-hidden="true"
    className="iconify iconify--emojione"
    viewBox="0 0 64 64"
    {...props}
  >
    <path fill="#ed4c5c" d="M2 32c0 16.6 13.4 30 30 30s30-13.4 30-30H2z" />
    <path fill="#f9f9f9" d="M32 2c16.6 0 30 13.4 30 30H32V2z" />
    <path fill="#2a5f9e" d="M32 2C15.4 2 2 15.4 2 32h30V2z" />
    <path
      fill="#f9f9f9"
      d="m20 21.7 4.9 3.3-1.8-5.3 4.9-3.5h-6.1L20 11l-1.8 5.2H12l4.9 3.5-1.8 5.3z"
    />
  </svg>
)
export default ChileIconComponent
