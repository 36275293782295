import React from 'react';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';

import SpainIcon from '../../../../images/talentCard/talentFiles/countries/spain.png';
import UruguayIcon from '../../../../images/talentCard/talentFiles/countries/uruguay.png';
import ArgentinaIcon from '../../../../images/talentCard/talentFiles/countries/argentina.png';
import ChileIcon from '../../../../images/talentCard/talentFiles/countries/chile.png';
import ColombiaIcon from '../../../../images/talentCard/talentFiles/countries/colombia.png';
import EcuadorIcon from '../../../../images/talentCard/talentFiles/countries/ecuador.png';
import PeruIcon from '../../../../images/talentCard/talentFiles/countries/peru.png';
import BrazilIcon from '../../../../images/talentCard/talentFiles/countries/brazil.png';
import UkIcon from '../../../../images/talentCard/talentFiles/countries/united-kingdom.png';
import EuropeIcon from '../../../../images/talentCard/talentFiles/countries/europe.png';
import NoMobilityIcon from '../../../../images/talentCard/talentFiles/countries/no-mobility.png';
import MobilityIcon from '../../../../images/talentCard/talentFiles/countries/mobility.png';
import HispamIcon from '../../../../images/talentCard/talentFiles/countries/hispam.png';
import GermanyIcon from '../../../../images/talentCard/talentFiles/countries/germany.png';

const countryIcons = {
  Germany: GermanyIcon,
  Spain: SpainIcon,
  Uruguay: UruguayIcon,
  Argentina: ArgentinaIcon,
  Chile: ChileIcon,
  Colombia: ColombiaIcon,
  Ecuador: EcuadorIcon,
  Peru: PeruIcon,
  Brazil: BrazilIcon,
  UK: UkIcon,
  Hispam: HispamIcon,
  Europe: EuropeIcon,
  SI: MobilityIcon,
  YES: MobilityIcon,
  NO: NoMobilityIcon,
  null:NoMobilityIcon
};

function getCountryIconImage(countryName) {
  return countryIcons[countryName] || null;
}

// Estilos StyleSheet.create
const styles = StyleSheet.create({
  container: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  employeeName: {
    fontSize: 14,
    fontFamily: 'Helvetica',
    marginTop: 13,
    marginBottom: 10,
    color: '#0066ff',
  },
  jobSubtitle: {
    fontSize: 12,
    fontFamily: 'Helvetica',
    marginBottom: 10,
  },
  summaryTitle: {
    fontSize: 12,
    fontFamily: 'Helvetica',
    marginTop: 10,
    marginBottom: 5,
    color: '#0066ff',
  },
  summaryText: {
    fontSize: 10,
    fontFamily: 'Helvetica',
    textAlign: 'justify',
  },
  countryIcon: {
    width: 10,
    height: 10,
    marginRight: 5,
    marginTop: 5,
  },
});

const SummaryPDF = ({ empleadoDetails, userInfoSSFF, empleado }) => {
  const firstCountry = empleadoDetails.countries ? empleadoDetails.countries[0] : null;
  const countryIcon = getCountryIconImage(firstCountry);
  const position = empleado.empInfo?.jobInfoNav?.results?.[0]?.jobTitle || '';

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {countryIcon && <Image src={countryIcon} style={styles.countryIcon} />}
        <Text style={styles.employeeName}>{empleado.displayName}</Text>
      </View>
      <Text style={styles.jobSubtitle}>{position}</Text>
      <Text style={styles.summaryTitle}>Summary</Text>
      <Text style={styles.summaryText}>
        {empleadoDetails.profile_summary || 'Insert summary profile please!'}
      </Text>
    </View>
  );
};

export default SummaryPDF;
