
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import '../Styles/FirstTalentView//EducationModal.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EducationModal = ({ isOpen, onClose, onSave }) => {
    const [degree, setDegree] = useState('');
    const [institution, setInstitution] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');

    const handleSave = () => {
        if (from && to && from > to) {
            toast.error("Ingrese una fecha correcta, 'From' no puede ser mayor que 'To'", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        const fromYear = from ? from.getFullYear() : '';
        const toYear = to ? to.getFullYear() : '';
        const newEducation = {
            title: degree,
            institution: institution,
            from_year: fromYear,
            to_year: toYear 
            //=== 2024 ? 'present' : toYear
        };

        onSave(newEducation);

        setDegree('');
        setInstitution('');
        setFrom(null);
        setTo(null);
        onClose();
    };

    return (
        <Modal show={isOpen} onHide={onClose} centered className="custom-modal">
            <Modal.Header closeButton>
                <Modal.Title className="custom-title text-primary">Education</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formDegree" className="d-flex align-items-center mb-3">
                        <Form.Label className="custom-label me-3">Degree</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Insert your degree" 
                            className="custom-input flex-grow-1"
                            value={degree}
                            onChange={e => setDegree(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="formInstitution" className="d-flex align-items-center mb-3">
                        <Form.Label className="custom-label me-3">Institution</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Insert the institution name" 
                            className="custom-input-Institution flex-grow-1"
                            value={institution}
                            onChange={e => setInstitution(e.target.value)}
                        />
                    </Form.Group>

                    <div className="d-flex align-items-center mb-3">
                        <Form.Group controlId="formFrom" className="me-2 d-flex align-items-center">
                            <Form.Label className="custom-label me-2">From</Form.Label>
                            <DatePicker
                                selected={from}
                                onChange={(date) => setFrom(date)}
                                showYearPicker
                                dateFormat="yyyy"
                                placeholderText="Select Year"
                                className="custom-input-from form-control"
                                yearItemNumber={9} 
                                maxDate={new Date()}
                                minDate={new Date(1980, 0, 1)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formTo" className="ms-2 flex-fill d-flex align-items-center">
                            <Form.Label className="custom-label me-2">To</Form.Label>
                            <DatePicker
                                selected={to}
                                onChange={(date) => setTo(date)}
                                showYearPicker
                                dateFormat="yyyy"
                                placeholderText="Select Year"
                                className="custom-input-to form-control"
                                yearItemNumber={9} 
                                maxDate={new Date()}
                                minDate={from || new Date(1980, 0, 1)}
                            />
                        </Form.Group>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={onClose} className="custom-button cancel-button rounded-pill">
                    Cancelar
                </Button>
                <Button variant="primary" onClick={handleSave} className="custom-button save-button rounded-pill">
                    Guardar
                </Button>
            </Modal.Footer>
            <ToastContainer />
        </Modal>
    );
};


export default EducationModal;