export const fetchClusterEmployeeIds = async (selectedCluster, idToken) => {
  try {
    const response = await fetch(
      `/employeeCluster?cluster=${selectedCluster}`,
      {
        method: 'GET',
        headers: {
          'x-msal-Authorization': `Bearer ${idToken}`,
        },
      },
    );
    if (!response.ok) {
      throw new Error('Fallo en la solicitud de empleados');
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error en la solicitud de empleados:', error.message);
    throw new Error('Error en la solicitud de empleados: ' + error.message);
  }
};
