import * as React from "react"
const EcuadorIconComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    aria-hidden="true"
    className="iconify iconify--emojione"
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      fill="#2a5f9e"
      d="M62 32H2c0 5.5 1.5 10.6 4 15h52c2.5-4.4 4-9.5 4-15"
    />
    <path fill="#ffe62e" d="M32 2C15.4 2 2 15.4 2 32h60C62 15.4 48.6 2 32 2z" />
    <path fill="#ed4c5c" d="M32 62c11.1 0 20.8-6 26-15H6c5.2 9 14.9 15 26 15" />
    <path
      fill="#034ea2"
      d="m25.383 29.553.802.82.344-.937.115-.586c-.115-1.64 0-3.28 0-3.28l.23.235-.918-.937-.573-.585v5.27"
    />
    <path fill="#ed1c24" d="m26.644 28.85.802-2.46-.802-.82v3.28" />
    <path
      fill="#fd0"
      d="m25.383 24.283.573.585-2.751-2.81c-.115-.118-.115 2.459-.115 5.27l2.293 2.342v-5.387"
    />
    <path
      fill="#fd0"
      d="M26.185 39.508c-1.948-1.171-2.178-4.802-2.178-5.388v-6.324l.574.586-2.752-2.811c-.229-.234 0 7.612 0 11.009 0 1.64 1.032 7.378 6.764 4.45 0 0 2.866-1.054 3.783-2.342h-.573c-.115.117-.23.117-.344.117-3.325 2.342-5.274.703-5.274.703"
    />
    <path
      fill="#034ea2"
      d="M24.007 34.12c0 .469.23 4.1 2.064 5.27 0 0 1.949 1.64 5.388-.585-1.032.469-1.834.469-2.408.351-1.605-.468-3.324-2.342-3.553-4.333-.23-1.99-.23-5.739-.23-5.739l.23.235-.917-.937-.574-.469v6.207"
    />
    <path
      fill="#ed1c24"
      d="M26.415 31.661v-1.523c-.344-.35.114.118-.803-.82l-.229-.234v.118c0 .468 0 3.864.23 5.621.229 1.991 1.948 3.865 3.553 4.333.573.118 1.376.118 2.407-.35.115 0 .115-.118.23-.118-3.096-.351-5.044-3.162-5.388-7.027"
    />
    <path
      fill="#fd0"
      d="M37.878 39.625c1.835-1.171 2.179-4.802 2.064-5.27V28.03l-.573.586 2.751-2.81c.23-.235 0 7.612 0 11.008 0 1.64-1.032 7.378-6.764 4.45 0 0-2.866-1.053-3.783-2.342h.574c.114 0 .229.117.229.117 3.554 2.226 5.502.586 5.502.586"
    />
    <path
      fill="#034ea2"
      d="M39.942 34.238c0 .468-.23 4.099-2.064 5.27 0 0-1.948 1.64-5.388-.586 1.032.469 1.835.469 2.408.352 1.605-.469 3.324-2.343 3.554-4.334.229-1.99.229-5.738.229-5.738l-.23.234.918-.937.573-.586v6.325"
    />
    <path
      fill="#ed1c24"
      d="M37.65 31.778v-1.522l.802-.82.229-.234v.117c0 .468 0 3.864-.23 5.621-.229 1.991-1.834 3.865-3.553 4.45-.573.118-1.376.118-2.408-.35-.114 0-.114-.118-.229-.118 2.98-.468 5.044-3.279 5.388-7.144"
    />
    <path
      fill="#8cddea"
      d="M26.3 31c0 4.1 2.6 7.4 5.7 7.4s5.7-3.3 5.7-7.4-2.6-7.4-5.7-7.4-5.7 3.4-5.7 7.4"
    />
    <path
      fill="#6dd337"
      d="M26.3 31.3c0 4.1 2.6 7.4 5.7 7.4 3.2 0 5.7-3.3 5.7-7.4H26.3"
    />
    <path
      fill="#557176"
      d="m26.5 31.5 10.8.2.1-.8c0-.1.2-.2-.1-.2-1.2-.1-.7-.2-1.4-.2-.2 0-4.8-1.1-5.2-1.1-.4-.1-4 .5-4 .5l-.2 1.6"
    />
    <path
      fill="#fff"
      d="m26.4 29.6 1.7.7s2.2-.4 2.4-.3c.2.1 2.1.4 2.2.4h2.9-.1c-.2-.1-2.4-.7-2.6-.8-.1-.1-1.8-.7-1.9-.9-.2-.2-1.4-.9-1.6-.9-.2-.1-3 1.8-3 1.8"
    />
    <path
      fill="#b1babf"
      d="M34.4 23.8s-1.1-.7-2.5-.7c-1.1 0-2.2.6-2.2.6-1.2.7-2.2 2-2.8 3.6-.4 1.1-.7 2.4-.7 3.7 0 3.9 2.1 6.9 4.8 7.7 0 0 .4.2.9.2s1-.1 1-.1c2.7-.7 4.9-3.8 4.9-7.7 0-5.1-3.9-7.7-3.4-7.3M33 38.3s-.5.1-1 .1-1-.1-1-.1c-2.4-.7-4.3-3.6-4.3-7.1 0-1.2.3-2.5.7-3.5.6-1.4 1.5-2.6 2.6-3.2 0 0 1.1-.7 2-.7 1.3 0 2.3.7 2.3.7 1.8 1.1 3.1 3.7 3.1 6.6-.1 3.6-2 6.6-4.4 7.2"
    />
    <path
      fill="#034ea2"
      d="m28.6 31.2 1.7 2.3-2.6 1.5s.2.6.7 1.3c.5.6 1.7 1.9 2.8 2 1.2.1 2 .1 2.7-.3.6-.4 1.5-.9 2.1-1.9.6-.9.6-1.1.6-1.1s-3.8-.8-3.9-.8c-.1-.1-3.7-2.9-3.8-3-.1-.1.8-1.2.8-1.2l-1.1 1.2z"
    />
    <path fill="#ed1c24" d="M31.1 39.8H33v4.9h-1.9z" />
    <g fill="#034ea2">
      <path d="M29.6 40.2h1.9v4.9h-1.9zM32.5 40.2h1.9v4.9h-1.9z" />
    </g>
    <g fill="#fd0">
      <path d="M29.2 40.2h1.2v6.2h-1.2zM33.6 40.2h1.2v6.2h-1.2z" />
    </g>
    <path fill="#fff" d="M27.5 38.1h9v2.4h-9z" />
    <path
      fill="#594640"
      d="M37.6 18.5c-1.8 0-3.7 2-5.6 2s-3.8-2-5.6-2c-1.7 0-8.4 2-8.4 2 9.3 0 14 5 14 5s4.7-5 14-5c0 0-6.7-2-8.4-2"
    />
    <circle cx={32} cy={19.7} r={1.2} fill="#f9f9f9" />
  </svg>
)
export default EcuadorIconComponent
