
const globalStyles = {
    blueBackground: {
        backgroundColor: '#007bff',
        border: 'none'
    },
    fontSize: {
        fontSize: "18px"
    },
    color: {
        color: '#007bff'
    },
    colorTelefo: {
        color: '#0066FF'
    }
};



export default globalStyles ;
