import React, { useState } from 'react';
import { capitalizeFirstLetter, getSafeString } from '../../../../../utils/utils';
import '../../../../styles/change/formChangeManager.css';

const TeamSearch = ({ managerEmployees, setSelectedEmployees }) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
      setSelectedEmployees([]);
    } else {
      const allIds = managerEmployees.map((emp) => emp.userId);
      setSelectedIds(allIds);
      setSelectedEmployees(managerEmployees);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (employee) => {
    if (selectedIds.includes(employee.userId)) {
      const newSelection = selectedIds.filter((id) => id !== employee.userId);
      setSelectedIds(newSelection);
      setSelectedEmployees(newSelection.map((id) => managerEmployees.find((emp) => emp.userId === id)));
      setSelectAll(newSelection.length === managerEmployees.length);
    } else {
      const newSelection = [...selectedIds, employee.userId];
      setSelectedIds(newSelection);
      setSelectedEmployees(newSelection.map((id) => managerEmployees.find((emp) => emp.userId === id)));
      setSelectAll(newSelection.length === managerEmployees.length);
    }
  };

  return (
    <div className='formJiraChangeCheckboxGroup_change'>
      {managerEmployees.length > 0 ? (
        <div className='managedEmployeesList'>
          <h3 className='formJiraChange__title'>Empleados a tu cargo:</h3>
          <p className='formJiraChangeWarningMessage'>
            Los cambios que solicites se aplicarán por igual a todas las personas que selecciones. Verifica que los cambios sean adecuados para todos
            antes de enviar la solicitud.
          </p>
          <label className='formJiraChangeCheckboxLabel'>
            <input type='checkbox' checked={selectAll} onChange={handleSelectAll} className='formJiraChangeCheckbox' />
            Seleccionar todos
          </label>
          <ul className='formJiraChangeCheckboxGroup'>
            {managerEmployees.map((employee) => (
              <li key={employee.userId} className='formJiraChangeCheckboxLabel'>
                <input
                  type='checkbox'
                  id={`employee-${employee.userId}`}
                  value={employee.userId}
                  onChange={() => handleCheckboxChange(employee)}
                  checked={selectedIds.includes(employee.userId)}
                  className='formJiraChangeCheckbox'
                />
                <label htmlFor={`employee-${employee.userId}`} className='formJiraChangeCheckboxLabel'>
                  {capitalizeFirstLetter(employee.defaultFullName)}
                </label>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p className='formJiraChangeLabel'>No hay empleados disponibles.</p>
      )}
    </div>
  );
};

export default TeamSearch;
