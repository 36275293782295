import React, { useEffect, useState } from 'react';
import { validateFields } from '../formValidate';

export const OrganizationalChange = ({
  organizationalChange,
  newDepartments,
  newManager,
  newAsigneeManager,
  roleChangeDetails,
  roleChange,
  newDuties,
  newDutiesDetails,
  categoryChange,
  categoryChangeDetails,
  setOrganizationalChange,
  setNewDepartments,
  setNewManager,
  setNewAsigneeManager,
  setRoleChangeDetails,
  setRoleChange,
  setNewDuties,
  setNewDutiesDetails,
  setCategoryChange,
  setCategoryChangeDetails,
  errors,
  setErrors,
  showManagerInput,
  setShowManagerInput,
  showRoleChangeInput,
  setShowRoleChangeInput,
  showNewDutiesInput,
  setShowNewDutiesInput,
  showCategoryChangeInput,
  setShowCategoryChangeInput,
}) => {
  const [showWarning, setShowWarning] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  // Setear valores de radios a 'No' por defecto al cargar el componente
  useEffect(() => {
    if (organizationalChange === '') setOrganizationalChange('no');
    if (newDepartments === '') setNewDepartments('no');
    if (newManager === '') setNewManager('no');
    if (roleChange === '') setRoleChange('no');
    if (newDuties === '') setNewDuties('no');
    if (categoryChange === '') setCategoryChange('no');
  }, []);

  const handleRadioChange = (currentValue, setter, selectedValue) => {
    if (currentValue === selectedValue) {
      setter(''); // Deseleccionar si se vuelve a hacer clic
    } else {
      setter(selectedValue); // Seleccionar si es un valor diferente
    }
  };

  useEffect(() => {
    setInitialValues({
      organizationalChange,
      newDepartments,
      newManager,
      newAsigneeManager,
      roleChangeDetails,
      newDutiesDetails,
      categoryChangeDetails,
    });
  }, []);

  useEffect(() => {
    const fields = {
      newAsigneeManager: showManagerInput ? newAsigneeManager : undefined,
      roleChangeDetails: showRoleChangeInput ? roleChangeDetails : undefined,
      newDutiesDetails: showNewDutiesInput ? newDutiesDetails : undefined,
      categoryChangeDetails: showCategoryChangeInput ? categoryChangeDetails : undefined,
    };

    const validationErrors = {};
    Object.keys(fields).forEach((key) => {
      if (fields[key] === undefined || fields[key].trim() === '') {
        validationErrors[key] = `${key} es un campo requerido`;
      }
    });

    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
  }, [
    newAsigneeManager,
    roleChangeDetails,
    newDutiesDetails,
    categoryChangeDetails,
    showManagerInput,
    showRoleChangeInput,
    showNewDutiesInput,
    showCategoryChangeInput,
  ]);

  const getInputClass = (fieldValue, fieldName) => {
    return errors[fieldName] ? 'formJiraChangeInputText errorBorder' : 'formJiraChangeInputText';
  };

  const getRadioClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeRadio prefilledBorder';
    }
    return errors[fieldName] ? 'formJiraChangeRadio errorBorder' : 'formJiraChangeRadio';
  };

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambios organizativos</div>

      {/* Pregunta: ¿Se trata de una reorganización? */}
      <div className='formJiraChangeRadioGroup'>
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>{errors['organizationalChange'] && '*'} ¿Se trata de una reorganización?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='organizationalChange'
                className={getRadioClass('organizationalChange', 'organizationalChange')}
                value='yes'
                checked={organizationalChange === 'yes'}
                onChange={() => handleRadioChange(organizationalChange, setOrganizationalChange, 'yes')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='organizationalChange'
                className={getRadioClass('organizationalChange', 'organizationalChange')}
                value='no'
                checked={organizationalChange === 'no'}
                onChange={() => handleRadioChange(organizationalChange, setOrganizationalChange, 'no')}
              />
              No
            </label>
          </div>
        </div>

        {/* Pregunta: ¿Hay que crear nuevos departamentos? */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>{errors['newDepartments'] && '*'} ¿Hay que crear nuevos departamentos?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newDepartments'
                className={getRadioClass('newDepartments', 'newDepartments')}
                value='yes'
                checked={newDepartments === 'yes'}
                onChange={() => handleRadioChange(newDepartments, setNewDepartments, 'yes')}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newDepartments'
                className={getRadioClass('newDepartments', 'newDepartments')}
                value='no'
                checked={newDepartments === 'no'}
                onChange={() => handleRadioChange(newDepartments, setNewDepartments, 'no')}
              />
              No
            </label>
          </div>
        </div>

        {/* Pregunta: ¿Quieres asignar a esta persona/s un nuevo responsable directo? */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>
            {errors['newManager'] && '*'} ¿Quieres asignar a esta persona/s un nuevo responsable directo?
          </label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newManager'
                className={getRadioClass(showManagerInput ? 'yes' : 'no', 'newManager')}
                value='yes'
                checked={newManager === 'yes'}
                onChange={() => {
                  setShowManagerInput(true);
                  setNewManager('yes');
                }}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newManager'
                className={getRadioClass(showManagerInput ? 'yes' : 'no', 'newManager')}
                value='no'
                checked={newManager === 'no'}
                onChange={() => {
                  setShowManagerInput(false);
                  setNewManager('no');
                  setNewAsigneeManager && setNewAsigneeManager('');
                }}
              />
              No
            </label>
          </div>
        </div>

        {/* Campo: Nuevo responsable directo */}
        {showManagerInput && (
          <div className='formJiraChangeSection'>
            <label className='formJiraChangeLabel'>
              {errors['newAsigneeManager'] && '*'} Nombre del nuevo responsable directo:
              <input
                type='text'
                placeholder='Responsable Directo'
                className={getInputClass(newAsigneeManager, 'newAsigneeManager')}
                value={newAsigneeManager}
                onChange={(e) => setNewAsigneeManager(e.target.value)}
              />
            </label>
          </div>
        )}

        {/* Pregunta: ¿Se trata de un cambio de rol? */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>{errors['roleChange'] && '*'} ¿Se trata de un cambio de rol?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='roleChange'
                className={getRadioClass(showRoleChangeInput ? 'yes' : 'no', 'roleChange')}
                value='yes'
                checked={roleChange === 'yes'}
                onChange={() => {
                  setShowRoleChangeInput(true);
                  setRoleChange('yes');
                }}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='roleChange'
                className={getRadioClass(showRoleChangeInput ? 'yes' : 'no', 'roleChange')}
                value='no'
                checked={roleChange === 'no'}
                onChange={() => {
                  setShowRoleChangeInput(false);
                  setRoleChange('no');
                  setRoleChangeDetails && setRoleChangeDetails('');
                }}
              />
              No
            </label>
          </div>
        </div>

        {/* Campo: Detalles del cambio de rol */}
        {showRoleChangeInput && (
          <div className='formJiraChangeSection'>
            <label className='formJiraChangeLabel'>
              {errors['roleChangeDetails'] && '*'} ¿Puedes indicarnos en qué consiste el cambio de rol y si crees que es necesario un cambio de
              puesto?:
              <input
                type='text'
                placeholder='Detalles del cambio de rol'
                className={getInputClass(roleChangeDetails, 'roleChangeDetails')}
                value={roleChangeDetails}
                onChange={(e) => setRoleChangeDetails(e.target.value)}
              />
            </label>
          </div>
        )}

        {/* Pregunta: ¿Cambian las funciones de esta persona? */}
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>{errors['newDuties'] && '*'} ¿Cambian las funciones de esta persona?</label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newDuties'
                className={getRadioClass(showNewDutiesInput ? 'yes' : 'no', 'newDuties')}
                value='yes'
                checked={newDuties === 'yes'}
                onChange={() => {
                  setShowNewDutiesInput(true);
                  setNewDuties('yes');
                }}
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newDuties'
                className={getRadioClass(showNewDutiesInput ? 'yes' : 'no', 'newDuties')}
                value='no'
                checked={newDuties === 'no'}
                onChange={() => {
                  setShowNewDutiesInput(false);
                  setNewDuties('no');
                  setNewDutiesDetails && setNewDutiesDetails('');
                }}
              />
              No
            </label>
          </div>
        </div>

        {/* Campo: Detalles de las nuevas funciones */}
        {showNewDutiesInput && (
          <div className='formJiraChangeSection'>
            <label className='formJiraChangeLabel'>
              {errors['newDutiesDetails'] && '*'} ¿Puedes indicarnos en qué consisten esas nuevas funciones?:
              <input
                type='text'
                placeholder='Detalles de las nuevas funciones'
                className={getInputClass(newDutiesDetails, 'newDutiesDetails')}
                value={newDutiesDetails}
                onChange={(e) => setNewDutiesDetails(e.target.value)}
              />
            </label>
          </div>
        )}
      </div>
      {/* Pregunta: ¿Se trata de un cambio de categoría? */}
      <div className='formJiraChangeLabelRadio'>
        <label className='formJiraChangeLabelRadio'>{errors['categoryChange'] && '*'} ¿Se trata de un cambio de categoría?</label>
        <div className='formJiraChangeRadio'>
          <label className='formJiraChangeLabelRadio'>
            <input
              type='radio'
              name='categoryChange'
              className={getRadioClass(showCategoryChangeInput ? 'yes' : 'no', 'categoryChange')}
              value='yes'
              checked={categoryChange === 'yes'}
              onChange={() => {
                setShowCategoryChangeInput(true);
                setCategoryChange('yes');
              }}
            />
            Sí
          </label>
          <label className='formJiraChangeLabelRadio'>
            <input
              type='radio'
              name='categoryChange'
              className={getRadioClass(showCategoryChangeInput ? 'yes' : 'no', 'categoryChange')}
              value='no'
              checked={categoryChange === 'no'}
              onChange={() => {
                setShowCategoryChangeInput(false);
                setCategoryChange('no');
                setCategoryChangeDetails && setCategoryChangeDetails('');
              }}
            />
            No
          </label>
        </div>
      </div>

      {/* Campo: Cambio de categoría */}
      {showCategoryChangeInput && (
        <div className='formJiraChangeSection'>
          <label className='formJiraChangeLabel'>
            {errors['categoryChangeDetails'] && '*'} ¿Qué cambio de categoría quieres realizar?
            <input
              type='text'
              placeholder='Describe el cambio de categoría'
              className={getInputClass(categoryChangeDetails, 'categoryChangeDetails')}
              value={categoryChangeDetails}
              onChange={(e) => setCategoryChangeDetails(e.target.value)}
            />
          </label>
        </div>
      )}
    </div>
  );
};
