import React, { useEffect } from 'react';
import elipse355 from '../../images/ellipse355.png';
import aboutUsPageEmployees from '../../images/aboutUsPage-employees.png';
import '../styles/aboutUsPage.css';
import { Link } from 'react-router-dom';
const AboutUsPage = () => {
  useEffect(() => {
    // Forzar el scroll a la parte superior cuando el componente se monta
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className='aboutUsPage'>
        {/* Breadcrumb */}
        <div className='aboutUsPage__breadcrumb'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <Link to='/knowledgepage' className='aboutUsPage__breadcrumb--title'>
            INICIO {''}
          </Link>
          <span className='aboutUsPage__breadcrumb--text'>&gt;</span>
          <h4 className='aboutUsPage__breadcrumb--title'>SOBRE NOSOTROS {''}</h4>
        </div>
        <section className='aboutUsPage__content'>
          <h2 className='aboutUsPage__title'>Sobre Nosotros</h2>
          <img src={aboutUsPageEmployees} alt='employees-faces' className='aboutUsPage__employees' />
          <div className='aboutUsPage__content'>
            <p className='aboutUsPage__text'>
              En el equipo de Personas de Telefónica apostamos fuertemente por empoderar a todas las personas para liderar el cambio que nos permita
              construir la Telefónica de los próximos 100 años. Creemos firmemente en cuidar la experiencia de nuestros empleados como motor de la
              compañía, para que lleguen a ser su mejor versión.
            </p>
            <p className='aboutUsPage__text'>
              La plataforma digital de atención al empleado, PeoplEx, ofrece tanto a los líderes de equipo, como a los empleados, un alto grado de
              autonomía para gestionar sus consultas de forma ágil y sencilla, cuando quieran y desde donde quieran. Sin prescindir de un
              acompañamiento más personalizado cuando así lo requieran, a través de los botones de contacto con People.
            </p>
          </div>
        </section>
      </section>
    </>
  );
};

export default AboutUsPage;
