import React from 'react';

const GeneralInfoViewnext = ({ ssffId, setSsffId }) => {
  return (
    <div>
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='ssffId'>
          SSFF ID empleado
        </label>
        <input type='text' id='ssffId' className='formJiraChangeInputText' value={ssffId} onChange={(e) => setSsffId(e.target.value)} />
      </div>
    </div>
  );
};

export default GeneralInfoViewnext;
