import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import plus from '../../../images/plus.svg';
import minus from '../../../images/minus.svg';
import copyIcon from '../../../images/copy.png'; // Importa tu ícono de copiar
import '../../styles/articleJiraItem.css';

const articleRoutes = {
  14: '/subcategories/articles/form/fusion',
  20: '/subcategories/articles/form/loan',
  17: '/subcategories/articles/form/healthinsurancebeneficiaries',
  18: '/subcategories/articles/form/advance',
  28: ['/subcategories/articles/form/telematic', '/subcategories/articles/form/telematicperiods'],
  64: '/subcategories/articles/form/gourmetcard',
  60: '/subcategories/articles/form/lifeinsurancebeneficiaries',
  235: '/subcategories/articles/form/healthinsurance',
  236: 'subcategories/articles/form/lifeinsurance',
};

const ArticleJiraItem = ({ title, answer, expandedState, articleId, onCopyToEmail }) => {
  const [isExpanded, setIsExpanded] = useState(expandedState || false);
  const [showMessage, setShowMessage] = useState(false); // Controla si se muestra el mensaje

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCopy = () => {
    onCopyToEmail(answer); // Copia el contenido al estado compartido
    navigator.clipboard.writeText(answer); // Copia al portapapeles
    setShowMessage(true); // Muestra el mensaje
    // Oculta el mensaje después de 2 segundos
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };

  return (
    <article style={{ fontSize: '14px' }} className={`articleItem ${isExpanded ? 'expanded' : ''}`}>
      <div onClick={handleToggle} className={`articleItem__firstSection ${isExpanded ? 'expanded' : ''}`}>
        <p className='articleItem__title'>{title}</p>
        <div className='expandIconBox'>
          <img src={plus} alt='plus-symbol' className={`articleItem__plus ${isExpanded ? 'expanded' : ''}`} />
        </div>
        {isExpanded && <img src={minus} alt='minus-symbol' className={`articleItem__minus ${isExpanded ? 'expanded' : ''}`} />}
      </div>
      {isExpanded && (
        <>
          <div className='answerBox'>
            <div className='copyContainer'>
              <button onClick={handleCopy} className='copyButton'>
                <img src={copyIcon} alt='Copiar contenido' title='Copiar contenido' className='copyIcon__img' />
              </button>
              {/* Mensaje dinámico */}
              {showMessage && (
                <div
                  style={{
                    position: 'absolute',
                    top: '-30px',
                    right: '-10px',
                    background: '#333',
                    color: '#fff',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  ¡Copiado!
                </div>
              )}
            </div>
            <p className='subcategories__articleList--article__description' dangerouslySetInnerHTML={{ __html: answer }}></p>
          </div>
          {articleRoutes[articleId] && (
            <div className='linkButtonContainer'>
              {Array.isArray(articleRoutes[articleId]) ? (
                articleRoutes[articleId].map((route, index) => (
                  <Link to={route} key={index} className='linkButton'>
                    Ir al formulario {index + 1}
                  </Link>
                ))
              ) : (
                <Link to={articleRoutes[articleId]} className='linkButton'>
                  Ir al formulario
                </Link>
              )}
            </div>
          )}
        </>
      )}
    </article>
  );
};

export default ArticleJiraItem;
