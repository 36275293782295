import React, { useState, useCallback, useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom';
import '../../styles/contactUsInbentaInfo.css';
import SearchArticleList from './contactUsSearchArticleList';
import iconSearch from '../../../images/search.svg';

const ContactUsInbentaInfo = ({ autocompleteArticles, handleFilterSearcher, handleUpperCategoryIdParam, userInfoSSFF, onCopyToEmail }) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1); // Índice del artículo seleccionado en el autocomplete
  const [searchTerm, setSearchTerm] = useState(''); // Término que escribe el usuario
  const [filteredAutocompletedArticles, setFilteredAutocompletedArticles] = useState([]); // Artículos sugeridos
  const [results, setResults] = useState([]); // Resultados finales
  const autocompleteRef = useRef(null);
  const [selectedJuridica, setSelectedJuridica] = useState(userInfoSSFF.custom03); // Estado para la jurídica seleccionada

  // Cierra el autocomplete si se hace clic fuera del componente
  const handleClickOutside = (event) => {
    if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
      setFilteredAutocompletedArticles([]);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Actualiza las sugerencias mientras el usuario escribe
  const handleChange = (ev) => {
    const { value } = ev.target;
    setSearchTerm(value);
    debouncedAutocomplete(value); // Actualiza las sugerencias
  };

  // Controla navegación con teclado (Enter, flechas)
  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowUp':
        setSelectedItemIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        break;
      case 'ArrowDown':
        setSelectedItemIndex((prevIndex) => Math.min(prevIndex + 1, filteredAutocompletedArticles.length - 1));
        break;
      case 'Enter':
        if (selectedItemIndex !== -1) {
          // Selecciona un artículo del autocomplete
          const selectedArticle = filteredAutocompletedArticles[selectedItemIndex];
          handleSelectArticle(selectedArticle);
        } else if (searchTerm) {
          // Busca directamente si no hay artículo seleccionado
          handleSearch(searchTerm);
        }
        break;
      default:
        break;
    }
  };

  // Ejecuta la búsqueda de artículos
  const handleSearch = useCallback(
    (term) => {
      const filteredResults = autocompleteArticles.filter((article) => article.repr.toLowerCase().includes(term.toLowerCase()));
      setResults(filteredResults); // Actualiza los resultados finales
      setFilteredAutocompletedArticles([]);
    },
    [autocompleteArticles]
  );

  // Maneja la selección de un artículo del autocomplete
  const handleSelectArticle = (article) => {
    handleFilterSearcher(article.repr);
    setSearchTerm(article.repr); // Actualiza el término de búsqueda
    setResults([article]); // Muestra solo el artículo seleccionado
    setFilteredAutocompletedArticles([]); // Limpia las sugerencias
  };

  // Lógica para autocompletar (mientras el usuario escribe)
  const debouncedAutocomplete = useCallback(
    debounce((value) => {
      if (value.trim() === '') {
        setFilteredAutocompletedArticles([]);
        return;
      }

      const normalizedValue = normalizeText(value);

      const filteredResults = autocompleteArticles.filter((article) => {
        const normalizedArticle = normalizeText(article.repr);
        return normalizedArticle.includes(normalizedValue);
      });

      setFilteredAutocompletedArticles(filteredResults); // Actualiza las sugerencias
    }, 300),
    [autocompleteArticles]
  );

  // Normaliza texto eliminando tildes y caracteres especiales
  const normalizeText = (text) => {
    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .toLowerCase();
  };
  // Maneja el cambio del select y actualiza el custom03 de userInfoSSFF
  const handleSelectChange = (event) => {
    const newCustom03 = event.target.value;
    setSelectedJuridica(newCustom03); // Actualiza el estado local
    setResults([]); // Limpia los resultados al cambiar la jurídica
  };

  // Actualiza resultados al cambiar el término de búsqueda o la jurídica
  useEffect(() => {
    if (searchTerm.trim() !== '') {
      handleSearch(searchTerm);
    } else {
      setResults([]); // Limpia resultados si no hay término de búsqueda
    }
  }, [searchTerm, selectedJuridica, handleSearch]); // Dependencias incluyen jurídica y término

  return (
    <div className='employeeChannel__infoContainer'>
      <div className='employeeChannel__firstSectionContainer'>
        <div className='employeeChannel__searcherContainer'>
          <img src={iconSearch} alt='search_icon' className='icon' />
          <p className='¿Qué necesitas?'></p>
          <input
            type='text'
            placeholder='Nóminas, vacaciones...'
            autoComplete='off'
            className='employeeChannel__searchInput'
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={searchTerm}
          />
          {/* Mostrar sugerencias del autocomplete */}
          {searchTerm !== '' && filteredAutocompletedArticles.length > 0 && (
            <ul className='JiraAutocomplete__container' ref={autocompleteRef}>
              {filteredAutocompletedArticles.map((article, index) => (
                <li
                  key={article.id}
                  className={`JiraAutocomplete__item ${index === selectedItemIndex ? 'selected' : ''}`}
                  onClick={() => handleSelectArticle(article)}
                >
                  {article.repr}
                </li>
              ))}
            </ul>
          )}
        </div>
        <select className='form-select juridicaSelect' value={selectedJuridica} onChange={handleSelectChange}>
          <option value='0250_ESP'>TSA</option>
          <option value='0024'>TID</option>
          <option value='1412'>TGS</option>
          <option value='1893'>T.CABLE</option>
          <option value='1702'>T.TELECOM</option>
          <option value='1343'>TCE</option>
          <option value='1017'>T.HISPAM</option>
          <option value='1583'>TOI</option>
          <option value='1970'>TELCRYP</option>
        </select>
      </div>
      {/* Mostrar resultados solo después de Enter o selección */}
      {results.length > 0 && (
        <SearchArticleList
          filterInSearcher={searchTerm}
          handleUpperCategoryIdParam={handleUpperCategoryIdParam}
          userInfoSSFF={{ ...userInfoSSFF, custom03: selectedJuridica }}
          handleFilterSearcher={handleFilterSearcher}
          onCopyToEmail={onCopyToEmail}
        />
      )}
    </div>
  );
};

// Función debounce para optimizar rendimiento
function debounce(func, delay) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export default ContactUsInbentaInfo;
