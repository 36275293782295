import React from 'react';
import ArrowComponent from '../../assests/FileComponentes/iconsComponent/ArrowComponent';
import '../Styles/clusterCard.css'

const ClusterComponentCard = ({ positions }) => {
    return (
      <div className="cluster-card">
        <div className="circles-container-card ">
          {positions.map((position) => (
            <div key={position.key} className="circle-card me-4 text-white">
              {position.label}
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default ClusterComponentCard;
  