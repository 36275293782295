import React, { useState, useEffect } from 'react';
import EmployeeSearch from '../employeeSearch';
import TeamSearch from '../teamSearch';
import { validateFields } from '../formValidate';
import { capitalizeFirstLetter, getSafeString } from '../../../../../../utils/utils';

export const GeneralInfo = ({
  searchType,
  setSearchType,
  setValue,
  setSelectedUser,
  employeeSuggestions,
  inputProps,
  selectedUser,
  handleChange,
  getSuggestionValue,
  renderSuggestion,
  handleSuggestionsFetchRequested,
  handleSuggestionsClearRequested,
  managerSuggestions,
  managedEmployees,
  setEffectiveDate,
  showEffectiveDateInfo,
  setShowEffectiveDateInfo,
  showEffectiveDateRequired,
  setIsGeneralInfoComplete,
  managerName,
  managerEmail,
  employeeName,
  setEmployeeName,
  employeeEmail,
  setEmployeeEmail,
  employeeSSFFId,
  setEmployeeSSFFId,
  employeeRegistrationId,
  setEmployeeRegistrationId,
  employeeLegalEntity,
  setEmployeeLegalEntity,
  effectiveDate,
  managerEmployees,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [errors, setErrors] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  ///////////LOGS CHECK INPUTS/////////
  useEffect(() => {
    console.log('managerName:', managerName);
    console.log('managerEmail:', managerEmail);
    console.log('employeeName:', employeeName);
    console.log('employeeEmail:', employeeEmail);
    console.log('employeeSSFFId:', employeeSSFFId);
    console.log('employeeRegistrationId:', employeeRegistrationId);
    console.log('employeeLegalEntity:', employeeLegalEntity);
    console.log('effectiveDate:', effectiveDate);
  }, [managerName, managerEmail, employeeName, employeeEmail, employeeSSFFId, employeeRegistrationId, employeeLegalEntity, effectiveDate]);

  useEffect(() => {
    setInitialValues({
      effectiveDate,
    });
  }, []);

  useEffect(() => {
    // Verifica si selectedUser tiene valores y los actualiza
    if (selectedUser) {
      setEmployeeName(capitalizeFirstLetter(getSafeString(selectedUser.defaultFullName)) || '');
      setEmployeeEmail(selectedUser.email || '');
      setEmployeeSSFFId(selectedUser.userId || '');
      setEmployeeRegistrationId(selectedUser.empInfo.personNav.personalInfoNav.results[0].customString5 || '');
      setEmployeeLegalEntity(selectedUser.custom08 || '');
    }
  }, [selectedUser, employeeName, employeeEmail, employeeSSFFId, employeeRegistrationId, employeeLegalEntity]);

  useEffect(() => {
    const fields = {
      effectiveDate,
      employeeName: employeeName || '', // Garantiza que sean cadenas
      employeeEmail: employeeEmail || '',
      employeeSSFFId: employeeSSFFId || '',
      employeeRegistrationId: employeeRegistrationId || '',
      employeeLegalEntity: employeeLegalEntity || '',
    };

    const validationErrors = {};
    Object.keys(fields).forEach((key) => {
      if (!fields[key].trim()) {
        validationErrors[key] = `${key} es un campo requerido`;
      }
    });

    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
    setIsGeneralInfoComplete(Object.keys(validationErrors).length === 0);
  }, [effectiveDate, employeeName, employeeEmail, employeeSSFFId, employeeRegistrationId, employeeLegalEntity]);

  const handleSearchTypeChange = (type) => {
    setSearchType(type);
    setValue('');
    setSelectedUser(null);
    setShowPopup(true);
  };

  const getInputClass = (fieldValue, fieldName) => {
    return errors[fieldName] ? 'formJiraChangeInputText_short errorBorder' : 'formJiraChangeInputText_short';
  };

  const handleEffectiveDateChange = (e) => {
    const selectedDate = e.target.value;
    setEffectiveDate(selectedDate);
    setShowEffectiveDateInfo(false);

    const today = new Date();
    const selectedDateObject = new Date(selectedDate);

    if (selectedDateObject < today) {
      setShowEffectiveDateInfo(true);
    }
  };

  const handleSelectTeam = () => {
    if (selectedEmployees.length > 0) {
      // Combinar valores de los empleados seleccionados
      setEmployeeName(selectedEmployees.map((emp) => capitalizeFirstLetter(getSafeString(emp.defaultFullName))).join(', ') || '');
      setEmployeeEmail(selectedEmployees.map((emp) => emp.email).join(', ') || '');
      setEmployeeSSFFId(selectedEmployees.map((emp) => emp.userId).join(', ') || '');
      setEmployeeRegistrationId(
        selectedEmployees.map((emp) => emp.empInfo.personNav.personalInfoNav.results[0].customString5 || '').join(', ') || ''
      );
      setEmployeeLegalEntity(selectedEmployees.map((emp) => emp.custom08 || '').join(', ') || '');
    }
    setShowPopup(false);
  };

  return (
    <div>
      <div className='formJiraChangeSection_searchButton'>
        <button type='button' className='formJiraChangeButton' onClick={() => handleSearchTypeChange('employee')}>
          Buscar por Empleado
        </button>
        <button type='button' className='formJiraChangeButton' onClick={() => handleSearchTypeChange('team')}>
          Buscar por Equipo
        </button>
      </div>

      {showPopup && (
        <div className='dislikePopup-overlay'>
          <div className='dislikePopup-content'>
            {searchType === 'employee' && (
              <EmployeeSearch
                employeeSuggestions={employeeSuggestions}
                inputProps={inputProps}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                setEmployeeName={setEmployeeName}
                handleChange={handleChange}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                handleSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                handleSuggestionsClearRequested={handleSuggestionsClearRequested}
                managerEmployees={managerEmployees}
              />
            )}

            {searchType === 'team' && (
              <TeamSearch
                managerSuggestions={managerSuggestions}
                inputProps={inputProps}
                managedEmployees={managedEmployees}
                handleChange={handleChange}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                handleSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                handleSuggestionsClearRequested={handleSuggestionsClearRequested}
                managerEmployees={managerEmployees}
                setSelectedEmployees={setSelectedEmployees}
              />
            )}
            <button className='contactMePopup--button' onClick={handleSelectTeam}>
              Seleccionar
            </button>
          </div>
        </div>
      )}

      <div className='formJiraChangeInfoTittle'>Información General</div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='name'>
          Nombre y apellidos del manager solicitante{' '}
        </label>
        <input type='text' className='formJiraChangeInputText' value={managerName} readOnly />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='username'>
          Email del manager solicitante
        </label>
        <input type='email' className='formJiraChangeInputText' value={managerEmail} readOnly />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='employeeName'>
          Nombre y apellidos del empleado
        </label>
        <input type='text' className={getInputClass(employeeName, 'employeeName')} value={employeeName} readOnly />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='employeeEmail'>
          Email del empleado
        </label>
        <input type='text' className={getInputClass(employeeEmail, 'employeeEmail')} value={employeeEmail} readOnly />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='employeeSSFFId'>
          Id del empleado
        </label>
        <input type='text' className={getInputClass(employeeSSFFId, 'employeeSSFFId')} value={employeeSSFFId} readOnly />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='employeeRegistrationId'>
          Matrícula del empleado
        </label>
        <input type='text' className={getInputClass(employeeRegistrationId, 'employeeRegistrationId')} value={employeeRegistrationId} readOnly />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='employeeLegalEntity'>
          Compañía
        </label>
        <input
          type='text'
          className={getInputClass(employeeLegalEntity, 'employeeLegalEntity')}
          value={Array.from(new Set(employeeLegalEntity.split(', '))).join(', ')} // Filtrar duplicados
          readOnly
        />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='effectiveDate'>
          Fecha de efectividad
          {showEffectiveDateRequired && <p className='formJiraChangeLabel__required'>Este es un campo requerido</p>}
        </label>
        <input
          type='date'
          className={getInputClass(effectiveDate, 'effectiveDate')}
          value={effectiveDate}
          onChange={(e) => handleEffectiveDateChange(e)}
        />
        {showEffectiveDateInfo && <p className='error'>Estas seleccionando una fecha anterior a la actual</p>}
      </div>
    </div>
  );
};

export default GeneralInfo;
