import LeftComponent from "../../assests/FileComponentes/iconsComponent/LeftComponent";
import RightComponent from "../../assests/FileComponentes/iconsComponent/RightComponent";
import PersonComponent from "../../assests/FileComponentes/iconsComponent/PersonComponent";
import LocationComponent from "../../assests/FileComponentes/iconsComponent/LocationComponent";
import ClockComponent from "../../assests/FileComponentes/iconsComponent/ClockComponent";
import PhotoComponent from "../../assests/FileComponentes/iconsComponent/PhotoComponent";
import DownloadComponent from "../../assests/FileComponentes/iconsComponent/DownloadComponent";
import LogoBlueSVG from "../../assests/FileComponentes/iconsComponent/LogoBlue";
import TalentTable from "../../TalentFiles/Components/TalentTable";
import ClusterComponentMobile from "./ClusterComponentMobile";
import { useEffect, useState } from "react";
import "../Styles/infoMobile.css";
import BackIconComponent from "../../assests/MobileIcons/BackIconComponent";
import { useNavigate } from 'react-router-dom';
import userImg from '../../../images/user-account.png';
import CryptoJS from 'crypto-js';

function InfoComponentMobile({ selectedEmployeeId,userInfoSSFF,empleadoDetails,empleadosList, empleado}) {
 
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 3;
  const navigate = useNavigate();



 
  const backPage = () => {
    navigate(`/talentCards`);
  };
  const secretKey = process.env.REACT_APP_TALENT_APP;

  const encrypted = CryptoJS.AES.encrypt(selectedEmployeeId, secretKey).toString();
  const encryptedEmployeeId = encodeURIComponent(btoa(encrypted));


  const handleViewSummaryMobile = () => {
    // Encripta el selectedEmployeeId
    //const encryptedEmployeeId = CryptoJS.AES.encrypt(selectedEmployeeId, secretKey).toString();

    navigate(`/talentCardsSecond/${encryptedEmployeeId}`, {
      state: { empleadoDetails, empleado }
    });
  };

  // Obtener la cadena de la imagen en Base64
  const base64Image = empleado.empInfo?.photoNav?.results?.[0]?.photo;

  // Construir la URL de la imagen en Base64
  const imageUrl = base64Image ? `data:image/jpeg;base64,${base64Image}` : userImg;

  // Obtener la fecha de nacimiento del empleado
  const birthdayTimestamp = empleado.empInfo?.personNav?.dateOfBirth?.match(/\d+/)?.[0];
  const birthday = birthdayTimestamp ? new Date(parseInt(birthdayTimestamp)) : null;

  // Calcular la edad si se dispone de la fecha de nacimiento
  const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const age = birthday ? calculateAge(birthday) : null;

 // Obtener las fechas de entrada y antigüedad
 const positionEntryDateTicks = empleado.empInfo?.jobInfoNav?.results?.[0]?.positionEntryDate?.match(/\d+/)?.[0];
 const seniorityDateTicks = empleado.empInfo?.seniorityDate?.match(/\d+/)?.[0];

 // Convertir las fechas a objetos Date
 const positionEntryDate = positionEntryDateTicks ? new Date(parseInt(positionEntryDateTicks)) : null;
 const seniorityDate = seniorityDateTicks ? new Date(parseInt(seniorityDateTicks)) : null;

 // Extraer solo el año
 const getYear = (date) => {
   return date ? date.getFullYear() : 'N/A';
 };

 const positionEntryYear = getYear(positionEntryDate);
 const seniorityYear = getYear(seniorityDate);
 const yearsinJob = positionEntryYear - seniorityYear; 
 const positionEntryDateYears = empleado.empInfo?.jobInfoNav?.results?.[0]?.positionEntryDate;
 const currentYear = new Date().getFullYear();
 
 let yearsInRole  = "N/A";
 if (positionEntryDateYears) {
   const positionEntryYear = new Date(parseInt(positionEntryDateYears.match(/\d+/)[0], 10)).getFullYear();
    yearsInRole  = currentYear - positionEntryYear;
 }


 const fullLocation = empleado.location || '';
 const cleanLocation = fullLocation.replace(/\s*\(.*?\)\s*/g, '').trim();
 // Convertir todo a minúsculas y luego capitalizar la primera letra
 const formattedLocation = cleanLocation.charAt(0).toUpperCase() + cleanLocation.slice(1).toLowerCase();



 const position = empleado.empInfo?.jobInfoNav?.results?.[0]?.jobTitle || '';
 

  const positions = [
    { key: 'cluster_ceo', label: 'CEO' },
    { key: 'cluster_b2c_growth', label: 'B2C' },
    { key: 'cluster_b2b_growth', label: 'B2B' },
    { key: 'cluster_technology', label: 'TEC' },
    { key: 'cluster_enablers', label: 'EN' },
    { key: 'cluster_pm_transversal', label: 'PM' }

  ].filter(position => empleadoDetails[position.key]);
 
  console.log();
  return (
    <div className="info-mobile p-0 ms-0 me-0" style={{ backgroundColor: "#007bff" }}>
      {/* HeaderMobile */}
      <div className="row custom-row">
        <div className="text-start">
          <div className="row align-items-center">
            <div className="col-auto ">
              <div className="exit-container me-2 ms-2">
                <BackIconComponent className="btnInfo-exit" onClick={backPage} />
              </div>
            </div>
            <div className="col text-start text-white">
              <h1 className="fs-3 mt-3 mb-1 employee-name">{empleado.displayName}</h1>
              <h2 className="fs-6 employee-job">{position}</h2>
            </div>
            <div className="col-auto text-center ">
                <div className="rounded-circle overflow-hidden mt-3 me-3" style={{ width: '90px', height: '90px', objectFit: 'cover', background:'#007bff' }}>
                  {imageUrl && (
                    <img 
                      src={imageUrl} 
                      alt="Foto de empleado" 
                      style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} 
                    />
                  )}
                </div>
            </div>
          </div>
        </div>
      </div>

      {/* Esto es para el cluster mobile */}
      <div className="row custom-row mb-2 ">
        <div className="text-start">
          <div className="row align-items-center">
            <div className="col-auto mt-3">
              <DownloadComponent className="btnInfo-download ms-3" />
            </div>
            <div className="col d-flex justify-content-center ">
              <ClusterComponentMobile positions={positions} empleadoDetails={empleadoDetails}  />
            </div>
            <div className="col-auto d-flex align-items-center justify-content-end mt-3 text-white ms-1">
              <LeftComponent className="btnInfo-left ms-0" />
              <span className="mx-1">{currentPage}/{totalPages}</span>
              <RightComponent className="btnInfo-right me-3" onClick={handleViewSummaryMobile} />
            </div>
          </div>
        </div>
      </div>

      {/* section de infor SSFF */}
      <div className="row custom-row text-white mt-5 mb-4">
        {/* Yearsold */}
        <div className="col-6 col-sm-3">
          <p className="d-flex justify-content-start ms-3 mt-2 mb-4 employee-years">{age}  years old</p>
        </div>

        {/* in year company */}
        <div className="col-6 col-sm-3">
          <div className="d-flex justify-content-start mt-2 mb-4 employee-years-role">
            <div>
              <ClockComponent className="clock-icon me-3" />
              {yearsInRole} years in current role 
              <div className="datefinal-exp ms-5">
              {yearsinJob} years in Telefónica
              </div>
            </div>
          </div>
        </div>

        {/* codigo */}
        <div className="col-6 col-sm-3">
          <p className="d-flex justify-content-start ms-2 employee-code"><PersonComponent className="person-icon me-1" />{selectedEmployeeId}</p>
        </div>
        {/* location */}
        <div className="col-6 col-sm-3">
          <p className="d-flex justify-content-start mt-1 employee-location"><LocationComponent className="location-icon me-3" />{formattedLocation}</p>
        </div>
      </div>

      {/* talent */}
      <div className="row custom-row text-white mb-3 ms-1">
        <p className="talent-subtitle-mobile mt-1 fs-5 mb-0 ms-2">Talent Pool & Performance</p>
        <div className="talent-table-mobile">
          <TalentTable empleadoDetails={empleadoDetails} />
        </div>
      </div>

      {/* section de lenguaje*/}
      <div className="row custom-row text-white ms-1">
        <div className="languages">
          <p className="lang-subtitle-mobile d-flex justify-content-start ms-2 fs-5 mb-3">Languages</p>
          {empleadoDetails.languages.map((lang, index) => (
            <div key={index} className="d-flex justify-content-start mb-0 ms-2">
              <p className="mb-0">{lang.language}</p>
              {lang.level && (
                <p className="level mt-0">({lang.level})</p>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* logoTelefo */}
      <div className="row custom-row d-flex justify-content-center ms-1">
        <LogoBlueSVG className="logo-telefo-mobile" />
      </div>
    </div>
  );
}

export default InfoComponentMobile;
