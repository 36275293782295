import React from "react";

import TalentNoComponenet from "../../assests/FileComponentes/iconsComponent/TalentNoComponenet";
import TalentYesComponent from "../../assests/FileComponentes/iconsComponent/TalentYesComponent";
import PerfomanceComponent from "../../assests/FileComponentes/iconsComponent/PerfomanceComponent";
import globalStyles from "../../Styles/globalStyle";
import "../Styles/FirstTalentView/talentTable.css"

const currentYear = new Date().getFullYear();
const expectedYears = [currentYear, currentYear - 1, currentYear - 2];


const getYearData = (year, empleadoYears) => {
  return empleadoYears.find((data) => data.talent_year === year) || {};
};

function TalentTable({ empleadoDetails, onClick }) {
  const empleadoYears = empleadoDetails.history || [];
  return (
    <table className="table table-ms  mb-0" style= {{cursor: 'pointer'}} onClick={onClick} >
      <thead>
        <tr>
          <td scope="col" style={{ ...globalStyles.blueBackground }}> </td>
          {expectedYears.map((year) => (
            <td key={year} scope="col" className="blue-background text-white fw-semibold date-talent" style={{ ...globalStyles.blueBackground}}>
              {year}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row" 
            className="text-white mb-1 d-flex justify-content-start align-items-center ms-1 toptalent-tittle"
            style={{ ...globalStyles.blueBackground }}>Top Talent</td>
          {expectedYears.map((year) => {
            const data = getYearData(year, empleadoYears);
            return (
              <td key={year} className=" top-talent text-white mb-2" style={{ ...globalStyles.blueBackground, ...globalStyles.fontSize }}>
                {data.top_talent === 1 ? <TalentYesComponent className="top_talent-circle-yes" /> : <TalentNoComponenet className="top_talent-circle-no"/>}
              </td>
            );
          })}
        </tr>
        <tr>
          <td scope="row"
            className="text-white mb-1 d-flex justify-content-start align-items-center ms-1 keytalent-tittle"
            style={{ ...globalStyles.blueBackground }}>Key Talent</td>
          {expectedYears.map((year) => {
            const data = getYearData(year, empleadoYears);
            return (
              <td key={year} style={{ ...globalStyles.blueBackground }}>
                {data.key_talent === 1 ? <TalentYesComponent  className="top_talent-circle-yes"  /> : <TalentNoComponenet className="top_talent-circle-no"/>}
              </td>
            );
          })}
        </tr>
        <tr>
          <td
            scope="row"
            className="text-white d-flex justify-content-start align-items-center ms-1 performance-tittle"
            style={{ ...globalStyles.blueBackground }}
          >
            Performance
          </td>
          {expectedYears.map((year, index) => {
            const data = getYearData(year, empleadoYears);
            return (
              <td
                key={index}
                style={{
                  ...globalStyles.blueBackground,
                  ...globalStyles.fontSize,
                }}
              >
                {data.performance !== undefined && data.performance !== null ? (
                  <span style={{ position: "relative", display: "inline-block" }}>
                    <PerfomanceComponent className="top_talent-circle-performance" />
                    <span className="performance-data">{data.performance}</span>
                  </span>
                ) : (
                  <TalentNoComponenet className="top_talent-circle-no" />
                )}
              </td>
            );
          })}
        </tr>

      </tbody>
    </table>
  );
}

export default TalentTable;

