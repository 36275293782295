
import React from 'react';
import ArrowComponent from '../../assests/FileComponentes/iconsComponent/ArrowComponent';
import '../Styles/FirstTalentView/clusterComponent.css'

const ClusterComponent = ({ positions }) => {
  return (
    <div className="cluster">
      {positions.length <= 2 ? (
        positions.map(position => (
          <svg key={position.key} className="svg-Style">
            <rect
              x="0"
              y="0"
              width="160"
              height="50"
              className="rect-style"
            />
            <g className='arrow-position' /*  transform="translate(17,7)" */>
              <ArrowComponent />
            </g>
            <text x="50%" y="50%" className="position-SVG " dominantBaseline="middle" textAnchor="middle">
              {position.label}
            </text>
          </svg>
        ))
      ) : (
        <div className="circles-container mt-2">
          {positions.map(position => (
            <div key={position.key} className="circle me-4 text-white">
              <ArrowComponent className="arrow-circle " />
              {position.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default ClusterComponent;
