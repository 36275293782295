import React from 'react';
import { Svg, Path } from '@react-pdf/renderer';

const ClockPDFComponent = () => (
  <Svg width={16} height={16} viewBox="0 0 30 30" fill="#F2F4FF">
    <Path
      fill="#F2F4FF"
      d="M14.994 2.694c7.773 0 12.307 3.925 12.307 12.307 0 8.383-4.534 12.3-12.307 12.3-7.773 0-12.3-3.917-12.3-12.3 0-8.382 4.527-12.307 12.3-12.307Zm0 23.07c7.248 0 10.77-3.522 10.77-10.763 0-7.248-3.522-10.77-10.77-10.77C7.754 4.23 4.231 7.753 4.231 15c0 7.241 3.522 10.764 10.763 10.764Zm4.335-7.18-3.561-3.565.003-4.808a.77.77 0 0 0-1.54 0v5.126c0 .203.08.4.224.543l3.827 3.827a.766.766 0 0 0 1.085-.038.766.766 0 0 0-.038-1.086Z"
    />
  </Svg>
);

export default ClockPDFComponent;
