import React from 'react';
import "../Styles/header.css";
import SvgComponent from '../../assests/mainPageComponent/header/logo-telefonica';
import LogOut from '../../assests/mainPageComponent/header/LogOut';

function HeaderTalent({ onBack }) {
  

  return (
    <header className="Header d-flex align-items-center justify-content-between" >
        <button onClick={onBack} className="btn ms-5 mb-2" style={{ padding: 0 }}>
          <LogOut />
        </button>
        <h1 className="tittleHeader ms-5  ">
        <span><strong className='talent-title'>Talent</strong></span><span className="hub-title ">Cards</span>
        </h1>
        <div className="logo-web ms-auto d-none d-md-block"> 
          <SvgComponent style={{ width: '220px', height: '200px' }} />
        </div>
       
    </header>
  );
}

export default HeaderTalent;
