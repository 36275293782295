import * as React from "react"
const SpainIconComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    aria-hidden="true"
    className="iconify iconify--emojione"
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      fill="#ffce31"
      d="M2 32c0 5.9 1.7 11.4 4.6 16h50.7c2.9-4.6 4.6-10.1 4.6-16s-1.7-11.4-4.6-16H6.6C3.7 20.6 2 26.1 2 32z"
    />
    <g fill="#ed4c5c">
      <path d="M57.4 16C52.1 7.6 42.7 2 32 2S11.9 7.6 6.6 16h50.8zM6.6 48c5.3 8.4 14.7 14 25.4 14s20.1-5.6 25.4-14H6.6z" />
    </g>
    <g fill="#c8b100">
      <path d="M9.2 28.7h3.2v1.8H9.2zM9.2 41.9h3.3v1.7H9.2z" />
    </g>
    <path
      fill="#ed4c5c"
      d="M8.9 39.1c-.3.2-.5.4-.5.5 0 .1.1.2.3.3.2.1.4.3.3.5.2-.2.3-.4.3-.6 0-.3-.2-.6-.4-.7"
    />
    <path fill="#fff" d="M9.7 30.5H12v11.4H9.7z" />
    <g fill="#ed4c5c">
      <path d="M14.4 34.7c-.5-.2-1.4-.4-2.4-.4-.3 0-.7 0-1.1.1-1.4.2-2.5.8-2.4 1.2L8 34.5c-.1-.5 1.1-1.1 2.6-1.4.5-.1 1-.1 1.4-.1 1 0 1.9.1 2.4.3v1.4" />
      <path d="M9.7 36.2c-.6 0-1.1-.2-1.1-.5 0-.2.2-.5.6-.7h.6l-.1 1.2M12 35.3c.4.1.7.2.9.3.1.1-.3.5-.9.8v-1.1" />
      <path d="M8.2 38.4c-.1-.2.6-.6 1.5-.9.4-.1.7-.3 1.2-.5 1.2-.5 2.2-1.2 2-1.4l.2 1.2c.1.2-.7.8-1.9 1.4-.4.2-1.1.5-1.5.6-.7.2-1.3.6-1.3.7l-.2-1.1" />
    </g>
    <g fill="#c8b100">
      <path d="M30.7 28.7h3.2v1.8h-3.2zM30.6 41.9h3.3v1.7h-3.3z" />
    </g>
    <path
      fill="#ed4c5c"
      d="M34.2 39.1c.3.2.5.4.5.5 0 .1-.1.2-.3.3-.2.2-.4.5-.3.6-.2-.2-.3-.4-.3-.6 0-.4.2-.7.4-.8"
    />
    <path fill="#fff" d="M31.1 30.5h2.3v11.4h-2.3z" />
    <g fill="#ed4c5c">
      <path d="M28.7 34.7c.5-.2 1.4-.4 2.4-.4.3 0 .7 0 1.1.1 1.4.2 2.5.8 2.4 1.2l.5-1.2c.1-.5-1.1-1.1-2.6-1.4h-1.4c-1 0-1.9.1-2.4.3v1.4" />
      <path d="M33.4 36.2c.6 0 1.1-.2 1.1-.5 0-.2-.2-.5-.6-.7h-.6l.1 1.2M31.1 35.3c-.4.1-.7.2-.9.3-.1.1.3.5.9.8v-1.1" />
      <path d="M34.9 38.4c.1-.2-.6-.6-1.5-.9-.4-.1-.7-.3-1.2-.5-1.2-.5-2.2-1.2-2-1.4l-.2 1.2c-.1.2.7.8 1.9 1.4.4.2 1.1.5 1.5.6.7.2 1.3.7 1.2.8l.3-1.2M21.5 22.3c1.9 0 5.8.4 7.2 1.8-1.5 3.6-3.9 2.1-7.2 2.1-3.2 0-5.7 1.5-7.2-2.1 1.4-1.4 5.2-1.8 7.2-1.8" />
    </g>
    <g fill="#c8b100">
      <path d="M26.4 26.3c-1.2-.7-3-.8-4.9-.8-1.9 0-3.7.2-4.9.8L17 28c1.1.3 2.7.5 4.5.5 1.8 0 3.3-.2 4.5-.5l.4-1.7M28.1 22c-.4-.3-1.2-.6-1.9-.6-.3 0-.6 0-.9.1 0 0-.6-.8-2-.8-.5 0-.9.1-1.3.3v-.1c-.1-.2-.3-.4-.5-.4s-.5.3-.5.5v.1c-.4-.2-.8-.3-1.3-.3-1.4 0-2 .9-2 .8-.3-.1-.6-.1-.9-.1-4.6 0-2.3 3.1-2.3 3.1l.5-.6c-1.1-1.4-.1-2.2 1.9-2.2.3 0 .5 0 .7.1-.7 1 .6 1.9.6 1.9l.3-.5c-.7-.5-.8-2.2 1.2-2.2.5 0 .9.1 1.3.4 0 .1-.1 1.5-.2 1.7l.8.7.8-.7c-.1-.3-.2-1.6-.2-1.7.3-.2.8-.4 1.3-.4 2.1 0 2.1 1.7 1.2 2.2l.3.5s1.1-.9.6-1.9c.2 0 .5-.1.7-.1 2.4 0 2.5 1.8 1.9 2.2l.4.6c-.2 0 .9-1.4-.5-2.6" />
    </g>
    <path
      fill="#005bbf"
      d="M20.9 20.1c0-.3.3-.6.6-.6.4 0 .6.3.6.6s-.3.6-.6.6-.6-.3-.6-.6"
    />
    <path
      fill="#c8b100"
      d="M21.3 18.4v.3H21v.3h.3v1h-.4v.3h1.2l.1-.2-.1-.1h-.4v-1h.3v-.3h-.3v-.3z"
    />
    <path
      fill="#ed4c5c"
      d="M21.5 28.3c-1.6 0-3-.2-4.1-.5 1.1-.3 2.5-.5 4.1-.5 1.6 0 3 .2 4.1.5-1 .3-2.5.5-4.1.5"
    />
    <g fill="#fff">
      <path d="M21.6 45.6c-1.9 0-3.7-.5-5.3-1.2-1.2-.6-1.9-1.7-1.9-3v-4.8h14.4v4.8c0 1.3-.8 2.5-1.9 3-1.6.8-3.4 1.2-5.3 1.2M21.5 28.6h7.2v8h-7.2z" />
    </g>
    <path
      fill="#ed4c5c"
      d="M21.6 41.4c0 1.9-1.6 3.4-3.6 3.4s-3.6-1.5-3.6-3.4v-4.8h7.2v4.8"
    />
    <g fill="#c8b100">
      <path d="M15.9 44.2c.2.1.5.3.9.4v-8.2H16l-.1 7.8M14.3 41.3c0 1 .4 1.8.8 2.2v-7.1h-.8v4.9" />
    </g>
    <path fill="#c7b500" d="M17.5 44.8h.8v-8.4h-.8v8.4" />
    <path fill="#c8b100" d="M19.1 44.6c.3-.1.7-.3.9-.4v-7.8h-.8l-.1 8.2" />
    <path fill="#ed4c5c" d="M14.3 28.6h7.2v8h-7.2z" />
    <path fill="#c8b100" d="M20.8 43.5c.4-.3.7-1 .8-1.8v-5.2h-.8v7" />
    <g fill="#ed4c5c">
      <path d="M28.8 36.6v4.8c0 1.9-1.6 3.4-3.6 3.4s-3.6-1.5-3.6-3.4v-4.8h7.2M26.2 30c.3.6.3 2.1-.6 1.8.2.1.3.8.6 1.2.5.6 1.1.1 1-.6-.2-1.1-.1-1.8.1-2.9 0 .1.5.1.7-.1-.1.3-.2.7 0 .7-.2.3-.7.8-.8 1.1-.1.7 1 2-.2 2.3-.8.2-.3.8 0 1.1 0 0-.4 1.3-.2 1.2-.8.3-.6-.4-.6-.4.4-1.2-.7-1.3-.6-1.5-1-.1.1.9-.8.9-.2 0-.6.2-.6.2-1.1-.1-.5-1.1-.1-1 .3.1.6.6.6-.1 0 0-.5-.8.8-.8-.5 0-.8-.4-1-.9-.2.1-.5.6-1.6.7 0 0-.3-1.1 0-.9.4.2.6.2 1-.2-.2-.3-1.4-.7-1.2-1.4 0-.2.6-.5.6-.5-.1.5.2 1 .8 1 .8.1.5-.2.6-.4.1-.2.7.1.5-.4 0-.1-.7-.2-.5-.5.4-.5 1-.1 1.5.4M21.6 44.6l-.2-.5.2-.6.2.6-.2.5" />
    </g>
    <g fill="#c8b100">
      <path d="M16.5 30.3v.5h.2v.4h-.5v1h.3v2.2h-.6v1.1H20v-1.1h-.5v-2.2h.2v-1h-.5v-.4h.3v-.5h-1v.5h.2v.4h-.5V30h.3v-.5h-1.1v.5h.3v1.2h-.5v-.4h.2v-.5zM27.8 42.6v-5h-5.2v5l2.4 1.1h.3l2.5-1.1M25 38v1.7L23.3 38H25m-2.1.1 2 2-2 2v-4m.2 4.4 1.9-1.9v2.8l-1.9-.9m2.2.8v-2.8l1.9 1.9-1.9.9m2.1-1.2-2-2 2-2v4M25.3 38H27l-1.7 1.7V38" />
    </g>
    <path
      fill="#ed4c5c"
      d="M19.2 36.5c0-1.5 1-2.6 2.3-2.6s2.3 1.2 2.3 2.6c0 1.4-1 2.6-2.3 2.6s-2.3-1.1-2.3-2.6"
    />
    <path
      fill="#005bbf"
      d="M19.9 36.5c0-1.1.7-1.9 1.6-1.9.9 0 1.6.9 1.6 1.9 0 1.1-.7 1.9-1.6 1.9-.8.1-1.6-.8-1.6-1.9"
    />
    <g fill="#c8b100">
      <path d="m20.8 35.2-.4 1.1.3.1-.2.4h.6l-.2-.4.3-.1-.4-1.1M22.3 35.2l-.4 1.1.3.1-.2.4h.6l-.1-.4.3-.1-.5-1.1M21.6 36.5l-.5 1.1.3.1-.1.4h.5l-.1-.4.3-.1-.4-1.1" />
    </g>
  </svg>
)
export default SpainIconComponent
