import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//styles
import '../styles/featured_content.css';

//images
import elipse355 from '../../images/ellipse355.png';

//icons
import iconArrowRight from '../../images/arrow-right.svg';
import iconArrowRight2 from '../../images/arrow-right.svg';

const Featured_content = ({ userInfoSSFF, setSelectedArticle }) => {
  const [highlightedData, setHighlightedData] = useState([]);

  const navigate = useNavigate();

  // Obtener el CONTENIDO DESTACADO / ARTICULOS HIGHLIGHTED
  useEffect(() => {
    const fetchHighlightedArticlesData = async () => {
      try {
        const response = await fetch(`/highlightedArticles`, {
          method: 'GET',
        });
        const data = await response.json();

        if (response.ok) {
          // Filtrar y ordenar los datos por jurídica y artículo ordenado
          const filteredAndSortedData = data.highlightedData
            .filter((article) => article.legal_entity_id === userInfoSSFF.custom03 || article.legal_entity_id === 'default')
            .sort((a, b) => a.article_order - b.article_order)
            .slice(0, 4);

          // Asignar imágenes secuenciales a cada artículo
          const articlesWithImages = filteredAndSortedData.map((article, index) => ({
            ...article,
            localImage: `/images/featured_content/${index + 1}.jpg`,
          }));

          setHighlightedData(articlesWithImages);
        } else {
          console.error('Error fetching highlighted articles:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching highlighted articles:', error);
      }
    };

    fetchHighlightedArticlesData();
  }, [userInfoSSFF]);

  const handleHighlightedArticleClick = (article) => {
    setSelectedArticle({
      title: article.title,
      answer: article.content,
      id: article.article_id,
    });
    navigate('/featured/articles');
  };

  return (
    <>
      {/* Contenido Destacado */}
      <section className='featured__articles'>
        {/* Breadcrumb */}
        <div className='featured__articles--breadcrumb'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <h4 className='featured__articles--title'>CONTENIDO DESTACADO</h4>
        </div>
        <div className='featured__boxes'>
          {/* BBDD */}
          {highlightedData.map((article) => (
            <article className='featured__contentbox' key={article.id} onClick={() => handleHighlightedArticleClick(article)}>
              <div className='featured__contentbox__imgbox'>
                <img
                  className='featured__contentbox__imgbox--img'
                  src={article.localImage} // Usar imagen asignada localmente
                  alt={`article-${article.title}`}
                />
                <div className='featured__contentbox__imgbox--text'>
                  <h5 className='featured__contentbox__textbox--categorie'>{article.category_name}</h5>
                  <h4 className='featured__contentbox__textbox--title'>{article.title}</h4>
                </div>
                <div className='featured__contentbox__iconbox'>
                  <div className='iconWidth'>
                    <img src={iconArrowRight} className='featured__contentbox__iconbox--iconArrowRight' alt='arrow-icon' />
                    <img src={iconArrowRight2} className='featured__contentbox__iconbox--iconArrowRight' alt='arrow-icon' />
                  </div>
                </div>
              </div>
            </article>
          ))}
        </div>
      </section>
    </>
  );
};

export default Featured_content;
