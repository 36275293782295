import "../styles/roles.css";
import elipse355 from "../../images/ellipse355.png";
import searchIconHeader from "../../images/rolesPage-searcher.png";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

//imagenes grupos roles
import rolesImage1 from "../../images/roles-peoplex/rolesImage (1).png";
import rolesImage2 from "../../images/roles-peoplex/rolesImage (2).png";
import rolesImage3 from "../../images/roles-peoplex/rolesImage (3).png";
import rolesImage4 from "../../images/roles-peoplex/rolesImage (4).png";
import rolesImage5 from "../../images/roles-peoplex/rolesImage (5).png";
import rolesImage6 from "../../images/roles-peoplex/rolesImage (6).png";
import rolesImage7 from "../../images/roles-peoplex/rolesImage (7).png";
import rolesImage8 from "../../images/roles-peoplex/rolesImage (8).png";

const rolesImageMap = {
  CEO: rolesImage1,
  "Strategy & Planning": rolesImage2,
  "Product & Services": rolesImage3,
  "Sales & Customer Care": rolesImage4,
  "Service Knowledge Domains": rolesImage5,
  "Tech Development": rolesImage6,
  "Carrier Network & Infrastructure": rolesImage7,
  "Business Support": rolesImage8,
};

const Roles = ({
  categories,
  setSelectedSubcategory,
  setIsPageGroup,
  setSelectedRole,
  handleFilterSearcher,
  setHandleUpperCategoryIdParam,
  filterInSearcher,
  userInfoSSFF,
}) => {
  const [rolesPageSearcherPlaceholder, setRolesPageSearcherPlaceholder] = useState("");

  // Función que maneja el cambio en el campo de búsqueda
  const handleChange = (ev) => {
    ev.preventDefault();
    setRolesPageSearcherPlaceholder(ev.target.value);
  };

  //Inicializa la veriable "navigate" para cambiar la ruta de navegación
  const navigate = useNavigate();

  // Función que maneja la tecla Enter para iniciar la búsqueda
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Cambia a la ruta deseada
      handleFilterSearcher(event.target.value);
      setRolesPageSearcherPlaceholder("");
      setHandleUpperCategoryIdParam("138"); //(BusinessEX-40/ PeoplEX-133/ Roles-138)
      navigate("/search/articles");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); //Coloca el scroll en la parte superior cuando carga el componente
  }, []);

  return (
    <>
      <section className="rolesPage">
        <div className="rolesPage__breadcrumb">
          <img src={elipse355} alt="circle-icon" className="circle-icon" />
          <Link to="/knowledgepage" className="rolesPage__breadcrumb--title">
            INICIO {""}
          </Link>
          <span className="rolesPage__breadcrumb--text">&gt; ROLES</span>
        </div>
        <div className="rolesPage__intro">
          <div className="rolesPage__intro--info">
            <h2 className="intro__info--title">
              ¿Cómo se organizan los roles?
            </h2>
            <p className="intro__info--text">
              Para Telefónica es una prioridad el desarrollo de las capacidades
              de futuro para asegurar que tenemos el mejor talento, en el lugar
              adecuado, en el momento oportuno. <br />
              Conocer a qué se dedican las personas de cada equipo, qué
              habilidades tienen y cuáles son necesarias para desempeñar su
              función, nos permitirá conseguir este objetivo. <br /> Como pieza
              básica del modelo de capacidades se ha definido una arquitectura
              de perfiles estandarizados que llamamos "perfiles globales" y que
              vamos a implementar en GBUs identificando el perfil que
              corresponde a cada empleado. Esto aportará grandes beneficios
              tanto en materia de Workforce Planning para nuestros negocios como
              en materia de recomendación de formación y vacantes para nuestros
              empleados. <br /> Estos "perfiles globales" (aprox. 150) se
              agrupan en Grupos y Familias (actualmente 36) siguiendo criterios
              de similitud de funciones y habilidades necesarias.
            </p>
          </div>
          <div className="rolesPage__intro--search">
            <div className="rolesPage__searcherSection">
              <Link
                to="/search/articles"
                onClick={() => handleFilterSearcher(filterInSearcher)}
              >
                <img
                  src={searchIconHeader}
                  alt="search-icon"
                  className="rolesPage__searcher"
                />
              </Link>

              <input
                type="text"
                className="rolesPage__searcherInput"
                placeholder="Roles, familias, códigos..."
                autoComplete="off"
                value={rolesPageSearcherPlaceholder}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            {userInfoSSFF.custom15 === "Y" ? (
              <a
                className="rolesPage__button"
                href="https://static-or00.inbenta.com/797204f1e793f88891490fc56a0e4ab9ec7c471ce63956b466799a91a36d3a4f/Perfiles/Managers_Profiles.xlsx"
              >
                Catálogo Completo
              </a>
            ) : (
              <a
                className="rolesPage__button"
                href="https://static-or00.inbenta.com/797204f1e793f88891490fc56a0e4ab9ec7c471ce63956b466799a91a36d3a4f/Perfiles/Managers_Profiles.xlsx"
              >
                Catálogo Completo
              </a>
            )}
          </div>
        </div>
        <div className="rolesPage__content">
          <h1 className="rolesPage__title">Grupos</h1>

          <div className="rolesSubcategories__all">
            {categories
              .filter((category) => category.parent === "138")
              .map((group) => (
                <Link
                  to="/roles/groups"
                  onClick={() =>
                    setSelectedRole({ id: group.id, name: group.name })
                  }
                >
                  <div className="rolesSubcategories">
                    <div className="rolesSubcategories__imageContent">
                      <img
                        src={rolesImageMap[group.name]}
                        alt="roles"
                        className="rolesSubcategories__image"
                      />
                    </div>
                    <div className="rolesSubcategories__textContent">
                      <h1 className="rolesSubcategories__groupTitle">
                        {group.name}
                      </h1>
                      <div className="rolesSubcategories__description">
                        <ul className="rolesSubcategories__list">
                          {categories
                            .filter((category) => category.parent === group.id)
                            .map((role) => (
                              <Link to="/subcategories/articles">
                                <li
                                  className="rolesSubcategories__li"
                                  onClick={() => {
                                    setIsPageGroup(true);
                                    setSelectedSubcategory({
                                      name: role.name,
                                      id: role.id,
                                      parent: group.name,
                                    });
                                  }}
                                >
                                  {role.name}
                                </li>
                              </Link>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Roles;
