import React, { useState} from "react";
import AddIcon from "../../assests/mainPageComponent/sideBard/AddIcon";
import DeleteIcon from "../../assests/mainPageComponent/sideBard/DeleteIcon";
import '../Styles/sideBar.css'
import 'react-toastify/dist/ReactToastify.css';
import AddEmployeeModal from "./AddEmployeeModal";
import { fetchClusterEmployeeIds } from "../../../services/TalentCards/FetchClusterEmployeeIds ";
import Select from 'react-select';
import CryptoJS from 'crypto-js';



const clusterOptions = [
 /*  { value: 'default', label: 'Selección' }, */
  { value: 'cluster_ceo', label: 'CEO' },
  { value: 'cluster_b2c_growth', label: 'B2C Growth' },
  { value: 'cluster_b2b_growth', label: 'B2B Growth' },
  { value: 'cluster_technology', label: 'Technology' },
  { value: 'cluster_enablers', label: 'Enablers' },
  { value: 'cluster_pm_transversal', label: 'Transversal' },
];


const Sidebar = ({  onDelete, onClusterEmployeeIdChange,
  isAdding, userInfoSSFF,
  updateEmployeeListAfterAdd,
  selectedOB,  
  setSelectedOB,
  obOptions,userPermissions,
  empleadosList,
  empleadosDeleteList, idToken, jwtSSFF
  }) => {
    
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [selectedClusters, setSelectedClusters] = useState([]);
   
    const handleAddClick = () => {
      if(userPermissions.edit_talent_cards){
        setIsAddModalOpen(true);
      }
    };
 
    const handleCloseAddModal = () => {
      setIsAddModalOpen(false);
    };
 
  
    const secretKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyZ+9lyNZhaTreOLckdL1xCcDH239iceMybexkQcv1A+goLBJiaOf6OXl0XqIjOOTNvoEQyLpAn907jdS+z46dqzOOHFq1PdSu2gSHT9PSmzHSSshOboS0tEVGRdq+QAMN/+L4IycXw+pkXAWfGm+DT6Km028qY0x+5GYsztlCMqDyzYde91FdU0ceV7fePCdtSkPM4Cj47ru9/tqBPadkpEezb7ruoobQ3oCDRWi0vYiS1i18lDJWgf667627tJy4/GD5Br4XYFJ448Ntp4RT9mrekpkIafHZ2CP80hX4ug140UmSmM0bQfBqnUsRTm1ftJtuHHyBVOb5GG/pJfkFwIDAQAB'; 

    // Manejar el cambio en el dropdown de clusters (multiselección)
    const handleClusterChange = async (selectedOptions) => {
      setSelectedClusters(selectedOptions || []); // Actualizar estado interno

      if (selectedOptions && selectedOptions.length > 0) {
        const encryptedClusters = await Promise.all(
          selectedOptions.map(async (option) => {
            const encrypted = CryptoJS.AES.encrypt(option.value, secretKey).toString();
            return encodeURIComponent(btoa(encrypted));
          })
        );

        try {
          const idsPromises = encryptedClusters.map((encryptedCluster) =>
            fetchClusterEmployeeIds(encryptedCluster, idToken)
          );
          const idsArray = await Promise.all(idsPromises);
          const allIds = idsArray.flat(); // Combinar todos los IDs en un solo array
          onClusterEmployeeIdChange(allIds, true);
        } catch (error) {
          console.error('Error al obtener los IDs de empleados:', error);
          onClusterEmployeeIdChange([], false);
        }
      } else {
        onClusterEmployeeIdChange([], false); // Limpiar si no hay clusters seleccionados
      }
    };

    // Manejar el cambio en el dropdown de OBs
    const handleOBChange = (selectedOption) => {
      setSelectedOB(selectedOption || []); 
    };

    

    return (
      <div className="sidebarContainer-big d-flex flex-column flex-shrink-0 p-3" >
        <div className="sidebarContainer-small row justify-content-start">
          <div className="sidebar-buttons col-4">
            <ul className="nav nav-pills flex-column mb-auto">
              <li className="nav-item">
                <button className="add nav-link text-black" onClick={handleAddClick} disabled={isAdding}>
                  <AddIcon className="add-icon" />
                </button>
              </li>
              <li>
                <button className="delete nav-link text-black" onClick={onDelete}>
                  <DeleteIcon className="delete-icon"  />
                </button>
              </li>
            </ul>
          </div>
          <div className="nav col-4">
            <div className="col-md-4">
              <div className="dropdown p-3 shadow rounded-4">
                <div className="mb-3">
                  <label className="ob-title form-label text-primary mb-0 fs-5">OB</label>
                  <Select
                    isMulti
                    options={obOptions}
                    className="basic-multi-select fst-italic"
                    classNamePrefix="select"
                    placeholder="Selección..."
                    value={selectedOB}
                    onChange={handleOBChange}
                    menuPosition="fixed"
                    menuPortalTarget={document.body}
                    components={{
                      ClearIndicator: () => null,
                    }}
                   
                  />
                </div>
                <div>
                  <label className="cluster-title form-label text-primary mb-0 fs-5">Cluster</label>
                  <Select
                    isMulti
                    options={clusterOptions}
                    className="basic-multi-select fst-italic "
                    classNamePrefix="select"
                    placeholder="Selección..." 
                    value={selectedClusters} 
                    onChange={handleClusterChange}
                    menuPosition="fixed"
                    menuPortalTarget={document.body}
                    components={{
                      ClearIndicator: () => null,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddEmployeeModal
          isOpen={isAddModalOpen}
          onClose={handleCloseAddModal}
          userInfoSSFF={userInfoSSFF}
          updateEmployeeListAfterAdd={updateEmployeeListAfterAdd}
          empleadosList={empleadosList}
          empleadosDeleteList={empleadosDeleteList}
          idToken={idToken}
          jwtSSFF = {jwtSSFF}
        />  
      </div>
    );
};


export default Sidebar;




