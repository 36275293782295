import React from 'react';

const GeneralInfoPeople = ({ formData, showEffectiveDateInfo, showEffectiveDateRequired, handleInputChange }) => {
  return (
    <div>
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='name'>
          Nombre y apellidos del manager solicitante{' '}
        </label>
        <input type='text' className='formJiraChangeInputText' value={formData.managerName} readOnly />
      </div>
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='username'>
          Email del manager solicitante
        </label>
        <input type='email' className='formJiraChangeInputText' value={formData.managerEmail} readOnly />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='employeeName'>
          {' '}
          Nombre y apellidos del empleado{' '}
        </label>
        <input type='text' className='formJiraChangeInputText' value={formData.employeeName} readOnly />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='employeeEmail'>
          {' '}
          Email del empleado{' '}
        </label>
        <input type='text' className='formJiraChangeInputText' value={formData.employeeEmail} readOnly />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='employeeSSFFId'>
          {' '}
          Id del empleado{' '}
        </label>
        <input type='text' className='formJiraChangeInputText' value={formData.employeeSSFFId} readOnly />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='employeeRegistrationId'>
          {' '}
          Matrícula del empleado{' '}
        </label>
        <input type='text' className='formJiraChangeInputText' value={formData.employeeRegistrationId} readOnly />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='employeeLegalEntity'>
          {' '}
          Compañía{' '}
        </label>
        <input type='text' className='formJiraChangeInputText' value={formData.employeeLegalEntity} readOnly />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='effectiveDate'>
          Fecha
          {showEffectiveDateRequired && <p className='formJiraChangeLabel__required'>Este es un campo requerido</p>}
        </label>
        <input
          type='date'
          placeholder='fecha'
          className='formJiraChangeInputText_short'
          value={formData.effectiveDate}
          onChange={(e) => handleInputChange && handleInputChange('effectiveDate', e.target.value)}
        />
        {showEffectiveDateInfo && <p className='error'>Estas seleccionando una fecha anterior a la actual</p>}
      </div>
    </div>
  );
};

export default GeneralInfoPeople;
