import React, { useState, useEffect, useRef } from 'react';
import LeadershipTable from './LeaderShipTable';
//import LogoSummary from "../../assests/FileComponentes/iconsComponent/LogoSummary";
import SpainComponent from '../../assests/FileComponentes/countries/SpainComponent';
import GermanyComponent from '../../assests/FileComponentes/countries/GermanyComponent';
import UruguayComponent from '../../assests/FileComponentes/countries/UruguayComponent';
import ArgentinaIconComponent from '../../assests/FileComponentes/countries/ArgentinaIconComponent';
import ChileIconComponent from '../../assests/FileComponentes/countries/ChileIconComponent';
import ColombiaIconComponent from '../../assests/FileComponentes/countries/ColombiaIconComponent';
import EcuadorIconComponent from '../../assests/FileComponentes/countries/EcuadorIconComponent';
import PeruIconComponent from '../../assests/FileComponentes/countries/PeruIconComponent';
import BrasilIconComponent from '../../assests/FileComponentes/countries/BrasilIconComponent';
import UkIconComponent from '../../assests/FileComponentes/countries/UkIconComponent';
import MexicoIconComponent from '../../assests/FileComponentes/countries/MexicoIconComponent';
import SouthIconComponent from '../../assests/FileComponentes/countries/SouthIconComponent';
import SiIconComponent from '../../assests/FileComponentes/countries/SiIconMovilityComponent';
import NoIconComponent from '../../assests/FileComponentes/countries/NoIconMovilityComponent';
import EuropeComponent from '../../assests/FileComponentes/countries/EuropeIconComponent';
//import MexicoIconComponent from "../../assests/FileComponentes/countries/MexicoIconComponent";
import globalStyles from '../../Styles/globalStyle';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Styles/SecondTalentView/leaderShip.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { updateEmployee } from '../../../services/TalentCards/FetchUpdateEmployee';
import LeadershipModal from '../Components/LeaderShipModal';
import CryptoJS from 'crypto-js';

const styleCSO = {
  fontSize: ' 1.125em',
  marginBottom: '10px',
};

const countryComponents = {
  Spain: SpainComponent,
  Madrid: SpainComponent,
  'Distrito telefónica-s1': SpainComponent,
  'Ger-1156-01 münchen georg-brauchle-ring 50': GermanyComponent,
  Germany: GermanyComponent,
  Uruguay: UruguayComponent,
  Argentina: ArgentinaIconComponent,
  Chile: ChileIconComponent,
  Colombia: ColombiaIconComponent,
  Ecuador: EcuadorIconComponent,
  Peru: PeruIconComponent,
  Brazil: BrasilIconComponent,
  UK: UkIconComponent,
  Mexico: MexicoIconComponent,
  Hispam: SouthIconComponent,
  SI: SiIconComponent,
  YES: SiIconComponent,
  NO: NoIconComponent,
  Europe: EuropeComponent,
};

function LeadershipComponent({
  empleadoDetails,
  empleado,
  selectedEmployeeId,
  userInfoSSFF,
  onUpdateEmployeeDetails,currentUserEmail,
  userPermissions,idToken,
  jwtSSFF
}) {

  const secretKey = process.env.REACT_APP_TALENT_APP;
  
  const encrypted = CryptoJS.AES.encrypt(selectedEmployeeId, secretKey).toString();
  const encryptedSelectedId = encodeURIComponent(btoa(encrypted));

  //const userId = userInfoSSFF.userId;
  //Inicializacion de variables
  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState({
    skillset_overview: '',
  });
  const [editingField, setEditingField] = useState(null);
  const [tempText, setTempText] = useState('');
  const [prevText, setPrevText] = useState('');
  const [prevSkills, setPrevSkills] = useState([]);
  const [editingSkillIndex, setEditingSkillIndex] = useState(null);
  const [isModalLeadershipOpen, setIsModalLeadershipOpen] = useState(false);

  const skillRefs = useRef([]);

  useEffect(() => {
    if (empleadoDetails) {
      setFormData({
        skillset_overview: empleadoDetails.skillset_overview || ' ',
      });
      setPrevSkills(
        empleadoDetails.skillset_overview
          ? empleadoDetails.skillset_overview.split('\n')
          : []
      );
    } else {
      setFormData({
        skillset_overview: ' ',
      });
      setPrevSkills([]);
    }
  }, [empleadoDetails, selectedEmployeeId]);


  const handleBlur = async (field, ref, index = null) => {
    let value = tempText.trim();

    // Validar si el campo está vacío
    if (!value) {
      toast.error('El campo no puede estar vacío.');
      if (ref && ref.current) {
        ref.current.innerText = index !== null ? prevSkills[index] : prevText;
      }
      setEditable(false);
      setEditingField(null);
      return;
    }

    // Validar si el campo no puede contener números (excepto para job_title)
    if (field !== 'job_title' && /\d/.test(value)) {
      toast.error('El campo no puede contener números en el nombre.');
      if (ref && ref.current) {
        ref.current.innerText = index !== null ? prevSkills[index] : prevText;
      }
      setEditable(false);
      setEditingField(null);
      return;
    }

    // Mostrar confirmación solo si pasa las validaciones
    confirmAlert({
      title: '¿Seguro?',
      message: '¿Estás seguro de modificar esto?',
      buttons: [
        {
          label: 'Sí',
          onClick: async () => {
            let updatedData = { ...empleadoDetails };

            if (field === 'skillset_overview' && index !== null) {
              const updatedSkills = updatedData.skillset_overview.split('\n');
              updatedSkills[index] = value;
              updatedData.skillset_overview = updatedSkills.join('\n');
            } else {
              updatedData[field] = value;
            }

            try {
              await updateEmployee(encryptedSelectedId,updatedData,idToken,jwtSSFF);
              if (ref && ref.current) {
                ref.current.innerText = value;
              }

              onUpdateEmployeeDetails(updatedData);

              if (field === 'skillset_overview' && index !== null) {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  skillset_overview: updatedData.skillset_overview,
                }));
                setPrevSkills(updatedData.skillset_overview.split('\n'));
              } else {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  [field]: value,
                }));
              }
              // Restablecer estados
              setEditable(false);
              setEditingField(null);
            } catch (error) {
              console.error('Error updating employee:', error);
              toast.error('Error al actualizar los datos del empleado.');
              if (ref && ref.current) {
                ref.current.innerText =
                  index !== null ? prevSkills[index] : prevText;
              }
              // Restablecer estados
              setEditable(false);
              setEditingField(null);
            }
          },
        },
        {
          label: 'No',
          onClick: () => {
            if (ref && ref.current) {
              ref.current.innerText =
                index !== null ? prevSkills[index] : prevText;
            }
            // Restablecer estados
            setEditable(false);
            setEditingField(null);
          },
        },
      ],
    });
  };

  const handleDoubleClick = (field, ref, index = null) => {
    if (userPermissions.edit_talent_cards) {
      setEditable(true);
      setEditingField(field);
      setEditingSkillIndex(index);
      if (index !== null) {
        setTempText(formData[field].split('\n')[index]);
        setPrevText(formData[field].split('\n')[index]);
      } else {
        setTempText(formData[field]);
        setPrevText(formData[field]);
      }
      setTimeout(() => {
        if (ref && ref.current) {
          ref.current.focus();
        }
      }, 0);
    }
  };

  const handleChange = (e) => {
    setTempText(e.target.innerText);
  };

  const handleOpenLeadershipModal = () => {
    if (userPermissions.edit_talent_cards) {
      setIsModalLeadershipOpen(true);
    }
  };

  const handleCloseLeadershipModal = () => {
    setIsModalLeadershipOpen(false);
  };

  const handleSaveLeadershipData = (updatedData) => {
    const updatedEmployeeData = {
        ...empleadoDetails,
        leadership_impact: updatedData.leadership_impact,
        leadership_collaboration: updatedData.leadership_collaboration,
        leadership_transformation: updatedData.leadership_transformation,
        leadership_growth: updatedData.leadership_growth,
    };

    updateEmployee(encryptedSelectedId, updatedEmployeeData, idToken,jwtSSFF)
        .then(() => {
            onUpdateEmployeeDetails(updatedEmployeeData); 
            toast.success(('Datos de liderazgo actualizados correctamente.'), {
              autoClose: 100, 
            });
            
        })
        .catch((error) => {
            console.error('Error updating leadership data:', error);
            toast.error('Error al actualizar los datos de liderazgo.');
        });
};

  const position = empleado.empInfo?.jobInfoNav?.results?.[0]?.jobTitle || '';

  const location = empleadoDetails?.country;

  const LogoSummary =
  location && countryComponents[location]
      ? countryComponents[location]
      : null;

  return (
    <div className="ms-4 leadershipcomponent-container">
      {/* Llamada al componente LeadershipTable */}
      <div className="d-flex align-items-center justify-content-start  employee-details ">
        {LogoSummary && (
          <LogoSummary className="logo-summary-leadership rounded-circle ms-1" />
        )}

        <h1 className="employee-title fs-4 ms-2 " style={globalStyles.color}>
          {empleado.displayName}
        </h1>
      </div>

      <h2 className="job-title mt-1 fs-5">{position}</h2>

      <div className="leadership-container ">
        <h4
          className="experience mt-3 fs-3 mb-4 leaderShip-title "
          style={globalStyles.color}
        >
          Leadership
        </h4>

        {/*Tabla LaderShip*/}
        {selectedEmployeeId && (
          <LeadershipTable
            leadershipData={empleadoDetails}
            onClick={handleOpenLeadershipModal}
          />
        )}
        <LeadershipModal
          isOpen={isModalLeadershipOpen}
          leadershipData={empleadoDetails}
          onSave={handleSaveLeadershipData}
          onClose={handleCloseLeadershipModal}
        />


        <p
          className="experience mt-3 fs-3 mb-4 skillset-title"
          style={globalStyles.color}
        >
          Critical skillset overview
        </p>
        <div className="mb-2 skill-table">
          {selectedEmployeeId &&
            empleadoDetails &&
            empleadoDetails.skillset_overview && (
              <ul className="list-unstyled">
                {empleadoDetails.skillset_overview
                  .split('\n')
                  .map((skill, index) => (
                    <li
                      key={index}
                      style={styleCSO}
                      ref={(el) => (skillRefs.current[index] = el)}
                      contentEditable={
                        editable &&
                        editingField === 'skillset_overview' &&
                        editingSkillIndex === index
                      }
                      onBlur={() =>
                        handleBlur(
                          'skillset_overview',
                          skillRefs.current[index],
                          index
                        )
                      }
                      onInput={handleChange}
                      onDoubleClick={() =>
                        handleDoubleClick(
                          'skillset_overview',
                          skillRefs.current[index],
                          index
                        )
                      }
                      suppressContentEditableWarning={true}
                    >
                      {skill.split('\\n').map((line, i) => (
                        <span key={i} style={{ display: 'block' }}>
                          {line}
                        </span>
                      ))}
                    </li>
                  ))}
              </ul>
            )}
        </div>
        <ToastContainer />
      </div>
    </div>
    
  );
}

export default LeadershipComponent;
