import '../../../styles/footer.css';
import { Link } from 'react-router-dom';
import tfoLogo from '../../../images/logo-tfo.svg';

const FooterTalent = () => {
  return (
    <section className="footer">
      <div className="footer__container">
        <div className="footer__logos">
          <img src={tfoLogo} alt="telefonica-logo" className="tfo__logo" />
        </div>
        <ul className="footer__links">
          <Link
            to="https://www.telefonica.com/es/telefonica-accesible/"
            target="_blank"
            className="footer__links--url"
          >
            Accesibilidad
          </Link>
          <Link
            to="https://www.telefonica.com/es/aviso-legal/"
            target="_blank"
            className="footer__links--url"
          >
            Aviso Legal
          </Link>
          <Link
            to="https://www.telefonica.com/es/politica-de-privacidad/"
            target="_blank"
            className="footer__links--url"
          >
            Política de Privacidad
          </Link>
        </ul>
      </div>
    </section>
  );
};

export default FooterTalent;
