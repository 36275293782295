
import React from 'react';
import ArrowComponentMobile from '../../assests/MobileIcons/ArrowComponentMobile';
import "../Styles/clusterMobile.css"

const ClusterComponentMobile = ({  positions,empleadoDetails }) => {
  
  return (
    <div className="cluster-mobile">
      {positions.length <= 2 ? (
        positions.map(position => (
          <svg key={position.key} className="svg-Style-mobile me-1">
            <rect
              x="0"
              y="0"
              width="160"
              height="50"
              className="rect-style-mobile"
            />
            <g className='arrow-position-initial'/* transform="translate(27,7)" */>
              <ArrowComponentMobile  />
            </g>
            <text x="50%" y="50%" className="position-SVG-mobile " dominantBaseline="middle" textAnchor="middle" 
            style={{fill: "#007bff"}}>
              {position.label}
            </text>
          </svg>
        ))
      ) : (
        <div className="circles-container-mobile mt-3  ">
          {positions.map(position => (
            <div key={position.key} className="circle-mobile text-primary">
              {position.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );

};


export default ClusterComponentMobile;
