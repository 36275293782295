import React from "react";
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import LeadershipYesPDF from "../../../assests/FileComponentes/PDFIcons/LeadershipYesPDF";
import LeadershipNoPDF from "../../../assests/FileComponentes/PDFIcons/LeadershipNoPDF";
import '../../Styles/SecondTalentView/leaderShipTable.css';

const styles = StyleSheet.create({
  leaderShipTable: {
    marginBottom: 10,
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 5,
    paddingTop: 5,
  },
  titleCell: {
    width: '60%',
    textAlign: 'left',
    fontFamily: 'Helvetica',
    fontSize: 12,
  },
  tickCell: {
    width: '40%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tickIcon: {
    marginLeft: 10,
  },
  footer: {
    flexDirection: 'right',
    textAlign: 'right',
    fontSize: 8,
  },
});

function LeadershipTable({ leadershipData }) {
  // Función para renderizar los componentes de tick según la cantidad
  const renderTicks = (count) => {
    const ticks = [];
    for (let i = 0; i < 3; i++) {
      ticks.push(
        i < count ? (
          <LeadershipYesPDF key={i} style={styles.tickIcon} />
        ) : (
          <LeadershipNoPDF key={i} style={styles.tickIcon} />
        )
      );
    }
    return ticks;
  };

  return (
    <View style={styles.leaderShipTable}>
      <View style={styles.row}>
        <Text style={styles.titleCell}>Impact & Execution for results</Text>
        <View style={styles.tickCell}>
          {renderTicks(leadershipData.leadership_impact)}
        </View>
      </View>
      <View style={styles.row}>
        <Text style={styles.titleCell}>Collaborative</Text>
        <View style={styles.tickCell}>
          {renderTicks(leadershipData.leadership_collaboration)}
        </View>
      </View>
      <View style={styles.row}>
        <Text style={styles.titleCell}>Transformative</Text>
        <View style={styles.tickCell}>
          {renderTicks(leadershipData.leadership_transformation)}
        </View>
      </View>
      <View style={styles.row}>
        <Text style={styles.titleCell}>Growth</Text>
        <View style={styles.tickCell}>
          {renderTicks(leadershipData.leadership_growth)}
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.lagging}>Lagging   On Track   Exceed</Text>
      </View>
    </View>
  );
}

export default LeadershipTable;

