
export const updateEmployee = async (user_id, updatedData,idToken,jwtSSFF) => {

  try {
    const response = await fetch(`/employeeUpdate/${user_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-msal-Authorization': `Bearer ${idToken}`,
        'x-jwt-Authorization': `Bearer ${jwtSSFF}`,
      },
      body: JSON.stringify(updatedData),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error al actualizar el empleado con ID ${user_id}:`, error);
    throw error;
  }
};