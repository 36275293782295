import React from 'react';
import { Svg, Path } from '@react-pdf/renderer';

const PersonPDFComponent = () => (
  <Svg width={16} height={16} viewBox="0 0 30 30" fill="#F2F4FF">
    <Path
      fill="#F2F4FF"
      d="M11.572 5.796c0 1.936 1.17 3.095 3.124 3.095 1.96 0 3.126-1.155 3.123-3.095 0-.99-.29-1.768-.858-2.31-.546-.526-1.31-.792-2.269-.792-.956 0-1.719.266-2.265.791-.567.546-.855 1.32-.855 2.311Zm1.916-1.225c.252-.246.658-.368 1.208-.368.55 0 .959.122 1.215.368.259.252.392.665.392 1.229 0 1.11-.48 1.586-1.607 1.586-1.12 0-1.6-.477-1.6-1.586 0-.568.133-.98.392-1.23Zm6.768 15.308a1.897 1.897 0 0 0 1.348-2.322l-1.429-5.206a1.288 1.288 0 0 0-.073-.235c-.357-1.614-1.82-2.78-3.498-2.78H13.4c-1.677 0-3.144 1.166-3.501 2.784a.961.961 0 0 0-.06.182L8.4 17.564a1.898 1.898 0 0 0 1.341 2.315 1.901 1.901 0 0 0 1.478-.195l.024-.015v5.742c0 1.064.778 1.866 1.807 1.866 1.026 0 1.768-.784 1.768-1.866v-5.567h.35v5.567c0 1.078.77 1.894 1.793 1.894 1.026 0 1.8-.816 1.8-1.894v-5.742a.198.198 0 0 0 .028.016c.007.004.014.007.02.012.449.255.967.318 1.447.182Zm-.123-1.933a.391.391 0 0 1-.28.48.45.45 0 0 1-.295-.031h-.002c-.053-.032-.144-.099-.165-.169l-.648-3.547c-.07-.382-.42-.637-.816-.616a.757.757 0 0 0-.69.75v10.601c0 .088-.02.385-.28.385-.252 0-.273-.294-.273-.385v-6.32a.755.755 0 0 0-.76-.753h-1.87a.755.755 0 0 0-.759.753v6.32c0 .361-.178.361-.248.361-.239 0-.288-.196-.288-.36V14.811a.757.757 0 0 0-.693-.75.753.753 0 0 0-.816.617l-.633 3.484a.368.368 0 0 1-.18.228.377.377 0 0 1-.297.035.39.39 0 0 1-.273-.473l1.44-5.263a.913.913 0 0 0 .055-.164.262.262 0 0 0 .014-.056A2.068 2.068 0 0 1 13.4 10.84h3.204a2.064 2.064 0 0 1 2.027 1.655c.003.009.006.018.007.026.014.063.032.12.06.175l1.435 5.249Z"
    />
  </Svg>
);

export default PersonPDFComponent;
