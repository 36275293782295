import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { getUserInfoSSFF } from '../../services/apiSSFF';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useCallback, useContext, useEffect, useState } from 'react';

import { TeamsFxContext } from '../../Context';

const ProtectedRoutes = ({ setUserInfoSSFF, setLoggedIn, loggedIn, setLoggedInDisplayName }) => {
  const cloudDeploy = (process.env.REACT_APP_CLOUD_DEPLOY || '').trim();
  const isProduction = cloudDeploy === 'PRE' || cloudDeploy === 'PRO';

  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const isAuthenticatedMSAL = useIsAuthenticated();
  const [idToken, setIdToken] = useState(localStorage.getItem('idToken'));
  // const idToken = localStorage.getItem('idToken');

  const juridicas = ['0250_ESP', '0024', '1343', '1583', '1412', '1702', '1893', '1970', '2205', '12NL', '2113', '0016', '1017'];

  const setUser = useCallback(
    (user) => {
      setLoggedInDisplayName(user.displayName);
    },
    [setLoggedInDisplayName]
  );

  // Load username either from TeamsFx or MSAL
  useEffect(() => {
    if (isProduction) {
      // Get username either with Teams auth or MSAL auth
      if (teamsUserCredential) {
        teamsUserCredential
          .getUserInfo()
          .then((userInfo) => {
            setUser({
              displayName: userInfo.displayName,
            });
          })
          .catch((error) => {
            console.error('ProtectedRoutes: Error getting Teams user info: ', error);
          });

        teamsUserCredential
          .getToken([])
          .then((ssoToken) => {
            if (ssoToken) {
              localStorage.setItem('idToken', ssoToken.token);
              setIdToken(ssoToken.token);
            }
          })
          .catch((error) => {
            console.error('Error getting SSO token:', error);
          });
      } else {
        if (isAuthenticatedMSAL) {
          const account = instance.getAccountByUsername(accounts[0].username);
          const idToken = account?.idToken;

          if (idToken) {
            localStorage.setItem('idToken', idToken);
            setIdToken(idToken);
          }
        } else {
          console.debug('ProtectedRoutes: No TeamsFx context and no user logged into MSAL yet...');
        }
      }
    }
    setUserLS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsUserCredential, isAuthenticatedMSAL, accounts, instance, setUser, idToken]);

  const setUserLS = async () => {
    if (localStorage.getItem('idToken')) {
      getUserInfoSSFF()
        .then((userInfo) => {
          //const { userInformation, token } = userInfo;
          const { userInformation, token } = userInfo;
          if (userInformation.d.results.length !== 0) {
            setUserInfoSSFF(userInformation.d.results[0]);
            localStorage.setItem('jwtSSFFToken', token);
            if (juridicas.includes(userInformation.d.results[0].custom03)) {
              setLoggedIn(true);
            } else {
              console.warn('ProtectedRoutes: Usuario no tiene aceso...');
              navigate('/');
            }
          }
        })
        .catch((err) => {
          console.error('ProtectedRoutes: Error calling SSFF');
          console.error(err);
        });
    } else {
      console.log('ProtectedRoutes: No TeamsFx context and no user logged into MSAL yet.');
    }
  };

  if (!teamsUserCredential && !isAuthenticatedMSAL && !idToken) {
    console.debug('REDIRECT: ProtectedRoutes: Not in Teams, authenticated with MSAL or logged in. Returning to root.');
    return <Navigate to='/' />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
