import * as React from "react"

const LeadershipNoComponent = (props) => (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      fill="none"
      viewBox="0 0 30 30"
      {...props}
    >
      <path
        fill="#06F"
        d="M14.996 30c-4.767 0-8.473-1.244-11.016-3.697C1.338 23.76 0 19.96 0 15.006 0 10.053 1.338 6.25 3.98 3.701 6.523 1.248 10.234 0 14.996 0c4.766 0 8.472 1.244 11.02 3.697C28.662 6.244 30 10.048 30 15.002s-1.342 8.754-3.984 11.3C23.468 28.757 19.762 30 14.996 30Zm0-28.337c-8.849 0-13.337 4.487-13.337 13.343 0 8.848 4.488 13.336 13.337 13.336 8.853 0 13.345-4.488 13.345-13.336 0-8.851-4.492-13.343-13.345-13.343Z"
      />
    </svg>
  )
export default LeadershipNoComponent
