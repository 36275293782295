import React, { useState } from 'react';
import '../styles/loginComponent.css';
import { useNavigate } from 'react-router-dom';
import { getUserInfoSSFF } from '../services/apiSSFF';

const LoginComponent = ({ setUserInfoSSFF }) => {
  const isProduction = false;
  const [selectedUser, setSelectedUser] = useState('');
  const navigate = useNavigate();

  const juridicas = ['0250_ESP', '0024', '1343', '1583', '1412', '1702', '1893', '1970', '2205', '12NL', '2113', '0016', '1017'];
  const callSSFF = async (idToken) => {
    const userSSFF = await getUserInfoSSFF(idToken);
    const { token } = userSSFF;

    if (userSSFF.userInformation.d.results.length !== 0) {
      setUserInfoSSFF(userSSFF.userInformation.d.results[0]);
      localStorage.setItem('jwtSSFFToken', token);
      if (juridicas.includes(userSSFF.userInformation.d.results[0].custom03)) {
        if (userSSFF.userInformation.d.results[0].custom03 === '12NL' || userSSFF.userInformation.d.results[0].custom03 === '2113') {
          navigate('/canalatencion');
        } else {
          navigate('/home');
        }
      }
    }
  };
  const handleLogin = async () => {
    if (!isProduction) {
      try {
        const response = await fetch('/generateFakeToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: selectedUser,
          }),
        });
        if (!response.ok) {
          throw new Error('Failed to fetch fake token');
        }
        let idToken = await response.json();
        localStorage.setItem('idToken', idToken);
        callSSFF(idToken);
      } catch (error) {
        console.error('Error fetching fake token:', error);
        throw new Error('Error fetching fake token');
      }
    }
  };

  return (
    <>
      <div>
        <h2>Selecciona user para entrar</h2>
        <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
          <option value='' disabled>
            Selecciona un usuario
          </option>
          <option value='gabriel.cruzb@telefonica.com'>Gabriel Cruz</option>
          <option value='nayra.romerosalas@telefonica.com'>Nayra Romero</option>
          <option value='pablo.navarrofh@telefonica.com'>Pablo Navarro</option>
          <option value='irene.serranosebastian@telefonica.com'>Irene Serrano</option>
          <option value='marcos.azabachesuarez.practicas@telefonica.com'>Marcos Azabache</option>
          <option value='asuncion.lopez@telefonica.com'>Asunción Lopez</option>
          <option value='adriana.bermeojaramillo@telefonica.com'>Adriana Bermejo</option>
          <option value='sonia.casadobarrero@telefonica.com'>Sonia</option>
          <option value='cristian.roibu@telefonica.com'>Cristian</option>
          <option value='david.alonsogarcia@telefonica.com'>David Alonso</option>
          <option value='carla.martinezcastellet@telefonica.com'>Carla</option>
          <option value='jesus.figueroafernandez.ext@telefonica.com'>Viewnext Jesus</option>
          <option value='ursulaana.demenaperez.ext@telefonica.com'>Viewnext ursula</option>
          <option value='susana.juradoapruzzese@telefonica.com'>susana jurado</option>
          <option value='annellysse.chacon@telefonica.com'>annellysse.chacon@telefonica.com</option>
          <option value='mariajose.navarromaillo@telefonica.com'>telfisa</option>
        </select>
        <button onClick={() => handleLogin()}>Iniciar sesión</button>
      </div>
    </>
  );
};

export default LoginComponent;
