import { useState, useCallback } from 'react';
import CryptoJS from 'crypto-js';

const secretKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyZ+9lyNZhaTreOLckdL1xCcDH239iceMybexkQcv1A+goLBJiaOf6OXl0XqIjOOTNvoEQyLpAn907jdS+z46dqzOOHFq1PdSu2gSHT9PSmzHSSshOboS0tEVGRdq+QAMN/+L4IycXw+pkXAWfGm+DT6Km028qY0x+5GYsztlCMqDyzYde91FdU0ceV7fePCdtSkPM4Cj47ru9/tqBPadkpEezb7ruoobQ3oCDRWi0vYiS1i18lDJWgf667627tJy4/GD5Br4XYFJ448Ntp4RT9mrekpkIafHZ2CP80hX4ug140UmSmM0bQfBqnUsRTm1ftJtuHHyBVOb5GG/pJfkFwIDAQAB'; 

export const useDecryptUserId = () => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const decryptUserId = useCallback(async (encryptedUserId) => {
        try {
            if (!encryptedUserId) {
                throw new Error('No se recibió un ID cifrado.');
            }

            setLoading(true);
            setError(null);

            // Decodificar Base64 y desencriptar
            const decoded = decodeURIComponent(encryptedUserId);
            const base64Decoded = atob(decoded);
            const bytes = CryptoJS.AES.decrypt(base64Decoded, secretKey);
            const decryptedUserId = bytes.toString(CryptoJS.enc.Utf8);

            if (!decryptedUserId) {
                throw new Error('El desencriptado retornó un valor vacío o inválido.');
            }

            return decryptedUserId;
        } catch (err) {
            console.error('Error al desencriptar el ID:', err.message);
            setError(err.message);
            throw err; // Retira el error si es necesario para controlarlo fuera
        } finally {
            setLoading(false);
        }
    }, []);

    return { decryptUserId, error, loading };
};
