import React from 'react';
import '../Styles/headerMobile.css';
import BackIconComponetBlue from '../../assests/MobileIcons/BackIconComponetBlue';
import LogoTelefonicaMobile from '../../assests/mainPageComponent/header/logoTelefonicaMobile';

function HeaderComponentMobile({ onBack }) {
  return (
    <header className="Header-mobile d-flex align-items-center justify-content-between mt-3">
      <div className="row w-100">
        <div className="col-4 d-flex align-items-center justify-content-start">
          <button onClick={onBack} className="btn mb-2" style={{ padding: 0 }}>
            <BackIconComponetBlue className="btnHeader-exit-mobile" />
          </button>
        </div>
        <div className="col-4 d-flex align-items-center justify-content-center">
          <h1 className="tittleHeader-mobile">
            <span>
              <strong className="talent-title-mobile">Talent</strong>
            </span>
            <span className="hub-title-mobile fst-italic">Cards</span>
          </h1>
        </div>
        <div className="col-4 d-flex align-items-center justify-content-end">
          <LogoTelefonicaMobile className="logo-telefonica-mobile" />
        </div>
      </div>
    </header>
  );
}

export default HeaderComponentMobile;
