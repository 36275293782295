import ExitComponent from "../../assests/FileComponentes/iconsComponent/ExitComponent";
import LeftComponent from "../../assests/FileComponentes/iconsComponent/LeftComponent";
import RightComponent from "../../assests/FileComponentes/iconsComponent/RightComponent";
import PersonComponent from "../../assests/FileComponentes/iconsComponent/PersonComponent";
import LocationComponent from "../../assests/FileComponentes/iconsComponent/LocationComponent";
import ClockComponent from "../../assests/FileComponentes/iconsComponent/ClockComponent";
import DownloadComponent from "../../assests/FileComponentes/iconsComponent/DownloadComponent";
import LogoBlueSVG from "../../assests/FileComponentes/iconsComponent/LogoBlue";
import "../Styles/FirstTalentView/info.css"
import TalentTable from "./TalentTable";
import { useEffect, useState,useRef  } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate } from 'react-router-dom';
import { updateEmployee } from "../../../services/TalentCards/FetchUpdateEmployee";
import TalentModal from "../Components/TalentModal"
import { PDFDownloadLink } from '@react-pdf/renderer';
import DownloadPDF from './DownloadPDF/DownloadPDF';
import userImg from '../../../images/user-account.png';
import CryptoJS from 'crypto-js';


const allowedLanguages = ['English', 'Spanish', 'French', 'German', 'Portuguese', 'Italian'];
const allowedLevels = ['Native', 'Intermediate', 'Advanced', 'Beginner'];



function InfoComponent({ empleadoDetails, selectedEmployeeId, userInfoSSFF, 
  onUpdateEmployeeDetails,empleado,currentUserEmail,
  userPermissions,idToken,jwtSSFF }) {
  
  const secretKey = process.env.REACT_APP_TALENT_APP;
  const encrypted = CryptoJS.AES.encrypt(selectedEmployeeId, secretKey).toString();
  const encryptedEmployeeId = encodeURIComponent(btoa(encrypted));

  //const userId = userInfoSSFF.userId;
  const navigate = useNavigate();
  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState({ languages: [] });
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [tempText, setTempText] = useState('');
  const [prevText, setPrevText] = useState('');
  const languageRefs = useRef([]);
  const [isModalTalentOpen, setIsModalTalentOpen] = useState(false);

  useEffect(() => {
    if (empleadoDetails && empleadoDetails.languages !== undefined) {
      if (typeof empleadoDetails.languages === 'string') {
        setFormData({
          languages: empleadoDetails.languages ? [{ language: empleadoDetails.languages, level: '' }] : [],
        });
      } else if (Array.isArray(empleadoDetails.languages)) {
        setFormData({ languages: empleadoDetails.languages });
      } else {
        setFormData({ languages: [] });
      }
    }
  }, [empleadoDetails]);

  function isLanguageAllowed(language) {
    return allowedLanguages.some(
      (allowedLanguage) => allowedLanguage.toLowerCase() === language.toLowerCase()
    );
  }

  function isLevelAllowed(level) {
    return allowedLevels.some(
      (allowedLevel) => allowedLevel.toLowerCase() === level.toLowerCase()
    );
  }

  const handleBlur = async (index, field) => {
    const value = tempText.trim();
    const isLanguageValid = isLanguageAllowed(value);
    const isLevelValid = isLevelAllowed(value);

    if ((field === 'language' && !isLanguageValid) || (field === 'level' && !isLevelValid)) {
        toast.info(`Por favor ingresa uno de los siguientes valores: ${field === 'language' ? allowedLanguages.join(', ') : allowedLevels.join(', ')}`);
        setTempText(prevText);
        languageRefs.current[index][field].innerText = prevText;
    } else {
        confirmAlert({
            title: '¿Seguro?',
            message: '¿Estás seguro de modificar esto?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: async () => {
                        try {
                            const updatedLanguages = [...formData.languages];
                            updatedLanguages[index] = { ...updatedLanguages[index], [field]: value };
                            const updatedData = { 
                                ...empleadoDetails,
                                languages: updatedLanguages
                            };
                            
                            await updateEmployee(encryptedEmployeeId, updatedData, idToken, jwtSSFF);

                            onUpdateEmployeeDetails(updatedData);
                        } catch (error) {
                            console.error('Error updating employee:', error);
                            toast.error('Error al actualizar los datos del empleado.');
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            languages: empleadoDetails.languages || [],
                        }));
                        languageRefs.current[index][field].innerText = prevText;
                    }
                }
            ]
        });
    }
    setEditable(false);
    setEditingIndex(null);
    setEditingField(null);
  };

  const handleDoubleClick = (index, field) => {
    if (userPermissions.edit_talent_cards) {  
      setEditable(true);
      setEditingIndex(index);
      setEditingField(field);
      setTempText(formData.languages[index][field]);
      setPrevText(formData.languages[index][field]);
      setTimeout(() => {
        languageRefs.current[index][field].focus();
      }, 0);
    } 
  };

  const handleChange = (e) => {
    setTempText(e.target.innerText);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const backPage = () => {
 
    navigate(`/talentCards/${encryptedEmployeeId}`, {
      state: { empleado }
    });
  };
  
  const HandleClickSecondTalent = () => {
    navigate(`/talentCardsSecond/${encryptedEmployeeId}`, {
      state: { empleadoDetails, empleado }
    });
  };
  
  const exitPage = () => {
    navigate(`/talentCards`);
  };

  const handleOpenTalentModal = () => {
    if (userPermissions.edit_talent_cards) {  
      setIsModalTalentOpen(true);
    } 
  };

  const handleCloseTalentModal = () => {
    setIsModalTalentOpen(false);
  };
  
  const handleSaveTalentData = (updatedData) => {
    const updatedEmployeeData = {
        ...empleadoDetails,
        history: updatedData.years.map(item => ({
            talent_year: item.year,
            top_talent: item.top_talent,
            key_talent: item.key_talent,
            performance: item.performance === '' ? null : item.performance,
        })),
    };
    updateEmployee(encryptedEmployeeId,  updatedEmployeeData,idToken, jwtSSFF )
        .then(() => {
            onUpdateEmployeeDetails(updatedEmployeeData); 
        })
        .catch(error => {
            console.error('Error updating employee:', error);
            toast.error('Error al actualizar los datos del empleado.');
        });
  };

  
  const base64Image = empleado.empInfo?.photoNav?.results?.[0]?.photo;

 
  const imageUrl = base64Image ? `data:image/jpeg;base64,${base64Image}` : userImg;

  
  const birthdayTimestamp = empleado.empInfo?.personNav?.dateOfBirth?.match(/\d+/)?.[0];
  const birthday = birthdayTimestamp ? new Date(parseInt(birthdayTimestamp)) : null;

  
  const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const age = birthday ? calculateAge(birthday) : null;


  const positionEntryDateTicks = empleado.empInfo?.jobInfoNav?.results?.[0]?.positionEntryDate?.match(/\d+/)?.[0];
  const seniorityDateTicks = empleado.empInfo?.seniorityDate?.match(/\d+/)?.[0];


  const positionEntryDate = positionEntryDateTicks ? new Date(parseInt(positionEntryDateTicks)) : null;
  const seniorityDate = seniorityDateTicks ? new Date(parseInt(seniorityDateTicks)) : null;


  const getYear = (date) => {
    return date ? date.getFullYear() : 'N/A';
  };

 const positionEntryYear = getYear(positionEntryDate);
 const seniorityYear = getYear(seniorityDate);

 const yearsinJob = positionEntryYear - seniorityYear; 


 const fullLocation = empleado.location || '';
 const cleanLocation = fullLocation.replace(/\s*\(.*?\)\s*/g, '').trim();

 
 const formattedLocation = cleanLocation.charAt(0).toUpperCase() + cleanLocation.slice(1).toLowerCase();


 const currentYear = new Date().getFullYear();

 
 const positionEntryDateYears = empleado.empInfo?.jobInfoNav?.results?.[0]?.positionEntryDate;


 let yearsInRole  = "N/A";
 if (positionEntryDateYears) {
   const positionEntryYear = new Date(parseInt(positionEntryDateYears.match(/\d+/)[0], 10)).getFullYear();
    yearsInRole  = currentYear - positionEntryYear;
 }


  return (
    <div className="infoComp text-white" style={{ backgroundColor: '#007bff' }}>

      
      <div className="container mt-2 arrow-container-info">
        <div className="row gx-2 gy-2">
          <div className="col-6 d-flex justify-content-between align-items-start  ">
            <ExitComponent className="btnInfo-exit" onClick={exitPage} />
          </div>
          <div className="col-6  d-flex justify-content-end align-items-center" >
            <LeftComponent className="btnInfo-left" onClick={backPage} style={{ marginRight: '10px' }} />
            <RightComponent className="btnInfo-right" onClick={HandleClickSecondTalent} />
          </div>
        </div>
      </div>


      <div className="container mt-2">
         {/* ImageINFO*/}
        <div className="row gx-2">
          <div className="col-auto d-flex flex-column align-items-center">
            <div className="row flex-grow-1 align-items-center">
              <div className="rounded-circle overflow-hidden photo-container" style={{ width: '100px', height: '100px', objectFit: 'cover', background:'#007bff' }}>
              {imageUrl && (
                <img 
                  src={imageUrl} 
                  alt="Foto de empleado" 
                  style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} 
                />
              )}
            </div>
            </div>
          </div>
          
         {/* info SSFF*/}
          <div className="col d-flex flex-column justify-content-between ms-2">
            <div className="row flex-grow-1 align-items-center"></div>
            <div className="row flex-grow-1 align-items-center mt-3 ms-2"><p>{age} years old</p></div>
            <div className="row flex-grow-1 align-items-center">
            <p><PersonComponent className=" person-icon ms-0" />
             {selectedEmployeeId}</p></div>
          </div>

         {/* Button Download*/}
          <div className="col offset d-flex align-items-center mt-4 download-container justify-content-end me-1 ">
            <PDFDownloadLink
              document={
                <DownloadPDF
                  empleadoDetails={empleadoDetails}
                  selectedEmployeeId={selectedEmployeeId}
                  userInfoSSFF={userInfoSSFF}
                  empleado={empleado}
                />
              }
              fileName="empleado-details.pdf"
            >
              {({ loading }) => (
                <div className="btnInfo-download">
                  <div className="rounded-circle overflow-hidden">
                    <DownloadComponent className="btnInfo-download" />
                  </div>
                  {loading ? '' : ''}
                </div>
              )}
            </PDFDownloadLink>
          </div>
        </div>
      </div>

      {/* InfoSSFF */}
      <div className="container mt-4 mb-4">
        <div className="row gx-1 gy-1">
          <div className="col-9 mb-2"><ClockComponent className="clock-icon me-2" />
              <>
                {yearsInRole} years in current role 
                <div className="datefinal-exp me-4">
                {yearsinJob} years in Telefónica
                </div>
              </>
          </div> 
          <div className="col-9 mt-1 d-flex align-items-center"><LocationComponent className="location-icon me-1" /> {formattedLocation}</div> 
        </div>
      </div>
      
      {/* Talent */}
      <div>
        <p className="talent-subtitle mt-2 fw-semibold mb-1 ms-2">Talent Pool & Performance</p>
        <TalentTable  empleadoDetails={empleadoDetails} onClick={handleOpenTalentModal} />

        <TalentModal
          isOpen={isModalTalentOpen}
          empleadoDetails={empleadoDetails}
          onSave={handleSaveTalentData}
          onClose={handleCloseTalentModal}
        />

      </div>
     

      <div className="languages ms-2 mb-4">
        <p className="lang-subtitle fw-semibold mb-1 mt-0">Languages</p>
        <div className="row">
          {formData.languages.map((lang, index) => (
            <div
              key={index}
              className={`col-md-6 d-flex justify-content-start language-container`}
            >
              <p
                ref={el =>
                  (languageRefs.current[index] = {
                    ...languageRefs.current[index],
                    language: el,
                  })
                }
                className="language me-2"
                contentEditable={
                  editable && editingIndex === index && editingField === "language"
                }
                onBlur={() => handleBlur(index, "language")}
                onInput={handleChange}
                onDoubleClick={() => handleDoubleClick(index, "language")}
                onKeyDown={handleKeyDown}
                suppressContentEditableWarning={true}
              >
                {lang.language}
              </p>
              {lang.level && (
                <p
                  className="level"
                  ref={el =>
                    (languageRefs.current[index] = {
                      ...languageRefs.current[index],
                      level: el,
                    })
                  }
                  contentEditable={
                    editable && editingIndex === index && editingField === "level"
                  }
                  onBlur={() => handleBlur(index, "level")}
                  onInput={handleChange}
                  onDoubleClick={() => handleDoubleClick(index, "level")}
                  onKeyDown={handleKeyDown}
                  suppressContentEditableWarning={true}
                >
                  ({lang.level})
                </p>
              )}
            </div>
          ))}
        </div>
      </div>



      <div className="logo-container d-flex justify-content-center">
        <LogoBlueSVG className="logo-telefo" />
      </div>
    </div>
  );
}

export default InfoComponent;