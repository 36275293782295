import CryptoJS from 'crypto-js';
import DOMPurify from 'dompurify';
import { createJiraTicket } from '../../../../services/apiJira';

const secretKey = 'your-secret-key'; // Cambia esto por una clave segura

const encryptValue = (value) => {
  return CryptoJS.AES.encrypt(value, secretKey).toString();
};

export const handleSubmit = async (e, formData, setIsSubmitting, handleClear, setErrors, ValidateForm) => {
  e.preventDefault();

  try {
    // Sanitizar y encriptar los campos del formulario
    const sanitize = (value) => DOMPurify.sanitize(value || '');
    const encrypt = (value) => encryptValue(value);

    // Reemplazar valores vacíos por 'default'
    const validatedVacancyType = formData.vacancyType || 'default';
    const validatedSameFunctions = formData.sameFunctions || 'default';
    const validatedCriticality = formData.criticality || 'default';

    const fields = {
      applicantName: formData.applicantName,
      applicantEmail: formData.applicantEmail,
      businessUnit: formData.businessUnit,
      division: formData.division,
      department: formData.department,
      vacancyType: formData.vacancyType,
      replacementName: formData.replacementName,
      sameFunctions: formData.sameFunctions,
      isIntern: formData.isIntern,
      internName: formData.internName,
      needReason: formData.needReason,
      criticality: formData.criticality,
      responsibilities: formData.responsibilities,
      skills: formData.skills,
      project: formData.project,
      location: formData.location,
      city: formData.city,
      building: formData.building,
      floor: formData.floor,
      desiredDate: formData.desiredDate,
      successFactors: formData.successFactors,
    };

    const customFields = {
      customfield_10374: encrypt(sanitize(fields.applicantName)),
      customfield_10375: encrypt(sanitize(fields.applicantEmail)),
      customfield_10395: encrypt(sanitize(fields.businessUnit)),
      customfield_10396: encrypt(sanitize(fields.division)),
      customfield_10349: encrypt(sanitize(fields.department)),
      customfield_10441: encrypt(sanitize(validatedVacancyType)),
      customfield_10442: encrypt(sanitize(fields.replacementName)),
      customfield_10448: encrypt(sanitize(validatedSameFunctions)),
      customfield_10443: encrypt(sanitize(fields.isIntern)),
      customfield_10444: encrypt(sanitize(fields.internName)),
      customfield_10445: encrypt(sanitize(fields.needReason)),
      customfield_10446: encrypt(sanitize(validatedCriticality)),
      customfield_10447: encrypt(sanitize(fields.responsibilities)),
      customfield_10449: encrypt(sanitize(fields.skills)),
      customfield_10450: encrypt(sanitize(fields.project)),
      customfield_10451: encrypt(sanitize(fields.location)),
      customfield_10478: encrypt(sanitize(fields.city)),
      customfield_10344: encrypt(sanitize(fields.building)),
      customfield_10452: encrypt(sanitize(fields.floor)),
      customfield_10453: encrypt(sanitize(fields.desiredDate)),
      customfield_10454: encrypt(sanitize(fields.successFactors)),
    };

    // Preparar los datos del ticket
    const ticketData = {
      summary: `Alta - Manager`,
      issueType: 'Alta',
      priority: 'Medium',
      ...customFields,
    };

    // Eliminar campos encriptados que tengan valor `undefined` para evitar errores en la API
    Object.keys(ticketData).forEach((key) => {
      if (ticketData[key] === '' || ticketData[key] === 0) {
        delete ticketData[key];
      }
    });

    // Enviar el ticket a Jira
    await createJiraTicket(ticketData);

    // Limpiar el formulario y mostrar mensaje de éxito
    handleClear();
    setErrors({});
    alert('Formulario enviado correctamente');
  } catch (error) {
    console.error('Error al enviar el formulario:', error);
  } finally {
    setIsSubmitting(false);
  }
};
