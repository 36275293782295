import * as React from "react"

const DownloadComponentMobile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={57}
    height={57}
    viewBox="0 0 57 57"
    fill="none"
    backgroundColor="#06F"
    {...props}
  >
    <circle cx={28.5} cy={28.5} r={28.5} fill="#06F" />
    <path
      fill="#ffff "
      fillRule="evenodd"
      d="M25.346 18.137a2.111 2.111 0 0 0-2.109 2.11v6.746h-3.971c-.44 0-.837.256-1.021.655-.199.412-.14.901.15 1.256l.009.01.01.011a.466.466 0 0 0 .021.022l.001.001 9.204 9.52a1.124 1.124 0 0 0 1.7 0l9.228-9.52.002-.002a1.176 1.176 0 0 0 .19-1.298 1.127 1.127 0 0 0-1.025-.655h-4l.002-6.747a2.107 2.107 0 0 0-2.109-2.109h-6.282Zm-.72 2.11c0-.399.323-.725.723-.725h6.282c.4 0 .724.326.724.727l-.003 6.965c0 .638.509 1.164 1.138 1.164h3.708l-8.706 8.98-8.687-8.983h3.683c.629 0 1.137-.526 1.137-1.164v-6.965Z"
      clipRule="evenodd"
    />
  </svg>
)
export default DownloadComponentMobile
