import * as React from "react"

const TalentYesComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    viewBox="0 0 26 26 "
    fill="none"
    {...props}
  >
    <path
      fill="#F2F4FF"
      d="M12.996 0c4.131 0 7.343 1.078 9.551 3.204C24.84 5.41 26 8.71 26 13.004c0 4.292-1.163 7.585-3.453 9.792C20.34 24.922 17.127 26 12.997 26c-4.132 0-7.344-1.078-9.548-3.204C1.16 20.59 0 17.296 0 13.004c0-4.293 1.16-7.59 3.45-9.797C5.652 1.077 8.864 0 12.995 0Zm-1.345 18.46 7.632-10.208a.685.685 0 0 0-.14-.956.68.68 0 0 0-.953.137l-7.102 9.504-2.941-3.696a.682.682 0 0 0-.956-.108.682.682 0 0 0-.107.956l3.49 4.389a.68.68 0 0 0 .533.255h.011a.676.676 0 0 0 .534-.274Z"
    />
  </svg>
)
export default TalentYesComponent
