import CryptoJS from 'crypto-js';
import DOMpurify from 'dompurify';
import { createJiraTicket } from '../../../../../services/apiJira';
import { validateFields } from './formValidate';

const secretKey = 'your-secret-key';

const encryptValue = (value) => {
  return CryptoJS.AES.encrypt(value, secretKey).toString();
};

// Transformamos los valores de los checkbox que se han almacenado como 'yes'y 'no' en 'Sí' y 'No', que es como lo espera la API de Jira.
const transformYesNo = (fields) => {
  const transformedFields = {};
  for (const key in fields) {
    if (fields[key] === 'yes') {
      transformedFields[key] = 'Sí';
    } else if (fields[key] === 'no') {
      transformedFields[key] = 'No';
    } else {
      transformedFields[key] = fields[key];
    }
  }
  return transformedFields;
};

export const handleSubmit = async (
  e,
  isButtonEnabled,
  changeType,
  setIsSubmitting,
  handleClearForm,
  setChangeType,
  setFeedbackPopup,
  setMessageSendingFailed,
  // Información General
  managerName,
  managerEmail,
  employeeName,
  employeeEmail,
  employeeSSFFId,
  employeeRegistrationId,
  employeeLegalEntity,
  effectiveDate,
  // Cambios Organizativos
  organizationalChange,
  newDepartments,
  newManager,
  newAsigneeManager,
  roleChange,
  roleChangeDetails,
  newDuties,
  newDutiesDetails,
  categoryChange,
  categoryChangeDetails,
  // Cambios Salariales
  salaryChange,
  currency,
  bonusOrIncentives,
  incentivesAmount,
  salaryReason,
  // Cambios de Contrato
  contractChange,
  // Cambios de Localización
  city,
  country,
  building,
  floor,
  // Observaciones
  comments
) => {
  e.preventDefault();

  // Si el botón está habilitado, proceder con el envío del ticket
  if (isButtonEnabled) {
    try {
      setIsSubmitting(true);

      const sanitize = (value) => DOMpurify.sanitize(value || '');
      const encrypt = (value) => encryptValue(value);

      // Reemplazar valores vacíos por 'default' o '0'
      const validatedCurrency = currency || 'default';
      const validatedBonusOrIncentives = bonusOrIncentives || 'default';
      const validatedContractChange = contractChange || 'default';
      const validatedSalaryChange = salaryChange || '0';
      const validatedIncentivesAmount = incentivesAmount || '0';

      const fields = {
        // Información General
        managerName,
        managerEmail,
        employeeName,
        employeeEmail,
        employeeSSFFId,
        employeeRegistrationId,
        employeeLegalEntity,
        effectiveDate,
        // Cambios Organizativos
        organizationalChange,
        newDepartments,
        newManager,
        newAsigneeManager,
        roleChange,
        roleChangeDetails,
        newDuties,
        newDutiesDetails,
        categoryChange,
        categoryChangeDetails,
        // Cambios Salariales
        salaryChange,
        currency,
        bonusOrIncentives,
        incentivesAmount,
        salaryReason,
        // Cambios de Contrato
        contractChange,
        // Cambios de Localización
        city,
        country,
        building,
        floor,
        // Observaciones
        comments,
      };

      console.log('comments en HS', comments);

      // Transformar los valores 'yes' y 'no' a 'Sí' y 'No'
      const transformedFields = transformYesNo(fields);
      console.log('TRANSFORMEDFIELDS', transformedFields);

      const employeeNames = transformedFields.employeeName.split(',').map((name) => name.trim());
      const employeeEmails = transformedFields.employeeEmail.split(',').map((email) => email.trim());
      const employeeSSFFIds = transformedFields.employeeSSFFId.split(',').map((id) => id.trim());
      const employeeRegistrationIds = transformedFields.employeeRegistrationId.split(',').map((id) => id.trim());
      const employeeLegalEntities = transformedFields.employeeLegalEntity.split(',').map((entity) => entity.trim());

      // Verificar si hay múltiples empleados
      if (employeeNames.length === 1) {
        // Procesar un único ticket
        const customFields = {
          customfield_10374: encrypt(sanitize(managerName)),
          customfield_10375: encrypt(sanitize(managerEmail)),
          customfield_10376: encrypt(sanitize(employeeName)),
          customfield_10377: encrypt(sanitize(employeeEmail)),
          customfield_10378: encrypt(sanitize(employeeSSFFId)),
          customfield_10379: encrypt(sanitize(employeeRegistrationId)),
          customfield_10380: encrypt(sanitize(employeeLegalEntity)),
          customfield_10381: encrypt(sanitize(effectiveDate)),
          customfield_10383: encrypt(sanitize(transformedFields.organizationalChange)),
          customfield_10384: encrypt(sanitize(transformedFields.newDepartments)),
          customfield_10385: encrypt(sanitize(transformedFields.newManager)),
          customfield_10386: encrypt(sanitize(newAsigneeManager)),
          customfield_10387: encrypt(sanitize(transformedFields.roleChange)),
          customfield_10391: encrypt(sanitize(roleChangeDetails)),
          customfield_10389: encrypt(sanitize(transformedFields.newDuties)),
          customfield_10390: encrypt(sanitize(newDutiesDetails)),
          customfield_10392: encrypt(sanitize(transformedFields.categoryChange)),
          customfield_10393: encrypt(sanitize(categoryChangeDetails)),
          customfield_10423: encrypt(sanitize(validatedSalaryChange)),
          customfield_10424: encrypt(sanitize(validatedCurrency)),
          customfield_10425: encrypt(sanitize(validatedBonusOrIncentives)),
          customfield_10426: encrypt(sanitize(validatedIncentivesAmount)),
          customfield_10427: encrypt(sanitize(salaryReason)),
          customfield_10428: encrypt(sanitize(city)),
          customfield_10343: encrypt(sanitize(country)),
          customfield_10344: encrypt(sanitize(building)),
          customfield_10429: encrypt(sanitize(floor)),
          customfield_10430: encrypt(sanitize(validatedContractChange)),
          customfield_10436: encrypt(sanitize(comments)),
        };

        await createJiraTicket({
          summary: `Cambio`,
          issueType: 'Cambio',
          priority: 'Medium',
          ...customFields,
        });
      } else {
        // Procesar múltiples tickets
        for (let i = 0; i < employeeNames.length; i++) {
          const customFields = {
            customfield_10374: encrypt(sanitize(managerName)),
            customfield_10375: encrypt(sanitize(managerEmail)),
            customfield_10376: encrypt(sanitize(employeeNames[i])),
            customfield_10377: encrypt(sanitize(employeeEmails[i])),
            customfield_10378: encrypt(sanitize(employeeSSFFIds[i])),
            customfield_10379: encrypt(sanitize(employeeRegistrationIds[i])),
            customfield_10380: encrypt(sanitize(employeeLegalEntities[i])),
            customfield_10381: encrypt(sanitize(effectiveDate)),
            customfield_10383: encrypt(sanitize(transformedFields.organizationalChange)),
            customfield_10384: encrypt(sanitize(transformedFields.newDepartments)),
            customfield_10385: encrypt(sanitize(transformedFields.newManager)),
            customfield_10386: encrypt(sanitize(newAsigneeManager)),
            customfield_10387: encrypt(sanitize(transformedFields.roleChange)),
            customfield_10391: encrypt(sanitize(roleChangeDetails)),
            customfield_10389: encrypt(sanitize(transformedFields.newDuties)),
            customfield_10390: encrypt(sanitize(newDutiesDetails)),
            customfield_10392: encrypt(sanitize(transformedFields.categoryChange)),
            customfield_10393: encrypt(sanitize(categoryChangeDetails)),
            customfield_10423: encrypt(sanitize(salaryChange)),
            customfield_10424: encrypt(sanitize(currency)),
            customfield_10425: encrypt(sanitize(bonusOrIncentives)),
            customfield_10426: encrypt(sanitize(incentivesAmount)),
            customfield_10427: encrypt(sanitize(salaryReason)),
            customfield_10428: encrypt(sanitize(city)),
            customfield_10343: encrypt(sanitize(country)),
            customfield_10344: encrypt(sanitize(building)),
            customfield_10429: encrypt(sanitize(floor)),
            customfield_10430: encrypt(sanitize(contractChange)),
            customfield_10436: encrypt(sanitize(comments)),
          };

          await createJiraTicket({
            summary: `Cambio`,
            issueType: 'Cambio',
            priority: 'Medium',
            ...customFields,
          });
        }
      }

      handleClearForm();
      setChangeType(['info']);
      setFeedbackPopup(true);
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
      setMessageSendingFailed(true);
    } finally {
      setIsSubmitting(false);
    }
  }
};
