import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../../../styles/change/formChangeManager.css';
import elipse355 from '../../../../../images/ellipse355.png';
import { getEmployeeSuggestions, getManagerSuggestions } from './suggestions';
import ContactInfoWithPopup from './contactInfoPopUp';
import { GeneralInfo } from '../components/formComponents/generalInfo';
import { OrganizationalChange } from '../components/formComponents/organizationalChange';
import { SalaryChange } from '../components/formComponents/salaryChange';
import { ContractChange } from '../components/formComponents/contractChange';
import { LocationChange } from '../components/formComponents/locationChange';
import { CommentsChange } from '../components/formComponents/commentsChange';
import { handleSubmit } from './handleSubmit';
import { validateFields } from './formValidate';
import { capitalizeFirstLetter, getSafeString } from '../../../../../utils/utils';

const FormManagerMain = ({ userInfoSSFF, ticketData, managerEmployees }) => {
  const [changeType, setChangeType] = useState(['info']);
  const [searchType, setSearchType] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [managedEmployees, setManagedEmployees] = useState([]);
  const [errors, setErrors] = useState({});
  const [focusedButton, setFocusedButton] = useState('');
  const [value, setValue] = useState('');
  const [showManagerInput, setShowManagerInput] = useState(false);
  const [showRoleChangeInput, setShowRoleChangeInput] = useState(false);
  const [showNewDutiesInput, setShowNewDutiesInput] = useState(false);
  const [showCategoryChangeInput, setShowCategoryChangeInput] = useState(false);

  //Campos del formulario
  ////Información General
  const [managerName, setManagerName] = useState('');
  const [managerEmail, setManagerEmail] = useState('');
  const [employeeName, setEmployeeName] = useState('');
  const [employeeEmail, setEmployeeEmail] = useState('');
  const [employeeSSFFId, setEmployeeSSFFId] = useState('');
  const [employeeRegistrationId, setEmployeeRegistrationId] = useState('');
  const [employeeLegalEntity, setEmployeeLegalEntity] = useState('');
  useEffect(() => {
    if (selectedUser) {
      setEmployeeName(capitalizeFirstLetter(getSafeString(selectedUser.displayName)) || '');
      setEmployeeEmail(capitalizeFirstLetter(getSafeString(selectedUser.email)) || '');
      setEmployeeSSFFId(capitalizeFirstLetter(getSafeString(selectedUser.userId)) || '');
      setEmployeeRegistrationId(
        capitalizeFirstLetter(getSafeString(selectedUser.empInfo?.personNav?.personalInfoNav?.results?.[0]?.customString5)) || ''
      );
      setEmployeeLegalEntity(capitalizeFirstLetter(getSafeString(selectedUser.custom08)) || '');
    }
  }, [selectedUser]);
  const [effectiveDate, setEffectiveDate] = useState('');
  ////Cambios organizativos
  const [organizationalChange, setOrganizationalChange] = useState('');
  const [newDepartments, setNewDepartments] = useState('');
  // const [newManager, setNewManager] = useState('');
  const [newManager, setNewManager] = useState('');
  const [newDuties, setNewDuties] = useState('');
  const [newAsigneeManager, setNewAsigneeManager] = useState('');
  const [roleChange, setRoleChange] = useState('');
  const [roleChangeDetails, setRoleChangeDetails] = useState('');
  const [newDutiesDetails, setNewDutiesDetails] = useState('');
  const [categoryChange, setCategoryChange] = useState('');
  const [categoryChangeDetails, setCategoryChangeDetails] = useState('');
  ////Cambios salariales
  const [salaryChange, setSalaryChange] = useState('');
  const [currency, setCurrency] = useState('');
  const [bonusOrIncentives, setBonusOrIncentives] = useState('');
  const [incentivesAmount, setIncentivesAmount] = useState('');
  const [salaryReason, setSalaryReason] = useState('');
  ////Cambios de localización
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [building, setBuilding] = useState('');
  const [floor, setFloor] = useState('');
  ////Cambios  de contrato
  const [contractChange, setContractChange] = useState('');
  ////Observaciones
  const [comments, setComments] = useState('');

  //Estados para mostrar mensajes de error en los campos requeridos
  const [showEffectiveDateRequired, setShowEffectiveDateRequired] = useState(false);
  const [showCommentsRequired, setShowCommentsRequired] = useState(false);
  const [showEffectiveDateInfo, setShowEffectiveDateInfo] = useState(false);

  //Estado para controlar si los campos de GeneralInfo se han rellenado
  const [isGeneralInfoComplete, setIsGeneralInfoComplete] = useState(false);

  //Estados para habilitar o deshabilitar el botón de enviar formulario
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);

  useEffect(() => {
    setManagerName(capitalizeFirstLetter(getSafeString(userInfoSSFF?.displayName)) || '');
    setManagerEmail(userInfoSSFF?.username || '');
  }, [userInfoSSFF]);

  //Establecer el componente de Información General por defecto al cargar el formulario
  useEffect(() => {
    setChangeType(['info']);
    setFocusedButton('info');
  }, []);
  //Manejo del menú de formulario
  const handleMenuClick = (type) => {
    setChangeType([type]);
    setFocusedButton(type);
  };

  const handleSearchTypeChange = (newSearchType) => {
    setSearchType(newSearchType); // Actualiza el tipo de búsqueda
    setValue(''); // Limpia el valor de búsqueda al cambiar el tipo
  };

  //Limpiar formulario
  const handleClearForm = () => {
    setEffectiveDate('');
    setEmployeeName('');
    setOrganizationalChange(false);
    setNewDepartments('');
    setSalaryChange('');
    setCurrency('');
    setBonusOrIncentives('');
    setIncentivesAmount('');
    setSalaryReason('');
    setCity('');
    setCountry('');
    setBuilding('');
    setFloor('');
    setContractChange('');
  };

  const handleCloseFeedbackPopup = () => {
    setFeedbackPopup(false);
    setMessageSendingFailed(false);
  };

  // Verificar si todos los campos de GeneralInfo están completos, y habilitar el botón de Enviar
  useEffect(() => {
    const allFieldsFilled =
      managerName.trim() &&
      managerEmail.trim() &&
      employeeName.trim() &&
      employeeEmail.trim() &&
      employeeSSFFId.trim() &&
      employeeRegistrationId.trim() &&
      employeeLegalEntity.trim() &&
      effectiveDate.trim();

    // Habilitar el botón si todos los campos tienen valor
    setIsButtonEnabled(!!allFieldsFilled);
  }, [managerName, managerEmail, employeeName, employeeEmail, employeeSSFFId, employeeRegistrationId, employeeLegalEntity, effectiveDate]);

  return (
    <>
      <section className='formJiraChange'>
        <div className='jiraChangeForm__breadcrumb'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <Link to='/form' className='jiraChangeForm__breadcrumb--title'>
            INICIO
          </Link>
          <div className='jiraChangeForm__breadcrumb--text'>&gt; SOLICITUD DE CAMBIO</div>
        </div>
        <h1 className='formJiraChange__title'>Solicitud de Cambio</h1>
        <div className='jiraChangeForm_menuFormContainer'>
          <nav className='jiraChangeForm_menu'>
            <button
              onClick={() => handleMenuClick('info')}
              style={{
                backgroundColor: focusedButton === 'info' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'info' ? '#06f' : 'initial',
                opacity: 1,
              }}
            >
              Información General
            </button>
            {['Organizational', 'Salary', 'Location', 'Contract', 'comments'].map((section) => (
              <button
                key={section}
                onClick={() => isGeneralInfoComplete && handleMenuClick(section)}
                style={{
                  backgroundColor: focusedButton === section ? '#F2F4FF' : 'initial',
                  color: focusedButton === section ? '#06f' : 'initial',
                  opacity: isGeneralInfoComplete ? 1 : 0.5, // Cambiar opacidad
                  pointerEvents: isGeneralInfoComplete ? 'auto' : 'none', // Deshabilitar clics
                }}
              >
                {section === 'Organizational' && 'Cambios Organizativos'}
                {section === 'Salary' && 'Cambios Salariales'}
                {section === 'Location' && 'Cambios de Localización'}
                {section === 'Contract' && 'Cambios de Contrato'}
                {section === 'comments' && 'Observaciones'}
              </button>
            ))}
          </nav>
          {/* Envío de campos del formulario */}
          <form
            className='formJiraChange_form'
            onSubmit={(e) =>
              handleSubmit(
                e,
                isButtonEnabled,
                changeType,
                setIsSubmitting,
                handleClearForm,
                setChangeType,
                setFeedbackPopup,
                setMessageSendingFailed,
                // Información General
                managerName,
                managerEmail,
                employeeName,
                employeeEmail,
                employeeSSFFId,
                employeeRegistrationId,
                employeeLegalEntity,
                effectiveDate,
                // Cambios Organizativos
                organizationalChange,
                newDepartments,
                newManager,
                newAsigneeManager,
                roleChange,
                roleChangeDetails,
                newDuties,
                newDutiesDetails,
                categoryChange,
                categoryChangeDetails,
                // Cambios Salariales
                salaryChange,
                currency,
                bonusOrIncentives,
                incentivesAmount,
                salaryReason,
                // Cambios de Contrato
                contractChange,
                // Cambios de Localización
                city,
                country,
                building,
                floor,
                // Observaciones
                comments
              )
            }
          >
            {/* Información General */}
            <div className='formJiraChange_section'>
              {changeType.includes('info') && (
                <GeneralInfo
                  setIsGeneralInfoComplete={setIsGeneralInfoComplete}
                  searchType={searchType}
                  setSearchType={setSearchType}
                  setSelectedUser={setSelectedUser}
                  managedEmployees={managedEmployees}
                  effectiveDate={effectiveDate}
                  setEffectiveDate={setEffectiveDate}
                  showEffectiveDateInfo={showEffectiveDateInfo}
                  setShowEffectiveDateInfo={setShowEffectiveDateInfo}
                  showEffectiveDateRequired={showEffectiveDateRequired}
                  managerName={managerName}
                  managerEmail={managerEmail}
                  managerEmployees={managerEmployees}
                  selectedUser={selectedUser}
                  employeeName={employeeName}
                  setEmployeeName={setEmployeeName}
                  employeeEmail={employeeEmail}
                  setEmployeeEmail={setEmployeeEmail}
                  employeeSSFFId={employeeSSFFId}
                  setEmployeeSSFFId={setEmployeeSSFFId}
                  employeeRegistrationId={employeeRegistrationId}
                  setEmployeeRegistrationId={setEmployeeRegistrationId}
                  employeeLegalEntity={employeeLegalEntity}
                  setEmployeeLegalEntity={setEmployeeLegalEntity}
                  setValue={setValue}
                />
              )}
              {/* Cambios Organizativos */}
              {changeType.includes('Organizational') && (
                <OrganizationalChange
                  organizationalChange={organizationalChange}
                  setOrganizationalChange={setOrganizationalChange}
                  newDepartments={newDepartments}
                  setNewDepartments={setNewDepartments}
                  newAsigneeManager={newAsigneeManager}
                  setNewAsigneeManager={setNewAsigneeManager}
                  newManager={newManager}
                  setNewManager={setNewManager}
                  roleChangeDetails={roleChangeDetails}
                  setRoleChangeDetails={setRoleChangeDetails}
                  roleChange={roleChange}
                  setRoleChange={setRoleChange}
                  newDutiesDetails={newDutiesDetails}
                  setNewDutiesDetails={setNewDutiesDetails}
                  newDuties={newDuties}
                  setNewDuties={setNewDuties}
                  categoryChange={categoryChange}
                  categoryChangeDetails={categoryChangeDetails}
                  setCategoryChange={setCategoryChange}
                  setCategoryChangeDetails={setCategoryChangeDetails}
                  errors={errors}
                  setErrors={setErrors}
                  showManagerInput={showManagerInput}
                  setShowManagerInput={setShowManagerInput}
                  showNewDutiesInput={showNewDutiesInput}
                  setShowNewDutiesInput={setShowNewDutiesInput}
                  showRoleChangeInput={showRoleChangeInput}
                  setShowRoleChangeInput={setShowRoleChangeInput}
                  showCategoryChangeInput={showCategoryChangeInput}
                  setShowCategoryChangeInput={setShowCategoryChangeInput}
                />
              )}
              {/* Cambios Salariales */}
              {changeType.includes('Salary') && (
                <SalaryChange
                  salaryChange={salaryChange}
                  setSalaryChange={setSalaryChange}
                  currency={currency}
                  bonusOrIncentives={bonusOrIncentives}
                  incentivesAmount={incentivesAmount}
                  salaryReason={salaryReason}
                  setCurrency={setCurrency}
                  setBonusOrIncentives={setBonusOrIncentives}
                  setIncentivesAmount={setIncentivesAmount}
                  setSalaryReason={setSalaryReason}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
              {/* Cambios de localización */}
              {changeType.includes('Location') && (
                <LocationChange
                  city={city}
                  country={country}
                  building={building}
                  floor={floor}
                  setCity={setCity}
                  setCountry={setCountry}
                  setBuilding={setBuilding}
                  setFloor={setFloor}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
              {/* Cambios de contrato */}
              {changeType.includes('Contract') && <ContractChange contractChange={contractChange} setContractChange={setContractChange} />}
              {/* Observaciones */}
              {changeType.includes('comments') && <CommentsChange comments={comments} setComments={setComments} />}
            </div>
            <div className='formJiraChangeButton_section'>
              <button className={`formJiraChangeButton ${isButtonEnabled ? 'enabled' : 'disabled'}`} type='submit'>
                Enviar
              </button>
              {isSubmitting && <div className='loader'></div>}
            </div>
          </form>
        </div>
        <ContactInfoWithPopup
          feedbackPopup={feedbackPopup}
          messageSendingFailed={messageSendingFailed}
          handleCloseFeedbackPopup={handleCloseFeedbackPopup}
        />
      </section>
    </>
  );
};

export default FormManagerMain;
