import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../Styles/Modals/talentModal.css";

const TalentModal = ({ isOpen, empleadoDetails, onSave, onClose }) => {
  
  const getLastYears = (count) => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: count }, (_, index) => currentYear - index);
  };

  const [formData, setFormData] = useState({
    years: getLastYears(3).map((year) => ({
      year,
      top_talent: 0,
      key_talent: 0,
      performance: "",
    })),
  });

  useEffect(() => {
    if (empleadoDetails && Array.isArray(empleadoDetails.history)) {
      const currentYear = new Date().getFullYear();
      const defaultYears = [currentYear, currentYear - 1, currentYear - 2];

      const historyMap = new Map(empleadoDetails.history.map(item => [item.talent_year, item]));
  
      const years = defaultYears.map(year => ({
        year,
        top_talent: historyMap.get(year)?.top_talent || 0,
        key_talent: historyMap.get(year)?.key_talent || 0,
        performance: historyMap.get(year)?.performance || "",
      }));
  
      setFormData({ years });
    }
  }, [empleadoDetails]);
  

  const handleSwitchChange = (year, field) => {
    const updatedYears = formData.years.map((item) => {
      if (item.year === year) {
        return { ...item, [field]: item[field] === 1 ? 0 : 1 };
      }
      return item;
    });
    setFormData({ ...formData, years: updatedYears });
  };

  const handlePerformanceChange = (year, value) => {
    const updatedYears = formData.years.map((item) => {
      if (item.year === year) {
        return { ...item, performance: parseInt(value) };
      }
      return item;
    });
    setFormData({ ...formData, years: updatedYears });
  };

  const handleSave = () => {
    onSave(formData);
    onClose();
  };

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Body>
        <div className="text-center mb-4">
          <h5>Talent Pool & Performance</h5>
        </div>
        <div className="table-responsive">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th></th>
                {formData.years.map((item) => (
                  <th key={item.year}>{item.year}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Top Talent</td>
                {formData.years.map((item) => (
                  <td key={`top_talent_${item.year}`}>
                    <Form.Check
                      type="switch"
                      checked={item.top_talent === 1}
                      onChange={() => handleSwitchChange(item.year, "top_talent")}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td>Key Talent</td>
                {formData.years.map((item) => (
                  <td key={`key_talent_${item.year}`}>
                    <Form.Check
                      type="switch"
                      checked={item.key_talent === 1}
                      onChange={() => handleSwitchChange(item.year, "key_talent")}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td>Performance</td>
                {formData.years.map((item) => (
                  <td key={`performance_${item.year}`}>
                    <Form.Select
                      className="gray-select"
                      value={item.performance}
                      onChange={(e) => handlePerformanceChange(item.year, e.target.value)}
                    >
                      {[0, 1, 2, 3, 4, 5].map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-3">
          <Button className="btn-acept-talent rounded-pill" onClick={onClose}>Cancelar</Button>
          <Button className="btn-delete-talent rounded-pill" onClick={handleSave}>Guardar</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};


export default TalentModal;
