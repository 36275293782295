import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/SecondTalentView/secondModal.css';
import InfoComponent from '../Components/InfoComponent';
import LeadershipComponent from '../Components/LeaderShipcomponent';
import OthersComponent from '../Components/OthersComponent';

import SummaryComponentMobile from '../../Mobile/Components/SummaryComponentMobile';
import { useParams, useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { useDecryptUserId } from '../../../services/TalentCards/useDecryptUserId';
import LoadingAnimation from '../Components/loadingButtonSplit';



const SecondTalentComponent = ({userInfoSSFF,currentUserEmail,
  userPermissions,idToken,
  jwtSSFF}) => {
  const { user_id: encryptedUserId } = useParams();
  const [user_id, setUserId] = useState(null);
  const { decryptUserId, loading: decryptLoading } = useDecryptUserId();

  useEffect(() => {
    const fetchDecryptedId = async () => {
      try {
        if (encryptedUserId) {
          const decryptedId = await decryptUserId(encryptedUserId);
          setUserId(decryptedId);
        }
      } catch (err) {
        console.error('No se pudo desencriptar el ID:', err.message);
      }
    };

    fetchDecryptedId();
  }, [encryptedUserId, decryptUserId]);


  const location = useLocation();
  // Cargamos empleado y empleadoDetails desde location.state si están presentes
  const empleado = location.state?.empleado || null;

  const [empleadoDetails, setEmpleadoDetails] = useState(
    location.state?.empleadoDetails || {}
  );
  // Ahora tienes acceso a la prop `empleado`

  const [isMobile, setIsMobile] = useState(false);
 

  //Version Mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleUpdateEmployeeDetails = (updatedDetails) => {
    setEmpleadoDetails(updatedDetails);
  };

  if (!empleadoDetails)   return (
    <div className="d-flex justify-content-center align-items-center min-vh-100 bg-light">
      <LoadingAnimation />
    </div>
  );

  return (
    <div>
      {isMobile ? (
        <SummaryComponentMobile
          empleadoDetails={empleadoDetails}
          selectedEmployeeId={user_id}
          userInfoSSFF={userInfoSSFF}
          empleado={empleado}
        />
      ) : (
        <div className="container-fluid p-2 ms-4 me-4">
          <div className="row d-flex mb-0">
            <div className="informationEmployee col-md-3 p-0 info-shadow">
              <InfoComponent
                empleadoDetails={empleadoDetails}
                selectedEmployeeId={user_id}
                userInfoSSFF={userInfoSSFF}
                onUpdateEmployeeDetails={handleUpdateEmployeeDetails}
                empleado={empleado}
                userPermissions={userPermissions}
                currentUserEmail={currentUserEmail}
                idToken = {idToken}
                jwtSSFF = {jwtSSFF}
              />
            </div>
            <div className="leaderShipEmployee col-md-4 mt-4">
              <LeadershipComponent
                empleadoDetails={empleadoDetails}
                selectedEmployeeId={user_id}
                userInfoSSFF={userInfoSSFF}
                onUpdateEmployeeDetails={handleUpdateEmployeeDetails}
                empleado={empleado}
                userPermissions={userPermissions}
                currentUserEmail={currentUserEmail}
                idToken = {idToken}
                jwtSSFF = {jwtSSFF}
              />
            </div>
            <div className="othersInfoEmployee col-md-4 ms-4  ">
              <OthersComponent
                empleadoDetails={empleadoDetails}
                selectedEmployeeId={user_id}
                onUpdateEmployeeDetails={handleUpdateEmployeeDetails}
                userInfoSSFF={userInfoSSFF}
                userPermissions={userPermissions}
                currentUserEmail={currentUserEmail}
                idToken = {idToken}
                jwtSSFF = {jwtSSFF}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SecondTalentComponent;
