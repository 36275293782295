import React from "react";
import { Modal, Button } from "react-bootstrap"; 
import '../Styles/Modals/leaderShipModal.css';

function LeadershipModal({ isOpen, leadershipData, onSave, onClose }) {

  const [impact, setImpact] = React.useState(leadershipData.leadership_impact);
  const [collaboration, setCollaboration] = React.useState(
    leadershipData.leadership_collaboration
  );
  const [transformation, setTransformation] = React.useState(
    leadershipData.leadership_transformation
  );
  const [growth, setGrowth] = React.useState(leadershipData.leadership_growth);

  // Función para manejar el guardado de los cambios
  const handleSave = () => {
    onSave({
      leadership_impact: impact,
      leadership_collaboration: collaboration,
      leadership_transformation: transformation,
      leadership_growth: growth,
    });
    onClose();
  };

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Body>
        <div  className="blue-title">Leadership</div>
        <div className="form-group row">
          <label className="col-sm-8 col-form-label text-sm-end">
            Impact & Execution for results:
          </label>
          <div className="col-sm-2">
            <select
              className="form-control gray-select"
              value={impact}
              onChange={(e) => setImpact(parseInt(e.target.value))}
            >
              {[0, 1, 2, 3].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-6 col-form-label text-sm-end">Collaborative:</label>
          <div className="col-sm-2">
            <select
              className="form-control gray-select"
              value={collaboration}
              onChange={(e) => setCollaboration(parseInt(e.target.value))}
            >
              {[0, 1, 2, 3].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-6 col-form-label text-sm-end">Transformative:</label>
          <div className="col-sm-2">
            <select
              className="form-control gray-select"
              value={transformation}
              onChange={(e) => setTransformation(parseInt(e.target.value))}
            >
              {[0, 1, 2, 3].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-6 col-form-label text-sm-end">Growth:</label>
          <div className="col-sm-2">
            <select
              className="form-control gray-select"
              value={growth}
              onChange={(e) => setGrowth(parseInt(e.target.value))}
            >
              {[0, 1, 2, 3].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
         <div className="d-flex justify-content-end gap-2 mt-3">
          <Button className="btn-acept-leaderShip rounded-pill" onClick={onClose}>Cancelar</Button>
          <Button className="btn-delete-leaderShip rounded-pill" onClick={handleSave}>Guardar</Button>
        </div>
      </Modal.Body>
      
    </Modal>
  );
}

export default LeadershipModal;
