// import React, { useState, useEffect } from 'react';
// //styles
// import '../styles/subcategories_article-list.css';
// import elipse355 from '../../images/ellipse355.png';

// //components
// import ArticleItem from './article-item';

// // import { fetchSubcategoriesArticles } from '../../services/api';
// import { Link } from 'react-router-dom';

// const Subcategories_articleList = ({ selectedSubcategory, isPageGroup, userInfoSSFF }) => {
//   const [articlesData, setArticlesData] = useState([]);

//   // Obtener los ARTICULOS POR SUBCATEGORY.ID
//   useEffect(() => {
//     const fetchArticlesData = async () => {
//       if (selectedSubcategory && selectedSubcategory.id) {
//         // Verifica si el id está disponible
//         try {
//           const response = await fetch(`/subcategories/articles/${selectedSubcategory.id}`, {
//             method: 'GET',
//           });
//           const data = await response.json();
//           if (response.ok) {
//             setArticlesData(data.articlesDataFromSubcategoryId);
//           } else {
//             console.error('Error fetching articles:', response.statusText);
//           }
//         } catch (error) {
//           console.error('Error fetching articles:', error);
//         }
//       } else {
//         console.error('No subcategory ID provided.');
//       }
//     };
//     fetchArticlesData();
//   }, [selectedSubcategory]);

//   // Usar articleId si está presente para cargar un artículo específico
//   useEffect(() => {
//     if (articleId) {
//       console.log('Cargando artículo con ID:', articleId);
//       // Aquí podrías agregar lógica para cargar ese artículo específico
//     }
//   }, [articleId]);

//   return (
//     <>
//       <section className='articleList'>
//         {/* Breadcrumb --> */}
//         <div className='articleList__breadcrumb--container'>
//           <img src={elipse355} alt='circle-icon' className='circle-icon' />
//           <Link to='/home' className='articleList__breadcrumb--title'>
//             INICIO {''}
//           </Link>
//           {/* {dataPage.dataisPageGroup && (
//             <span className="articleList__breadcrumb--text parent">
//               <Link to="/roles">&gt; ROLES {''}</Link>
//             </span>
//           )}
//           {dataPage.dataisPageGroup ? (
//             <span className='articleList__breadcrumb--text parent'>
//               <Link to='/roles/groups'>
//                 &gt; {dataPage.parent} {''}
//               </Link>
//             </span>
//           ) : (
//             <span className='articleList__breadcrumb--text parent'>
//               <Link to='/subcategories'>
//                 &gt; {dataPage.parent} {''}
//               </Link>
//             </span>
//           )}
//           <span className="articleList__breadcrumb--text">
//             &gt; {dataPage.name}
//           </span> */}
//         </div>
//         {/* Breadcrumb --> */}
//         {/* Listado artículos */}
//         <section className='articleList__content'>
//           {/* BBDD */}
//           {articlesData.map((article) => (
//             // <div>
//             //   <h2 className="bubble__title">{article.title}</h2>
//             // </div>
//             <ArticleItem
//               title={article.title}
//               answer={article.content}
//               expandedState={false}
//               // clickCode={article.tracking.clickCode}
//               // rateCode={article.tracking.rateCode}
//               userInfoSSFF={userInfoSSFF}
//               articleId={article.id}
//               // contactMe={article.attributes.CONTACT_MANAGER}
//             ></ArticleItem>
//           ))}
//         </section>
//       </section>
//     </>
//   );
// };

// export default Subcategories_articleList;

import React, { useState, useEffect } from 'react';
//styles
import '../styles/subcategories_article-list.css';
import elipse355 from '../../images/ellipse355.png';

//components
import ArticleItem from './article-item';

// import { fetchSubcategoriesArticles } from '../../services/api';
import { Link } from 'react-router-dom';

const Subcategories_articleList = ({ selectedSubcategory, selectedCategory, userInfoSSFF }) => {
  const [articlesData, setArticlesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Estado para la carga
  const legalEntity = userInfoSSFF.custom03;

  // Obtener los ARTICULOS POR SUBCATEGORY.ID
  useEffect(() => {
    const fetchArticlesData = async () => {
      if (selectedSubcategory && selectedSubcategory.id) {
        // Verifica si el id está disponible
        try {
          setIsLoading(true); // Inicia la carga

          const response = await fetch(`/subcategories/articles/${selectedSubcategory.id}/${legalEntity}`, {
            method: 'GET',
          });
          const data = await response.json();
          console.log('data', data);
          if (response.ok) {
            const sortedData = data.articlesDataFromSubcategoryId.sort((a, b) => a.article_id - b.article_id);
            setArticlesData(sortedData);
          } else {
            console.error('Error fetching articles:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching articles:', error);
        } finally {
          setIsLoading(false); // Finaliza la carga
        }
      } else {
        console.error('No subcategory ID provided.');
        setIsLoading(false); // Finaliza la carga
      }
    };
    fetchArticlesData();
  }, [selectedSubcategory]);

  return (
    <>
      <section className='articleList'>
        {/* Breadcrumb --> */}
        <div className='articleList__breadcrumb--container'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <Link to='/knowledgepage' className='articleList__breadcrumb--title'>
            INICIO {''}
          </Link>
          <span className='subcategories__breadcrumb--text'>&gt; {selectedCategory.name}</span>
          <span className='subcategories__breadcrumb--text'>&gt; {selectedSubcategory.name}</span>
          {/* {dataPage.dataisPageGroup && (
            <span className="articleList__breadcrumb--text parent">
              <Link to="/roles">&gt; ROLES {''}</Link>
            </span>
          )}
          {dataPage.dataisPageGroup ? (
            <span className="articleList__breadcrumb--text parent">
              <Link to="/roles/groups">
                &gt; {dataPage.parent} {''}
              </Link>
            </span>
          ) : (
            <span className="articleList__breadcrumb--text parent">
              <Link to="/subcategories">
                &gt; {dataPage.parent} {''}
              </Link>
            </span>
          )}
          <span className="articleList__breadcrumb--text">
            &gt; {dataPage.name}
          </span> */}
        </div>
        {isLoading ? (
          <div className='loading'>Loading...</div> // Mensaje de carga
        ) : (
          <section className='articleList__content'>
            {/* BBDD */}
            {articlesData.map((article) => (
              <ArticleItem
                title={article.title}
                answer={article.content}
                expandedState={false}
                userInfoSSFF={userInfoSSFF}
                articleId={article.article_id}
              ></ArticleItem>
            ))}
          </section>
        )}
      </section>
    </>
  );
};

export default Subcategories_articleList;
