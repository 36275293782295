import React from 'react';
import { Svg, Path } from '@react-pdf/renderer';

const LeadershipYesComponent = (props) => (
  <Svg viewBox="0 0 30 30" width={props.width || 16} height={props.height || 16}>
    <Path
      fill="#06F"
      d="M14.996 0c4.766 0 8.472 1.244 11.02 3.697C28.662 6.244 30 10.05 30 15.004s-1.342 8.752-3.984 11.3C23.468 28.755 19.762 30 14.996 30c-4.767 0-8.473-1.244-11.016-3.697C1.338 23.756 0 19.957 0 15.004c0-4.953 1.338-8.756 3.98-11.303C6.523 1.244 10.23 0 14.996 0Zm-1.552 21.3L22.25 9.52a.79.79 0 0 0-.163-1.102.785.785 0 0 0-1.098.158l-8.195 10.966L9.4 15.278a.787.787 0 0 0-1.103-.124.786.786 0 0 0-.124 1.102L12.2 21.32c.15.189.376.295.616.295h.012a.78.78 0 0 0 .616-.316Z"
    />
  </Svg>
);

export default LeadershipYesComponent;
