import * as React from "react"
const TalentNoComponenet = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    {...props}
  >
    <path
      fill="#F2F4FF"
      d="M12.996 26c-4.13 0-7.343-1.078-9.547-3.204C1.16 20.592 0 17.299 0 13.006c0-4.294 1.16-7.59 3.45-9.798C5.652 1.082 8.87 0 12.995 0c4.131 0 7.343 1.078 9.551 3.204C24.84 5.412 26 8.71 26 13.002s-1.163 7.586-3.453 9.794C20.34 24.922 17.127 26 12.997 26Zm0-24.56C5.327 1.441 1.437 5.33 1.437 13.006c0 7.667 3.89 11.557 11.56 11.557 7.672 0 11.566-3.89 11.566-11.557 0-7.672-3.894-11.565-11.567-11.565Z"
    />
  </svg>
)
export default TalentNoComponenet