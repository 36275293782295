export const FetchEmployeeDetails = async (user_id, idToken) => {
  try {
    const response = await fetch(`/employeeDetails/${user_id}`, {
      method: 'GET',
      headers: {
        'x-msal-Authorization': `Bearer ${idToken}`,
      },
    });
    if (!response.ok) {
      throw new Error('Fallo en la solicitud de detalles del empleado');
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    //console.error('Error en la solicitud de detalles del empleado:', error.message);
    throw new Error(
      'Error en la solicitud de detalles del empleado: ' + error.message
    );
  }
};

