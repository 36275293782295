import '../Styles/sideBarMobile.css';
import { fetchClusterEmployeeIds } from "../../../services/TalentCards/FetchClusterEmployeeIds ";
import React, { useState } from 'react';
import Select from 'react-select';
import CryptoJS from 'crypto-js';


// Opciones de clúster para el dropdown
const clusterOptionsMobile = [
  { value: 'default', label: 'Selección' },
  { value: 'cluster_ceo', label: 'CEO' },
  { value: 'cluster_b2c_growth', label: 'B2C Growth' },
  { value: 'cluster_b2b_growth', label: 'B2B Growth' },
  { value: 'cluster_technology', label: 'Technology' },
  { value: 'cluster_enablers', label: 'Enablers' },
  { value: 'cluster_pm_transversal', label: 'Transversal' },

];


const SideBarComponentMobile = ({
  onClusterEmployeeIdChange,
  selectedOB,  
  setSelectedOB,
  obOptions,
  idToken
}) => {
  
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedClusters, setSelectedClusters] = useState([]);
  const placeholderOption = { value: 'seleccion', label: 'Selección...' };
  const dropdownOptions = [placeholderOption, ...obOptions];

  const secretKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyZ+9lyNZhaTreOLckdL1xCcDH239iceMybexkQcv1A+goLBJiaOf6OXl0XqIjOOTNvoEQyLpAn907jdS+z46dqzOOHFq1PdSu2gSHT9PSmzHSSshOboS0tEVGRdq+QAMN/+L4IycXw+pkXAWfGm+DT6Km028qY0x+5GYsztlCMqDyzYde91FdU0ceV7fePCdtSkPM4Cj47ru9/tqBPadkpEezb7ruoobQ3oCDRWi0vYiS1i18lDJWgf667627tJy4/GD5Br4XYFJ448Ntp4RT9mrekpkIafHZ2CP80hX4ug140UmSmM0bQfBqnUsRTm1ftJtuHHyBVOb5GG/pJfkFwIDAQAB'; 

  // Manejar el cambio en el dropdown de clúster
  const handleClusterChange = async (selectedOption) => {
    setSelectedClusters(selectedOption); 

    if (selectedOption && selectedOption.value !== "default") {
      const cluster = selectedOption.value;

      try {
        // Encriptar el clúster seleccionado
        const encrypted = CryptoJS.AES.encrypt(cluster, secretKey).toString();
        const encryptedCluster = encodeURIComponent(btoa(encrypted));
        // Obtener los IDs de empleados para el clúster encriptado
        const ids = await fetchClusterEmployeeIds(encryptedCluster, idToken);
        onClusterEmployeeIdChange(ids, true);
      } catch (error) {
        console.error('Error al obtener los IDs de empleados:', error);
        onClusterEmployeeIdChange([], false);
      }
    } else {
      // Si no se selecciona un clúster válido, limpiar los IDs
      onClusterEmployeeIdChange([], false);
    }
  };

  // Manejar el cambio en el dropdown de OBs
  const handleOBChange = (selectedOption) => {
  if (selectedOption?.value === 'seleccion') {
    setSelectedOB(null);
  }
  setSelectedOB(selectedOption || ''); 
};

  return (
    <div className="mobile-nav ">
      <div className="row">
        <div className="col-6 d-flex justify-content-center">
          <div className="dropdown-ob p-3 shadow rounded-4 mb-6" style={{ width: '190px', height: '100px' }}>
            <label className="ob-title form-label text-primary mb-0 fs-4">OB</label>
            <Select
                  options={dropdownOptions}
                  className="basic-multi-select fst-italic"
                  classNamePrefix="select"
                  placeholder="Selección..."
                  value={selectedOB}
                  onChange={handleOBChange}
                  menuPosition="fixed"
                  menuPortalTarget={document.body}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: 'auto',   
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      maxHeight: '40px',   
                      overflowY: 'auto',   
                    }),
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                    menu: base => ({ 
                      ...base, 
                      zIndex: 9999,
                      marginTop: 0,       
                    }),
                  }}
                />
          </div>
        </div>
        <div className="col-6 d-flex justify-content-center">
          <div className="dropdown-cluster p-3 shadow rounded-4" style={{ width: '190px', height: '100px' }}>
            <label className="cluster-title form-label text-primary mb-0 fs-4">Cluster</label>
            <Select
              isMulti={false}
              options={clusterOptionsMobile}
              className="basic-multi-select fst-italic"
              classNamePrefix="select"
              placeholder="Selección..." 
              value={selectedClusters} // Se utiliza el estado local para manejar las selecciones
              onChange={handleClusterChange}
              menuPosition="fixed"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                    control: (provided) => ({
                      ...provided,
                      minHeight: 'auto', 
                    }),
                  }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBarComponentMobile;
