import * as React from "react"
const DeleteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={57}
    height={57}
    fill="none"
    viewBox="0 0 57 57"
    {...props}
  >
    <circle cx={28.5} cy={28.5} r={28.5} fill="#06F" />
    <path
      fill="#F2F4FF"
      d="M26.346 26.615c.41 0 .747.304.8.699l.008.11v6.46a.808.808 0 0 1-1.608.11l-.008-.11v-6.46c0-.447.362-.809.808-.809ZM31.454 27.314a.808.808 0 0 0-1.608.11v6.46l.008.11a.808.808 0 0 0 1.608-.11v-6.46l-.008-.11Z"
    />
    <path
      fill="#F2F4FF"
      d="M33.61 20.366C33.526 19.056 32.487 18 31.192 18h-5.384l-.168.006c-1.27.09-2.255 1.2-2.255 2.532v1.77h-4.577l-.11.007a.808.808 0 0 0 .11 1.608h1.346V35.5a3.5 3.5 0 0 0 3.5 3.5h9.692a3.5 3.5 0 0 0 3.5-3.5V23.923h1.346l.11-.007a.808.808 0 0 0-.11-1.608h-4.577v-1.77l-.005-.172ZM32 22.308h-7v-1.77l.006-.118c.052-.462.402-.805.802-.805h5.384l.099.007c.39.056.709.435.709.916v1.77Zm-10.23 1.615h13.46V35.5a1.884 1.884 0 0 1-1.884 1.885h-9.692l-.155-.007a1.884 1.884 0 0 1-1.73-1.878V23.923Z"
    />
  </svg>
)
export default DeleteIcon