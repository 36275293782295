import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../Styles/Modals/clusterModal.css";

const ClusterEditModal = ({ isOpen, empleadoDetails, onSave, onClose }) => {
  const [clusters, setClusters] = useState({});

  useEffect(() => {
    if (empleadoDetails) {
      const initialClusters = Object.keys(empleadoDetails)
        .filter((key) => key.startsWith("cluster_"))
        .reduce((acc, key) => {
          acc[key] = !!empleadoDetails[key]; 
          return acc;
        }, {});
      setClusters(initialClusters);
    }
  }, [empleadoDetails]);

  const handleSwitchChange = (key) => {
    setClusters((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleSaveChanges = () => {
    const updatedData = { ...empleadoDetails, ...clusters };
    onSave(updatedData); 
    onClose(); 
  };

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Body>
        <div className="text-center mb-4">
          <h5>Clusters</h5>
        </div>
        <div className="table-responsive">
          <table className="table table-borderless">
            <tbody>
              {Object.keys(clusters).map((key) => (
                <tr key={key}>
                  <td>{key.replace("cluster_", "").toUpperCase()}</td>
                  <td>
                    <Form.Check
                      type="switch"
                      checked={clusters[key]}
                      onChange={() => handleSwitchChange(key)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-3">
          <Button
            className="btn-acept-cluster rounded-pill"
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            className="btn-delete-cluster rounded-pill"
            onClick={handleSaveChanges}
          >
            Guardar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ClusterEditModal;
