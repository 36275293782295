
import React from 'react';
import "../Styles/clusterMobileBlue.css"
import ArrowComponentMobileWhite from '../../assests/MobileIcons/ArrowComponentMobileWhite';

const ClusterComponentMobileBlue = ({  empleadoDetails, positions }) => {
  // Renombra la variable local para evitar conflictos
  return (
    <div className="cluster-mobile-blue">
      {positions.length <= 2 ? (
        positions.map(position => (
          <svg key={position.key} className="svg-Style-mobile-blue me-1">
            <rect
              x="0"
              y="0"
              width="160"
              height="50"
              className="rect-style-mobile-blue"
            />
            <g className='arrow-position-initial-blue' /* transform="translate(27,7)" */>
              <ArrowComponentMobileWhite  />
            </g>
            <text x="50%" y="50%" className="position-SVG-mobile-blue " dominantBaseline="middle" textAnchor="middle" 
            style={{fill: "white"}}>
              {position.label}
            </text>
          </svg>
        ))
      ) : (
        <div className="circles-container-mobile-blue mt-3 ">
          {positions.map(position => (
            <div key={position.key} className="circle-mobile-blue text-white">
              {position.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );



};


export default ClusterComponentMobileBlue;
