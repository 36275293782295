import React from "react";
import LeadershipYesComponent from "../../assests/FileComponentes/iconsComponent/LeadershipYesComponent";
import LeadershipNoComponent from "../../assests/FileComponentes/iconsComponent/LeadershipNoComponent";
import '../Styles/leaderShipTableMobile.css'

function LeadershipTableMobile({ leadershipData }) {
  // Función para renderizar los componentes de tick según la cantidad
  const renderTicks = (count) => {
    const ticks = [];
    for (let i = 0; i < 3; i++) {
      ticks.push(
        i < count ? (
          <LeadershipYesComponent key={i} style={{ marginLeft: '20px' }} />
        ) : (
          <LeadershipNoComponent key={i} style={{ marginLeft: '20px' }} />
        )
      );
    }
    return ticks;
  };

  const renderEmptyRow = () => (
    <tr style={{ height: '20px' }}>
      <td colSpan="2"></td>
    </tr>
  );

  return (
    <table className='leaderShip-table-mobile ms-2 '  >
      <tbody>
        <tr>
          <td className='title-cell-mobile impact-title-mobile ' >Impact & Execution for results</td>
          <td className='tick-cell-mobileInfo'>{renderTicks(leadershipData.leadership_impact)}</td>
        </tr>
        {renderEmptyRow()}
        <tr>
          <td className=' title-cell-mobile collab-title-mobile'>Collaborative</td>
          <td className='tick-cell-mobileInfo'>{renderTicks(leadershipData.leadership_collaboration)}</td>
        </tr>
        {renderEmptyRow()}
        <tr>
          <td className='title-cell-mobile transform-title-mobile'>Transformative</td>
          <td className='tick-cell-mobileInfo'>{renderTicks(leadershipData.leadership_transformation)}</td>
        </tr >
        {renderEmptyRow()}
        <tr>
          <td className='title-cell-mobile growth-title-mobile'>Growth</td>
          <td className='tick-cell-mobileInfo' >{renderTicks(leadershipData.leadership_growth)}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr className="mt-2">
        <td className="title-cell-mobile"></td>
          <td className="tick-cell-mobileInfo" style={{ textAlign: 'center' }}>
            <div className="mt-2">
              <span className="lagging-mobile" >Lagging</span>
              <span className="onTrack-mobile" >On track</span>
              <span className="exceed-mobile" >Exceed</span>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

export default LeadershipTableMobile;
