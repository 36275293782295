import * as React from "react"
const UruguayIconComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={800}
    height={800}
    viewBox="0 0 512 512"
    {...props}
  >
    <circle
      cx={256}
      cy={256}
      r={256}
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M256 189.217h247.181a254.559 254.559 0 0 0-28.755-66.783H256v66.783zM96.643 456.348h318.713a257.293 257.293 0 0 0 59.069-66.783H37.574a257.346 257.346 0 0 0 59.069 66.783zM256 0v55.652h159.357C371.627 20.824 316.249 0 256 0z"
      style={{
        fill: "#338af3",
      }}
    />
    <path
      d="M256 189.217h247.181a254.559 254.559 0 0 0-28.755-66.783H256v66.783zM0 256c0 23.107 3.08 45.489 8.819 66.783h494.363C508.92 301.489 512 279.107 512 256H0z"
      style={{
        fill: "#338af3",
      }}
    />
    <path
      d="m222.609 149.821-31.266 14.707 16.649 30.28-33.95-6.494-4.302 34.295-23.646-25.224-23.648 25.224-4.301-34.295-33.95 6.492 16.648-30.279-31.264-14.706 31.265-14.705-16.649-30.28 33.949 6.494 4.303-34.295 23.647 25.224 23.647-25.224 4.301 34.295 33.951-6.494-16.649 30.281z"
      style={{
        fill: "#ffda44",
      }}
    />
  </svg>
)
export default UruguayIconComponent
