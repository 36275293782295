import React from 'react';

const OthersViewnext = ({
  organizationalChangeStatus,
  setOrganizationalChangeStatus,
  salaryChangeStatus,
  setSalaryChangeStatus,
  locationChangeStatus,
  setLocationChangeStatus,
  contractChangeStatus,
  setContractChangeStatus,
}) => {
  const picklistOptions = ['Completado', 'En curso'];

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      {/* Cambios organizativos */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel' htmlFor='organizationalChangeStatus'>
          Cambios organizativos, rol o categoría
        </label>
        <select
          id='organizationalChangeStatus'
          className='formJiraChangeInputText'
          value={organizationalChangeStatus}
          onChange={(e) => setOrganizationalChangeStatus(e.target.value)}
        >
          <option value=''>Selecciona una opción</option>
          {picklistOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {/* Cambios salariales */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel' htmlFor='salaryChangeStatus'>
          Cambios salariales
        </label>
        <select
          id='salaryChangeStatus'
          className='formJiraChangeInputText'
          value={salaryChangeStatus}
          onChange={(e) => setSalaryChangeStatus(e.target.value)}
        >
          <option value=''>Selecciona una opción</option>
          {picklistOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {/* Cambios de localización */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel' htmlFor='locationChangeStatus'>
          Cambios de localización
        </label>
        <select
          id='locationChangeStatus'
          className='formJiraChangeInputText'
          value={locationChangeStatus}
          onChange={(e) => setLocationChangeStatus(e.target.value)}
        >
          <option value=''>Selecciona una opción</option>
          {picklistOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {/* Cambios de contrato */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel' htmlFor='contractChangeStatus'>
          Cambios de contrato
        </label>
        <select
          id='contractChangeStatus'
          className='formJiraChangeInputText'
          value={contractChangeStatus}
          onChange={(e) => setContractChangeStatus(e.target.value)}
        >
          <option value=''>Selecciona una opción</option>
          {picklistOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default OthersViewnext;
